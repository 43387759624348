import React from 'react';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';

import MetaItem from 'components/MetaItem';

const DueDate = () => {
    const issue = issuesList.issue;

    if (!issue.due_date) return null;
    const isISOTime = /\dT\d/.test(issue.due_date);
    const dateTime = isISOTime ? DateTime.fromISO(issue.due_date) : DateTime.fromSQL(issue.due_date);

    return <MetaItem title="Due date">{dateTime.toFormat(isISOTime ? 'DD' : 'DDD t')}</MetaItem>;
};

export default observer(DueDate);
