import { runInAction } from 'mobx';

import api from 'api';
import { failRequest } from 'utils';

import { IS_PUBLIC_BOARD } from 'utils/consts';

export async function getInviteSuggestions(abortController) {
    if (IS_PUBLIC_BOARD) return;

    try {
        const { data } = await api.get(`${this.apiEndpoint}/invite-suggestions`, {
            signal: abortController?.signal,
        });
        runInAction(() => {
            this.inviteSuggestions = data || [];
        });
    } catch (e) {
        if (e?.code === 'ERR_CANCELED') return;
        failRequest(e);
    }
}
