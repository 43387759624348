import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { CURRENT_USER_IS_ADMIN } from 'utils/consts';

import Avatar from 'components/Avatar';

export const CurrentUser = observer(({ large = false, size, className, withName }) => {
    const user = mainStore.currentUser;
    const avatarSize = size ? size : large ? 21 : 16;
    if (user) {
        return (
            <>
                <Avatar
                    className={className}
                    isAdmin={CURRENT_USER_IS_ADMIN}
                    user={user}
                    size={avatarSize}
                    hideStatus
                />
                {withName && <span>{user.name}</span>}
            </>
        );
    }
    return null;
});
