import React from 'react';

import { observer } from 'mobx-react-lite';

import criteriaStore from 'store/models/CriteriaStore';

import { StHr } from 'atoms/StHr';

import Flex from 'components/Flex';
import Space from 'components/Space';

import { AlignCriterionRow } from './AlignCriterionRow';

export const UsersVotesTableTooltipContent = observer(({ issue, crId }) => {
    const list = issue.getVotesForAlignmentTooltip(crId);

    if (issue.all_voted && !list.length) {
        return 'Task is evaluated by all teammates';
    }

    const criterion = criteriaStore.criteriaIds.get(crId);
    const value = issue.externalData?.criteriaAlignmentIds?.get(crId);

    const fieldRed = 'alignmentRed';
    const fieldYellow = 'alignmentYellow';
    const alignmentRed = issue.board.settings[fieldRed];
    const alignmentYellow = issue.board.settings[fieldYellow];

    const isDanger = value >= 0 && value <= alignmentRed;
    const isWarning = value && value > alignmentRed && value <= alignmentYellow;

    return (
        <div style={{ padding: '8px 4px' }}>
            <p className="t-w-sb">{criterion?.name}</p>
            {list.length > 0 && (
                <div className="table-wrpp">
                    <table className="cr-table">
                        <tbody>
                            {list.map((item, i) => (
                                <AlignCriterionRow showOld key={i} issue={issue} criterion={item} />
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <Space height={8} />
            <StHr />
            <Space height={8} />
            <Flex gap={16} className="t-w-sb">
                <span className="flex-grow flex-shrink">
                    {(isDanger && 'Low Alignment') || (isWarning && 'Medium Alignment') || 'High Alignment'}
                </span>
                <span className="flex-shrink">{value}/100</span>
            </Flex>
        </div>
    );
});
