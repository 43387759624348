import React from 'react';

import { Position, Toaster, Intent } from '@blueprintjs/core';

const Notifier = Toaster.create();

/**
 *
 * @param {Object} props
 * @param {number} [props.timeout]
 * @param {string} [props.text]
 * @param {string|null} [props.icon]
 * @param {Function|null} [props.callbacks]
 * @param {string} [props.type] - none, success, warning, error, primary
 * @param {string} [props.position] - bottom, bottom-left, bottom-right, left, left-bottom, left-top, right,right-bottom,right-top,top,top-left,top-right
 */
export default function Notification(props = {}) {
    props = props || {};
    const timeout = props.timeout || 0;
    const position = props.position || Position.TOP;
    const message = props.message || '';
    const icon = props.icon || null;
    const intent = props.type || Intent.NONE;

    let onDismiss = () => {};
    if (typeof props.callbacks === 'function') {
        onDismiss = props.callbacks;
    }

    const show = () => {
        const htmlText = typeof message === 'string' ? <div dangerouslySetInnerHTML={{ __html: message }} /> : message;
        return Notifier.show({ position, message: htmlText, timeout, intent, icon, onDismiss }, props.key);
    };

    return {
        show: show,
    };
}
