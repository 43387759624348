import { styled } from 'stitches.config';

import TextEditor from 'components/TextEditor';

export const StTextEditor = styled(TextEditor, {
    padding: '2px !important',
    boxShadow: '0 0 0 1px $colors$grayA4 inset',
    borderRadius: '6px !important',

    '&:hover': {
        boxShadow: '0 0 0 1px $colors$grayA6 inset',
    },

    '.ProseMirror': {
        borderRadius: 6,
        padding: '6px 8px',
        minHeight: 37,
    },

    '&[data-size="l"]': {
        '.ProseMirror': {
            minHeight: 70,
        },
    },

    variants: {
        error: {
            true: {
                '&, &:hover, &:focus-within': {
                    boxShadow: '0 0 0 1px $colors$tomato8 inset',
                    '.ProseMirror': {
                        outline: 'none',
                    },
                },
            },
        },
        clear: {
            true: {
                '&,&:hover': {
                    boxShadow: 'none !important',
                },
            },
        },
        isComment: {
            true: {
                '.ProseMirror': {
                    minHeight: 30,
                    padding: '6px 8px',
                },
            },
        },
    },
});
