import { runInAction } from 'mobx';

import { rootApi } from 'api';

import failRequest from 'utils/failRequest';

export async function fetchNotificationsSettings() {
    try {
        const { data } = await rootApi.get('/notifications/subscriptions');
        runInAction(() => {
            this.notificationsSettings = data;
        });
    } catch (e) {
        failRequest(e);
    }
}
