import { yellowDarkA } from '@radix-ui/colors';

export const PRIMARY = {
    ...yellowDarkA,
    primary3: '$yellowA3',
    primary4: '$yellowA4',
    primary5: '$yellowA5',
    primary6: '$yellowA6',
    primary7: '$yellowA7',
    primary8: '$yellowA8',
    primary9: '$yellowA9',
    primary11: '$yellowA11',
    primary12: '$yellowA12',
};

export const PRIMARY_DARK = {
    primary3: '$yellow3',
    primary4: '$yellow4',
    primary5: '$yellow5',
    primary6: '$yellow6',
    primary7: '$yellow7',
    primary8: '$yellow8',
    primary9: '$yellow9',
    primary11: '$yellow11',
    primary12: '$yellow12',
};

export const PRIMARY_LIGHT = {
    primary3: '$amber3',
    primary4: '$amber4',
    primary5: '$amber5',
    primary6: '$amber6',
    primary7: '$amber7',
    primary8: '$amber8',
    primary9: '$amber9',
    primary11: '$amber11',
    primary12: '$amber12',
};

export const PRIMARY_VIOLET = {
    primary3: '$violetA3',
    primary4: '$violetA4',
    primary5: '$violetA5',
    primary6: '$violetA6',
    primary7: '$violetA7',
    primary8: '$violetA8',
    primary9: '$violetA9',
    primary11: '$violetA11',
    primary12: '$violetA12',
};
