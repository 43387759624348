import React from 'react';

import { observer } from 'mobx-react-lite';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Spinner from 'components/Spinner';
import TableSearch from 'components/TableSearch';
import Tooltip from 'components/Tooltip';

const VotersListDialogHeader = ({ query, setQuery, onClose, idea, loading }) => {
    return (
        <Flex gap={20} className="t-mutted">
            <Flex gap={4}>
                <CustomIcon icon={CustomIconName.LAMP} />
                <div className="t-crop">{idea.name}</div>
            </Flex>
            <Flex gap={4} className="flex-shrink">
                <CustomIcon icon={CustomIconName.TEAM_ALT} />
                <div>Voters’ List</div>
                {loading && <Spinner size={16} />}
            </Flex>

            <div className="flex-grow" />
            <Flex shrink gap={8} css={{ margin: 'auto 0 0 auto' }}>
                <TableSearch query={query} onSearch={setQuery} tooltip="Search by Users" />
                <Tooltip content="Close Voters’ List" side="bottom">
                    <Button border minimal size={24} onClick={onClose} icon={CustomIconName.X_CROSS} />
                </Tooltip>
            </Flex>
        </Flex>
    );
};

export default observer(VotersListDialogHeader);
