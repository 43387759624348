import React from 'react';

import { observer } from 'mobx-react-lite';

import { Idea } from 'store/models/Idea';

import { PROVIDER_DUCALIS } from 'utils/consts';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import InputEditor from 'components/InputEditor';
import Space from 'components/Space';

import styles from './Name.module.sass';

import PlatformIcon from '../PlatformIcon';

function IssueName({ onChange, issue, editable = false, single, placeholder, autoFocus, onBlur }) {
    const subtaskIcon = issue.subtask && (
        <>
            <CustomIcon className="o-6" icon={CustomIconName.SUBTASK} />
            <Space width={4} />
        </>
    );

    const trackerIcon = issue.provider !== PROVIDER_DUCALIS && (
        <>
            <PlatformIcon provider={issue.provider} className="o-6" />
            <Space width={4} />
        </>
    );

    if (single) {
        return (
            <h2 className={styles.name}>
                {trackerIcon}
                {subtaskIcon}
                {issue.fullName}
            </h2>
        );
    }

    function updateName(name) {
        if (name !== issue.name) {
            issue.update({ name, needFocus: undefined });
        }
        const el = document.querySelector('.ProseMirror');
        if (issue instanceof Idea && el?.editor) {
            el?.editor.commands.focus('start');
        }
        onBlur && onBlur();
    }

    let title;
    if (editable) {
        return (
            <InputEditor
                isEditing={autoFocus}
                editableTextProps={{
                    confirmOnEnterKey: true,
                    tabIndex: 2,
                    placeholder,
                    large: true,
                    border: true,
                    css: { fontWeight: 'bold' },
                    minWidth: 10,
                }}
                onChange={onChange}
                multiline
                text={issue.name !== null ? issue.name ?? '' : ''}
                onDone={updateName}
            />
        );
    } else if (issue._links && issue._links.url) {
        title = (
            <a href={issue._links && issue._links.url} rel="noopener noreferrer" target="_blank">
                {trackerIcon}
                {subtaskIcon}
                <span className="link">{issue.fullName.trim()}</span>
            </a>
        );
    } else {
        title = issue.fullName;
    }

    return (
        <h2 itemProp="headline" className={styles.name}>
            {title}
        </h2>
    );
}

export default observer(IssueName);
