import React, { useState } from 'react';

import logEvent from 'utils/logEvent';

import { ImageEl } from './';

export function ImgThumb({ small, children, thumbnail, index, onClick, alt }) {
    const [disabled, setDisabled] = useState(false);

    function toggleLightbox() {
        if (disabled) {
            return null;
        }
        onClick(+index);
        logEvent('Open issue image preview');
    }

    return (
        <ImageEl
            small={small}
            disableWrapper={() => setDisabled(true)}
            thumbnail={thumbnail}
            onClick={toggleLightbox}
            alt={alt}
            key={thumbnail}
            aria-disabled={disabled}
        >
            {children}
        </ImageEl>
    );
}
