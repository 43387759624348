import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { IS_PUBLIC_BOARD } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Kbd from 'components/Kbd';

export const WatchContextMenuButton = observer(({ issue }) => {
    if (IS_PUBLIC_BOARD) return null;

    const isWatcher = issue.watchers.includes(mainStore.currentUser?.id);
    const icon = isWatcher ? CustomIconName.EYE_SLASH : CustomIconName.EYE;
    const text = isWatcher ? 'Stop Watch' : 'Start Watch';

    return (
        <Button
            block
            className="bp5-popover-dismiss"
            minimal
            text={text}
            icon={icon}
            rightElm={<Kbd>W</Kbd>}
            onClick={() => issue.changeWatcher(mainStore.currentUser, !isWatcher)}
        />
    );
});
