import React, { forwardRef } from 'react';

import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';

import { keyframes, styled } from 'stitches.config';

const SCROLLBAR_SIZE = 7;

const StyledScrollArea = styled(ScrollAreaPrimitive.Root, {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: 'inherit',
});

const StyledViewport = styled(ScrollAreaPrimitive.Viewport, {
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',

    '&>div': {
        display: 'flex !important',
        flexDirection: 'column',
        minHeight: '100%',
    },
});

const overlayShow = keyframes({
    '0%': { opacity: 0 },
    '100%': { opacity: 0.5 },
});

const overlayHide = keyframes({
    '0%': { opacity: 0.5 },
    '100%': { opacity: 0 },
});

const StyledScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
    display: 'flex',
    userSelect: 'none',
    touchAction: 'none',
    padding: 1,
    zIndex: 10,
    opacity: 0.5,
    animation: `${overlayShow} 300ms cubic-bezier(0.16, 1, 0.3, 1)`,

    '&[data-orientation="vertical"]': {
        width: SCROLLBAR_SIZE,
    },

    '&[data-orientation="horizontal"]': {
        flexDirection: 'column',
        height: SCROLLBAR_SIZE,
    },

    '&[data-state="hidden"]': {
        animation: `${overlayHide} 270ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },

    '&:hover': {
        transition: 'all .3s',
        opacity: 1,
    },
});

const StyledThumb = styled(ScrollAreaPrimitive.Thumb, {
    flex: 1,
    background: '$grayA6',
    borderRadius: SCROLLBAR_SIZE,
    position: 'relative',

    '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        minWidth: 16,
        minHeight: 16,
    },
});

const StyledCorner = styled(ScrollAreaPrimitive.Corner, {});

// Exports
export const ScrollArea = StyledScrollArea;
export const ScrollAreaViewport = StyledViewport;
export const ScrollAreaScrollbar = StyledScrollbar;
export const ScrollAreaThumb = StyledThumb;
export const ScrollAreaCorner = StyledCorner;
const Scroll = forwardRef(({ children, ...props }, ref) => (
    <ScrollArea scrollHideDelay={150} {...props}>
        <ScrollAreaViewport ref={ref}>{children}</ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="vertical">
            <ScrollAreaThumb />
        </ScrollAreaScrollbar>
        <ScrollAreaScrollbar orientation="horizontal">
            <ScrollAreaThumb />
        </ScrollAreaScrollbar>
        <ScrollAreaCorner />
    </ScrollArea>
));
export default Scroll;
