import React from 'react';

import { observer } from 'mobx-react-lite';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

const DoneVotesCount = ({ issue }) => {
    const { userLimit, votesCount } = issue;

    return (
        <Button
            size={28}
            border
            tabIndex={-1}
            disabled
            color={userLimit > 0 ? 'accent' : undefined}
            icon={CustomIconName.UPVOTE}
            text={votesCount || 0}
        />
    );
};

export default observer(DoneVotesCount);
