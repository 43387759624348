import { storageApi } from 'api';
import { failRequest } from 'utils';

export async function fetchWatchers() {
    try {
        const { data } = await storageApi.get(`/issues-watchers`);
        window.debug && window.debug('before fetchWatchers');
        this.fillWatchers(data);
        window.debug && window.debug('after fetchWatchers');
    } catch (e) {
        failRequest(e);
    }
}
