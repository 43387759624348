import { Intent } from '@blueprintjs/core';

import validateVote, { prepareNumber, validateNumber, getVoteError } from 'utils/validateVote';

import { errorSingleNotification } from 'components/AppToaster';

import { focusNext } from './focusNext';

export const onChange = (issue, criterion, setFocus, singleFastInput = false, setInputValue) => {
    return (event) => {
        const value = event.target.value;
        if (validateVote(value, criterion)) {
            setInputValue(prepareNumber(value));
            if (singleFastInput && validateNumber(value, criterion)) {
                const tabIndex = +event.target.getAttribute('tabindex');
                const selector = `[tabindex="${tabIndex + 1}"]`;
                const el = document.querySelector(selector);

                focusNext(event, el);

                if (selector) {
                    event.target.blur();
                }
                setFocus(false);
            }
        } else {
            errorSingleNotification.show({
                maxToasts: 1,
                message: getVoteError(criterion),
                intent: Intent.DANGER,
                timeout: 1000,
            });
        }
    };
};
