import React from 'react';

import { observer } from 'mobx-react-lite';

import IdeaStripe from 'modules/InsideIssue/components/IdeaStripe';
import PublicIdeaStripe from 'modules/PublicIdeaStripe';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

function IssueIdeasGroups() {
    if (!issuesList.issue || issuesList.issue.ideas.length === 0) {
        return <IdeaStripe />;
    }

    return (
        <>
            <IdeaStripe />
            {issuesList.issue.ideas.map((idea) =>
                issuesList.idea?.id === idea.id ? null : (
                    <PublicIdeaStripe key={idea.id} showVotes idea={idea} editable={!mainStore.currentUser?.isViewer} />
                )
            )}
        </>
    );
}

export default observer(IssueIdeasGroups);
