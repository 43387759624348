import React from 'react';

import IssueName from 'components/IssueName';

import IssueEpic from './IssueEpic';
import IssueHeader from './IssueHeader';

export function FocusModeHeader({ issue }) {
    return (
        <IssueHeader>
            <IssueEpic {...issue} />
            <IssueName issue={issue} single />
        </IssueHeader>
    );
}
