import React from 'react';

import { observer } from 'mobx-react-lite';

import { UNSAVED_MODEL_ID } from 'utils/consts';

import DictionaryValueItem from 'components/DictionaryValueItem';
import Flex from 'components/Flex';

import AnnounceLabelsMultiselect from './AnnounceLabelsMultiselect';

function AnnounceLabels({ announce, editable }) {
    if (announce.id === UNSAVED_MODEL_ID) {
        return null;
    }

    if (editable) {
        return <AnnounceLabelsMultiselect announce={announce} />;
    }

    const list = announce.labelsList.map((label, i) => <DictionaryValueItem isVoting tag {...label} key={i} isLabel />);

    return (
        <Flex gap={8} wrap>
            {list}
        </Flex>
    );
}

export default observer(AnnounceLabels);
