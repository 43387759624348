import React from 'react';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';

export default function DropDownLink({ onClick, title, icon, open }) {
    return (
        <Button
            text={title}
            icon={icon}
            rightElm={<CustomIcon icon={open ? CustomIconName.CHEVRON_DOWN : CustomIconName.CHEVRON_RIGHT} size={14} />}
            border
            size={24}
            onClick={() => onClick((state) => !state)}
        />
    );
}
