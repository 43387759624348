import React, { useRef, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { utilsStore } from 'store/models/UtilsStore';

import { CustomIconName } from 'components/CustomIcon';
import insertFilesIntoView from 'components/TextEditor/insertFilesIntoView';
import Tooltip from 'components/Tooltip';

import ToolBarButton from './ToolBarButton';

export default function FileUploaderBtn({ editor }) {
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false);

    function onButtonClick() {
        inputRef.current?.click();
    }

    async function addImageFromInput(files) {
        const filesList = Array.from(files);
        if (filesList.length === 0) return;

        const formData = new FormData();
        filesList.forEach((file) => formData.append('file[]', file));

        try {
            setLoading(true);
            await insertFilesIntoView(editor.view, formData);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Tooltip
                content={<FormattedMessage id="btn.attach-file" defaultMessage="Attach file" />}
                side="top"
                align="end"
            >
                <ToolBarButton
                    text={
                        utilsStore.isMobile ? (
                            <FormattedMessage id="btn.attach-file" defaultMessage="Attach file" />
                        ) : undefined
                    }
                    minimal={!utilsStore.isMobile}
                    border={!utilsStore.isMobile}
                    onClick={onButtonClick}
                    loading={loading}
                    icon={CustomIconName.ATTACH}
                />
            </Tooltip>
            <input
                ref={inputRef}
                disabled={loading}
                style={{ display: 'none' }}
                id="file-input"
                multiple
                type="file"
                onChange={(e) => addImageFromInput(e.target.files)}
            />
        </>
    );
}
