import { ENTER, DOWN, UP, RIGHT, LEFT, TAB } from 'utils/consts';
import { getCaretPosition } from 'utils/getCaretPosition';
import getKeyCode from 'utils/getKeyCode';

export function getNextSelector(event, rowIndex, columnIndex, focus, isCurentValue) {
    const keyCode = isCurentValue ? ENTER : getKeyCode(event);
    const tabIndex = +event.target.getAttribute('tabindex');
    const { isStart, isEnd } = getCaretPosition(event);
    const isEmpty = event.target.value === '';

    switch (keyCode) {
        case TAB:
            return (event.shiftKey && `[tabindex="${tabIndex - 1}"]`) || null;
        case DOWN:
            return `#i${rowIndex + 1}${columnIndex}`;
        case UP:
            return `#i${rowIndex - 1}${columnIndex}`;
        case RIGHT:
            if (!event.ctrlKey && (event.altKey || !focus || isEnd || isEmpty)) {
                return `[tabindex="${tabIndex + 1}"]`;
            }
            return null;
        case ENTER:
            return `[tabindex="${tabIndex + 1}"]`;
        case LEFT:
            if (event.altKey || !focus || isStart || isEmpty) {
                return `[tabindex="${tabIndex - 1}"]`;
            }
            return null;
        default:
            return null;
    }
}
