import React, { useState } from 'react';

import { Popover } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { POPOVER_PROPS_DIV } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

import AnnounceMenuContent from './AnnounceMenuContent';

function AnnounceMenu({ announce }) {
    const [open, setOpen] = useState(false);

    return (
        <div className="flex-shrink">
            <Popover
                {...POPOVER_PROPS_DIV}
                usePortal={false}
                minimal
                position="bottom-right"
                content={<AnnounceMenuContent announce={announce} />}
                isOpen={open}
                onClose={() => setOpen(false)}
            >
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpen((state) => !state);
                    }}
                    block
                    border
                    minimal
                    icon={CustomIconName.DOTES_H}
                    size={24}
                />
            </Popover>
        </div>
    );
}

export default observer(AnnounceMenu);
