import { Intent } from '@blueprintjs/core';

import validateVote, { getVoteError } from 'utils/validateVote';

import { showToaster } from 'components/AppToaster';

export const onBlur = (event, criterion, issue, crCnt, setFocus, setSelected, oldValue, setVote) => {
    setFocus(false);
    setSelected(false);

    const value = event.target.value;

    if (validateVote(value || '', criterion, true)) {
        setVote(value);
        const old = oldValue !== null ? oldValue.toString() : oldValue;
        if (old !== value) {
            issue.vote({ criterion, vote: value }, true);
        }
    } else {
        showToaster({
            message: getVoteError(criterion),
            intent: Intent.DANGER,
            timeout: 4000,
        });
        event.target.focus();
        return false;
    }
};
