import React from 'react';

import { observer } from 'mobx-react-lite';

import useVotingAuthor from 'hooks/useVotingAuthor';

import Avatar from 'components/Avatar';
import Flex from 'components/Flex';
import Tooltip from 'components/Tooltip';

import styles from './IssueCommentAuthor.module.sass';

function IssueCommentAuthor({ users, author, usersList, isIdea }) {
    const desc = useVotingAuthor(author, users?.[0]?.user, isIdea);

    if (!author && (!users || users.length === 0)) {
        return null;
    }

    const avatars = [];
    if (author) {
        const resolved = (usersList && usersList.find(({ user_id }) => user_id === author.id)?.resolved) || false;
        avatars.push(
            <div data-resolved={resolved} key={author.id || author.name}>
                <Avatar hideStatus={resolved} size={isIdea ? 28 : 20} user={author} />
            </div>,
        );
    }

    if (users) {
        users.forEach(
            ({ resolved, user }) =>
                user &&
                avatars.push(
                    <Tooltip
                        side="top"
                        key={user.id}
                        content={resolved ? `${user.name} has resolved the question` : user.name}
                    >
                        <div data-resolved={resolved}>
                            <Avatar hideStatus={resolved} size={isIdea ? 28 : 20} user={user} />
                        </div>
                    </Tooltip>,
                ),
        );
    }

    return (
        <Flex gap={8} itemProp="author" itemType="https://schema.org/Person" itemScope>
            <div className={styles.avatars}>{avatars}</div>
            <span className={styles.meta}>{desc}</span>
        </Flex>
    );
}

export default observer(IssueCommentAuthor);
