import { runInAction } from 'mobx';

import api from 'api';

import { IS_VOTING_BOARD } from 'utils/consts';
import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl, { VALUE_NULL } from 'utils/toUrl';

export async function setVotingSettings(settings) {
    const requestData = toUrl(settings);

    runInAction(() => {
        if (settings?.access_domains && settings.access_domains === VALUE_NULL) {
            settings.access_domains = [];
        }
        this.voting_settings = { ...this.voting_settings, ...settings };

        if (IS_VOTING_BOARD && (settings.name || settings.emoji)) {
            settings.name && (this.name = settings.name);
            settings.emoji && (this.emoji = settings.emoji);
        }
    });
    logEvent('Board change Voting Settings', { ...settings });

    try {
        const { data } = await api.put(`${this.apiEndpoint}/voting-settings`, requestData);
        IS_VOTING_BOARD && this.updateVotingSettings(data.voting_settings);
    } catch (error) {
        failRequest(error);
        await Promise.reject(error);
    }
}
