import React from 'react';

import { observer } from 'mobx-react-lite';

import IssueComment from 'modules/IssueComment';

function IssueComments({ comments = [] }) {
    if (!comments || comments.length === 0) {
        return null;
    }
    return comments.map((comment) => <IssueComment key={comment.id} comment={comment} />);
}

export default observer(IssueComments);
