import React from 'react';

import { EMPTY_ARRAY } from 'utils/consts';

import MetaItem from 'components/MetaItem';

export default function AsanaProjects({ issue, onlyList }) {
    if ((issue.projects_sections || EMPTY_ARRAY).length === 0) {
        return null;
    }

    if (onlyList) {
        return issue.projects_sections.map((el) => el.name).join(', ');
    }

    return (
        <>
            {issue.projects_sections.map((project, i) => (
                <MetaItem key={i} title="Project:" inline>
                    <a
                        className="o-6 bp5-text-overflow-ellipsis t-w-sb"
                        target="_blank"
                        href={project.url}
                        key={i}
                        style={{ color: 'inherit' }}
                        title={`${project.name} / ${project.section}`}
                    >
                        {`${project.name} / ${project.section}`}
                    </a>
                </MetaItem>
            ))}
            <div />
        </>
    );
}
