import React from 'react';

import { StButton } from 'atoms/StButton';

import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';

import styles from './CustomCallout.module.sass';

function CustomCallout({ children, icon, actions, onClose, leftElm, css, ...props }) {
    function onAnalyticsLink(e) {
        if (e.target?.tagName === 'A') {
            logEvent('Click on Board Callout', { text: e.target.textContent });
        }
    }

    return (
        <StButton
            size={42}
            block
            as="div"
            border
            css={{ lineHeight: 1.33, height: 'auto', minHeight: 42, paddingTop: 6, paddingBottom: 6, ...css }}
            {...props}
        >
            {icon && <CustomIcon className={props.color ? undefined : 't-intent-warning'} icon={icon} />}
            {leftElm}
            <div className="flex-grow t-l" onClick={onAnalyticsLink}>
                {children}
            </div>
            <Flex shrink gap={20} className={styles.actions}>
                {actions}
                {onClose && (
                    <Button
                        className={styles.closeBtn}
                        onClick={onClose}
                        icon={CustomIconName.CROSS_M}
                        minimal
                        size={24}
                        border
                    />
                )}
            </Flex>
        </StButton>
    );
}

export default CustomCallout;
