import React from 'react';

import { observer } from 'mobx-react-lite';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import { mainStore } from 'store/models/MainStore';

import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import CellWrapper from 'components/CellWrapper';
import CriterionDesc from 'components/CriterionDesc/CriterionDesc';
import { CustomIconName } from 'components/CustomIcon';
import ColumnDropDown from 'components/MasterTable/components/ColumnDropDown';
import { HeaderColumnIcon } from 'components/MasterTable/components/HeaderCell/HeaderColumnIcon';
import Tooltip from 'components/Tooltip';

import styles from './CriteriaHeaderCell.module.sass';

function CriteriaHeaderCell({ column, showTooltips, ...props }) {
    let title;
    if (column.type !== 'input' || (!showTooltips && !!mainStore.activeBoard)) {
        title = (
            <Tooltip
                disableHoverableContent={false}
                content={<CriterionDesc criterionId={column.criterion_id} boardId={mainStore.activeBoard?.id} />}
                side="bottom"
            >
                <span className="ReactVirtualized__Table__headerTruncatedText">{column.name}</span>
            </Tooltip>
        );
    } else {
        title = <span className="ReactVirtualized__Table__headerTruncatedText">{column.name}</span>;
    }

    function openCriterion() {
        settingsStore.goToLink(`/board/${mainStore.activeBoard.id}/criteria/${column.criterion_id}`);
        logEvent('Click - Criteria Header Cell', { name: column.name });
    }

    const button = (
        <ColumnDropDown field={column} {...props}>
            {mainStore.activeBoard && mainStore.currentUser?.isAdmin && (
                <Button minimal block onClick={openCriterion} icon={CustomIconName.EDIT} text="Edit criterion" />
            )}
        </ColumnDropDown>
    );

    return (
        <CellWrapper className={styles.cell} button={button}>
            {column.type !== 'input' ? (
                <HeaderColumnIcon
                    tooltip={
                        <>
                            Criteria Column:
                            <br />
                            <b>{column.name}</b>
                        </>
                    }
                    icon={CustomIconName.CRITERIA}
                />
            ) : null}
            {title}
        </CellWrapper>
    );
}

export default observer(CriteriaHeaderCell);
