import { Plugin } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

export const placeholderPlugin = new Plugin({
    widget: null,
    setWidth(value) {
        if (!this.widget && !this.widget.style) return;
        this.widget.style.width = `${value}%`;
    },
    state: {
        init() {
            return DecorationSet.empty;
        },
        apply(tr, set) {
            set = set.map(tr.mapping, tr.doc);
            let action = tr.getMeta(this);
            if (action && action.add) {
                let progressBar = document.createElement('div');
                progressBar.className = 'bp5-progress-bar bp5-progress-bar--image';
                this.widget = document.createElement('div');
                this.widget.className = 'bp5-progress-meter';
                this.widget.style.width = '5%';
                progressBar.appendChild(this.widget);
                let deco = Decoration.widget(action.add.pos, progressBar, { id: action.add.id });
                set = set.add(tr.doc, [deco]);
            } else if (action && action.remove) {
                const placeholder = set.find(null, null, (spec) => spec.id === action.remove.id);
                if (placeholder) {
                    set = set.remove(placeholder);
                }
            }
            return set;
        },
    },
    props: {
        decorations(state) {
            return this.getState(state);
        },
    },
});
