import { CustomIconName } from 'components/CustomIcon';

export const TOOLBAR_ACTIONS = {
    headers: 'headers',
    bold: 'bold',
    italic: 'italic',
    strike: 'strike',
    bulletList: 'bulletList',
    orderedList: 'orderedList',
    code: 'code',
    line: 'line',
    link: 'link',
    image: 'image',
    clear: 'clear',
};

export const TOOLBAR_ACTIONS_PROPS = {
    [TOOLBAR_ACTIONS.headers]: {
        icon: CustomIconName.HEADER,
        title: 'Heading',
    },
    [TOOLBAR_ACTIONS.bold]: {
        title: 'Bold',
        icon: CustomIconName.BOLD,
        method: 'toggleBold',
        incompatible: ['code'],
    },
    [TOOLBAR_ACTIONS.italic]: {
        title: 'Italic',
        icon: CustomIconName.ITALIC,
        method: 'toggleItalic',
        incompatible: ['code'],
    },
    [TOOLBAR_ACTIONS.strike]: {
        title: 'Strike',
        icon: CustomIconName.STRIKE,
        method: 'toggleStrike',
        incompatible: ['code'],
    },
    [TOOLBAR_ACTIONS.bulletList]: {
        title: 'Bullet list',
        icon: CustomIconName.BULLET_LIST,
        method: 'toggleBulletList',
    },
    [TOOLBAR_ACTIONS.orderedList]: {
        title: 'Ordered list',
        icon: CustomIconName.NUMBER_LIST,
        method: 'toggleOrderedList',
    },
    [TOOLBAR_ACTIONS.code]: {
        title: 'Code',
        icon: CustomIconName.CODE,
        method: 'toggleCode',
        incompatible: ['bold', 'italic', 'underline', 'strike', 'link'],
    },
    [TOOLBAR_ACTIONS.line]: {
        title: 'Line',
        icon: CustomIconName.LINE,
        method: 'setHorizontalRule',
    },
    [TOOLBAR_ACTIONS.link]: {
        title: 'Link',
        icon: CustomIconName.LINK,
    },
    [TOOLBAR_ACTIONS.image]: {
        title: 'Image',
        icon: CustomIconName.IMAGE,
    },
    [TOOLBAR_ACTIONS.clear]: {
        icon: CustomIconName.NO_FORMAT,
        title: 'Clear format',
    },
};

export const TOOLBAR_ACTIONS_INCOMPATIBLE = {
    [TOOLBAR_ACTIONS.bold]: ['code'],
    [TOOLBAR_ACTIONS.italic]: ['code'],
    [TOOLBAR_ACTIONS.strike]: ['code'],
    [TOOLBAR_ACTIONS.code]: ['bold', 'italic', 'underline', 'strike', 'link'],
};

export const TOOLBAR_ACTIONS_METHODS = {
    [TOOLBAR_ACTIONS.bold]: 'toggleBold',
    [TOOLBAR_ACTIONS.italic]: 'toggleItalic',
    [TOOLBAR_ACTIONS.strike]: 'toggleStrike',
    [TOOLBAR_ACTIONS.bulletList]: 'toggleBulletList',
    [TOOLBAR_ACTIONS.orderedList]: 'toggleOrderedList',
    [TOOLBAR_ACTIONS.code]: 'toggleCode',
    [TOOLBAR_ACTIONS.line]: 'setHorizontalRule',
};

export const DEFAULT_TOOLBAR_STATE = [
    [TOOLBAR_ACTIONS.headers, TOOLBAR_ACTIONS.bold, TOOLBAR_ACTIONS.italic, TOOLBAR_ACTIONS.strike],
    [TOOLBAR_ACTIONS.bulletList, TOOLBAR_ACTIONS.orderedList, TOOLBAR_ACTIONS.code],
    [TOOLBAR_ACTIONS.line, TOOLBAR_ACTIONS.link],
    [TOOLBAR_ACTIONS.clear],
];
