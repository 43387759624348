import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import { styled } from 'stitches.config';

import InsideIssueAnnounce from 'modules/IdeaAnnounce/InsideIssueAnnounce';
import Questions from 'modules/Questions';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { StHr } from 'atoms/StHr';

import { IS_PUBLIC_BOARD, IS_VOTING_BOARD, PAYWALL_LIMITS, UNSAVED_MODEL_ID, VIEWS } from 'utils/consts';

import CreatedField from 'components/CreatedField';
import DictionaryRemoveAlert from 'components/DictionaryRemoveAlert';
import Flex from 'components/Flex';
import IssueDescription from 'components/IssueDescription';
import NoneJiraAttachments from 'components/NoneJiraAttachments';
import QButton from 'components/QButton';
import Space from 'components/Space';

import * as InsideIssueComponent from './components';
import Blocks from './components/Blocks/Blocks';
import BlocksOmniBar from './components/BlocksOmnibar/BlocksOmnibar';
import HeaderMeta from './components/HeaderMeta';
import IssueHotkeys from './components/HeaderMeta/IssueHotkeys';
import IssueHeader from './components/IssueHeader';
import IssueIdeasGroups from './components/IssueIdeasGroups';
import RemoveAction from './components/RemoveAction';
import Reporter from './components/Reporter';
import Title from './components/Title';
import UnlinkedPublicIdea from './components/UnlinkedPublicIdea/UnlinkedPublicIdea';
import Watchers from './components/Watchers';
import IssueMatrixData from './IssueMatrixData';

const StFlexWrapper = styled('div', {
    padding: '0 16px',

    variants: {
        extraSpace: {
            true: {
                paddingBottom: 60,
            },
        },
    },
});

function InsideIssue({ isPreview, showQuestionBtn, onHide }) {
    const idea = issuesList.idea;
    const issue = issuesList.issue;

    useEffect(() => {
        !IS_PUBLIC_BOARD && issue?.analyticsRead();
        return () => {
            issue?.id === UNSAVED_MODEL_ID && issuesList.clearUnsaved();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!issuesList.activeIssue?.id || issuesList.activeIssue?.isRemoved) {
        return null;
    }

    if (idea?.id && !issue?.id) {
        return <UnlinkedPublicIdea onHide={onHide} />;
    }

    const hasAlignment = mainStore.showFinalScoreBlock;
    const showMatrixData = mainStore.page === VIEWS.MATRIX;

    const isNewIssue = issue?.id === UNSAVED_MODEL_ID;

    const editable = !IS_PUBLIC_BOARD && issue.isEditable;

    return (
        <>
            {!isPreview && !issue.isExternal && !issue.isRemoved ? <IssueHotkeys /> : null}

            <IssueHeader sticky>
                <HeaderMeta isPreview={isPreview || issue.id === UNSAVED_MODEL_ID || issue.isExternal}>
                    <InsideIssueComponent.CloseBtn onClick={onHide} />
                    {showQuestionBtn && (
                        <QButton hotkey micro={false} position="left-bottom" rowIndex={0} item={issue} />
                    )}
                </HeaderMeta>
                {!IS_VOTING_BOARD && <StHr css={{ marginBottom: 0 }} />}
            </IssueHeader>

            <IssueHeader>
                <Space height={8} />
                <Title issue={issue} editable={editable} />
            </IssueHeader>

            <StFlexWrapper
                as={Flex}
                column
                align="none"
                gap={8}
                extraSpace={mainStore.currentUser?.showMegaVideoBanner}
            >
                {!isNewIssue && showMatrixData ? <IssueMatrixData issue={issue} /> : null}

                {!IS_PUBLIC_BOARD && hasAlignment && issue.id !== UNSAVED_MODEL_ID && (
                    <InsideIssueComponent.FinalScore issue={issue} />
                )}

                {!isNewIssue && (
                    <>
                        <InsideIssueAnnounce />
                        <IssueIdeasGroups />
                        <Blocks />
                        <InsideIssueComponent.LinkedIssues issue={issue} />
                        <InsideIssueComponent.SubTasks issue={issue} />
                        <InsideIssueComponent.AsanaProjects issue={issue} />
                        {!IS_PUBLIC_BOARD && <Questions issue={issue} />}
                    </>
                )}

                <IssueDescription placeholder="Add Description" editable={editable} issue={issue} />

                {editable && <div />}

                {!isNewIssue && (
                    <InsideIssueComponent.Labels issue={issue} editable={editable} labels={issue.labelsList} />
                )}

                {editable && <div />}

                <InsideIssueComponent.IssueChecklists checklists={issue.checklists} />

                <NoneJiraAttachments
                    description={issue.description}
                    attachments={issue.attachments}
                    provider={issue.provider}
                />

                {!IS_PUBLIC_BOARD && <Questions issue={issue} closed />}

                <div />

                {!isPreview && issue.id !== UNSAVED_MODEL_ID && (
                    <Flex gap={24}>
                        <CreatedField issue={issue} />
                        <Reporter />
                        {!IS_PUBLIC_BOARD && !issue.isExternal && <Watchers key={issue.id} issue={issue} />}
                    </Flex>
                )}

                <Space height={4} />
            </StFlexWrapper>

            {(editable || issue.idea) && <DictionaryRemoveAlert board={issue.board} />}
            {!IS_PUBLIC_BOARD &&
                mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.ISSUE_DEPENDENCIES) &&
                !mainStore.currentUser?.isViewer && <BlocksOmniBar />}
            {editable && issue.id !== UNSAVED_MODEL_ID && !mainStore.currentUser?.isViewer && (
                <RemoveAction issue={issue} />
            )}
        </>
    );
}

export default observer(InsideIssue);
