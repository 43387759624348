import React from 'react';

import { observer } from 'mobx-react-lite';

import { AnchorButton } from 'components/Button';

function IssueEpic({ issue }) {
    if (!issue?.parent_task) return null;

    const { key, name } = issue.parent_task;
    const text = key ? `${key}: ${name}` : name;

    return (
        <AnchorButton
            title={text}
            likeLink
            href={issue.parent_task.url}
            rel="noopener noreferrer"
            target="_blank"
            text={text}
        />
    );
}

export default observer(IssueEpic);
