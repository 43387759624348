import { storageApi } from 'api';
import { failRequest } from 'utils';

import { issuesList } from 'store/models/IssuesList';
import { ORG_DB_STORES } from 'store/updateOrgDB';

import { prepareServerIssuesResponse } from 'utils/prepareServerIssuesResponse';

export async function fetchScores(boards) {
    try {
        const { data } = await storageApi.get('/issues-scores', { params: { boards } });

        const issuesScore = prepareServerIssuesResponse(data);
        issuesList.fillIssuesData(issuesScore);

        await this.db.saveList(issuesScore, ORG_DB_STORES.issuesScore);
    } catch (e) {
        failRequest(e);
    }
}
