import React, { useState } from 'react';

import { IntlProvider } from 'react-intl';

import English from 'lang/en.json';

import AuthChild from 'modules/Auth/AuthChild';

import InviteError from '../InviteError';

function Login() {
    const [inviteError, setInviteError] = useState({});

    if (inviteError.email && inviteError.type && inviteError.invitedEmail) {
        return (
            <InviteError
                email={inviteError.email}
                type={inviteError.type}
                invitedEmail={inviteError.invitedEmail}
                setInviteError={setInviteError}
            />
        );
    }

    return (
        <IntlProvider messages={English} locale="en">
            <div className="login-form flex flex-center flex-a-center">
                <AuthChild isLogin setInviteError={setInviteError} />
            </div>
        </IntlProvider>
    );
}

function LoginApp() {
    return (
        <IntlProvider messages={English} locale="en">
            <Login />
        </IntlProvider>
    );
}

export default LoginApp;
