import { useCallback } from 'react';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import { IS_VOTING_BOARD, MAIN_SITE_URL } from 'utils/consts';

export default function usePaywallUpgrade(paywall) {
    return useCallback(() => {
        if (IS_VOTING_BOARD) {
            const path = paywall ? `/billing-plan#${paywall}` : '/billing-plan';
            window.open(`${MAIN_SITE_URL}${path}`);
        } else {
            const link = paywall ? `/billing-plan/${paywall}` : '/billing-plan';
            settingsStore.goToLink(link);
        }
    }, [paywall]);
}
