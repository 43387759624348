import React from 'react';

import { ProgressBar } from '@blueprintjs/core';

import { SettingsSwitcher } from 'pages/FocusMode/FocusModeForm/SettingsSwitcher';

import { FocusModeHeader } from 'modules/InsideIssue/components/FocusModeHeader';
import IssueCard from 'modules/IssueCard';

import { FAST_INPUT, SHOW_TOOLTIPS } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import HotkeysHelper from 'components/Hotkeys';
import IssueCardWrapper from 'components/IssueCardWrapper';
import QuestionDialog from 'components/QButton/QuestionDialog';

import CloseBtn from './CloseBtn';
import { TypeFormInput } from './TypeFormInput';

import styles from './style.module.sass';

const FOCUS_MODE_SETTINGS_LIST = [FAST_INPUT, SHOW_TOOLTIPS];

class FocusModeForm extends React.PureComponent {
    state = { isSelected: false };

    componentDidUpdate(prevProps) {
        if (!this.props.issue?.unvoted) {
            this.props.onComplete();
        }
        if (this.props.issue && prevProps.issue && prevProps.issue.id !== this.props.issue.id) {
            this.focus(1);
        }
    }

    focus(index) {
        let el = document.querySelector(`input[tabindex="${index}"]`) || document.querySelector('input[tabindex="1"]');
        if (el) {
            el.focus();
            el.select();
        }
    }

    getTop() {
        if (this.props.prev) {
            return (
                <div className={styles.historyTask} role="button" onClick={this.props.onUp}>
                    <div>
                        <Button size={42} minimal border icon={CustomIconName.CHEVRON_UP} />
                    </div>
                    <div className="w-20" />
                    <div className="issue-card  flex-vc">
                        <FocusModeHeader issue={this.props.prev} />
                    </div>
                </div>
            );
        }
        return <div className={styles.historyTask} />;
    }

    getBottom() {
        if (this.props.next) {
            return (
                <div className={styles.historyTask} role="button" onClick={this.props.onDown}>
                    <div>
                        <Button size={42} minimal border icon={CustomIconName.CHEVRON_DOWN} />
                    </div>
                    <div className="w-20" />
                    <div className="issue-card flex-vc">
                        <FocusModeHeader issue={this.props.next} />
                    </div>
                </div>
            );
        }
        return <div className={styles.historyTask} />;
    }

    getWrapper() {
        if (this.props.completeView !== null) {
            return this.props.completeView;
        }

        const { issue, board, criteria } = this.props;

        if (!issue) return null;

        const crCnt = criteria.length;

        return (
            <div className={styles.container_wrapper}>
                <div className={styles.issue_wrapper}>
                    {this.getTop()}

                    <div className="flex flex-grow" style={{ minHeight: 0 }}>
                        <IssueCardWrapper>
                            <IssueCard canEdit showQuestionBtn key={`fm-${issue?.id}`} />
                        </IssueCardWrapper>
                        <form key={issue.id} className={styles.criteria} onSubmit={() => false}>
                            {criteria.map((criterion, i) => (
                                <TypeFormInput
                                    key={criterion.id}
                                    criterion={criterion}
                                    board={board}
                                    id={i}
                                    issue={issue}
                                    crCnt={crCnt}
                                />
                            ))}
                        </form>
                    </div>

                    {this.getBottom()}
                </div>
            </div>
        );
    }

    getHeader() {
        if (this.props.completeView !== null) {
            return null;
        }

        const completeCount = this.props.allCount - this.props.listCount;
        const progress = this.props.allCount === null ? 1 : completeCount / this.props.allCount;

        return (
            <header className={styles.header}>
                <div className={styles.container_wrapper}>
                    <div className="">
                        <div className="flex">
                            {(progress * 100).toFixed(0)}% Completed <div className="w-20 flex-grow" />
                            <span className="o-4">
                                {completeCount}/{this.props.allCount || 0}
                            </span>
                        </div>
                        <ProgressBar stripes={false} value={progress} />
                    </div>
                    <div className="flex-grow" />
                    <Flex gap={20}>
                        <HotkeysHelper iconOnly />
                        {FOCUS_MODE_SETTINGS_LIST.map((field) => (
                            <SettingsSwitcher field={field} key={field} style={{ margin: 0, height: 23 }} />
                        ))}
                    </Flex>
                </div>
                <CloseBtn />
            </header>
        );
    }

    render() {
        return (
            <>
                <div className={styles.container}>
                    {this.getHeader()}
                    {this.getWrapper()}
                </div>
                <QuestionDialog />
            </>
        );
    }
}

export default FocusModeForm;
