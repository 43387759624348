import React, { useCallback } from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';
import { utilsStore } from 'store/models/UtilsStore';

import { StRow } from 'components/MasterTable/components/Row/StRow';

export const Row = observer(
    ({
        interactive,
        className,
        columns,
        index,
        onRowClick,
        onRowMouseOut,
        onRowMouseOver,
        rowData,
        style,
        forceTableNav,
        rowClassNames,
        isScrolling,
        needFocus = true,
        rowProps,
    }) => {
        const a11yProps = { 'aria-rowindex': index + 1, role: 'row', 'aria-label': 'row', tabIndex: 0 };
        if (onRowClick) {
            a11yProps.onClick = useCallback(
                (event) => onRowClick({ event, index, rowData }),
                [index, onRowClick, rowData]
            );
            needFocus && (a11yProps.onFocus = a11yProps.onClick);
        }
        if (onRowMouseOut) {
            a11yProps.onMouseOut = useCallback(
                (event) => onRowMouseOut({ event, index, rowData }),
                [index, onRowMouseOut, rowData]
            );
        }
        if (onRowMouseOver) {
            a11yProps.onMouseOver = useCallback(
                (event) => onRowMouseOver({ event, index, rowData }),
                [index, onRowMouseOver, rowData]
            );
        }
        const disableTableNav = utilsStore.disableTableNav && !forceTableNav;

        let classes, active;
        if (index === -1 || disableTableNav || !rowData) {
            classes = className;
        } else {
            active = issuesList.activeIssue
                ? issuesList.activeIssue?.id === rowData.id && issuesList.activeIssue.boardId === rowData.boardId
                : false;
            classes = classNames(className, rowClassNames?.(rowData));
        }

        return (
            <StRow
                {...a11yProps}
                {...rowProps}
                interactive={interactive}
                active={interactive && active}
                removed={rowData.isRemoved}
                scrolling={isScrolling}
                className={classes}
                style={{ ...style, top: 0, transform: `translate3d(0px,${style.top}px, 0px)` }}
            >
                {columns}
            </StRow>
        );
    }
);

Row.displayName = 'MasterTable.Row';
