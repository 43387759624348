import { styled } from 'stitches.config';

import { colorsVariant } from 'utils/colorsVariant';

const DEFAULT_TAG = 'button';

export const StHoveredContent = styled('div', {
    opacity: 0,
    transition: 'opacity .2s',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    minWidth: 0,
    textOverflow: 'ellipsis',
});

export const StButton = styled(DEFAULT_TAG, {
    alignItems: 'center',
    appearance: 'none',
    border: 'none',
    borderRadius: 4,
    color: '$tc1',
    display: 'flex',
    gap: 4,
    margin: '0',
    maxWidth: '100%',
    minWidth: '0',
    outline: 'none',
    outlineOffset: '-1px !important',
    padding: '0 8px',
    textAlign: 'left',
    textDecoration: 'none',
    transition: 'all .2s',

    fontSize: 13,
    fontWeight: 400,

    '@hover': {
        '&:hover': {
            textDecoration: 'none',
            [StHoveredContent]: {
                opacity: 1,
            },
        },
    },

    '&:disabled, &[aria-disabled="true"]': {
        cursor: 'not-allowed',
        opacity: 0.5,
    },

    '&>[icon]': {
        opacity: 0.6,
        transition: 'opacity .2s',
    },

    '&:enabled, &:is(a)': {
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        userSelect: 'none',

        cursor: 'pointer',

        '@hover': {
            '&:hover, &.bp5-active': {
                '&>[icon]': {
                    opacity: 1,
                },
            },
        },
    },

    '&[aria-haspopup="dialog"][data-state="open"]': {
        $$mainColor: '$colors-primary11',
        $$borderColor: '$colors-primary6',
        $$borderColorHover: '$colors-primary6',
        bc: '$primary4',
        color: '$primary11',
        '@hover': {
            '&:hover': {
                color: '$primary11',
            },
        },
    },

    kbd: {
        fontSize: 11,
    },

    variants: {
        round: {
            true: {
                borderRadius: '50% !important',
            },
        },
        loading: {
            true: {
                cursor: 'wait !important',
            },
        },
        disabled: {
            true: {
                cursor: 'not-allowed',
                opacity: 0.5,
            },
        },
        border: {
            true: {
                boxShadow: '0 0 0 1px $$borderColor inset',
                '@hover': {
                    '&:enabled, &:is(a)': {
                        '&:hover, &.bp5-active': {
                            boxShadow: '0 0 0 1px $$borderColorHover inset',
                        },
                    },
                },
            },
        },
        relative: {
            true: {
                position: 'relative',
            },
        },
        block: {
            true: {
                width: '100%',
                flexGrow: 1,
            },
        },
        noneInteractive: {
            true: {
                pointerEvents: 'none',
            },
        },
        inline: {
            true: {
                display: 'inline-flex',
            },
        },
        inlineLink: {
            true: {
                display: 'inline',
            },
        },
        likeLink: {
            true: {
                display: 'inline-flex',
                padding: 0,
                lineHeight: 'inherit',
                height: 'auto',
                color: '$$mainColor',
                background: 'none !important',
                '&:hover': { background: 'none !important', textDecoration: 'underline' },
                '&:active': { background: 'none !important' },
            },
        },
        linkStyle: {
            dotted: {
                textDecoration: 'underline',
                textDecorationStyle: 'dotted',
                '&:hover': {
                    textDecoration: 'underline',
                    textDecorationStyle: 'dotted',
                },
            },
        },
        size: {
            18: {
                //extra - for labels only or button without icons and avatars
                lineHeight: '18px',
                height: 18,
                minWidth: 18,
                fontSize: 11,
                padding: '0 4px',
                gap: 2,
            },
            20: {
                lineHeight: '20px',
                padding: '0 4px',
                height: 20,
                minWidth: 20,
            },
            24: {
                lineHeight: '24px',
                height: 24,
                minWidth: 24,
            },
            28: {
                lineHeight: '28px',
                height: 28,
                minWidth: 28,
            },
            30: {
                lineHeight: '30px',
                height: 30,
                minWidth: 30,
            },
            32: {
                lineHeight: '32px',
                height: 32,
                minWidth: 32,
            },
            34: {
                lineHeight: '34px',
                height: 34,
                minWidth: 34,
                gap: 6,
            },
            36: {
                lineHeight: '36px',
                height: 36,
                minWidth: 36,
                gap: 6,
                fontSize: 24,
                fontWeight: 700,
            },
            42: {
                lineHeight: '42px',
                height: 42,
                minWidth: 42,
                fontSize: 14,
                padding: '0 12px',
                fontWeight: 500,
                gap: 10,
                borderRadius: 4,
                textAlign: 'center',
            },
            74: {
                lineHeight: '74px',
                height: 74,
                minWidth: 74,
                fontSize: 48,
                fontWeight: 900,
                padding: '0 16px',
            },
        },
        color: {
            ...colorsVariant.colors,
            null: {
                color: '$whiteA12',
            },
            accent: {
                $$mainColor: 'var(--colors-accent-text,  $yellowA11)',
                $$borderColor: 'var(--colors-grayA3, $colors$yellow5)',
                $$borderColorHover: 'var(--colors-grayA6, $colors$yellow6)',
                background: 'var(--colors-accent,  $yellowA3)',
                color: 'var(--colors-accent-text,  $yellowA11)',
                boxShadow: '0 0 0 1px $$borderColor inset',
                '@hover': {
                    '&:enabled, &:is(a)': {
                        '&:hover': {
                            color: '$$mainColor',
                            boxShadow: '0 0 32px 0 rgba(255,255,255,.05) inset, 0 0 0 1px $$borderColorHover inset',
                        },
                        '&:active': {
                            color: '$$mainColor',
                            boxShadow: '0 0 32px 0 rgba(255,255,255,.1) inset, 0 0 0 1px $$borderColorHover inset',
                        },
                    },
                },
            },
            primary: {
                $$mainColor: '$colors-primary11',
                $$borderColor: '$colors-primary5',
                $$borderColorHover: '$colors-primary6',
                bc: '$primary3',
                color: '$primary11',
                '@hover': {
                    '&:enabled, &:is(a)': {
                        '&:hover': {
                            bc: '$primary4',
                            color: '$primary11',
                        },
                        '&:active': {
                            bc: '$primary5',
                            color: '$primary11',
                        },
                    },
                },
            },
            gray: {
                $$mainColor: '$btnColorHover',
                $$borderColor: '$colors-btnBorder',
                $$borderColorHover: '$colors-btnBorderHover',
                bc: '$btnBg',
                color: '$btnColorHover',
                '@hover': {
                    '&:enabled, &:is(a),&.bp5-editable-text': {
                        '&:hover': {
                            bc: '$btnBgHover',
                            color: '$btnColorHover',
                        },
                        '&:active, &.bp5-active': {
                            bc: '$btnBgActive',
                            color: '$btnColorHover',
                        },
                    },
                },
            },
        },
        iconContent: {
            true: {
                padding: '0 !important',
                justifyContent: 'center',
            },
        },
        canRemove: {
            true: {
                paddingRight: 3,
            },
        },
        center: {
            true: {
                paddingLeft: 2,
                paddingRight: 2,
                justifyContent: 'center',
                textAlign: 'center',
                '.t-crop': {
                    flexGrow: 0,
                },
            },
        },
        minimal: {
            true: {},
        },
        active: {
            true: {
                opacity: '1 !important',
                '&> [icon]': {
                    opacity: 1,
                },
                [StHoveredContent]: {
                    opacity: 1,
                },
            },
        },
        cell: {
            true: {
                background: 'none',
            },
        },
    },

    compoundVariants: [
        ...colorsVariant.minimal,
        ...colorsVariant.active,
        {
            iconContent: false,
            center: false,
            css: {
                '&>[icon]:first-child, &>[role="progressbar"]:first-child': {
                    marginLeft: -2,
                },
                '&>[icon]:last-child, &>[role="progressbar"]:last-child': {
                    marginRight: -2,
                },
            },
        },
        {
            color: 'primary',
            active: true,
            likeLink: false,
            css: {
                background: `$primary5 !important`,
                color: `$primary12 !important`,
                '@hover': {
                    '&:hover': {
                        background: `$primary4 !important`,
                        color: `$primary12 !important`,
                    },
                },
            },
        },
        {
            color: 'primary',
            minimal: true,
            css: {
                background: 'none',
                '@hover': {
                    '&:enabled, &:is(a)': {
                        '&:hover': {
                            bc: `$primary4`,
                            color: `$primary11`,
                        },
                    },
                },
            },
        },
        {
            color: 'gray',
            active: true,
            likeLink: false,
            css: {
                background: `$btnBgActive !important`,
                color: `$btnColorHover !important`,
                '@hover': {
                    '&:hover': {
                        background: `$btnBgHover !important`,
                    },
                },
            },
        },
        {
            likeLink: true,
            active: true,
            css: {
                textDecoration: 'underline',
            },
        },
        {
            color: 'gray',
            minimal: true,
            css: {
                background: 'none',
                '@hover': {
                    '&:enabled, &:is(a)': {
                        '&:hover': {
                            bc: `$btnBgHover`,
                            color: `$btnColorHover`,
                        },
                    },
                },
            },
        },
        {
            size: 32,
            likeLink: true,
            css: {
                height: 'auto',
                lineHeight: 'inherit',
            },
        },
        {
            size: 30,
            likeLink: true,
            css: {
                height: 'auto',
                lineHeight: 'inherit',
            },
        },
        {
            minimal: true,
            border: true,
            css: {
                boxShadow: 'none',
                '&:enabled, &:is(a),&.bp5-editable-text': {
                    '&:hover': {
                        boxShadow: '0 0 0 1px $$borderColorHover inset',
                    },
                },
            },
        },
        {
            minimal: true,
            border: true,
            active: true,
            css: {
                boxShadow: '0 0 0 1px $$borderColorHover inset',
            },
        },
    ],
    defaultVariants: {
        size: 30,
        color: 'gray',
        likeLink: false,
        minimal: false,
    },
});

StButton.displayName = 'StButton';
