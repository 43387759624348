import Image from '@tiptap/extension-image';

import { placeholderPlugin } from './placeholderImage';
import { uploadImagePlugin } from './upload_image';

export default Image.extend({
    addAttributes() {
        return {
            src: {
                default: null,
            },
            alt: {
                default: null,
            },
            title: {
                default: null,
            },
            'data-attach': {
                default: true,
            },
            loading: {
                default: 'lazy',
            },
        };
    },
    addProseMirrorPlugins() {
        return [uploadImagePlugin, placeholderPlugin];
    },
});
