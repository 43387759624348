import React from 'react';

import { NodeViewWrapper } from '@tiptap/react';
import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { User } from 'components/IssueDescription/components';

export const CustomMentionContent = observer((props) => {
    const userFromState = issuesList.activeIssue?.mentionsUsers?.find((user) => user.id === props.node.attrs.id);
    const user = userFromState ||
        mainStore.users.usersIds.get(Number(props.node.attrs.id)) || {
            ...props.node.attrs,
        };

    return (
        <NodeViewWrapper className="mention">
            <User user={user} isEditor />
        </NodeViewWrapper>
    );
});
