import React from 'react';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';

import { HeaderColumnIcon } from './HeaderColumnIcon';

export const ColumnIcon = ({ isAlignment, votingField, icon, label }) => {
    if (isAlignment) {
        return (
            <HeaderColumnIcon
                tooltip={
                    <>
                        Criteria Alignment:
                        <br />
                        <b>{label}</b>
                    </>
                }
                icon={CustomIconName.ALIGNMENT}
            />
        );
    }

    if (votingField) {
        return (
            <HeaderColumnIcon
                tooltip={
                    <>
                        Public Idea Field:
                        <br />
                        <b>{label}</b>
                    </>
                }
                icon={CustomIconName.LAMP}
            />
        );
    }

    if (icon) {
        return <CustomIcon size={16} className="o-4" icon={icon} />;
    }

    return null;
};
