import React from 'react';

import classNames from 'classnames';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import HelpArticleLink from 'components/HelpArticleLink';
import PopoverTooltip from 'components/PopoverTooltip';

import styles from './ImageError.module.sass';

export default function ImageError({ small, error }) {
    const classes = classNames(styles.image, {
        [styles.small]: small,
    });

    const content = error || (
        <>
            <HelpArticleLink article="media-files-in-issue-cards-not-loading">
                Enable third-party cookies
            </HelpArticleLink>{' '}
            can help you to load media files
        </>
    );

    return (
        <span className={classes}>
            <PopoverTooltip className={styles.tooltip} content={content} position="top-left">
                <CustomIcon className={styles.icon} icon={CustomIconName.EXCLAMATION_CIRCLE} />
            </PopoverTooltip>
        </span>
    );
}
