import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';
import { defineMessages, useIntl } from 'react-intl';

import { IS_VOTING_BOARD } from 'utils/consts';

import ReadOnlyDescription from 'components/ReadOnlyDescription';
import TextEditor from 'components/TextEditor';
import { TOOLBAR_ACTIONS } from 'components/TextEditor/ToolBar/constants';

import styles from './IssueCommentText.module.sass';

const ISSUE_COMMENT_EDITOR_ACTIONS = [
    [TOOLBAR_ACTIONS.bold, TOOLBAR_ACTIONS.italic],
    [TOOLBAR_ACTIONS.link],
    [TOOLBAR_ACTIONS.clear],
];

/**
 *
 * @param {Comment} parent
 * @param {Issue} issue
 * @param {boolean} canEdit
 * @param {boolean} isIdea
 * @param {Comment} comment
 * @param {string} placeholder
 * @returns {JSX.Element}
 */
function IssueCommentText({ parent, issue, canEdit, comment, placeholder, isIdea }) {
    const [timestamp, setTimestamp] = useState(Date.now());
    const intl = useIntl();
    const messages = defineMessages({
        placeholder: {
            id: 'comment.placeholder',
            defaultMessage: 'Add your comment about it...',
        },
    });
    const placeholderField = placeholder || intl.formatMessage(messages.placeholder);

    function updateRequest(message) {
        if (comment.message !== message && message) {
            if (parent && typeof issue.updateQuestionComment === 'function') {
                issue.updateQuestionComment({ request: parent, id: comment.id, message });
            } else {
                issue.updateRequest({ request: comment, message });
            }
        } else {
            setTimestamp(Date.now());
        }
    }

    if (canEdit) {
        return (
            <TextEditor
                key={timestamp}
                actions={ISSUE_COMMENT_EDITOR_ACTIONS}
                hasMention
                hasPublicMention={isIdea}
                className={styles.text}
                placeholder={placeholderField}
                onConfirm={updateRequest}
                description={comment.message || ''}
            />
        );
    }

    return (
        <ReadOnlyDescription
            hasMention
            className={styles.text}
            description={comment.message}
            itemProp={IS_VOTING_BOARD ? 'text' : undefined}
        />
    );
}

export default observer(IssueCommentText);
