import { useCallback, useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react-lite';

import alertStore from 'store/models/Alert';
import { utilsStore } from 'store/models/UtilsStore';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import PopoverAIText from 'components/PopoverAIText/PopoverAIText';
import Tooltip from 'components/Tooltip';

const AIButton = ({ side, tooltipProps, buttonProps, onGenerate, clearAIText, onConfirm, children }) => {
    const abortController = useRef(null);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const confirm = useRef(false);

    useEffect(() => {
        utilsStore.toggleTabNav(open);
    }, [open]);

    useEffect(() => {
        return () => {
            utilsStore.toggleTabNav(false);
            abortController.current?.abort();
        };
    }, []);

    const handleClickGenerate = async () => {
        setLoader(true);
        setOpen(true);
        abortController.current = new AbortController();

        try {
            await onGenerate(abortController);
        } finally {
            setLoader(false);
            abortController.current = null;
        }
    };

    const handleClickCancel = () => {
        confirm.current = true;
        setOpen(false);
        clearAIText();
    };

    const handleClickConfirm = () => {
        confirm.current = true;
        setOpen(false);
        onConfirm();
        clearAIText();
    };

    const onStop = useCallback(() => {
        abortController.current?.abort();
    }, []);

    const onClose = () => {
        if (confirm.current) {
            confirm.current = false;
            return;
        }
        alertStore.confirm({
            message: 'Do you want to discard the AI response?',
            onConfirm: handleClickCancel,
            confirmButtonText: 'Discard',
        });
    };

    return (
        <>
            <PopoverAIText
                side={side}
                onClose={onClose}
                open={open}
                content={children({
                    onStop,
                    handleClickConfirm,
                    onRepeat: handleClickGenerate,
                    onConfirm: handleClickConfirm,
                    onCancel: handleClickCancel,
                    loading: loader,
                })}
            />
            <Tooltip side="left" {...tooltipProps} contentClassName="limit limit-300">
                <Button
                    size={24}
                    border
                    {...buttonProps}
                    onClick={handleClickGenerate}
                    loading={loader}
                    icon={CustomIconName.AI}
                />
            </Tooltip>
        </>
    );
};

export default observer(AIButton);
