import { runInAction } from 'mobx';

import api from 'api';
import { failRequest } from 'utils';

export async function getBoardHistory() {
    try {
        const { data } = await api.get(`${this.apiEndpoint}/history`);
        runInAction(() => {
            this.history = data;
        });
    } catch (e) {
        failRequest(e);
    }
}
