import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';

import alertStore from 'store/models/Alert';

import { IS_VOTING_BOARD } from 'utils/consts';

import { Button } from 'components/Button';

function RemoveBtn({ model, comment }) {
    const intl = useIntl();

    function onRemove() {
        const message = IS_VOTING_BOARD
            ? intl.formatMessage({
                  id: 'ideacomment.remove-alert',
                  defaultMessage: "Do you really want to delete this comment? Once you delete, it's gone for good",
              })
            : 'Do you really want to remove this question from the issue?';

        const cancelButtonText = intl.formatMessage({
            id: 'btn.cancel',
            defaultMessage: 'Cancel',
        });

        const confirmButtonText = intl.formatMessage({
            id: 'btn.remove',
            defaultMessage: 'Remove',
        });

        alertStore.confirm({
            message,
            onConfirm: () => {
                model.issue.removeRequest(comment, model);
            },
            confirmButtonText,
            cancelButtonText,
        });
    }

    return (
        <Button
            block
            minimal
            className="bp5-popover-dismiss"
            onClick={onRemove}
            text={<FormattedMessage id="btn.remove" defaultMessage="Remove" />}
        />
    );
}
export default observer(RemoveBtn);
