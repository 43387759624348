import React from 'react';

import { observer } from 'mobx-react-lite';

import IssuesComments from 'modules/Questions/IssuesComments';

function Questions({ issue, closed }) {
    if (!issue) {
        return null;
    }

    if (!issue.comments || issue.comments.length === 0) {
        return null;
    }

    return <IssuesComments issue={issue} closed={closed} />;
}

export default observer(Questions);
