import React from 'react';

import { observer } from 'mobx-react-lite';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import { mainStore } from 'store/models/MainStore';

import { PAYWALL_LIMITS } from 'utils/consts';

import { DisabledScoreValueTooltip } from 'components/DisabledScoreValueTooltip/DisabledScoreValueTooltip';
import UpgradePlanBtn from 'components/UpgradePlanBtn';

export const WithBlockedInput = ({ ComponentUseFull, ComponentBlocked }) => {
    return observer((props) => {
        if (mainStore.disconnected || settingsStore.isOpen || props.issue?.id === -1 || props.issue?.isRemoved) {
            return <ComponentBlocked {...props} />;
        }
        if (mainStore.organization.paymentRequired) {
            return (
                <UpgradePlanBtn paywall={PAYWALL_LIMITS.USERS_LIMIT} text="to evaluate">
                    <ComponentBlocked {...props} />
                </UpgradePlanBtn>
            );
        }
        if (props.issue.board?.disableEvaluation) {
            return (
                <DisabledScoreValueTooltip
                    date={props.issue.board.sprint?.ends_at}
                    isAdmin={mainStore.currentUser?.isAdmin}
                >
                    <ComponentBlocked {...props} />
                </DisabledScoreValueTooltip>
            );
        }

        return <ComponentUseFull {...props} />;
    });
};
