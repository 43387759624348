import React from 'react';

import ImageWrapper from 'components/ImageWrapper';

import { ImageEl } from './';

export default function Monosnap({ file }) {
    if (!file) {
        return null;
    }
    return (
        <ImageWrapper>
            <ImageEl
                file={`https://monosnap.com/file/${file}`}
                thumbnail={`https://api.monosnap.com/file/download?id=${file}`}
            />
        </ImageWrapper>
    );
}
