import { APP_STORAGE_KEYS, IS_VOTING_BOARD } from 'utils/consts';
import appStorage from 'utils/LsHelper';

export function getRedirectAuthUrl() {
    if (!IS_VOTING_BOARD) {
        const searchParams = new URLSearchParams(window.location?.search || '');
        return searchParams.get('redirect');
    }
    const actions = appStorage.get(APP_STORAGE_KEYS.voting_action, null);
    const action = actions && (actions.voting || actions.comment);
    if (action) {
        return window.location.href.replace(/\/summary(.+)?/gm, `/issue-${action.id}`);
    }
    return window.location.href;
}
