import React from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';
import { utilsStore } from 'store/models/UtilsStore';

import Scroll from 'components/Scroll/Scroll';

import styles from './IssueCardWrapper.module.sass';

function IssueCardWrapper({ children, className, style, slim = false, onClick, ...props }) {
    const classes = classNames(styles.card, className);

    return (
        <div
            key={issuesList.activeIssue?.getKey()}
            onClick={onClick}
            className={classes}
            style={style}
            data-slim={slim}
            aria-disabled={utilsStore.blocked || utilsStore.newVersion}
            {...props}
        >
            <Scroll>{children}</Scroll>
        </div>
    );
}

export default observer(IssueCardWrapper);
