import React from 'react';

import { observer } from 'mobx-react-lite';

import useScrollTo from 'hooks/useScrollTo';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { ANNOUNCE_STATUS } from 'utils/consts';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Space from 'components/Space';
import Stripe from 'components/Stripe';
import Tooltip from 'components/Tooltip';

import AnnounceFooter from './AnnounceFooter';
import AnnounceMenu from './AnnounceMenu';
import CreateAnnounceButton from './CreateAnnounceButton';
import CreateAnnounceButtonWrapper from './CreateAnnounceButtonWrapper';
import { IdeaAnnounceContent } from './IdeaAnnounceContent';
import IdeaAnnounceContentDescription from './IdeaAnnounceContentDescription';
import ManualCreateAnnounceButton from './ManualCreateAnnounceButton';

function InsideIssueAnnounce() {
    const announce = issuesList.idea?.announce;
    const ref = useScrollTo(announce?.idea?.initialOpenAnnounce);

    if (!issuesList.idea) return null;

    if (
        mainStore.currentUser?.isViewer &&
        (!issuesList.idea?.announce_id || announce?.text_status === ANNOUNCE_STATUS.AI_IN_PROGRESS)
    ) {
        return null;
    }

    if (!issuesList.idea?.announce_id) {
        if (!issuesList.idea.board?.allowAnnounceAI) {
            return <ManualCreateAnnounceButton idea={issuesList.idea} />;
        }
        return <CreateAnnounceButtonWrapper idea={issuesList.idea} />;
    } else if (announce?.text_status === ANNOUNCE_STATUS.AI_IN_PROGRESS) {
        return <CreateAnnounceButton needLoader useAI disabled className="animate-typing" />;
    }

    if (!announce) return null;

    let leftElm = (
        <Tooltip
            side="top"
            content={announce.text_status === ANNOUNCE_STATUS.APPROVED ? 'Approved' : 'The draft needs approval'}
        >
            <CustomIcon
                color={announce.text_status === ANNOUNCE_STATUS.APPROVED ? 'green' : 'orange'}
                icon={
                    announce.text_status === ANNOUNCE_STATUS.APPROVED
                        ? CustomIconName.CALENDAR_TICK
                        : CustomIconName.WARNING
                }
            />
        </Tooltip>
    );

    return (
        <Stripe
            style={announce.aiName ? { zIndex: 2, position: 'relative' } : undefined}
            initialOpen={issuesList.idea.initialOpenAnnounce}
            leftElm={leftElm}
            title={
                !announce?.isEmpty ? (
                    <span className="t-crop">{announce.name || announce.plainDescription}</span>
                ) : (
                    <span className="flex-shrink">Announcement</span>
                )
            }
            subTitleShrink={false}
            action={<AnnounceMenu announce={announce} />}
        >
            <Space height={4} ref={ref} css={{ scrollMarginTop: 120 }} />
            <IdeaAnnounceContentDescription announce={announce} />
            <Space height={8} />
            <IdeaAnnounceContent announce={announce} readOnly={mainStore.currentUser?.isViewer} />
            <AnnounceFooter announce={announce} />
            <Space height={8} />
        </Stripe>
    );
}

export default observer(InsideIssueAnnounce);
