import React, { useRef } from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import Flex from 'components/Flex';

import {
    TOOLBAR_ACTIONS,
    TOOLBAR_ACTIONS_INCOMPATIBLE,
    TOOLBAR_ACTIONS_METHODS,
    TOOLBAR_ACTIONS_PROPS,
} from './constants';
import HeaderMenu from './HeaderMenu';
import { StToolbar } from './StToolbar';
import ToolBarButton from './ToolBarButton';
import ToolBarInputLink from './ToolBarInputLink';

function ToolBarItem({ action, editor, parentRef }) {
    const buttonProps = TOOLBAR_ACTIONS_PROPS[action];

    if (action === TOOLBAR_ACTIONS.clear) {
        return (
            <ToolBarButton onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()} {...buttonProps} />
        );
    }

    if (action === TOOLBAR_ACTIONS.headers) {
        return <HeaderMenu key={action} editor={editor} />;
    }

    if (action === TOOLBAR_ACTIONS.link) {
        return <ToolBarInputLink parentPref={parentRef} key={action} editor={editor} />;
    }

    const method = TOOLBAR_ACTIONS_METHODS[action];
    const incompatible = TOOLBAR_ACTIONS_INCOMPATIBLE[action];
    return (
        <ToolBarButton
            active={editor.isActive(action)}
            onClick={() => editor.chain().focus()[method]().run()}
            disabled={incompatible?.some((item) => editor.isActive(item)) || false}
            {...buttonProps}
        />
    );
}

function ToolBar({ actions, editor }) {
    const ref = useRef();

    const theme = mainStore.organization.isDarkTheme ? 'dark' : 'light';

    return (
        <StToolbar gap={8} popover theme={theme} ref={ref}>
            {actions.map((group, key) => (
                <Flex gap={4} key={key}>
                    {group.map((action) => (
                        <ToolBarItem parentRef={ref} editor={editor} action={action} key={action} />
                    ))}
                </Flex>
            ))}
        </StToolbar>
    );
}

export default observer(ToolBar);
