import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage, useIntl } from 'react-intl';

import useUndoNotification from 'hooks/useUndoNotification';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';

import { IS_VOTING_BOARD, POPOVER_PROPS_DIV, VIEWS } from 'utils/consts';

import { Button } from 'components/Button';
import DarkTooltip from 'components/DarkTooltip';

/**
 * @param {Idea} idea
 * @return {JSX.Element}
 */
function PublishButton({ idea }) {
    const intl = useIntl();
    const notify = useUndoNotification();
    const [disabled, setDisabled] = useState(false);

    const handleClickSave = async () => {
        setDisabled(true);
        await idea.save(issuesList.activeIssue);

        if (IS_VOTING_BOARD && mainStore.page === VIEWS.VOTING) return;

        const message = intl.formatMessage(
            {
                id: 'notification.new.idea',
                defaultMessage: 'Idea "<b>{ideaName}</b>" is created. Find it on <a>Roadmap</a>',
            },
            {
                ideaName: idea.name,
                a: (children) => <a href={idea.internalHref}>{children}</a>,
                b: (children) => <strong>{children}</strong>,
            }
        );
        notify(message);
    };

    const btn = (
        <Button
            disabled={idea?.isEmpty || disabled || utilsStore.fileUploading}
            tabIndex={0}
            onClick={handleClickSave}
            className="flex-wrap"
            block
            center
            color="accent"
            text={<FormattedMessage id="btn.publish_idea" defaultMessage="Publish idea" />}
        />
    );

    if (idea?.isEmpty) {
        return (
            <div style={{ width: '100%' }}>
                <DarkTooltip
                    {...POPOVER_PROPS_DIV}
                    fast
                    position="top"
                    content={
                        <FormattedMessage id="idea.tooltip.publish" defaultMessage="Please, fill out the Idea Name" />
                    }
                >
                    {btn}
                </DarkTooltip>
            </div>
        );
    }

    return btn;
}

export default observer(PublishButton);
