import React, { useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import { mainStore } from 'store/models/MainStore';

import { ENTER } from 'utils/consts';
import getKeyCode from 'utils/getKeyCode';
import queryInText from 'utils/queryInText';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import * as InputField from 'components/InputField';
import Space from 'components/Space';
import UserListItem from 'components/UserListItem';
import CurrentUsersMenuItem from 'components/UserMultiSelect/CurrentUsersMenuItem';
import styles from 'components/UserMultiSelect/UserMultiSelect.module.sass';
import VirtuosoList from 'components/VirtuosoList';

/**
 * @param {Object[]} list
 * @param {function} onItemSelect
 * @param {number[]} selected
 * @param {boolean} hasSubscribe
 * @param {string} placeholder
 *
 * @return {JSX.Element}
 */
function UserMultiSelectPopover({ list, onItemSelect, selected, hasSubscribe, placeholder }) {
    const [currentItemIndex, setCurrentItemIndex] = React.useState(-1);
    const [query, setQuery] = useState('');
    const [items, setItems] = useState([]);

    const onSelect = useCallback(
        (user) => {
            setQuery('');
            user && onItemSelect(user);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const onSelectByIndex = useCallback(
        (index) => {
            onSelect(items[index]);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [items],
    );

    useEffect(() => {
        query.length > 1 && setCurrentItemIndex(0);

        let items;
        if (query) {
            items = list.filter((el) => {
                const text = (el.name || '') + ' ' + (el.email || '');
                return queryInText(text, query);
            });
        } else {
            items = list;
        }

        setItems(items);
    }, [query, list]);

    const maxCount = items.length;

    const keyDownCallback = useCallback(
        (e) => {
            let nextIndex = null;

            if (e.code === 'ArrowUp') {
                nextIndex = Math.max(0, currentItemIndex - 1);
            } else if (e.code === 'ArrowDown') {
                nextIndex = Math.min(maxCount - 1, currentItemIndex + 1);
            } else if (getKeyCode(e) === ENTER) {
                onSelectByIndex(currentItemIndex >= 0 ? currentItemIndex : 0);
            }

            if (nextIndex !== null) {
                setCurrentItemIndex(nextIndex);
                e.preventDefault();
            }
        },
        [currentItemIndex, maxCount, onSelectByIndex],
    );

    const scrollerRef = useCallback(
        (element) => {
            if (element) {
                document.addEventListener('keydown', keyDownCallback);
            } else {
                document.removeEventListener('keydown', keyDownCallback);
            }
        },
        [keyDownCallback],
    );

    let emptyField;
    if (maxCount === 0) {
        emptyField = <Button minimal block disabled text="No user found" />;
    }

    function activeItemIndex() {
        return currentItemIndex;
    }

    const renderItem = useCallback(
        (item, index) => {
            return (
                <UserListItem
                    item={item}
                    modifiers={{ active: index === currentItemIndex }}
                    handleClick={() => onSelect(item)}
                    active={selected.indexOf(item.id) !== -1}
                    query={query}
                />
            );
        },
        [currentItemIndex, onSelect, query, selected],
    );

    const listItems =
        maxCount > 0 ? (
            <VirtuosoList
                className="hide-v-scroll"
                sizeItem={32}
                activeItemIndex={activeItemIndex}
                filteredItems={items}
                renderItem={renderItem}
                scrollerRef={scrollerRef}
            />
        ) : (
            emptyField
        );

    return (
        <div className={styles.wrapper} data-query={!!query} style={{ minWidth: 280 }}>
            <CurrentUsersMenuItem
                user={mainStore.currentUser}
                query={query}
                isSelected={hasSubscribe}
                onSelect={onSelect}
            />
            {list.length > 0 && (
                <>
                    <Space height={4} />
                    <InputField.Root>
                        <InputField.Slot side="left">
                            <CustomIcon icon={CustomIconName.SEARCH} className="t-mutted" />
                        </InputField.Slot>
                        <InputField.Input
                            value={query}
                            onChange={(event) => setQuery(event.target.value || '')}
                            placeholder={placeholder}
                            autoFocus
                        />
                        {settingsStore.query ? (
                            <Button
                                icon={<CustomIcon icon={CustomIconName.CROSS_M} />}
                                minimal
                                onClick={() => setQuery('')}
                            />
                        ) : null}
                    </InputField.Root>
                    <Space height={6} />
                    {listItems}
                </>
            )}
        </div>
    );
}

export default observer(UserMultiSelectPopover);
