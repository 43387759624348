import { mainStore } from 'store/models/MainStore';

import { setUserOrganization, setUserProperties } from 'utils/amplitude';
import { CURRENT_USER_ID, CURRENT_USER_IS_OWNER, IS_APP } from 'utils/consts';

export function sendConnectedData(user, id, orgName, payments = {}, logo) {
    if (user && id && IS_APP) {
        mainStore.socket.emit('add user', { user: CURRENT_USER_ID, id });

        setUserOrganization(id);
        setUserProperties(
            {
                name: user.name,
                orgName: orgName || '',
                ...payments,
                owner: CURRENT_USER_IS_OWNER,
                role: user.role,
                theme: user.theme,
            },
            user.avatar,
            user.email,
            logo
        );
    }
}
