import { CURRENT_USER_ID } from 'utils/consts';

export function parseUserVotes(votes = null, userVotes) {
    if (!votes) {
        userVotes.clear();
        return { unvoted: true, skipped: false };
    }

    const rawVotes = votes.find((el) => el.user_id === CURRENT_USER_ID);

    if (!rawVotes || !rawVotes.votes) {
        userVotes.clear();
        return { unvoted: rawVotes?.unvoted ?? true, skipped: rawVotes?.skipped ?? false };
    }

    rawVotes.votes.split(',').forEach((el) => {
        const [criterion_id, value] = el.split(':');
        userVotes.set(+criterion_id, +value);
    });

    return Object.assign({}, rawVotes);
}
