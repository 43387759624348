import React from 'react';

import { MenuItem } from '@blueprintjs/core';

import highlightText from 'utils/highlightText';

import DictionaryValueItem from 'components/DictionaryValueItem';

export function itemRenderer(item, { modifiers, handleClick, query }, hideColor) {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    return (
        <MenuItem
            style={{ height: 28, paddingRight: 2 }}
            intent=""
            className="flex flex-a-center"
            active={modifiers.active}
            key={item.id}
            onClick={handleClick}
            text={
                <DictionaryValueItem
                    color={hideColor ? undefined : item.color}
                    name={highlightText(item.name, query)}
                />
            }
        />
    );
}
