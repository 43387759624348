import { styled } from 'stitches.config';

import Flex from 'components/Flex';

export const StToolbar = styled(Flex, {
    padding: 4,
    variants: {
        theme: {
            dark: {
                background: '$bg3',
            },
            light: {
                background: '#fff',
            },
        },
        popover: {
            true: {
                borderRadius: 4,
                boxShadow: '0 0 0 1px rgba(0,0,0,.1), 0 2px 4px rgba(0,0,0,.2), 0 8px 24px rgba(0,0,0,.2)',
                border: '1px solid rgba(255, 255, 255, 0.05)',
            },
        },
    },
});
