import React from 'react';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';

const HiddenContent = React.memo(({ tooltip = 'Nobody can see scores till', revealAt }) => {
    return (
        <DarkTooltip content={`${tooltip} ${revealAt}`}>
            <CustomIcon icon={CustomIconName.EYE_SLASH} className="o-1" />
        </DarkTooltip>
    );
});

export default HiddenContent;
