import React from 'react';

import { StCardStripeMainCounter } from 'atoms/StCardStripe';

import DarkTooltip from 'components/DarkTooltip';

function VotingCounter({ count, voters }) {
    const tooltip = voters ? 'Number of voters for the idea' : 'Total upvotes for the idea';
    return (
        <DarkTooltip content={tooltip} fast>
            <StCardStripeMainCounter data-test="votesCount">{count || 0}</StCardStripeMainCounter>
        </DarkTooltip>
    );
}

export default VotingCounter;
