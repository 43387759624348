import React from 'react';

import { observer } from 'mobx-react-lite';

import * as IdeaStripeComponents from 'modules/PublicIdeaStripe/components';
import { IdeaInternalLink } from 'modules/PublicIdeaStripe/IdeaInternalLink';
import styles from 'modules/PublicIdeaStripe/PublicIdeaStripe.module.sass';

import { StCardStripeOpacityElement } from 'atoms/StCardStripe';

import Flex from 'components/Flex';
import RoundCounter from 'components/RoundCounter';

const PublicIdeaStripeTitle = ({ idea }) => {
    const hasUsersList = idea.votersData.length > 0;
    const len = idea.votersCount - idea.votersData.length;

    return (
        <>
            <IdeaInternalLink idea={idea} />
            <IdeaStripeComponents.VotingCounter
                count={idea.votesCount}
                voters={!idea.board.voting_settings.card_display_upvotes}
            />
            <StCardStripeOpacityElement as={Flex} grow css={{ marginLeft: 4 }}>
                <IdeaStripeComponents.AddHandleVote issue={idea} />

                {hasUsersList && (
                    <div className={styles.users} data-test="voting-users">
                        {idea.votersData.map((item) => (
                            <IdeaStripeComponents.VotingIssueUser key={item.email} item={item} />
                        ))}
                        {len > 0 && <RoundCounter size={24} count={len} />}
                    </div>
                )}
            </StCardStripeOpacityElement>
        </>
    );
};

export default observer(PublicIdeaStripeTitle);
