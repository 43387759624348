import { PROVIDER_ASANA, PROVIDER_DUCALIS } from 'utils/consts';
import { fixTipTapContent } from 'utils/fixTipTapContent';

export function clearDescription(description, provider) {
    const clearDesc = String(description || '');
    if (provider === PROVIDER_ASANA) {
        return clearDesc.replace('<body></body>', '');
    } else if (provider === PROVIDER_DUCALIS && clearDesc.match(/<p[^>]*>.*<img.*<\/p>/m)) {
        return fixTipTapContent(clearDesc);
    }
    return clearDesc;
}
