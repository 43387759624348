import React from 'react';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import MetaItem from 'components/MetaItem';

function CreatedField({ issue }) {
    if (!issue?.create_date && !issue?.created) {
        return null;
    }

    return (
        <MetaItem title="Created">
            <div style={{ lineHeight: '22px' }}>
                {DateTime.fromISO(issue?.create_date || issue?.created).toRelative()}
            </div>
        </MetaItem>
    );
}

export default observer(CreatedField);
