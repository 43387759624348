import React, { useEffect, useState } from 'react';

import { NumericInput } from '@blueprintjs/core';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import FinalScoreTooltip from 'modules/InsideIssue/components/FinalScore/components/FinalScoreTooltip';

import { Idea } from 'store/models/Idea';
import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { ENTER, PAYWALL_LIMITS, POPOVER_PROPS_DIV } from 'utils/consts';
import delay from 'utils/delay';
import getKeyCode from 'utils/getKeyCode';
import validateVote, { getVoteError } from 'utils/validateVote';

import { showToaster } from 'components/AppToaster';
import { DisabledScoreValueTooltip } from 'components/DisabledScoreValueTooltip/DisabledScoreValueTooltip';
import UpgradePlanBtn from 'components/UpgradePlanBtn';

import { StFakeValueInput } from './StFakeValueInput';
import { StValueInputWrapper } from './StValueInputWrapper';

function ValueInput({ value = '', criterion, custom, style = {}, className, disabled, isScore }) {
    const [vote, setVote] = useState(value);
    const [success, setSuccess] = useState(false);
    const [focus, setFocus] = useState(false);

    useEffect(() => {
        success && delay(1000).then(() => setSuccess(false));
    }, [success]);

    useEffect(() => {
        setVote((state) => (state !== value ? value : state));
    }, [value]);

    const classes = classNames(className, 't-r-input');

    const customStyle = { transition: 'all .2s', ...style, fontSize: 13, lineHeight: '24px' };

    if (custom && !mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.CUSTOM_VOTE)) {
        return (
            <StValueInputWrapper>
                <StFakeValueInput focus={false}>
                    <UpgradePlanBtn
                        {...POPOVER_PROPS_DIV}
                        paywall={PAYWALL_LIMITS.CUSTOM_VOTE}
                        text="to change custom vote"
                    >
                        <NumericInput
                            buttonPosition="none"
                            small
                            disabled
                            className={classes}
                            style={customStyle}
                            value={vote}
                        />
                    </UpgradePlanBtn>
                </StFakeValueInput>
            </StValueInputWrapper>
        );
    }

    if (!issuesList.activeIssue) {
        return null;
    }

    async function save(criterion, vote) {
        if (issuesList.activeIssue instanceof Idea) {
            await issuesList.activeIssue.parentIssue.vote({ criterion: criterion, vote, custom });
        } else {
            await issuesList.activeIssue.vote({ criterion: criterion, vote, custom });
        }
        setSuccess(true);
    }

    function onBlur(event) {
        setFocus(false);
        if (validateVote(vote === null ? '' : vote, criterion, true)) {
            if (vote !== value) {
                save(criterion, vote);
            }
        } else {
            showToaster({ message: getVoteError(criterion), intent: 'danger', timeout: 4000 });
            event.target.focus();
            return false;
        }
    }

    function keyDown(e) {
        if (getKeyCode(e) === ENTER) {
            e.target.blur();
        }
    }

    const canUserEditScore = isScore
        ? issuesList.issue.board.userCanChangeCriteriaFinalScore(mainStore.currentUser.id, criterion.id)
        : !disabled;

    const input = (
        <NumericInput
            buttonPosition="none"
            small
            disabled={!canUserEditScore}
            intent={success ? 'success' : undefined}
            className={classes}
            style={customStyle}
            value={vote}
            onBlur={onBlur}
            onValueChange={(a, b) => setVote(b)}
            selectAllOnFocus
            onKeyDown={keyDown}
            onFocus={() => setFocus(true)}
        />
    );

    if (!canUserEditScore && custom) {
        return (
            <StValueInputWrapper>
                <StFakeValueInput focus={false}>
                    <FinalScoreTooltip board={issuesList.activeIssue.board} isInput>
                        {input}
                    </FinalScoreTooltip>
                </StFakeValueInput>
            </StValueInputWrapper>
        );
    }

    if (!canUserEditScore) {
        return (
            <DisabledScoreValueTooltip
                date={issuesList.activeIssue.board.sprint?.ends_at}
                isAdmin={mainStore.currentUser?.isAdmin}
            >
                <StValueInputWrapper>
                    <StFakeValueInput focus={focus}>{input}</StFakeValueInput>
                </StValueInputWrapper>
            </DisabledScoreValueTooltip>
        );
    }

    return (
        <StValueInputWrapper>
            <StFakeValueInput focus={focus}>{input}</StFakeValueInput>
        </StValueInputWrapper>
    );
}

export default observer(ValueInput);
