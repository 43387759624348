import React from 'react';

import { Popover } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { defaultPopoverProps, IS_PUBLIC_BOARD } from 'utils/consts';
import logEvent from 'utils/logEvent';

import ButtonLink from 'components/ButtonLink';
import { CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';

export const AssessmentGridLink = observer(() => {
    const board = mainStore.activeBoard;

    if (!board) {
        return null;
    }

    function onClick() {
        logEvent('Click on Assessment grid popover link');
    }

    if (IS_PUBLIC_BOARD || !mainStore.activeBoard.currentUserInBoard) {
        return (
            <DarkTooltip fast content="Nothing evaluated yet" position="right">
                <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
            </DarkTooltip>
        );
    }

    return (
        <Popover
            {...defaultPopoverProps}
            position="right"
            interactionKind="hover"
            className="flex-grow"
            content={
                <div style={{ padding: '4px 8px' }}>
                    Nothing evaluated yet.
                    <br />
                    To get a priority score go to:
                    <div className="h-5" />
                    <ButtonLink
                        block={false}
                        border
                        minimal={false}
                        color="primary"
                        onClick={onClick}
                        size={24}
                        to={`/board/${board.id}/unvoted`}
                        title="Evaluation"
                        icon={CustomIconName.EVALUATION}
                    />
                </div>
            }
        >
            <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
        </Popover>
    );
});
