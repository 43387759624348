import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { PAYWALL_LIMITS } from 'utils/consts';

import ValueInput from 'components/MasterTable/components/CellAlignment/ValueInput';

export const CriterionScore = observer(({ criterion, votes }) => {
    const vote = votes?.get(criterion.id);

    const score = vote?.value ?? '';

    return (
        <td className="t-nw" key={criterion.id}>
            <ValueInput
                payWall={!mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.CUSTOM_VOTE)}
                value={score}
                criterion={criterion}
                custom
                isScore
            />
        </td>
    );
});
