import React from 'react';

import { Button } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { Idea } from 'store/models/Idea';

import logEvent from 'utils/logEvent';

import DarkTooltip from 'components/DarkTooltip';

function JoinBtn({ comment, className }) {
    const { sub } = useParams();

    if (comment.issue instanceof Idea || !comment.canJoin) {
        return null;
    }

    function joinToQuestion() {
        logEvent('Join to Question');
        comment.issue.sendSubscribe({ question: comment, sub });
    }

    return (
        <DarkTooltip content="I have the same question">
            <Button className={className} onClick={joinToQuestion} small minimal text="Join" />
        </DarkTooltip>
    );
}

export default observer(JoinBtn);
