import React from 'react';

import { observer } from 'mobx-react-lite';

import { CreatedDate } from 'modules/PublicIdeaStripe/components/MergedIssues/MergedIssue/CreatedDate';

import { mainStore } from 'store/models/MainStore';

import Avatar from 'components/Avatar';
import ReadOnlyDescription from 'components/ReadOnlyDescription';

import MergedIssueDotMenu from './DotMenu';

import styles from './MergedIssue.module.sass';

export const MergedIssue = observer(({ boardId, issue }) => {
    return (
        <div className={styles.mergedIssue} key={issue.id}>
            <div>
                <b>{issue.name}</b>
            </div>
            <div className="h-5" />

            <ReadOnlyDescription className="t-size-s" description={issue.description} />

            {issue.author && (
                <>
                    <div className="h-10" />
                    <div className="flex flex-a-center flex-wrap t-size-s gap-4">
                        <CreatedDate created={issue.created} />
                        {}
                        <div className="o-6">by</div>
                        <Avatar size={20} user={issue.author} />
                        {issue.author.name}
                        <div className="flex-grow" />

                        {!mainStore.currentUser?.isViewer && (
                            <MergedIssueDotMenu className={styles.menu} issue={issue} boardId={boardId} />
                        )}
                    </div>
                </>
            )}
        </div>
    );
});
