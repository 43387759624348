import React, { useCallback, useRef, useState } from 'react';

import { Suggest } from '@blueprintjs/select';
import debounce from 'lodash/debounce';

import { POPOVER_PROPS_DIV } from 'utils/consts';
import delay from 'utils/delay';
import failRequest from 'utils/failRequest';
import { isEmail } from 'utils/validators';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Spinner from 'components/Spinner';
import VirtuosoList from 'components/VirtuosoList';

import { itemRenderer } from './itemRenderer';

const INITIAL_STATE = { email: '' };

function activeItemIndex(list, active) {
    return list.findIndex(({ email }) => email === active?.email);
}

export const AddHandleVotePopoverContent = ({ onClose, issue }) => {
    const ref = useRef(null);
    const buttonRef = useRef(null);
    const inputRef = useRef(null);
    const abortControllerRef = useRef(new AbortController());
    const [loading, setLoading] = useState(false);
    const [wait, setWait] = useState(false);
    const [list, setList] = useState([]);
    const [error, setError] = useState(false);
    const [vote, setVote] = useState(INITIAL_STATE);
    const [query, setQuery] = useState('');

    async function sendVote() {
        if (!vote.email || !isEmail(vote.email)) {
            return setError(true);
        }
        setLoading(true);
        try {
            await issue.customVote(vote);
            onClose();
            setVote(INITIAL_STATE);
        } catch (e) {
            failRequest(e);
        } finally {
            setLoading(false);
        }
    }

    const searchRequest = useCallback(
        async (query) => {
            abortControllerRef.current?.abort();
            abortControllerRef.current = new AbortController();
            setWait(true);
            const data = await issue.board.suggestVoters(query, abortControllerRef);
            if (data) {
                setWait(false);
                setList(data);
            }
        },
        [issue.board]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchRequestDebounced = useCallback(
        debounce((query) => searchRequest(query), 400),
        []
    );

    const onQueryChange = useCallback(
        (query) => {
            setQuery(query);
            searchRequestDebounced(query);
        },
        [searchRequestDebounced]
    );

    function onItemSelect(item) {
        setVote(item);
        setError(false);
        delay(300).then(() => buttonRef.current?.focus());
    }

    function clear() {
        setQuery('');
        setVote(INITIAL_STATE);
        delay(300).then(() => inputRef.current?.focus());
    }

    return (
        <div ref={ref} style={{ padding: 15, maxWidth: 380 }}>
            <b>Vote on Behalf of a User</b>
            <div style={{ margin: '10px 0' }}>
                <Suggest
                    selectedItem={vote}
                    inputValueRenderer={(item) => item.email}
                    itemListRenderer={(props) => (
                        <VirtuosoList
                            width={328}
                            className="hide-v-scroll"
                            {...props}
                            sizeItem={30}
                            activeItemIndex={activeItemIndex}
                            noResults={
                                <Button
                                    minimal
                                    block
                                    disabled={true}
                                    text={!query ? 'Start typing for suggestions' : 'No results.'}
                                />
                            }
                        />
                    )}
                    popoverProps={{
                        ...POPOVER_PROPS_DIV,
                        minimal: true,
                        usePortal: false,
                    }}
                    createNewItemFromQuery={(query) => ({ id: -1, email: query })}
                    items={list}
                    query={query}
                    onQueryChange={onQueryChange}
                    itemRenderer={itemRenderer}
                    onItemSelect={onItemSelect}
                    createNewItemRenderer={(query, active, onItemSelect) => (
                        <Button
                            block
                            minimal
                            icon={CustomIconName.PLUS}
                            text={
                                <>
                                    Add "<b>{query}</b>"
                                </>
                            }
                            active={active}
                            onClick={onItemSelect}
                        />
                    )}
                    inputProps={{
                        inputRef: inputRef,
                        autoFocus: true,
                        placeholder: 'Search by name or email...',
                        leftElement: (
                            <Flex center style={{ height: 30, width: 30 }}>
                                <CustomIcon className="t-mutted" icon={CustomIconName.SEARCH} />
                            </Flex>
                        ),
                        rightElement:
                            query.length >= 1 ? (
                                <Flex center style={{ height: 30, width: 30 }}>
                                    <Button
                                        loading={wait}
                                        size={24}
                                        minimal
                                        icon={CustomIconName.CROSS_M}
                                        onClick={clear}
                                    />
                                </Flex>
                            ) : undefined,
                    }}
                />
                {error && <span className="t-err">Email is invalid</span>}
            </div>
            <Button
                btnRef={buttonRef}
                disabled={!vote?.email}
                onClick={sendVote}
                text={(loading && <Spinner size={20} />) || 'Vote'}
                block
                border
                color="primary"
                size={42}
            />
            <div className="h-5" />
            <div className="o-4">The user will get an email that you voted on their behalf</div>
        </div>
    );
};
