import React from 'react';

import { StButton } from 'atoms/StButton';

import Flex from 'components/Flex';
import PlatformIcon from 'components/PlatformIcon';

export function itemRenderer(item, { modifiers, handleClick }) {
    if (!modifiers.matchesPredicate || modifiers.disabled) {
        return null;
    }

    const text = item.key ? `${item.key}: ${item.name}` : item.name;

    return (
        <StButton
            active={modifiers.active}
            size={42}
            minimal
            block
            className="bp5-popover-dismiss"
            onClick={handleClick}
            title={text}
            gap={8}
            css={{ textAlign: 'left', fontWeight: 400 }}
        >
            <Flex shrink className="t-mutted">
                <PlatformIcon provider={item.provider} />
            </Flex>
            {item.key && <div className="t-mutted flex-shrink">{item.key}</div>}
            <div className="t-crop flex-grow">{item.name}</div>
        </StButton>
    );
}
