import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';

import { ANNOUNCE_STATUS, SERVICE_NAME } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Tooltip from 'components/Tooltip';

const AnnounceAITextApprove = ({ announce, ...props }) => {
    const [loader, setLoader] = useState(false);
    const [state, setState] = useState(announce.text_status);

    useEffect(() => {
        setState((state) => (announce.text_status !== state ? announce.text_status : state));
    }, [announce.text_status]);

    const handleClickApprove = async () => {
        setLoader(true);
        await announce.update({
            text_status: state !== ANNOUNCE_STATUS.APPROVED ? ANNOUNCE_STATUS.APPROVED : ANNOUNCE_STATUS.DRAFT,
        });
        setLoader(false);
    };

    return (
        <Tooltip
            side="left"
            contentClassName="limit limit-300"
            fast
            content={
                state === ANNOUNCE_STATUS.AI_READY
                    ? `Approve ${SERVICE_NAME} AI variant of the Public Idea Announcement`
                    : state === ANNOUNCE_STATUS.APPROVED
                      ? 'Change the Announcement to draft'
                      : 'Approve Public Idea Announcement'
            }
        >
            <Button
                color={state !== ANNOUNCE_STATUS.APPROVED ? 'primary' : undefined}
                onClick={handleClickApprove}
                size={24}
                loading={loader}
                border
                icon={state !== ANNOUNCE_STATUS.APPROVED ? CustomIconName.TICK : CustomIconName.DRAFT}
                text={state !== ANNOUNCE_STATUS.APPROVED ? 'Approve' : 'To Draft'}
                {...props}
            />
        </Tooltip>
    );
};

export default observer(AnnounceAITextApprove);
