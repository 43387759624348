import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';

import useUndoNotification from 'hooks/useUndoNotification';

import { mainStore } from 'store/models/MainStore';

import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import { DisabledScoreValueTooltip } from 'components/DisabledScoreValueTooltip/DisabledScoreValueTooltip';
import Tooltip from 'components/Tooltip';

function ResumeColumn({ issue, minimal = true, text, className }) {
    const [loading, setLoading] = useState(false);
    const notify = useUndoNotification();

    if (!issue.skipped) return null;

    async function resumeEvaluation() {
        setLoading(true);
        await issue.resumeEvaluation({ user_id: mainStore.currentUser.id });
        setLoading(false);
        notify(
            <>
                The issue has been moved back to <a href={`${issue.board.basePath}/unvoted/ag`}>Evaluation</a>
            </>,
            () => {
                logEvent('Resume evaluation - Undo');
                issue.skipEvaluation();
            },
        );
    }

    if (issue?.board?.disableEvaluation) {
        return (
            <DisabledScoreValueTooltip date={issue.board.sprint?.ends_at} isAdmin={mainStore.currentUser?.isAdmin}>
                <Button border disabled size={24} minimal icon={CustomIconName.FORWARD} />
            </DisabledScoreValueTooltip>
        );
    }

    return (
        <Tooltip content="Resume evaluation of this issue" side="top" align="start">
            <Button
                loading={loading}
                disabled={issue?.board?.disableEvaluation}
                text={text}
                className={className}
                size={24}
                border
                minimal={minimal}
                icon={CustomIconName.FORWARD}
                onClick={resumeEvaluation}
            />
        </Tooltip>
    );
}

export default observer(ResumeColumn);
