import React from 'react';

import { CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Popover from 'components/Popover';
import ToolBarButton from 'components/TextEditor/ToolBar/ToolBarButton';

export default function HeaderMenu({ editor }) {
    return (
        <Popover
            side="top"
            content={
                <Flex gap={4}>
                    {[1, 2, 3, 4, 5, 6].map((level) => (
                        <ToolBarButton
                            iconSize={20}
                            key={`header-${level}`}
                            icon={CustomIconName[`HEADER_${level}`]}
                            active={editor.isActive('heading', { level })}
                            onClick={() => editor.chain().focus().toggleHeading({ level }).run()}
                            disabled={editor.isActive('code')}
                        />
                    ))}
                </Flex>
            }
        >
            <ToolBarButton
                title="Heading"
                icon={CustomIconName.HEADER}
                active={editor.isActive('heading')}
                disabled={editor.isActive('code')}
            />
        </Popover>
    );
}
