import { runInAction } from 'mobx';

import api from 'api';

import { USER_STATUS } from 'utils/consts';
import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function toggle({ status }) {
    const oldStatus = this.status;

    oldStatus !== USER_STATUS.pending &&
        runInAction(() => {
            this.status = status;
        });

    try {
        await api.put(`/users/${this.id}/change-status`, toUrl({ status }));
    } catch (e) {
        failRequest(e);
        runInAction(() => {
            this.status = oldStatus;
        });
    }
}
