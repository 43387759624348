import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { Spinner } from '@blueprintjs/core';

import highlightText from 'utils/highlightText';

import Avatar from 'components/Avatar';
import { Button } from 'components/Button';
import Flex from 'components/Flex';
import VirtuosoList from 'components/VirtuosoList';

import { activeItemIndex } from './activeItemIndex';
import asyncItems from './asyncItems';
import { useMentionListNavigator } from './useMentionListNavigator';

const AsyncMentionList = forwardRef(({ query, ...props }, ref) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const { selectedIndex, selectItem, setSelectedIndex } = useMentionListNavigator(items, props.command, ref);

    useEffect(() => {
        async function search(query) {
            setLoading(true);
            try {
                const data = await asyncItems(query);
                setItems(data);
                setSelectedIndex(0);
            } finally {
                setLoading(false);
            }
        }

        search(query);
    }, [query, setSelectedIndex]);

    const renderItem = useCallback(
        (item, index) => {
            return (
                <Button
                    block
                    minimal
                    key={item.id}
                    active={index === selectedIndex}
                    onClick={() => selectItem(index)}
                    leftElm={<Avatar size={24} user={item} />}
                >
                    {highlightText(item.name, query)}
                </Button>
            );
        },
        [selectedIndex, query, selectItem]
    );

    return (
        <Flex
            ref={ref}
            column
            align="none"
            className="p-r"
            css={{ padding: 4, background: '$bg2', borderRadius: 4, border: '1px solid $grayA4' }}
        >
            {loading && items.length > 0 && (
                <Button loading={loading} block css={{ zIndex: 1, position: 'absolute', left: 4, top: 4, right: 4 }} />
            )}
            <VirtuosoList
                noResults={
                    loading ? (
                        <Button leftElm={<Spinner size={18} />} minimal block disabled text="..." />
                    ) : (
                        <Button minimal block disabled text="No results." />
                    )
                }
                className="hide-v-scroll"
                sizeItem={30}
                activeItemIndex={activeItemIndex}
                filteredItems={items}
                activeItem={items?.[selectedIndex]}
                maxCount={6}
                renderItem={renderItem}
            />
        </Flex>
    );
});

export default AsyncMentionList;
