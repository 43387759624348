import React from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import styles from './IssueCommentMeta.module.sass';

function IssueCommentMeta({ children, ...props }) {
    const classes = classNames(styles.meta, 'o-4');

    return (
        <span className={classes} {...props}>
            {children}
        </span>
    );
}

export default observer(IssueCommentMeta);
