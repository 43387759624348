import React from 'react';

import { styled } from 'stitches.config';

const StAttachLink = styled('span', {
    variants: {
        small: {
            true: {
                width: 70,
                height: 70,
                margin: 0,
                overflow: 'hidden',
                position: 'relative',
                a: {
                    background: 'rgba(0,0,0,.1)',
                    borderRadius: 4,
                    display: 'block',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    height: '100%',
                    padding: '50px 4px 0',
                    color: '$text-color !important',
                    opacity: 0.6,
                    '&:hover': {
                        opacity: 1,
                    },
                },
            },
        },
    },
});

export function AttachLink({ small, children, ...props }) {
    return (
        <StAttachLink small={small}>
            {children}
            <a className={props.gdrive ? 'gdrive' : ''} rel="noopener noreferrer" target="_blank" href={props.url}>
                {props.text}
            </a>
        </StAttachLink>
    );
}
