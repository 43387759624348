import React from 'react';

import { observer } from 'mobx-react-lite';

import { utilsStore } from 'store/models/UtilsStore';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Tooltip from 'components/Tooltip';

function HistoryButton({ announce }) {
    if (!announce || !announce.historyObject) return null;

    return (
        <Tooltip content="Change history" side="top">
            <Button
                size={24}
                onClick={() => utilsStore.setOpenAnnounceHistory(announce)}
                border
                icon={CustomIconName.BACKUP}
            />
        </Tooltip>
    );
}

export default observer(HistoryButton);
