import React from 'react';

import { Popover } from '@blueprintjs/core';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { POPOVER_PROPS_DIV } from 'utils/consts';

import UserMultiSelectPopover from './UserMultiSelectPopover';

/**
 * Multiselect from users list
 *
 * @param {Object} props
 * @param {Object[]} props.list
 * @param {boolean} props.hasSubscribe
 * @param {Function} props.onItemSelect
 * @param {JSX.Element} props.children
 * @param {number[]} props.selected
 * @param {string} props.position
 * @param {string} props.placeholder
 *
 * @return {JSX.Element}
 */
function UserMultiSelect({ children, position = 'bottom-left', ...props }) {
    const classes = classNames('bp5-select-popover filter-select-popover');

    return (
        <Popover
            {...POPOVER_PROPS_DIV}
            boundary="window"
            minimal
            popoverClassName={classes}
            content={<UserMultiSelectPopover {...props} />}
            position={position}
        >
            {children}
        </Popover>
    );
}

export default observer(UserMultiSelect);
