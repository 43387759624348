import React, { useCallback, useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react-lite';

import alertStore from 'store/models/Alert';
import { utilsStore } from 'store/models/UtilsStore';

import { ANNOUNCE_STATUS, POPOVER_PROPS_DIV, UNSAVED_MODEL_ID } from 'utils/consts';

import DarkTooltip from 'components/DarkTooltip';
import PopoverAIText from 'components/PopoverAIText/PopoverAIText';

import CreateAnnounceButton from './CreateAnnounceButton';
import { NewAnnounceAIRewriteText } from './NewAnnounceAIText';

function CreateAnnounceButtonWrapper({ idea }) {
    const [open, setOpen] = useState(false);
    const [block, setBlock] = useState(false);
    const [loader, setLoader] = useState(false);
    const confirm = useRef(false);
    const abortController = useRef(null);

    useEffect(() => {
        utilsStore.toggleTabNav(open);
    }, [open]);

    useEffect(() => {
        return () => {
            utilsStore.toggleTabNav(false);
            abortController.current?.abort();
        };
    }, []);

    const handleClickGenerate = async () => {
        setLoader(true);
        setOpen(true);
        abortController.current = new AbortController();

        try {
            await idea.generateAIAnnounceDesc(abortController.current);
            await idea.generateAIAnnounceName(abortController.current, true);
        } finally {
            setLoader(false);
            abortController.current = null;
        }
    };
    const handleClickCancel = () => {
        abortController.current?.abort();
        confirm.current = true;
        setOpen(false);
        setBlock(false);
        idea.clearAIText();
    };

    const handleClickConfirm = () => {
        confirm.current = true;
        setOpen(false);
        idea.createIdeaAnnounce({ name: idea.aiName, description: idea.aiText, text_status: ANNOUNCE_STATUS.AI_READY });
        idea.clearAIText();
    };

    const onStop = useCallback(() => {
        try {
            abortController.current.abort();
        } catch (e) {
            console.error('fail abort', e);
        }
    }, []);

    async function create() {
        setBlock(true);
        if (idea.board?.allowAnnounceAI) {
            setOpen(true);
            handleClickGenerate().finally(() => setBlock(false));
        }
    }

    const onClose = () => {
        if (confirm.current) {
            confirm.current = false;
            return;
        }
        alertStore.confirm({
            message: 'Do you want to discard the AI response?',
            onConfirm: handleClickCancel,
            confirmButtonText: 'Discard',
        });
    };

    return (
        <div className="p-r">
            <PopoverAIText
                onClose={onClose}
                open={open}
                content={
                    <NewAnnounceAIRewriteText
                        loading={loader}
                        idea={idea}
                        onConfirm={handleClickConfirm}
                        onCancel={handleClickCancel}
                        onStop={onStop}
                        onRepeat={handleClickGenerate}
                    />
                }
            />
            <DarkTooltip
                {...POPOVER_PROPS_DIV}
                position="left"
                isOpen={open ? false : undefined}
                popoverClassName="limit limit-300"
                content="Create a public Idea Announcement for Future Release Notes"
            >
                <CreateAnnounceButton
                    needLoader
                    disabled={block || idea.id === UNSAVED_MODEL_ID}
                    onClick={create}
                    useAI
                />
            </DarkTooltip>
        </div>
    );
}

export default observer(CreateAnnounceButtonWrapper);
