import React from 'react';

import { Switch } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { utilsStore } from 'store/models/UtilsStore';

import { FAST_INPUT, SHOW_TOOLTIPS } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import VideoTooltip from 'components/VideoTooltip';

export const ASSESSMENT_VIEW_SETTINGS = {
    [SHOW_TOOLTIPS]: {
        tooltip: (children, side = 'left') => (
            <VideoTooltip
                side={side}
                text="Show a tooltip with full description of criterion while evaluating."
                video="https://res.cloudinary.com/ducalis/video/upload/v1594973450/Criterion_description_lcweq8.mp4"
            >
                {children}
            </VideoTooltip>
        ),
        icon: CustomIconName.TEXT_LONG,
        rotate: true,
        text: 'Criteria Description',
    },
    [FAST_INPUT]: {
        tooltip: (children, side = 'left') => (
            <VideoTooltip
                side={side}
                text="Auto jump to next cell. 3x faster."
                video="https://res.cloudinary.com/ducalis/video/upload/v1594981586/Fast_Input_x1odrw.mp4"
            >
                {children}
            </VideoTooltip>
        ),
        icon: CustomIconName.BOLT,
        text: 'Fast Input',
    },
};

const LIST = [FAST_INPUT, SHOW_TOOLTIPS];

const SettingsMenuItem = observer(function SettingsMenuItem({ field }) {
    const value = utilsStore.settings[field];

    function toggleSettings() {
        utilsStore.updateSettings({ field, value: !value });
    }

    const item = (
        <Button
            minimal
            block
            text={ASSESSMENT_VIEW_SETTINGS[field].text}
            onClick={toggleSettings}
            iconProps={{ color: value && 'primary' }}
            icon={ASSESSMENT_VIEW_SETTINGS[field].icon}
            rightElm={
                <Switch
                    name={field}
                    onChange={() => false}
                    alignIndicator="right"
                    className="switch--slim"
                    checked={value}
                />
            }
        />
    );

    if (ASSESSMENT_VIEW_SETTINGS[field].tooltip) {
        return ASSESSMENT_VIEW_SETTINGS[field].tooltip(item);
    }
    return item;
});

export default function SettingsBtn() {
    return LIST.map((field) => <SettingsMenuItem key={field} field={field} />);
}
