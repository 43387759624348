import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { AnchorButton } from 'components/Button';
import CustomCallout from 'components/CustomCallout';
import { CustomIconName } from 'components/CustomIcon';
import Space from 'components/Space';

const VotersListCustomFieldsBanner = () => {
    const onCloseBanner = () => {
        mainStore.currentUser.closeNote('voters-custom-fields');
    };

    return (
        <div>
            <Space height={6} />
            <CustomCallout icon={CustomIconName.TEAM_ALT} border as="div" color="primary" onClose={onCloseBanner}>
                <div>
                    Voters Additional Context. Include additional data like voters’ spending, customer plan, signup
                    date, or your own custom fields.{' '}
                    <AnchorButton
                        css={{ fontSize: 14, fontWeight: 500 }}
                        likeLink
                        active
                        target="_blank"
                        color="primary"
                        href="https://help.ducalis.io/knowledge-base/single-sign-on-sso-for-authorizing-users-on-voting-boards/#add-custom-fields"
                    >
                        Read more
                    </AnchorButton>
                </div>
            </CustomCallout>
            <Space height={6} />
        </div>
    );
};

export default observer(VotersListCustomFieldsBanner);
