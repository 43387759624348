import React from 'react';

import { observer } from 'mobx-react-lite';

import { Idea } from 'store/models/Idea';
import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { CustomIconName } from 'components/CustomIcon';

import { BlocksList } from './BlocksList';
import { BlockTitle } from './BlockTitle';
import { removeLinking } from './removeLinking';

function Blocks() {
    if (!issuesList.activeIssue) return null;

    const issue = issuesList.activeIssue instanceof Idea ? issuesList.activeIssue.parentIssue : issuesList.activeIssue;

    async function removeBLocks(blockIssueId) {
        await removeLinking({ linked_issue_id: issue.id, issueId: blockIssueId });
    }

    async function removeBlockedBy(blockIssueId) {
        await removeLinking({ issueId: issue.id, linked_issue_id: blockIssueId });
    }

    return (
        <>
            <BlocksList
                list={mainStore.blocksIssues.get(issue.id)}
                onRemove={removeBLocks}
                removeTooltip="Remove blocked issue"
                title={<BlockTitle title="Blocks" icon={CustomIconName.BLOCKS} classNameIcon="t-blocked" />}
            />
            <BlocksList
                list={mainStore.blockedByIssues.get(issue.id)}
                onRemove={removeBlockedBy}
                removeTooltip="Remove blocking issue"
                title={<BlockTitle title="Blocked By" icon={CustomIconName.BLOCKED_BY} classNameIcon="t-blocked" />}
            />
            <BlocksList
                list={mainStore.linked.get(issue.id)}
                onRemove={removeBlockedBy}
                removeTooltip="Remove link"
                title={<BlockTitle title="Relates to" icon={CustomIconName.REFERENCED} />}
            />
            <BlocksList
                list={mainStore.linkedBy.get(issue.id)}
                onRemove={removeBLocks}
                removeTooltip="Remove link"
                title={<BlockTitle title="Relates to" icon={CustomIconName.REFERENCED} />}
            />
        </>
    );
}

export default observer(Blocks);
