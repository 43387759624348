import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { StCellWrapperBtnDropdown } from 'atoms/StCellWrapperBtnDropdown';

import { SORT_DIRECTION } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import FormulaDropDownLink from 'components/MasterTable/components/ColumnDropDown/FormulaDropDownLink';
import Popover, { PopoverClose } from 'components/Popover';

import AlignmentDropDownLink from './AlignmentDropDownLink';
import VotingDropDownLink from './VotingDropDownLink';

const ICONS = {
    [SORT_DIRECTION.asc]: CustomIconName.ARROW_UP,
    [SORT_DIRECTION.desc]: CustomIconName.ARROW_DOWN,
};

const MENU_ITEMS_TEXT = {
    [SORT_DIRECTION.asc]: 'Sort ascending',
    [SORT_DIRECTION.desc]: 'Sort descending',
};

const ColumnDropDownMenu = observer(({ fieldId, onSort, disableSort, isActive, sortDirection, children }) => {
    if (disableSort) {
        return (
            <Flex column align="none">
                {fieldId === 'alignmentValue' ? <AlignmentDropDownLink /> : null}
                {fieldId === 'idea_votes_count' ? <VotingDropDownLink /> : null}
                {fieldId === 'totalValue' ? <FormulaDropDownLink /> : null}
                {children}
            </Flex>
        );
    }

    return (
        <Flex column align="none">
            {[SORT_DIRECTION.asc, SORT_DIRECTION.desc].map((direction) => (
                <PopoverClose asChild key={direction}>
                    <Button
                        minimal
                        active={isActive && sortDirection === direction}
                        icon={ICONS[direction]}
                        onClick={() => onSort(direction)}
                        text={MENU_ITEMS_TEXT[direction]}
                    />
                </PopoverClose>
            ))}
            {fieldId === 'alignmentValue' ? <AlignmentDropDownLink /> : null}
            {fieldId === 'idea_votes_count' ? <VotingDropDownLink /> : null}
            {fieldId === 'totalValue' ? <FormulaDropDownLink /> : null}
            {children}
        </Flex>
    );
});

function ColumnDropDown({ field, sortBy, sortDirection, children, disableSort, sortType }) {
    if (disableSort && !children) return null;

    const fieldId = String(field.id || field.field);

    if (!mainStore.activeModel) {
        return (
            <StCellWrapperBtnDropdown>
                <Button
                    data-show={sortBy === fieldId}
                    active={sortBy === fieldId}
                    size={24}
                    minimal
                    disabled={sortBy !== fieldId}
                    icon={sortBy === fieldId ? ICONS[sortDirection] : CustomIconName.ARROW_UP}
                    css={{ pointerEvents: 'none' }}
                />
            </StCellWrapperBtnDropdown>
        );
    }

    function onSort(direction) {
        mainStore.activeModel.setSort({ field, direction });
    }

    const isActive = sortBy === fieldId && sortType === field.type;

    return (
        <StCellWrapperBtnDropdown>
            <Popover
                side="bottom"
                align="start"
                content={
                    <ColumnDropDownMenu fieldId={fieldId} isActive={isActive} onSort={onSort}>
                        {children}
                    </ColumnDropDownMenu>
                }
            >
                <Button
                    block
                    border
                    minimal={!isActive}
                    color={isActive ? 'primary' : undefined}
                    size={20}
                    icon={isActive ? ICONS[sortDirection] : CustomIconName.DOTES_H}
                    data-show={isActive}
                />
            </Popover>
        </StCellWrapperBtnDropdown>
    );
}

export default observer(ColumnDropDown);
