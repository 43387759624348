/**
 * @description Constants for field types
 * @module FieldType
 * @namespace FieldType
 * @property {string} alignment - Alignment field type
 * @property {string} criterion - Criterion field type
 * @property {string} voting - Voting field type
 */
const FIELD_TYPE = {
    alignment: 'alignment',
    criterion: 'criterion',
    voting: 'voting',
};

export default FIELD_TYPE;
