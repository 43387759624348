import React, { useMemo } from 'react';

import { Popover } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';

import { StCellWrapperBtnDropdown } from 'atoms/StCellWrapperBtnDropdown';

import { defaultPopoverProps, FILTER_TYPES } from 'utils/consts';

import { AnchorButton, Button } from 'components/Button';
import CellWrapper from 'components/CellWrapper';
import { CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import Emoji from 'components/Emoji';
import Flex from 'components/Flex';
import CompanyCustomFieldCell from 'components/MasterTable/components/CompanyCustomFieldCell';
import { calcFieldSize } from 'components/MasterTable/widthUtils';

import ViewStatus from '../ViewStatus';

const VoterMenu = observer(function VoterMenu({ user, setOpenRemoveDialog }) {
    return (
        <StCellWrapperBtnDropdown>
            <Popover
                {...defaultPopoverProps}
                isPortal={false}
                position="bottom-right"
                isOpen={utilsStore.cardIsHidden ? false : undefined}
                content={
                    <Flex column align="none" style={{ width: 252, padding: 4 }} gap={4}>
                        {user.count > 0 ? (
                            <Button
                                block
                                onClick={() => setOpenRemoveDialog({ user, onlyIdea: true })}
                                disabled={!user.count}
                                border
                                text="Delete only votes"
                                minimal
                            />
                        ) : (
                            <DarkTooltip content={'Viewer cannot be deleted'}>
                                <Button size={20} disabled border text="Delete Voter and its votes and ideas" minimal />
                            </DarkTooltip>
                        )}
                    </Flex>
                }
            >
                <Button border icon={CustomIconName.DOTES_H} size={24} />
            </Popover>
        </StCellWrapperBtnDropdown>
    );
});

export const useTableColumns = (customFields, showStats, setOpenRemoveDialog, last_status_update) => {
    return useMemo(() => {
        const columnsList = [
            {
                name: 'Voter’s Name',
                id: 'name',
                width: 180,
                disableSort: true,
                format: (user) => {
                    return (
                        <CellWrapper
                            showBtn
                            button={
                                mainStore.currentUser?.isAdmin && (
                                    <VoterMenu user={user} setOpenRemoveDialog={setOpenRemoveDialog} />
                                )
                            }
                        >
                            {showStats && user.read_at ? <ViewStatus last_status_update={last_status_update} /> : null}
                            <span className="flex-grow t-crop">{user.name}</span>
                        </CellWrapper>
                    );
                },
            },
            {
                name: 'Voter’s Email',
                id: 'email',
                width: 240,
                disableSort: true,
            },
            {
                name: 'Voter’s Company',
                id: 'organization_name',
                format: (item) => {
                    return (
                        <CellWrapper>
                            <span className="bp5-text-overflow-ellipsis">{item.company?.name}</span>
                        </CellWrapper>
                    );
                },
                width: 200,
                disableSort: true,
            },
            {
                name: 'Votes',
                disableSort: true,
                id: 'count',
                width: 70,
                type: FILTER_TYPES.INT,
            },
            {
                name: 'Reactions',
                disableSort: true,
                id: 'reactions',
                width: 86,
                format: (item) => (
                    <CellWrapper>
                        <Flex className="t-crop" gap={2}>
                            {item.reactions.map((emoji) => (
                                <Emoji emoji={emoji} key={emoji} />
                            ))}
                        </Flex>
                    </CellWrapper>
                ),
            },
            ...Object.entries(customFields).map(([id, value]) => {
                return {
                    type: value.type,
                    name: value.title,
                    disableSort: true,
                    id,
                    width: calcFieldSize(value.title),
                    format: (item) => <CompanyCustomFieldCell field={id} item={item} value={value} />,
                };
            }),
        ];

        if (customFields?.length === 0) {
            columnsList.push({
                name: (
                    <>
                        Add more fields?{' '}
                        <AnchorButton
                            likeLink
                            active
                            target="_blank"
                            href="https://help.ducalis.io/knowledge-base/single-sign-on-sso-for-authorizing-users-on-voting-boards/#add-custom-fields"
                        >
                            Read here
                        </AnchorButton>
                    </>
                ),
                disableSort: true,
                id: 'fake-link',
                format: () => null,
                width: 195,
            });
        }
        return columnsList;
    }, [customFields, showStats, setOpenRemoveDialog, last_status_update]);
};
