import { Intent } from '@blueprintjs/core';

import validateVote, { prepareNumber, validateNumber, getVoteError } from 'utils/validateVote';

import { showToaster } from 'components/AppToaster';

export const setValue = (event, criterion, issue, crCnt, singleFastInput) => {
    const value = event.target.value;

    if (validateVote(value, criterion)) {
        issue.changeVote({ value: prepareNumber(value), criterion, crCnt });
        if (singleFastInput && validateNumber(value, criterion)) {
            const tabIndex = +event.target.getAttribute('tabindex');
            const selector = `[tabindex="${tabIndex + 1}"]`;
            const el = document.querySelector(selector);
            if (el) {
                event.stopPropagation();
                setTimeout(() => el.focus(), 0);
            }
            if (selector) {
                event.target.blur();
            }
        }
    } else {
        return showToaster({
            message: getVoteError(criterion),
            intent: Intent.DANGER,
            timeout: 4000,
        });
    }
};
