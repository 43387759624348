import React, { useCallback, useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react-lite';

import useUndoNotification from 'hooks/useUndoNotification';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { StTextEditor } from 'atoms/StTextEditor';

import delay from 'utils/delay';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import DotDivider from 'components/DotDivider';
import Flex from 'components/Flex';
import Kbd, { KeyCombo } from 'components/Kbd';
import PlatformIcon from 'components/PlatformIcon';
import { UserQuestionSelector } from 'components/QuestionForm/UserQuestionSelector';
import Space from 'components/Space';
import { TOOLBAR_ACTIONS } from 'components/TextEditor/ToolBar/constants';
import Tooltip from 'components/Tooltip';

import styles from './QuestionInput.module.sass';

const QUESTION_EDITOR_ACTIONS = [
    [TOOLBAR_ACTIONS.bold, TOOLBAR_ACTIONS.italic],
    [TOOLBAR_ACTIONS.link],
    [TOOLBAR_ACTIONS.clear],
];

const QuestionForm = observer(({ defaultUser }) => {
    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState(defaultUser);
    const [message, setMessage] = useState('');
    const btnRef = useRef(null);
    const wrapperRef = useRef(null);
    const notify = useUndoNotification();

    const focusEditor = useCallback(() => {
        delay(100).then(() => {
            const editor = wrapperRef.current?.querySelector('.ProseMirror')?.editor;
            document.activeElement?.blur();
            editor && editor.commands.focus('end');
        });
    }, []);

    useEffect(() => {
        defaultUser && focusEditor();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendRequest = useCallback(async () => {
        if (user && message) {
            setLoader(true);

            await issuesList.questionIssue.sendQuestion({ message, assignee_id: user.id });

            notify(
                <>
                    You asked a question! Find it on <a href={mainStore.activeBoard.questionsPath}>Questions section</a>
                </>,
            );
            close();
        }
    }, [message, notify, user]);

    const selectUser = useCallback(
        (user) => {
            setUser(user);
            focusEditor();
        },
        [focusEditor],
    );

    const close = () => {
        issuesList.setQuestion({ issue: null, row: null });
    };

    return (
        <div className={styles.question}>
            <Flex gap={4} className={styles.question_title}>
                Ask{' '}
                <div className="flex-shrink">
                    <UserQuestionSelector
                        tabIndex={-1}
                        shouldReturnFocusOnClose={false}
                        onChange={selectUser}
                        users={mainStore.users.activeUsersWithoutMe}
                        user={user}
                    />
                </div>
                <DotDivider />
                <PlatformIcon className="o-6" provider={issuesList.questionIssue.provider} />
                <div className="t-crop flex-grow t-mutted">{issuesList.questionIssue.name}</div>
                <Button icon={CustomIconName.CROSS_M} minimal size={24} onClick={close} />
            </Flex>
            <Space height={20} />
            <StTextEditor
                ref={wrapperRef}
                autoFocus={!!defaultUser}
                hasMention
                placeholder="Describe what is unclear about the issue"
                description={message}
                onChange={setMessage}
                sendMethod={() => btnRef.current?.click()}
                actions={QUESTION_EDITOR_ACTIONS}
                css={{
                    '.ProseMirror': {
                        minHeight: 120,
                    },
                }}
            />
            <Space height={16} />
            <Flex className="t-mutted" gap={20}>
                <div className="t-crop flex-grow">
                    {(user && user.name) || 'User'} will be notified about your question
                </div>
                <Tooltip
                    content={
                        <>
                            Send a question
                            <DotDivider />
                            <KeyCombo>
                                <Kbd>cmd</Kbd>
                                <Kbd>Enter</Kbd>
                            </KeyCombo>
                        </>
                    }
                >
                    <Button
                        tabIndex={-1}
                        border
                        loading={loader}
                        color="primary"
                        onClick={sendRequest}
                        disabled={!message || !user}
                        ref={btnRef}
                        text="Send"
                    />
                </Tooltip>
            </Flex>
        </div>
    );
});

export default QuestionForm;
