import React from 'react';

import { SERVICE_NAME } from 'utils/consts';

import { AnchorButton } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

export default function QuickStartLink(props) {
    return (
        <AnchorButton
            block
            minimal
            icon={CustomIconName.VIDEO}
            target="_blank"
            href="https://youtu.be/fCWUjRoTBoI"
            text={`${SERVICE_NAME} Quick Start`}
            {...props}
        />
    );
}
