import { runInAction, toJS } from 'mobx';

import restApi from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function setVotingSettings(data) {
    logEvent('Organization voting settings change', { data });

    runInAction(() => {
        Object.entries(data).forEach(([field, value]) => {
            this.public_voting_settings[field] = value;
        });
    });

    try {
        await restApi.put('/organization/voting-settings', toUrl(data));
    } catch (e) {
        failRequest(e);
        await Promise.reject(e);
    }
}

export async function setBotSettings(provider, data) {
    logEvent('Organization voting settings change', { data });

    const oldData = this.ideas_bots_settings ? toJS(this.ideas_bots_settings[provider]) : null;

    runInAction(() => {
        if (this.ideas_bots_settings) {
            Object.assign(this.ideas_bots_settings[provider], data);
        } else {
            this.ideas_bots_settings = { [provider]: data };
        }
    });

    try {
        await restApi.put(`/organization/bot-settings/${provider}`, toUrl(data));
    } catch (e) {
        runInAction(() => {
            oldData ? Object.assign(this.ideas_bots_settings[provider], oldData) : (this.ideas_bots_settings = null);
        });
        failRequest(e);
    }
}

export async function setVotingEmailSettings(requestData) {
    runInAction(() => {
        Object.assign(this.voting_email_settings, requestData);
    });

    try {
        await restApi.put(`/organization/voting-email-settings`, toUrl(requestData));
        return null;
    } catch (e) {
        if (e.response?.status === 422) {
            return e.response.data?.reduce((res, el) => {
                res[el.field] = el.message;
                return res;
            }, {});
        }
        failRequest(e);
    }
}
