import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import IssueBlockWrapper from 'modules/InsideIssue/components/IssueBlockWrapper';

import { issuesList } from 'store/models/IssuesList';

import IssueComments from 'components/IssueComments';
import Space from 'components/Space';

function IdeaCardComments() {
    if (!issuesList.idea?.comments?.length) return null;

    return (
        <IssueBlockWrapper>
            <Space height={20} />
            <h3 className="t-w-sb">
                <FormattedMessage id="comment.title" defaultMessage="Comments" />
            </h3>
            <IssueComments comments={issuesList.idea.comments} />
        </IssueBlockWrapper>
    );
}

export default observer(IdeaCardComments);
