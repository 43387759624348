import React, { useState } from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { isJira, isYT, PROVIDER_DUCALIS } from 'utils/consts';

import ImagePreview from 'components/ImagePreview';
import ImageWrapper from 'components/ImageWrapper';
import { ImgThumb, AttachLink } from 'components/IssueDescription/components';
import Space from 'components/Space';

import styles from './NoneJiraAttachments.module.sass';

function allowAttachments(provider) {
    return !isJira(provider) && provider !== PROVIDER_DUCALIS;
}

function NoneJiraAttachments({ small, description, attachments = [], provider }) {
    const [index, setIndex] = useState(0);
    const [modal, setModal] = useState(false);
    let images = [];

    if (!attachments || attachments.length === 0 || !allowAttachments(provider)) {
        return null;
    }

    function toggleLightbox(index) {
        setModal(!modal);
        setIndex(index);
    }

    const list = attachments.map((attachment, index) => {
        let item;
        if (isYT(provider) && String(description || '').indexOf(attachment.name) !== -1) {
            return null;
        }

        const key = `${index}-${attachment.id || attachment.filename || attachment.name}`;

        const filename = (attachment?.filename || attachment?.name || attachment?.url || '').toLowerCase();
        if (attachment.type === 'video' || /\.mp4|\.mov/.test(filename)) {
            item = (
                <ImageWrapper key={key} data-type="video">
                    <video preload="metadata" controls>
                        <source src={attachment.url || attachment.content} type="video/mp4" />
                    </video>
                </ImageWrapper>
            );
        } else if (attachment.type === 'image' || /\.jpg|\.bmp|\.png|\.tiff|\.gif|\.jpeg|\.webp/.test(filename)) {
            images.push({ source: attachment.url || attachment.content, caption: '', alt: '' });
            const imgIndex = images.length - 1;
            item = (
                <ImgThumb
                    small={small}
                    key={key}
                    onClick={() => toggleLightbox(imgIndex)}
                    index={imgIndex}
                    thumbnail={attachment.preview || attachment.thumbnail}
                />
            );
        } else {
            item = (
                <AttachLink
                    key={key}
                    small={small}
                    gdrive={attachment.type === 'gdrive'}
                    url={attachment.url || attachment.content}
                    text={attachment.name || attachment.filename}
                />
            );
        }
        return item;
    });

    const classes = classNames({
        [styles.smallList]: small,
        'flex flex-column gap-6': !small,
    });

    return (
        <div>
            <div className="o-6 t-size-s">Attachments</div>
            <Space height={8} />
            <div className={classes}>{list}</div>
            <ImagePreview images={images} isOpen={modal} selectedIndex={index} onClose={() => setModal(false)} />
        </div>
    );
}

export default observer(NoneJiraAttachments);
