/* eslint-disable no-useless-escape */

export function convertJiraTablesToMarkdown(str) {
    return (
        str
            // This regex matches the pattern [label|link]
            .replace(/\[([^\|]+)\|([^\]]+)\]/g, '[$1]($2)')
            .replace(/\|\|([^|]*)\|\|/g, '|$1|')
            .split(/\n\s*\n/)
            .map((section) => {
                // Check if the section is a table (starts with '|')
                if (section.trim().startsWith('|')) {
                    const lines = section.split('\n');
                    const headerSeparator = lines[0]
                        .split('|')
                        .slice(1, -1)
                        .map(() => '---')
                        .join(' | ');

                    const separatorLine = '| ' + headerSeparator + ' |';

                    // Insert the separator line after the header
                    lines.splice(1, 0, separatorLine);
                    return lines.join('\n');
                } else {
                    // If the section is not a table, return it unchanged
                    return section;
                }
            })
            .join('\n\n')
    );
}

export default (str) => {
    return convertJiraTablesToMarkdown(
        str
            //Clear 4 \s fo code - Jira bug
            .replace(/^(?: {4})/gm, '')
            // Clear |smart-link
            .replace(/\|smart-link/gi, '')
            // Exp: https://monosnap.com/direct/19OeUbKf0PWDUvwbFuOAobLP86UAHj, https://monosnap.com/file/19OeUbKf0PWDUvwbFuOAobLP86UAHj
            .replace(/\[https:\/\/www.loom.com\/share\/(([a-z]|\d)+)\|(.+?)\]/gi, '<Loom file="$1" />')
            .replace(/!(http[^|]+?)(\|(.+?))?!/gm, '<img src="$1" $3 />')
            // .replace(/!((http[^|]+?)(|.+?))!/gi, '<img src="$1" />')
            .replace(/https:\/\/www.loom.com\/share\/(([a-z]|\d)+)/gi, '<Loom file="$1" />')
            .replace(/\[https:\/\/monosnap.com\/(direct|file)\/(([a-z]|\d)+)\|(.+?)\]/gi, '<Monosnap file="$2" />')
            .replace(/https:\/\/monosnap.com\/(direct|file)\/(([a-z]|\d)+)/gi, '<Monosnap file="$2" />')
            // User Component
            .replace(/(\[~(([a-z ]|\.)+)\])/gi, '<User accountId="$2" />')
            .replace(/(\[~accountid:(.+?)\])/gi, '<User accountId="$2" />')
            // Color Text Component
            .replace(/\{panel:bgColor=(\#[^\0]+?)\}([^\0]+?)\{panel\}/g, '<ColorPanel color="$1">$2</ColorPanel>')
            .replace(/\+{color:(\#[^\0]+?)\}([^\0]+?)\{color\}\+/g, '$2')
            .replace(/\{color:(\#[^\0]+?)\}([^\0]+?)\{color\}/g, '$2')
            // Horizontal line
            .replace(/(\n|\s)(-{3,4})(\n|\s)/gi, '$1<hr />$3')
            // Ordered Lists
            .replace(/^[ \t]*(\*+|#+\*+)\s+/gm, function (match, stars) {
                return Array(stars.length).join('  ') + '* ';
            })
            // Un-ordered lists
            .replace(/^[ \t]*(#+|#\s\*)\s+/gm, function (match, nums) {
                return Array(nums.length).join('  ') + '1. ';
            })
            // Headers 1-6
            .replace(/^h([0-6])\.(.*)$/gm, function (match, level, content) {
                return Array(parseInt(level) + 1).join('#') + content;
            })
            .replace(/\|h([0-6])\.(.*?)\|/gm, function (match, level, content) {
                const number = parseInt(level) + 1;
                return `|<h${number}>${content}</h${number}>|`;
            })
            // Subscript
            .replace(/~([^~]*)~/g, '<sub>$1</sub>')
            // Strikethrough
            .replace(/(\+|_|\*|~|\s+)-(\S+.*?\S)-(\+|_|\*|~|\s+)/g, '$1~~$2~~$3')
            // Bold
            .replace(/\{\*\}(\S.*?)\{\*\}/g, '*$1*')
            .replace(/\*(\S.*?)\*/g, '**$1**')
            // Monospaced text
            .replace(/\{\{([^}]+)\}\}/g, '`$1`')
            // Underscore
            .replace(/(-|_|\*|~|\s+)\+(\S+.*?\S)\+(-|_|\*|~|\s+)/g, '$1<u>$2</u> $3')
            // Inserts (bug with images)
            // .replace(/\+([^+]*)\+/g, '<ins>$1</ins>')
            // Superscript
            .replace(/\^([^^]*)\^/g, '<sup>$1</sup>')
            // Code Block
            .replace(
                /\{code(:([a-z]+))?([:|]?(title|borderStyle|borderColor|borderWidth|bgColor|titleBGColor)=.+?)*\}([^]*)\{code\}/gm,
                '```$2$5```',
            )
            // Pre-formatted text
            .replace(/\{noformat\}([^\0]+?)\{noformat\}/g, '<CodeBl>$1</CodeBl>')
            // Named Links
            .replace(/\[(.+?)\|(.+?)\]/g, '[$1]($2)')
            // Un-named Links
            // .replace(/\[(http[^|]+?)\]/g, '[$1]($1)')
            // Single Paragraph Blockquote
            .replace(/^bq\.\s+/gm, '> ')
            .replace(/\{quote\}([^\0]+?){quote}/g, '<blockquote>$1</blockquote>')
            .replace(/\?\?(.+?)\?\?/g, '<blockquote>$1</blockquote>')
            // panel into table
            .replace(/\{panel:title=([^}]*)\}\n?([^]*?)\n?\{panel\}/gm, '\n| $1 |\n| --- |\n| $2 |')
            // remove leading-space of table headers and rows
            .replace(/^[ \t]*\|/gm, '|'),
    );
};
