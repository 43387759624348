import { action, autorun, makeAutoObservable, reaction, runInAction } from 'mobx';

import { sendConnectedData } from 'socket';
import { getSocket, getSocketNameSpace } from 'socket/init';
import { switchTheme } from 'themes';
import { fbPixelEvent, gaEvent, logEvent, sendToSentry, setSentryUser, trackCrispEvent } from 'utils';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import IndexDb from 'store/IndexDb';
import Announce from 'store/models/Announce';
import * as mainStoreApi from 'store/models/api/mainStore';
import Comment from 'store/models/Comment';
import criteriaStore from 'store/models/CriteriaStore';
import csvExportStore from 'store/models/CsvExportStore';
import dictionaryStore from 'store/models/DictionaryStore';
import Report from 'store/models/Report';
import { User } from 'store/models/User';
import hideLoadingScreen from 'store/models/utils/hideLoadingScreen';
import { votingComment } from 'store/models/VotingComment';

import { initAmplitude } from 'utils/amplitude';
import { calcPerf } from 'utils/calcPerf';
import {
    APP_VERSION,
    CURRENT_ORG_ID,
    CURRENT_USER_ID,
    CURRENT_USER_IS_ADMIN,
    INSTANCE_PREFIX,
    IS_APP,
    IS_PUBLIC_BOARD,
    IS_VOTING_BOARD,
    IS_WEB_BOT,
    MAIN_404_PAGE,
    SERVICE_DOMAIN_NAME,
    VIEWS,
    VIEWS_DATA,
} from 'utils/consts';
import debugLog from 'utils/debugLog';
import delay from 'utils/delay';
import { groupBy } from 'utils/groupBy';
import { setSentryContext } from 'utils/sentry';

import Boards from './Boards';
import educationBanners from './EducationBanners';
import { issuesList } from './IssuesList';
import Organization from './Organization';
import Platforms from './Platforms';
import Users from './Users';
import { utilsStore } from './UtilsStore';

import { ORG_DB_STORES } from '../updateOrgDB';

initAmplitude();

let disconnectedTime = null;

const NEED_RUN_SECONDARY = false;

export class MainStore {
    me = IS_PUBLIC_BOARD ? new User() : null;
    boardsList = new Boards();
    platformsList = new Platforms();
    questionsIds = new Map();
    organization = new Organization((window.userKey && !IS_PUBLIC_BOARD && window.organization) || undefined);
    users = new Users();
    boardsLen = 0;
    fileProgress = {
        '/storage/organization': 0,
        '/storage/users': 0,
        '/storage/platforms': 0,
        '/storage/dictionaries': 0,
        '/storage/voted-percents': 0,
        '/storage/reports': 0,
        '/storage/voting-votes': 0,
        '/storage/boards': 0,
        '/storage/release-notes': 0,
        '/storage/requests': 0,
        '/storage/issues': 0,
        '/storage/issues-links': 0,
        '/storage/issues-scores': 0,
    };

    /**
     * @type {Map<number,Report>}
     */
    reportsIds = new Map();

    /**
     * @type {Map<number,Announce>}
     */
    announcesIds = new Map();

    documentTitle = IS_VOTING_BOARD ? window.publicName : SERVICE_DOMAIN_NAME;
    ready = false;
    readyFetchAll = false;
    readyIdeas = false;
    disconnected = false;
    dialogImport = false;
    onlineUsers = [];
    page = '';
    publicBoard = window.publicBoard;
    activeReportId = null;
    watchersIds = new Map();
    votedPercents = new Map();

    /**
     * @type {string|null}
     */
    activeReleaseNoteId = null;
    linkedIssues = new Map();
    votingVotes = new Map();

    db = new IndexDb();
    socket;

    constructor() {
        window.beforeGlobalStart = 0;
        process.env.REACT_APP_ENV !== 'prod' && debugLog('version', APP_VERSION);
        calcPerf('Init Store', window.globalStart);

        const socketNamespace = getSocketNameSpace();
        this.socket = getSocket(socketNamespace);

        makeAutoObservable(this, { db: false, updatePageTitle: action });
    }

    onSocketConnect() {
        const deltaTime = (disconnectedTime && Date.now() - disconnectedTime > 60000) || false;
        const user = mainStore.currentUser;

        process.env.REACT_APP_ENV !== 'prod' &&
            debugLog('socket: reconnected', { deltaTime, now: Date.now(), hasUser: !!user });

        if (user && !IS_PUBLIC_BOARD) {
            const { id, name, payments, logo } = mainStore.organization;
            sendConnectedData(user, id, name, payments, logo);
            if (deltaTime) {
                mainStore.fetchAll();
            }
        } else if (deltaTime && IS_PUBLIC_BOARD) {
            mainStore.fetchPublic();
        }
    }

    afterReady = () => {
        this.runSocket();
        hideLoadingScreen();
        !IS_PUBLIC_BOARD && settingsStore.checkPageUrl();
    };

    runSocket = () => {
        if (IS_VOTING_BOARD) return;

        this.socket.on('update users', this.setOnlineUsers.bind(this));
        this.socket.on('connect', () => {
            process.env.REACT_APP_ENV !== 'prod' &&
                debugLog('socket: connected', {
                    disconectedTime: disconnectedTime,
                    id: this.socket.id,
                    ready: this?.ready,
                });

            this.connectionStatus(false);

            if (this.ready) this.onSocketConnect();
        });

        this.socket.on('disconnect', () => {
            disconnectedTime = Date.now();

            delay(500).then(() => {
                process.env.REACT_APP_ENV !== 'prod' && debugLog('socket: disconnected', disconnectedTime);
                this.connectionStatus(true);
            });
        });
        this.socket.on('reconnect_error', () => {
            process.env.REACT_APP_ENV !== 'prod' && debugLog('socket: attempt to reconnect has failed');
        });

        /** MAIN EVENTS */

        this.socket.on('Organization.delete', async () => {
            mainStore.db.dropDB();
            window.location.href = '/login/logout';
        });
        this.socket.on('Organization.update', async (data) => {
            this.organization.updateModel(data.item);
            await this.db.updateOrganization(data.item);
        });

        // Questions
        this.socket.on('Request.delete', this.removeComment.bind(this));
        this.socket.on('Request.create', this.setQuestion.bind(this));
        this.socket.on('Request.update', this.updateQuestion.bind(this));

        // PLATFORMS
        this.socket.on('PlatformFactory.create', (data) => {
            this.platformsList.update(data.item);
            this.db.updateRowDB(data.item, 'platforms');
        });
        this.socket.on('PlatformFactory.update', (data) => {
            this.platformsList.update(data.item);
            this.db.updateCurrentRowById(data.item, ORG_DB_STORES.platforms);
        });
        this.socket.on('PlatformFactory.delete', (platformId) => {
            this.platformsList.removeSingle(platformId);
            this.db.removeRowDB(platformId, 'platforms');
        });

        // USERS
        this.socket.on('OrganizationUser.create', (data) => {
            this.users.update(data.item, true);
            this.db.updateRowDB(data.item, ORG_DB_STORES.users);
        });
        this.socket.on('OrganizationUser.update', async (data) => {
            this.users.update(data.item);
            await this.db.updateCurrentRowById(data.item, ORG_DB_STORES.users);
        });
        this.socket.on('OrganizationUser.delete', (userId) => {
            this.users.removeSingle(userId);
            this.db.removeRowDB(userId, 'users');
        });

        this.socket.on('PlanMetrics.update', (data) => {
            this.organization.fillLimits(data);
        });

        // REPORTS
        this.socket.on('BoardReport.create', (data) => {
            this.updateReport(data.item, true);
            this.db.updateRowDB(data.item, ORG_DB_STORES.reports);
        });
        this.socket.on('BoardReport.update', async (data) => {
            this.updateReport(data.item);
            await this.db.updateCurrentRowById(data.item, ORG_DB_STORES.reports);
        });

        this.socket.on('BoardReport.delete', (reportId) => {
            runInAction(() => this.reportsIds.delete(reportId));
            this.db.removeRowDB(reportId, ORG_DB_STORES.reports);
        });

        // Watcher
        this.socket.on('Watcher.create', (data) => {
            this.addWatcher(data.item);
        });
        this.socket.on('Watcher.delete', (data) => {
            this.removeWatcher(data.item);
        });

        /**
         * Remove Our issue
         */
        this.socket.on('IssueFactory.delete', ({ id, boardId }) => {
            const realBoardId = this.getRealBoardIdByPublicId(boardId);
            issuesList.removeSingle(id, realBoardId, true);
        });

        /**
         * Change status for done (or removed) for all providers
         * needs validation providers
         */
        this.socket.on('IssueFactory.removeFromBoard', ({ id, boardId }) => {
            const realBoardId = this.getRealBoardIdByPublicId(boardId);
            issuesList.removeSingle(id, realBoardId);
        });

        this.socket.on('Board.create', async (data) => {
            this.boardsList.updateBoard(data.item);
            await this.db.updateRowDB(data.item, 'boards');
        });

        this.socket.on('Board.delete', async (boardId) => {
            this.boardsList.removeSingle(boardId);
            await this.db.removeRowDB(boardId, 'boards');
        });

        this.socket.on('Criterion.create', async (data) => {
            criteriaStore.addCriteria(data.item);
            await this.db.updateRowDB(data.item, ORG_DB_STORES.criteria);
        });
        this.socket.on('Criterion.update', async (data) => {
            criteriaStore.updateCriteria(data.item);
        });
        this.socket.on('Criterion.delete', async (id) => {
            criteriaStore.removeCriteria(id);
            await this.db.removeRowDB(id, ORG_DB_STORES.criteriaBoards);
        });

        this.socket.on('BoardCriterion.delete', async (id) => {
            criteriaStore.removeBoardCriteria(id);
            await this.db.removeRowDB(id, ORG_DB_STORES.criteria);
        });
        this.socket.on('BoardCriterion.update', async (data) => {
            criteriaStore.updateBoardCriteria(data.item);
        });
        this.socket.on('BoardCriterion.create', async (data) => {
            criteriaStore.addBoardCriteria(data.item);
            await this.db.updateRowDB(data.item, ORG_DB_STORES.criteriaBoards);
        });

        // IssueLink
        this.socket.on('IssueLink.create', (data) => {
            runInAction(() => {
                this.linkedIssues.set(data.item.id, data.item);
            });
        });
        this.socket.on('IssueLink.update', (data) => {
            runInAction(() => {
                this.linkedIssues.set(data.item.id, data.item);
            });
        });
        this.socket.on('IssueLink.delete', (data) => {
            runInAction(() => {
                this.linkedIssues.delete(data.item.id);
            });
        });

        // Education Banners
        this.socket.on('OrganizationBanner.update', (data) => {
            const { user_id, ...banner } = data.item;
            if (!user_id || user_id === mainStore.currentUser?.id) {
                educationBanners.updateActiveBanner(banner);
            }
        });
        this.socket.on('OrganizationBanner.delete', (data) => {
            const { user_id, ...banner } = data.item;
            if (!user_id || user_id === mainStore.currentUser?.id) {
                educationBanners.removeActiveBanner(banner);
            }
        });

        this.socket.on('FileExport.update', csvExportStore.updateStatus);

        this.socket.on('BoardVotedPercentModel.update', this.setVotedPercent.bind(this));

        this.socket.on('VotingIssue.delete', this.removeBoardIdea.bind(this));

        this.socket.on('IssueStatus.create', (data) => {
            dictionaryStore.addDictionaryItem('IssueStatus', data.item);
        });
        this.socket.on('IdeaLabel.create', (data) => {
            dictionaryStore.addDictionaryItem('IdeaLabel', data.item);
        });
        this.socket.on('IdeaStatus.create', (data) => {
            dictionaryStore.addDictionaryItem('IdeaStatus', data.item);
        });
        this.socket.on('IssueLabel.create', (data) => {
            dictionaryStore.addDictionaryItem('IssueLabel', data.item);
        });
        this.socket.on('IssueType.create', (data) => {
            dictionaryStore.addDictionaryItem('IssueType', data.item);
        });
        this.socket.on('IdeaAnnounceLabel.create', (data) => {
            dictionaryStore.addDictionaryItem('IdeaAnnounceLabel', data.item);
        });

        this.socket.on('IssueStatus.update', (data) => {
            dictionaryStore.updateDictionaryItem('IssueStatus', data.item);
        });
        this.socket.on('IdeaLabel.update', (data) => {
            dictionaryStore.updateDictionaryItem('IdeaLabel', data.item);
        });
        this.socket.on('IssueLabel.update', (data) => {
            dictionaryStore.updateDictionaryItem('IssueLabel', data.item);
        });
        this.socket.on('IssueType.update', (data) => {
            dictionaryStore.updateDictionaryItem('IssueType', data.item);
        });

        this.socket.on('IssueStatus.delete', (itemId) => {
            dictionaryStore.removeDictionaryItem('IssueStatus', itemId);
        });
        this.socket.on('IdeaLabel.delete', (itemId) => {
            dictionaryStore.removeDictionaryItem('IdeaLabel', itemId);
        });
        this.socket.on('IdeaStatus.delete', (itemId) => {
            dictionaryStore.removeDictionaryItem('IdeaStatus', itemId);
        });
        this.socket.on('IssueLabel.delete', (itemId) => {
            dictionaryStore.removeDictionaryItem('IssueLabel', itemId);
        });
        this.socket.on('IssueType.delete', (itemId) => {
            dictionaryStore.removeDictionaryItem('IssueType', itemId);
        });
        this.socket.on('IdeaAnnounceLabel.delete', (itemId) => {
            dictionaryStore.removeDictionaryItem('IdeaAnnounceLabel', itemId);
        });
    };

    removeComment = (id) => {
        this.removeQuestion(id);
    };

    removeBoardIdea = async (id) => {
        issuesList.removeSingleVotingIssue(id);
        await this.db.removeRowDB(id, ORG_DB_STORES.ideas);
    };

    fillAllVotedPercents(list) {
        list.forEach((item) => {
            this.votedPercents.set(item.id, item.voted_percent);
        });
    }

    addWatcher(item) {
        if (this.watchersIds.has(item.issue_id)) {
            !this.watchersIds.get(item.issue_id).includes(item.user_id) &&
                this.watchersIds.get(item.issue_id).push(item.user_id);
        } else {
            this.watchersIds.set(item.issue_id, [item.user_id]);
        }
    }
    removeWatcher(item) {
        const users = this.watchersIds.get(item.issue_id);
        if (users && users.includes(item.user_id)) {
            this.watchersIds.set(
                item.issue_id,
                users.filter((userId) => userId !== item.user_id),
            );
        }
    }

    fillWatchers(list) {
        const groups = groupBy(list, (item) => item.issue_id);
        Object.entries(groups).forEach(([issue_id, item]) => {
            const users = item.map((el) => el.user_id);
            this.watchersIds.set(Number(issue_id), users);
        });
    }

    getRealBoardIdByPublicId(publicId) {
        return this.boardsList.boards.find((board) => board.public_id === publicId)?.id;
    }

    setProgress(path, value) {
        this.fileProgress[path] = value;
    }

    setVotedPercent = (data) => {
        this.votedPercents.set(data.item.id, data.item.voted_percent);
    };

    setVotingVotes(objData) {
        Object.entries(objData).forEach(([voting_issue_id, count]) => {
            this.votingVotes.set(Number(voting_issue_id), count);
        });
    }

    setVotingVote(voting_issue_id, count) {
        this.votingVotes.set(Number(voting_issue_id), count);
    }

    deleteVotingVote(voting_issue_id) {
        this.votingVotes.delete(Number(voting_issue_id));
    }

    get questions() {
        return Array.from(this.questionsIds.values());
    }

    fillQuestions(list) {
        this.questionsIds.clear();
        list.forEach((question) => this.questionsIds.set(question.id, new Comment(question)));
    }

    setQuestion(data) {
        if (issuesList.allIssues.some((issue) => issue.id === data.item.issue_id)) {
            this.questionsIds.set(data.item.id, new Comment(data.item));
        }
    }

    removeQuestion(id) {
        this.questionsIds.delete(id);
    }

    updateQuestion(data) {
        if (this.questionsIds.has(data.item.id)) {
            this.questionsIds.get(data.item.id).update(data.item);
        }
    }

    get blocksIssues() {
        const list = Array.from(this.linkedIssues.values()).filter((el) => el.type === 'block');
        const groups = groupBy(list, (link) => link.linked_issue_id);
        const blocksIssues = new Map();

        Object.entries(groups).forEach(([id, list]) => {
            const clearList = list.map(({ issue_id, issue_name, issue_completed }) => ({
                id: issue_id,
                name: issue_name,
                completed: issue_completed,
            }));
            blocksIssues.set(Number(id), clearList);
        });
        return blocksIssues;
    }

    get blockedByIssues() {
        const list = Array.from(this.linkedIssues.values()).filter((el) => el.type === 'block');
        const groups = groupBy(list, (link) => link.issue_id);
        const blockedByIssues = new Map();
        Object.entries(groups).forEach(([id, list]) => {
            const clearList = list.map(({ linked_issue_id, linked_issue_name, linked_issue_completed }) => ({
                id: linked_issue_id,
                name: linked_issue_name,
                completed: linked_issue_completed,
            }));
            blockedByIssues.set(Number(id), clearList);
        });
        return blockedByIssues;
    }

    get linked() {
        const list = Array.from(this.linkedIssues.values()).filter((el) => el.type === 'link');
        const groups = groupBy(list, (link) => link.issue_id);
        const linked = new Map();
        Object.entries(groups).forEach(([id, list]) => {
            const clearList = list.map(({ linked_issue_id, linked_issue_name, linked_issue_completed }) => ({
                id: linked_issue_id,
                name: linked_issue_name,
                completed: linked_issue_completed,
            }));
            linked.set(Number(id), clearList);
        });
        return linked;
    }
    get linkedBy() {
        const list = Array.from(this.linkedIssues.values()).filter((el) => el.type === 'link');
        const groups = groupBy(list, (link) => link.linked_issue_id);
        const linkedBy = new Map();
        Object.entries(groups).forEach(([id, list]) => {
            const clearList = list.map(({ issue_id, issue_name, issue_completed }) => ({
                id: issue_id,
                name: issue_name,
                completed: issue_completed,
            }));
            linkedBy.set(Number(id), clearList);
        });
        return linkedBy;
    }

    async init() {
        if (MAIN_404_PAGE) {
            this.setReady();
        } else if (IS_VOTING_BOARD) {
            await this.fetchVoting();
        } else if (IS_PUBLIC_BOARD) {
            await this.fetchPublic();
        } else if ((window.userKey && !CURRENT_USER_ID) || (!CURRENT_USER_ID && IS_APP)) {
            this.setReady();
        } else if (CURRENT_USER_ID && IS_APP) {
            await this.start();
        }
    }

    async start() {
        process.env.REACT_APP_ENV !== 'prod' && debugLog('START');
        if (!CURRENT_USER_IS_ADMIN) {
            try {
                process.env.REACT_APP_ENV !== 'prod' && debugLog('START > DB');
                delay(0).then(() => this.fetchAll());
                const { hasOrganization, dataDb } = await this.db.startDB(CURRENT_USER_ID, CURRENT_ORG_ID);

                calcPerf('StartDB', window.globalStart, window.globalStart - window.beforeGlobalStart);
                window.beforeGlobalStart = performance.now();

                this.fillDataFromDB(hasOrganization, dataDb);
            } catch (e) {
                sendToSentry('Fail init DB', { error: e });
            }
        } else {
            process.env.REACT_APP_ENV !== 'prod' && debugLog('SKIP START DB > user is Admin');
            delay(0).then(() => this.fetchAll());
        }
        process.env.REACT_APP_ENV !== 'prod' && debugLog('START > fetch');
    }

    fillReports(reports = []) {
        const updatedIds = [];

        reports.forEach((report) => {
            updatedIds.push(report.id);
            this.updateReport(report, true);
        });

        Array.from(this.reportsIds.keys()).forEach((reportId) => {
            if (!updatedIds.includes(reportId)) {
                this.reportsIds.delete(reportId);
            }
        });
    }

    setReady(readyFetchAll = false) {
        if (!this.ready) {
            this.ready = true;
            this.afterReady();
        }

        this.readyFetchAll = this.readyFetchAll || readyFetchAll || IS_PUBLIC_BOARD;
        process.env.REACT_APP_ENV !== 'prod' && debugLog('START > READY');
    }

    fillSecondaryDataFromDb(dataDb) {
        const {
            issues,
            ideas,
            issuesScore,
            votedPercents,
            IssueStatus,
            IdeaLabel,
            IdeaStatus,
            IssueLabel,
            IssueType,
            IdeaAnnounceLabel,
            issuesLinks,
            announces,
        } = dataDb;

        dictionaryStore.fillCollection({
            IssueStatus,
            IdeaLabel,
            IdeaStatus,
            IssueLabel,
            IssueType,
            IdeaAnnounceLabel,
        });

        this.fillAllVotedPercents(votedPercents);

        issuesList.fillIssuesData(issuesScore);
        issuesList.fillData(issues);
        issuesList.setIdeas(ideas);
        issuesLinks.forEach((item) => this.linkedIssues.set(item.id, item));

        this.updateAnnounces(announces);

        process.env.REACT_APP_ENV !== 'prod' && debugLog('END > FILL FROM DB > secondary');

        calcPerf('Fill from DB - secondary', window.globalStart, window.globalStart - window.beforeGlobalStart);
        window.beforeGlobalStart = performance.now();

        this.setReady(true);
        this.readyIdeas = true;
    }

    fillDataFromDB(hasOrganization, dataDb) {
        process.env.REACT_APP_ENV !== 'prod' &&
            debugLog('START > FILL FROM DB', {
                hasOrganization,
                dataDb: !!dataDb,
                ready: this.ready,
            });

        if (this.ready) return;

        if (dataDb) {
            const { boards, reports, platforms, users, comments, criteria, criteriaBoards } = dataDb;

            this.boardsLen = boards.length;

            criteriaStore.fillData(criteria, criteriaBoards);

            this.users.set(users);
            this.platformsList.set(platforms);

            this.fillQuestions(comments);
            this.boardsList.set(boards);
            this.activeBoards.forEach((board) => board.fillChangelogsId());

            this.fillReports(reports);

            calcPerf('Fill from DB', window.globalStart, window.globalStart - window.beforeGlobalStart);

            if (NEED_RUN_SECONDARY || dataDb.issues) {
                this.fillSecondaryDataFromDb(dataDb);
            } else {
                this.setReady();
            }
        }
    }

    get blockAll() {
        return this.organization.payment_required;
    }

    /**
     * Current report
     *
     * @return {Report|null}
     */
    get report() {
        return this.activeReportId ? this.reportsIds.get(this.activeReportId) : null;
    }

    /**
     * @return {Report[]}
     */
    get reports() {
        if (mainStore.currentUser?.isAdmin) {
            return Array.from(this.reportsIds.values());
        }
        return Array.from(this.reportsIds.values()).filter((report) => !report.hasPrivateBoard);
    }

    /**
     * @return {Report[]}
     */
    get activeReports() {
        return this.reports.filter((report) => !report.hasPrivateBoard);
    }

    /**
     * Get current board
     *
     * @returns {Board|undefined|null}
     */
    get activeBoard() {
        return this.boardsList.activeBoard;
    }

    /**
     *
     * @returns {Board[]}
     */
    get activeBoards() {
        return this.boardsList.activeBoards;
    }

    /**
     * @return {Report|Board|undefined|null}
     */
    get activeModel() {
        return this.report || this.activeBoard;
    }

    /**
     *
     * @return {User}
     */
    get currentUser() {
        if (IS_PUBLIC_BOARD) {
            return this.me;
        }
        return this.users.currentUser;
    }

    countBoardsByOwner(ownerId, provider) {
        return this.boardsList.boards.filter(
            (board) => board.owner && board.owner.id === ownerId && board.provider === provider,
        ).length;
    }

    get staticAnalyticsData() {
        const user = this.currentUser;
        if (!user || !this.ready) {
            return JSON.stringify(null);
        }
        const { name, payments, id, logo } = this.organization;
        const readyData = {
            name,
            payments,
            id: `${INSTANCE_PREFIX}${id}`,
            userId: `${INSTANCE_PREFIX}${user.id}`,
            userName: user.name,
            email: user.email,
            avatar: user.avatar,
            role: user.role,
            logo,
            theme: user.theme,
        };

        return JSON.stringify(readyData);
    }

    get showFinalScoreBlock() {
        return (
            !IS_PUBLIC_BOARD &&
            ![VIEWS.QUESTIONS, VIEWS.IDEAS, VIEWS.FOCUS_MODE, VIEWS.EVALUATION, VIEWS.SCORES].includes(this.page)
        );
    }

    get allReportsAndBoards() {
        return [...this.activeReports, ...this.boardsList.activeBoardsWithAccess];
    }

    get language() {
        return this.activeBoard?.language || this.organization.language;
    }

    /**
     * @param {string} title
     * @param {Object=} page
     * @param {Object=} extra
     */
    updatePageTitle = ({ title = '', page = null, extra }) => {
        const pageName = title || VIEWS_DATA?.[page]?.title || page;
        const titleArray = [pageName];
        if (extra) {
            titleArray.unshift(extra);
        }
        if (this.activeBoard) {
            titleArray.push(this.activeBoard.fullName);
        } else if (this.report) {
            titleArray.push(this.report.fullName);
        }
        this.organization?.name && titleArray.push(this.organization.name);
        const documentTitle = titleArray.join(' | ');

        logEvent(`Open: ${pageName}`, { extra, page });

        this.page = page;
        this.documentTitle = documentTitle;
    };

    connectionStatus(disconnected) {
        this.disconnected = disconnected;
    }

    setOnlineUsers(data) {
        this.onlineUsers = data.users;
    }

    setActiveReportId(id) {
        this.activeReportId = Number(id);
    }

    setActiveReleaseNoteId(id) {
        this.activeReleaseNoteId = id;
    }

    updateReport(data, isNew) {
        if (this.reportsIds.has(data.id)) {
            const preReport = this.reportsIds.get(data.id);
            preReport.updateModel(data);
        } else if (isNew) {
            this.reportsIds.set(data.id, new Report(data));
        }
    }

    updateAnnounces = (list) => {
        list.forEach((item) => {
            if (this.announcesIds.has(item.id)) {
                const announce = this.announcesIds.get(item.id);
                announce.updateModel(item);
            } else {
                this.announcesIds.set(item.id, new Announce(item));
            }
        });
    };

    useVotingHistory = () => {
        if (!utilsStore.historyAction || !CURRENT_USER_ID || !mainStore.activeBoard) return;

        Object.entries(utilsStore.historyAction).forEach(([key, value]) => {
            let issue;
            if (value?.id) {
                issue = issuesList.activeIssue?.id === value.id && issuesList.activeIssue;
            }
            switch (key) {
                case 'reaction':
                    if (issue) {
                        issuesList.setActiveIssue(issue);
                    }
                    break;
                case 'subscribe':
                    !mainStore.activeBoard.isUserSubscribed && mainStore.activeBoard.toggleSubscriptionVotingBoard();
                    break;
                case 'voting':
                    if (issue) {
                        issue.voting(value.vote, value.value);
                        issuesList.setActiveIssue(issue);
                    }
                    break;
                case 'create':
                    delay(1000).then(() => issuesList.createIdea());
                    break;
                case 'comment':
                    if (issue && value.message) {
                        issue.commentQuestion({ message: value.message, question: votingComment.comment });
                        votingComment.clear();
                    }
                    break;
                default:
                    logEvent('Undefined history action', { action: utilsStore.historyAction });
                    break;
            }
        });

        utilsStore.clearHistoryActions();
    };

    isEmailExist = (email) => this.users.activeUsers.some((user) => user.email === email);

    // API methods

    createDucalisBoard = mainStoreApi.createDucalisBoard;

    updateIssues = mainStoreApi.updateIssues;

    fetchVoting = mainStoreApi.fetchVoting;

    fetchBoards = mainStoreApi.fetchBoards;

    fetchBoardsDictionaries = mainStoreApi.fetchBoardsDictionaries;

    fetchPublic = mainStoreApi.fetchPublic;

    fetchScores = mainStoreApi.fetchScores;

    fetchAll = mainStoreApi.fetchAll;

    fetchIdeas = mainStoreApi.fetchIdeas;

    fetchAnnounces = mainStoreApi.fetchAnnounces;

    fetchBoardIdeas = mainStoreApi.fetchBoardIdeas;

    fetchWatchers = mainStoreApi.fetchWatchers;
}

export const mainStore = new MainStore();

if (!IS_WEB_BOT) {
    autorun(() => {
        if (mainStore.ready) {
            const title = mainStore.documentTitle;
            document.title = title;
            trackCrispEvent('pageView', { title });

            if (window._paq) {
                // Matomo
                window._paq.push(['setCustomUrl', window.location.href.replace(window.location.origin, '')]);
                window._paq.push(['setDocumentTitle', document.title]);
                window._paq.push(['trackPageView']);
            }
        }
    });
}

if (!IS_PUBLIC_BOARD && CURRENT_USER_ID && !CURRENT_USER_IS_ADMIN) {
    reaction(
        () => mainStore.staticAnalyticsData,
        (staticAnalyticsData) => {
            const data = JSON.parse(staticAnalyticsData);
            if (data) {
                const user = {
                    id: data.userId,
                    name: data.userName,
                    role: data.role,
                    avatar: data.avatar,
                    email: data.email,
                    theme: data.theme,
                };

                sendConnectedData(user, data.id, data.name, data.payments, data.logo);
                setSentryUser({ id: data.userId, username: data.userName }, { organizationId: data.id });
                setSentryContext('Customer', {
                    user: data.userName,
                    organization: data.name,
                    role: data.role,
                    isObserver: CURRENT_USER_IS_ADMIN,
                });
            }
        },
    );

    autorun(() => {
        if (
            mainStore.ready &&
            !mainStore.currentUser.event_11_issues_sent &&
            mainStore.boardsList.analyticsIssuesCount >= 11
        ) {
            logEvent('HasMoreElevenIssues', { count: mainStore.boardsList.analyticsIssuesCount });
            fbPixelEvent('HasMoreElevenIssues');
            gaEvent('HasMoreElevenIssues', 'Engagement', 'Has more 11 issues');
            mainStore.currentUser.send11IssuesDone();
        }
    });
}

if (!IS_PUBLIC_BOARD && CURRENT_USER_ID) {
    reaction(
        () => mainStore.activeBoard?.analytics,
        (analytics = 'null') => {
            if (analytics) {
                const analyticsBoard = JSON.parse(analytics);
                analyticsBoard && logEvent('Board', analyticsBoard);
            }
        },
    );
}

if (!IS_VOTING_BOARD && IS_PUBLIC_BOARD) {
    const sharedBoardDisposer = reaction(
        () => mainStore.activeBoard?.settings?.is_shared,
        (is_shared) => {
            if (is_shared === false) {
                window.location.href = '/?is_shared=false';
                sharedBoardDisposer();
            }
        },
    );
}

if (!IS_VOTING_BOARD) {
    const boardAccessDisposer = reaction(
        () => mainStore.activeBoard?.hasAccess,
        (hasAccess = true) => {
            if (!hasAccess) {
                window.location.replace('/');
                boardAccessDisposer();
            }
        },
    );
}

if (IS_VOTING_BOARD) {
    const votingEnableDisposer = reaction(
        () => mainStore.activeBoard?.voting_settings?.enabled,
        (enabled) => {
            if (enabled === false) {
                window.location.href = '/?is_voting=false';
                votingEnableDisposer();
            }
        },
    );

    autorun(() => {
        const themeId = mainStore.organization.theme;
        switchTheme(themeId);
    });

    autorun(() => {
        const settings = mainStore.organization.public_voting_settings;
        settings?.mainColor && document.body.style.setProperty('--colors-accent', settings?.mainColor);
        settings?.secondaryColor && document.body.style.setProperty('--colors-accent-text', settings?.secondaryColor);
    });
}
