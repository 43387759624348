import React from 'react';

import * as PopoverPrimitive from '@radix-ui/react-popover';

import { keyframes, styled } from 'stitches.config';

const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StPopoverContent = styled(PopoverPrimitive.Content, {
    borderRadius: 4,
    padding: '4px 6px',
    maxWidth: 500,
    backgroundColor: '$bg2',
    border: '1px solid var(--colors-grayA4)',
    boxShadow: '0px 3px 14px 1px var(--colors-blackA10)',
    animationDuration: '300ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',

    '&[data-state="open"]': {
        '&[data-side="top"]': { animationName: slideDownAndFade },
        '&[data-side="right"]': { animationName: slideLeftAndFade },
        '&[data-side="bottom"]': { animationName: slideUpAndFade },
        '&[data-side="left"]': { animationName: slideRightAndFade },
    },

    '&:focus': {
        boxShadow: 'inset 0 0 0 1px var(--colors-grayA4)',
        outline: 'none',
    },

    variants: {
        contentWidth: {
            true: {
                maxWidth: 'var(--radix-popover-content-available-width)',
            },
        },
        fillHeight: {
            true: {
                maxHeight: 'calc(var(--radix-popover-content-available-height) - 20px)',
            },
        },
    },
});

export const PopoverRoot = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverClose = PopoverPrimitive.PopoverClose;
export const PopoverContent = React.forwardRef(({ children, container, ...props }, forwardedRef) => (
    <PopoverPrimitive.Portal container={container}>
        <StPopoverContent sideOffset={4} {...props} ref={forwardedRef}>
            {children}
        </StPopoverContent>
    </PopoverPrimitive.Portal>
));
export default function Popover({ content, children, contentProps, side, align, container, ...props }) {
    return (
        <PopoverRoot {...props}>
            <PopoverTrigger asChild>{children}</PopoverTrigger>
            <PopoverContent side={side} align={align} container={container} {...contentProps}>
                {content}
            </PopoverContent>
        </PopoverRoot>
    );
}
