import React from 'react';

import classNames from 'classnames';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { mainStore } from 'store/models/MainStore';

import { IS_VOTING_BOARD } from 'utils/consts';

function UpdatedDate({ date, className }) {
    if (!date) return null;

    const dateFrom = DateTime.fromISO(date, { setZone: true }).setLocale(mainStore.activeBoard?.language).toRelative();

    const classes = classNames('t-size-s flex-shrink', className);

    return (
        <div className={classes} style={{ lineHeight: IS_VOTING_BOARD ? '20px' : '26px' }}>
            <FormattedMessage id="idea.updated" defaultMessage="Updated" />{' '}
            <span className="t-w-lb" itemProp="datePublished" content={date}>
                {dateFrom}
            </span>
        </div>
    );
}

export default observer(UpdatedDate);
