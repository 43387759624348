import React, { useEffect } from 'react';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import Labels from 'modules/InsideIssue/components/Labels';
import AIButtons from 'modules/PublicIdeaStripe/components/AIButtons/AIButtons';
import * as IdeaStripeComponents from 'modules/PublicIdeaStripe/components/index';
import styles from 'modules/PublicIdeaStripe/PublicIdeaStripe.module.sass';

import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';

import { IS_PUBLIC_BOARD, SHORT_RELATIVE_TIME_OPTIONS, UNSAVED_MODEL_ID } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import IdeaAuthor from 'components/IdeaAuthor';
import IdeaCommentForm from 'components/IdeaCommentForm';
import IssueComments from 'components/IssueComments';
import IssueDescription from 'components/IssueDescription';
import IssueName from 'components/IssueName';
import MetaItem from 'components/MetaItem';
import Space from 'components/Space';
import Tooltip from 'components/Tooltip';

function IdeMetaItemDate({ date, title }) {
    const dateFrom = DateTime.fromISO(date, { setZone: true }).toRelative(SHORT_RELATIVE_TIME_OPTIONS);

    return (
        <MetaItem title={title}>
            <span className="t-crop t-mutted">{dateFrom}</span>
        </MetaItem>
    );
}

const PublicIdeaStripeContent = ({ idea, editable }) => {
    useEffect(() => {
        if (!IS_PUBLIC_BOARD && idea.isDone) {
            idea.getVoteStats();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <IdeaStripeComponents.VotingIssueUsersTable idea={idea} />

            <Space height={8} />

            <Flex spaceBetween>
                {idea.allow_voting ? (
                    <Flex gap={8} className="t-mutted t-size-s">
                        <CustomIcon icon={CustomIconName.EYE} />
                        <span>
                            Idea is visible at{' '}
                            <a className="color-link" target="_blank" href={idea.href}>
                                voting board
                            </a>
                        </span>
                    </Flex>
                ) : (
                    <Flex gap={8} className="t-mutted t-size-s">
                        <CustomIcon icon={CustomIconName.EYE_SLASH} />
                        <span>
                            Idea is NOT visible at{' '}
                            <a className="color-link" target="_blank" href={idea.href}>
                                voting board
                            </a>
                        </span>
                    </Flex>
                )}
                <Flex gap={8}>
                    {editable && idea.board?.allowAI && idea.id !== UNSAVED_MODEL_ID ? <AIButtons idea={idea} /> : null}
                    {editable && idea.id !== UNSAVED_MODEL_ID ? (
                        <Tooltip content="Backup history">
                            <Button
                                data-place="idea-history"
                                icon={CustomIconName.BACKUP}
                                size={24}
                                border
                                onClick={() => utilsStore.setOpenIdeaHistory(idea)}
                            />
                        </Tooltip>
                    ) : null}
                </Flex>
            </Flex>
            <Space height={8} />
            <IssueName
                placeholder="Idea Name..."
                issue={idea}
                editable={editable}
                autoFocus={!idea.name && !mainStore.activeModel?.query}
            />

            <Space height={10} />

            <IssueDescription
                placeholder="Add Idea Description..."
                issue={idea}
                editable={editable}
                defaultValue={idea.board.ideaTemplate}
            />

            <Space height={16} />

            <Flex>
                <IdeaStripeComponents.Status disabled={!editable} issue={idea} />
            </Flex>

            <Space height={16} />

            <Labels issue={idea} editable={editable} labels={idea.labelsList} />

            <Space height={16} />

            <Flex gap={24}>
                <MetaItem title="Author">
                    <IdeaAuthor idea={idea} />
                </MetaItem>
                <div className="flex-grow" />
                <IdeMetaItemDate title="Updated" date={idea.last_update} />
                <IdeMetaItemDate title="Created" date={idea.created} />
            </Flex>

            {editable && !idea.allow_voting ? <IdeaStripeComponents.DraftFooter idea={idea} /> : null}

            <IdeaStripeComponents.MergedIssues issue={idea} />

            {idea.id !== -1 && idea.allow_voting ? (
                <>
                    <Space height={16} />
                    {idea.comments.length > 0 && <h3 className="t-w-sb">Comments</h3>}
                    <div className={styles.comments}>
                        <IssueComments comments={idea.comments} />
                    </div>
                    <div className={styles.commentFormWrapper}>
                        <IdeaCommentForm className={styles.commentForm} issue={idea} />
                    </div>
                </>
            ) : null}
        </>
    );
};

export default observer(PublicIdeaStripeContent);
