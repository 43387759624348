import { ENTER, DOWN, RIGHT, TAB, UP, LEFT } from 'utils/consts';
import { getCaretPosition } from 'utils/getCaretPosition';
import getKeyCode from 'utils/getKeyCode';

export function getNextSelector(event, tabIndex, isSelected, isCurentValue) {
    const keyCode = isCurentValue ? ENTER : getKeyCode(event);
    const { isStart, isEnd } = getCaretPosition(event);
    const isEmpty = event.target.value === '';

    // Go to next input
    if (
        keyCode === ENTER ||
        (event.altKey && keyCode === DOWN) ||
        (keyCode === RIGHT && (event.altKey || isEmpty || (isEnd && !isSelected)))
    ) {
        return `[tabindex="${tabIndex + 1}"]`;
    }

    // Go to prev input
    if (
        (event.shiftKey && keyCode === TAB) ||
        (event.altKey && keyCode === UP) ||
        (keyCode === LEFT && (event.altKey || isEmpty || (isStart && !isSelected)))
    ) {
        return `[tabindex="${tabIndex - 1}"]`;
    }

    return null;
}
