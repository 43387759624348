import { rootApi } from 'api';
import { failRequest } from 'utils';

import { utilsStore } from 'store/models/UtilsStore';

import { placeholderPlugin } from './Extensions/placeholderImage';

const config = {
    onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.max(Math.round((progressEvent.loaded * 100) / progressEvent.total), 5);
        if (placeholderPlugin?.widget?.style) {
            placeholderPlugin.widget.style.width = `${percentCompleted}%`;
        }
    },
};

function findPlaceholder(state, id) {
    let editorState = placeholderPlugin.getState(state);
    let found = editorState.find(null, null, (spec) => spec.id === id);
    return found.length ? found[0].from : null;
}

async function insertFilesIntoView(view, formData, onFinally, coordinates) {
    const id = {};
    utilsStore.setFileUploading(true);

    let tr = view.state.tr;

    const pos = coordinates?.pos || tr.selection.from;

    if (!tr.selection.empty) tr.deleteSelection();
    tr.setMeta(placeholderPlugin, { add: { id, pos } });
    view.dispatch(tr);

    const { schema } = view.state;

    try {
        const { data } = await rootApi.post('/attachments', formData, config);
        if (findPlaceholder(view.state, id) !== null) {
            view.dispatch(tr.setMeta(placeholderPlugin, { remove: { id } }));
        }

        const nodes = data.map((source) => {
            if (source.type === 'image') {
                return schema.nodes.image.create({ src: source.url });
            } else if (source.type === 'video') {
                return schema.nodes.video.create({ src: source.url });
            } else if (source.type === 'audio') {
                return schema.nodes.audio.create({ src: source.url });
            } else {
                return schema.text(source.name, [schema.marks.link.create({ href: source.url })]);
            }
        });

        view.dispatch(view.state.tr.insert(pos, schema.text(' ')));
        view.dispatch(view.state.tr.insert(pos, nodes));
    } catch (e) {
        if (findPlaceholder(view.state, id) !== null) {
            view.dispatch(view.state.tr.setMeta(placeholderPlugin, { remove: { id } }));
        }
        failRequest(e);
    } finally {
        onFinally && onFinally();
        utilsStore.setFileUploading(false);
        view.focus();
    }
}

export default insertFilesIntoView;
