import React, { useMemo } from 'react';

import { useHotkeys } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { IS_PUBLIC_BOARD } from 'utils/consts';

import AvatarsList from 'components/AvatarsList';
import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import MetaItem from 'components/MetaItem';
import UserMultiSelect from 'components/UserMultiSelect';

const Watchers = () => {
    if (IS_PUBLIC_BOARD || !issuesList.issue) return null;

    return <WatchersBtn />;
};

const WatchersBtn = observer(() => {
    const hasSubscribe = issuesList.issue.watchers.includes(mainStore.currentUser?.id);
    const users = issuesList.issue.watchers.map((userId) => mainStore.users.usersIds.get(userId));

    const hotkeys = useMemo(() => {
        return [
            {
                combo: 'W',
                global: true,
                label: 'Change watcher',
                group: 'Issue',
                onKeyDown: () => {
                    issuesList.issue?.changeWatcher(mainStore.currentUser, !hasSubscribe);
                },
            },
        ];
    }, [hasSubscribe]);

    useHotkeys(hotkeys);

    return (
        <MetaItem
            title={
                <DarkTooltip
                    popoverClassName="limit limit-300"
                    position="top-right"
                    content="Watchers receive issue updates notifications"
                    rootElementTag={false}
                >
                    Watchers
                </DarkTooltip>
            }
        >
            <UserMultiSelect
                position="top-right"
                selected={issuesList.issue.watchers}
                list={mainStore.currentUser?.isViewer ? [] : mainStore.users.activeOrderedUsers}
                hasSubscribe={hasSubscribe}
                onItemSelect={(user) =>
                    issuesList.issue.changeWatcher(user, !issuesList.issue.watchers.includes(user.id))
                }
                placeholder="Add watchers..."
            >
                <Button
                    border
                    size={24}
                    rightElm={<CustomIcon icon={CustomIconName.CHEVRON_DOWN} size={14} />}
                    leftElm={
                        (users.length > 0 && <AvatarsList hideStatus size={22} isButton limit={9} list={users} />) ||
                        undefined
                    }
                >
                    {!users.length && <i className="o-4">Not set</i>}
                </Button>
            </UserMultiSelect>
        </MetaItem>
    );
});

export default observer(Watchers);
