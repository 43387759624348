import React, { useEffect } from 'react';

import { FormattedMessage } from 'react-intl';

import { logEvent } from 'utils';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Scroll from 'components/Scroll/Scroll';

import { StScroll, StScrollHeader } from './atoms';
import { SimilarIdeaCard } from './SimilarIdeaCard';

export const IdeasPopoverContent = ({ ideas, onClose }) => {
    const handleClick = () => {
        logEvent('Close ideas suggestions window');
        onClose();
    };

    const ideasCnt = ideas.length;

    useEffect(() => {
        ideasCnt > 0 && logEvent('Find ideas suggestions', { count: ideasCnt });
    }, [ideasCnt]);

    return (
        <StScroll as={Scroll}>
            <StScrollHeader as={Flex} spaceBetween css={{ borderBottom: 0 }}>
                <span className="o-6">
                    <FormattedMessage id="idea.suggested" defaultMessage="Similar ideas" />
                </span>
                <Button border minimal icon={CustomIconName.CROSS_M} size={24} onClick={handleClick} />
            </StScrollHeader>
            <Flex column align="none" gap={6}>
                {ideas.map((idea) => (
                    <SimilarIdeaCard idea={idea} key={idea.id} />
                ))}
            </Flex>
        </StScroll>
    );
};
