import { makeAutoObservable } from 'mobx';

import dictionaryStore from 'store/models/DictionaryStore';

import { mainStore } from './MainStore';

export class AutoSyncRule {
    id = 0;
    issue_status = '';
    idea_status_id = 0;
    organization_webhook_id = 0;
    platform_id = 0;
    board_id = 0;
    provider = '';

    /**
     * @param {undefined|Object} rule
     * @param {Board} board
     */
    constructor(rule, board) {
        this.board = board;
        this.fillModel(rule);

        makeAutoObservable(this, { board: false });
    }

    fillModel = (obj) => {
        obj &&
            Object.entries(obj).forEach(([field, value]) => {
                this[field] = value;
            });
    };

    get issueStatus() {
        if (!this.issue_status) {
            return null;
        }
        if (this.platform?.isDucalis) {
            return dictionaryStore.issueStatuses.find((el) => el.name === this.issue_status);
        }
        return { name: this.issue_status, id: this.issue_status };
    }

    get ideaStatus() {
        return this.idea_status_id && this.board.ideaStatuses.find((el) => el.id === this.idea_status_id);
    }

    /**
     * @returns {null|Platform}
     */
    get platform() {
        return this.platform_id ? mainStore.platformsList.platforms.find((el) => el.id === this.platform_id) : null;
    }

    get webhook() {
        return this.organization_webhook_id
            ? mainStore.organization.webhooks.find((el) => el.id === this.organization_webhook_id)
            : null;
    }
}
