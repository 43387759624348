import React from 'react';

import { observer } from 'mobx-react-lite';

import PublicVotingIssue from 'modules/PublicVotingIssue';

import { issuesList } from 'store/models/IssuesList';

function IdeaCard() {
    if (!issuesList.idea) return null;

    return <PublicVotingIssue />;
}

export default observer(IdeaCard);
