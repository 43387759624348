import React from 'react';

import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import { mainStore } from 'store/models/MainStore';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import { PopoverClose } from 'components/Popover';

function AlignmentDropDownLink() {
    if (!mainStore.activeBoard || !mainStore.currentUser?.isAdmin) return null;

    return (
        <PopoverClose asChild>
            <Button
                as={Link}
                minimal
                block
                text="Open Criteria Alignment"
                icon={CustomIconName.ALIGN_TOTAL}
                to={`${mainStore.activeBoard.basePath}/alignment`}
            />
        </PopoverClose>
    );
}

export default observer(AlignmentDropDownLink);
