import React, { useState } from 'react';

import { Popover } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { defaultPopoverProps, IS_VOTING_BOARD } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';

import RemoveBtn from './RemoveBtn';
import ResolveBtn from './ResolveBtn';
import RestoreBtn from './RestoreBtn';

function IssueCommentMenu({ comment, parent, classNameBtn }) {
    const [open, setOpen] = useState(false);

    let mainAction;
    if (!parent && !IS_VOTING_BOARD && !mainStore.currentUser?.isViewer) {
        mainAction = comment.open ? <ResolveBtn comment={comment} /> : <RestoreBtn comment={comment} />;
    }

    const menu = (
        <Flex css={{ minWidth: 100, padding: 4 }} column align="none">
            {mainAction}
            <RemoveBtn model={parent || comment} comment={comment} />
        </Flex>
    );

    return (
        <Popover
            {...defaultPopoverProps}
            onOpening={() => setOpen(true)}
            onClosing={() => setOpen(false)}
            position="bottom-right"
            content={menu}
        >
            <Button
                active={open}
                className={open ? undefined : classNameBtn}
                icon={CustomIconName.DOTES_H}
                minimal
                size={24}
                border
            />
        </Popover>
    );
}

export default observer(IssueCommentMenu);
