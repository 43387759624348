import { DateTime } from 'luxon';
import { makeAutoObservable, runInAction } from 'mobx';

import api, { storageApi } from 'api';
import { THEMES, THEMES_IDS } from 'themes';

import * as OrganizationApi from 'store/models/api/Organization';
import { mainStore } from 'store/models/MainStore';

import { EXPORT_ACCESS, INSTANCE_PREFIX, IS_VOTING_BOARD, PAYWALL_LIMITS, USER_ROLE } from 'utils/consts';
import failRequest from 'utils/failRequest';
import getCurrencySymbolByCode from 'utils/getCurrencySymbolByCode';
import logEvent from 'utils/logEvent';

export default class Organization {
    id = null;
    name = '';
    timezone = '';
    export_access = null;
    typeform_response = null;
    payment_subscription = {
        plan: null,
        status: '',
        trial_days_left: 0,
        trial_start: '',
        trial_end: '',
    };
    last_updated = '';
    _links = null;
    platforms = [];
    inviteUsers = [];
    inviteUsersLoading = false;
    invite_type = null;
    domains = [];
    voting_email_settings = null;
    ideas_bots_settings = null;
    public_voting_settings = (IS_VOTING_BOARD && window.votingSetting) || null;
    has_admin_access = false;
    invite_role = USER_ROLE.Member;
    next_email_limit_reset = false;
    insights_enabled = false;
    created = '';
    webhooks = '';
    owner_id = null;

    deal_paid_users = 0;
    deal_paid_viewers = 0;
    deal_next_billing_cycle_date = '';

    has_payment_methods = false;

    limits = null;

    openWebHook = null;

    votingEmailStats = false;

    constructor(data) {
        data && this.setOrganization(data);

        makeAutoObservable(this);
    }

    get publicName() {
        return this.public_voting_settings?.publicName || this.name || '';
    }

    get language() {
        if (!IS_VOTING_BOARD) {
            return 'en';
        }
        return this.languageSettings;
    }

    get languageSettings() {
        return this.public_voting_settings?.language ?? window.defaultLanguage ?? 'en';
    }

    get canFree() {
        return this.paymentStatus === 'trial';
    }

    /**
     * @return {THEMES_IDS}
     */
    get theme() {
        return this.public_voting_settings?.theme || window.theme || THEMES_IDS.dark;
    }

    get isDarkTheme() {
        if (!IS_VOTING_BOARD) {
            return true;
        }
        return this.theme !== 2;
    }

    /**
     * @return {string}
     */
    get themeName() {
        return THEMES.get(this.theme)?.name ?? '';
    }

    get ducalisBranding() {
        if (!IS_VOTING_BOARD) {
            return true;
        }
        return this.public_voting_settings?.ducalis_branding ?? true;
    }

    get logo() {
        return this.public_voting_settings?.logo || this.public_voting_settings?.smallLogo;
    }

    fillLimits(fields) {
        if (!this.limits) {
            this.limits = {};
        }
        Object.entries(fields).forEach(([field, value]) => {
            this.limits[field] = value;
        });
    }

    updateModel(fields) {
        Object.assign(this, fields);
    }

    setOrganization = (data, limits) => {
        this.id = data.id;
        this.name = data.name;
        this.timezone = data.timezone;
        this.payment_subscription = data.payment_subscription;
        this.last_updated = data.last_updated;
        this._links = data._links;
        this.platforms = data.platforms;
        this.invite_type = data.invite_type;
        this.domains = data.domains;
        this.public_voting_settings = data.public_voting_settings;
        this.voting_email_settings = data.voting_email_settings;
        this.ideas_bots_settings = data.ideas_bots_settings;
        this.has_admin_access = data.has_admin_access;
        this.typeform_response = data.typeform_response || null;
        this.created = data.created || '';
        this.invite_role = data.invite_role || false;
        this.next_email_limit_reset = data.next_email_limit_reset;
        this.has_payment_methods = data.has_payment_methods;
        this.insights_enabled = data.insights_enabled || false;
        this.webhooks = data.webhooks;
        this.export_access = data.export_access;
        this.owner_id = data.owner_id;

        this.deal_paid_users = data.deal_paid_users;
        this.deal_paid_viewers = data.deal_paid_viewers;
        this.deal_next_billing_cycle_date = data.deal_next_billing_cycle_date;

        if (limits) {
            this.limits = limits;
        }
    };

    canUserUseCsvExport(user) {
        switch (this.export_access) {
            case EXPORT_ACCESS.owner:
                return user.is_owner;
            case EXPORT_ACCESS.admins:
                return user.isAdmin;
            case EXPORT_ACCESS.admins_members:
                return !user.isViewer;
            default:
                return false;
        }
    }

    get date_updated() {
        return DateTime.fromISO(this.last_updated).toFormat('ff');
    }

    get paymentStatus() {
        return (this.payment_subscription && this.payment_subscription.status) || null;
    }

    get paymentRequired() {
        return ['trial_ended', 'cancelled', 'stopped'].indexOf(this.payment_subscription?.status) !== -1;
    }

    get hideTrialBanner() {
        return this.payment_subscription?.status !== 'trial';
    }

    get payments() {
        return {
            payment_status: this.payment_subscription?.status,
            payment_plan: this.paymentPlanKey,
            payment_required: this.payment_required,
            trial_days_left: this.trialDaysLeft,
            trial_end: this.payment_subscription?.trial_end,
            trial_start: this.payment_subscription?.trial_start,
        };
    }

    get trialDaysLeft() {
        return !this.payment_subscription ? null : this.payment_subscription.trial_days_left;
    }

    get paymentPlan() {
        return (this.payment_subscription && this.payment_subscription.plan) || null;
    }

    get emailPlan() {
        return this.voting_email_settings?.plan_id || null;
    }

    get paymentQuantity() {
        return mainStore.users.activeUsersWithoutViewers?.length || 1;
    }

    get paymentPlanKey() {
        return this.paymentPlan?.key;
    }

    get paymentPlanName() {
        return this.paymentPlan?.name;
    }

    get paymentPlanLimits() {
        return (
            this.paymentPlan?.paywalls.filter((el) => el.value === null || el.value === true).map((el) => el.key) || []
        );
    }

    hasPaymentPlan(limit) {
        if (this.paymentRequired) {
            return false;
        }
        return this.paymentPlanLimits.includes(limit);
    }

    get canVotingDomain() {
        return this.hasPaymentPlan(PAYWALL_LIMITS.VOTING_DOMAIN);
    }

    get canVotingAppearance() {
        return this.hasPaymentPlan(PAYWALL_LIMITS.VOTING_APPEARANCE);
    }

    get canPushToTracker() {
        return this.hasPaymentPlan(PAYWALL_LIMITS.PUSH_ISSUE);
    }

    get needBlockBoard() {
        return this.paymentRequired;
    }

    get needBlockInvite() {
        if (this.paymentRequired) {
            return true;
        }
        const limit = this.paymentPlan?.[PAYWALL_LIMITS.USERS_LIMIT];
        if (limit === null) {
            return false;
        }
        return this.limits?.[PAYWALL_LIMITS.USERS_LIMIT] >= limit;
    }

    get currentPlanId() {
        return (this.paymentPlan && this.paymentPlan.id) || null;
    }

    get currency() {
        return getCurrencySymbolByCode(this.paymentPlan?.currency);
    }

    get extraBillingPage() {
        if (!this.payment_subscription) {
            return false;
        }
        return this.has_payment_methods || ['paying', 'stopped'].indexOf(this.payment_subscription.status) !== -1;
    }

    get votingLink() {
        return `https://${mainStore.organization.votingDomain}`;
    }

    get votingDomain() {
        if (this.public_voting_settings.is_subdomain) {
            return `${this.public_voting_settings.subdomain}${INSTANCE_PREFIX ? '-' : '.'}${window.location.hostname}`;
        }
        return this.public_voting_settings.custom_domain;
    }

    updateSettings(obj) {
        Object.entries(obj).forEach(([key, value]) => {
            this[key] = value;
        });
        this.pushChangeToServer(obj);
    }

    setName(name, save = false) {
        if (this.name !== name) {
            this.name = name;
        }
        if (save) {
            logEvent('CHANGE_ORG_NAME', { name: this.name });
            this.pushChangeToServer({ name: this.name });
        }
    }

    setTimezone = (timezone) => {
        if (timezone && this.timezone !== timezone) {
            this.timezone = timezone;
            this.pushChangeToServer({ timezone });
        }
    };

    setInviteType = (invite_type) => {
        if (invite_type && this.invite_type !== invite_type) {
            logEvent('Change org invite type', { invite_type });
            this.invite_type = invite_type;
            this.pushChangeToServer({ invite_type });
        }
    };

    changeAccountAccess() {
        const has_admin_access = !this.has_admin_access;
        this.has_admin_access = has_admin_access;
        logEvent('Change Account Access', { has_admin_access });
        this.pushChangeToServer({ has_admin_access });
    }

    // Api methods list

    fetchOrganization = async () => {
        try {
            const { data } = await storageApi.get(`/organization`);
            this.setOrganization(data);
        } catch (e) {
            failRequest(e);
        }
    };

    getVotingEmailsStats = async () => {
        try {
            const { data } = await api.get(`/organization/voting-email-stats`);
            runInAction(() => {
                this.votingEmailStats = data;
            });
        } catch (e) {}
    };

    setDomain = OrganizationApi.setDomain;

    changeOrg = OrganizationApi.changeOrg;

    pushChangeToServer = OrganizationApi.pushChangeToServer;

    pullInviteUsers = OrganizationApi.debounceAsyncInviteUsers;

    sendInviteUsers = OrganizationApi.sendInviteUsers;

    addDomain = OrganizationApi.addDomain;

    deleteLogo = OrganizationApi.deleteLogo;

    changeLogo = OrganizationApi.changeLogo;

    changePublicName = OrganizationApi.changePublicName;

    setColor = OrganizationApi.setColor;

    removeDomain = OrganizationApi.removeDomain;

    setVotingSettings = OrganizationApi.setVotingSettings;

    setVotingEmailSettings = OrganizationApi.setVotingEmailSettings;

    runDnsCheck = OrganizationApi.runDnsCheck;

    removeAll = OrganizationApi.removeAll;

    getSecret = OrganizationApi.getSecret;

    setWebhook = OrganizationApi.setWebhook;

    getWebhookDate = OrganizationApi.getWebhookDate;

    deleteWebhook = OrganizationApi.deleteWebhook;

    setBotSettings = OrganizationApi.setBotSettings;
}
