import React from 'react';

import { observer } from 'mobx-react-lite';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Kbd from 'components/Kbd';

const CurrentUsersMenuItem = observer(({ user, onSelect, isSelected }) => (
    <Button
        block
        minimal
        icon={!isSelected ? CustomIconName.EYE : CustomIconName.EYE_SLASH}
        onClick={() => onSelect(user)}
        rightElm={<Kbd>w</Kbd>}
    >
        {isSelected ? 'Stop watching' : 'Start watching'}
    </Button>
));

export default CurrentUsersMenuItem;
