import React from 'react';

import { FormattedMessage } from 'react-intl';

import { mainStore } from 'store/models/MainStore';

import { IS_PUBLIC_BOARD } from 'utils/consts';

export default function useVotingAuthor(author, altAuthor, isIdea) {
    if (!author && !altAuthor) {
        return null;
    }
    const user = author || altAuthor;

    if (isIdea && (user.is_member || (!IS_PUBLIC_BOARD && user.organizations?.length > 0))) {
        const orgName = user.is_member
            ? mainStore.organization.name
            : (!IS_PUBLIC_BOARD && user.organizations?.join(', ')) || null;
        return (
            <FormattedMessage
                id="voting_issue.author_from"
                defaultMessage="{author} from {orgName}"
                values={{
                    author: (
                        <span className="t-w-lb" itemProp="name">
                            {user.name}
                        </span>
                    ),
                    orgName: orgName ? (
                        <span
                            title={orgName}
                            className="t-w-lb bp5-text-overflow-ellipsis b-ib"
                            style={{ maxWidth: 220 }}
                        >
                            {orgName}
                        </span>
                    ) : (
                        ''
                    ),
                }}
            />
        );
    }

    return (
        <span className="t-w-lb bp5-text-overflow-ellipsis b-ib" style={{ maxWidth: 220 }} itemProp="name">
            {user.name}
        </span>
    );
}
