import React, { useCallback, useState } from 'react';

import classNames from 'classnames';
import Draggable from 'react-draggable';

import styles from './DraggableDiv.module.sass';

export default function DraggableDiv({ onDrag, slim }) {
    const [x, setX] = useState(0);

    const classes = classNames(styles.DragHandle, {
        [styles.DragHandle_slim]: slim,
    });

    const handleDrag = useCallback((event, { deltaX }) => setX((state) => state + deltaX), [setX]);

    const handleDragStop = useCallback(() => {
        onDrag(x);
        setX(0);
    }, [onDrag, setX, x]);

    const onMouseDown = useCallback((e) => {
        e.stopPropagation();
        return false;
    },[]);

    if (!slim) {
        return (
            <Draggable
                onMouseDown={onMouseDown}
                axis="x"
                defaultClassName={classes}
                defaultClassNameDragging={styles.DragHandleActive}
                position={{ x: 0 }}
                onDrag={(event, { deltaX }) => onDrag(deltaX)}
                zIndex={999}
            >
                <span className="DragHandleIcon" />
            </Draggable>
        );
    }

    return (
        <Draggable
            onMouseDown={onMouseDown}
            axis="x"
            defaultClassName={classes}
            defaultClassNameDragging={styles.DragHandleActive}
            onDrag={handleDrag}
            onStop={handleDragStop}
            position={{ x: x, y: 0 }}
            zIndex={999}
        >
            <span className="DragHandleIcon" />
        </Draggable>
    );
}
