import { useEffect, useMemo, useState } from 'react';

import debounce from 'lodash/debounce';

import queryInText from 'utils/queryInText';

const debounceUpdater = debounce((fn) => fn(), 300);
export const useUserFilter = (query, list) => {
    const [queryStr, setQueryStr] = useState('');

    useEffect(() => {
        debounceUpdater(() => setQueryStr(query));
    }, [query]);

    return useMemo(() => {
        if (queryStr.length > 1) {
            return list.filter((item) => {
                const { email = '', company, name = '' } = item;
                return queryInText(`${email} ${company?.name} ${name}`, queryStr);
            });
        }

        return list;
    }, [list, queryStr]);
};
