import orderBy from 'lodash/orderBy';
import { makeAutoObservable } from 'mobx';

import api from 'api';
import { failRequest, toUrl } from 'utils';

import { RELEASE_NOTE_STATUS } from 'pages/Board/ReleaseNotes/constants';

import { mainStore } from 'store/models/MainStore';
import ReleaseNoteItem from 'store/models/ReleaseNoteItem';

import arrayMoveMutable from 'utils/arrayMoveMutable';

export default class ReleaseNote {
    id = null;
    is_master = false;
    author_id = null;
    title = '';
    main_intro = '';
    board_id = null;
    is_custom = false;
    is_system = false;
    status = '';
    status_message = null;
    audience = null;
    ideas_minimum = null;
    sent_date = '';
    schedule_date = '';
    time_frame_start = '';
    time_frame_end = '';
    created = '';
    updated = '';
    itemsIds = new Map();
    organization_webhook_id = null;
    slug = '';
    url = '';

    minPosition = 1;

    stats = null;

    constructor(data) {
        this.updateModel(data);

        makeAutoObservable(this);
    }

    updateModel({ items, ...otherData }) {
        Object.assign(this, otherData);

        if (items && Array.isArray(items)) {
            let ids = Array.from(this.itemsIds.keys());
            items.forEach((item) => {
                if (this.itemsIds.has(item.id)) {
                    this.itemsIds.get(item.id).updateModel(item);
                    ids = ids.filter((id) => id !== item.id);
                } else {
                    this.itemsIds.set(item.id, new ReleaseNoteItem(item, this));
                }
            });
            ids.forEach((id) => this.itemsIds.delete(id));
        }
    }

    get maxPosition() {
        return this.itemsIds.size;
    }

    get items() {
        const items = Array.from(this.itemsIds.values());
        return orderBy(items, 'position');
    }

    moveItems({ fromIndex, toIndex }) {
        arrayMoveMutable(this.items, fromIndex, toIndex).map((item, index) => {
            item.position = index + 1;
            return item;
        });
    }

    /**
     * Returns the corresponding author from mainStore.
     */
    get author() {
        return mainStore.users.usersIds.get(this.author_id);
    }

    /**
     * Returns the corresponding board from mainStore.
     */
    get board() {
        return mainStore.activeBoards.find((board) => board.id === this.board_id);
    }

    get apiEndpoint() {
        return `/boards/${this.board?.id}/release-notes/${this.id}`;
    }

    get isSent() {
        return [RELEASE_NOTE_STATUS.PUBLISHED, RELEASE_NOTE_STATUS.IN_PROGRESS].includes(this.status);
    }

    get emailPreviewUrl() {
        return `${process.env.NODE_ENV !== 'production' ? 'http://localhost' : ''}/board/${
            this.board_id
        }/release-notes/${this.id}/preview`;
    }

    get readOnly() {
        return [RELEASE_NOTE_STATUS.PUBLISHED, RELEASE_NOTE_STATUS.IN_PROGRESS, RELEASE_NOTE_STATUS.EMPTY].includes(
            this.status,
        );
    }

    // API methods

    async update(data) {
        try {
            await api.put(this.apiEndpoint, toUrl(data));
        } catch (e) {
            failRequest(e);
        }
    }

    async getStats() {
        try {
            const { data } = await api.get(`${this.apiEndpoint}/item-stats`);
            this.updateModel({ stats: data });
        } catch (e) {
            failRequest(e);
        }
    }

    async sendTestEmail() {
        try {
            await api.post(`${this.apiEndpoint}/send`);
        } catch (e) {
            failRequest(e);
            await Promise.reject(e);
        }
    }

    async createItem({ type, position, idea_id }) {
        try {
            const data = { type, enabled: true, position: position + 1, idea_id };
            await api.post(`${this.apiEndpoint}/items`, toUrl(data));
        } catch (e) {
            failRequest(e);
        }
    }
}
