import { action, makeAutoObservable, runInAction } from 'mobx';

import dictionaryStore from 'store/models/DictionaryStore';
import { Idea } from 'store/models/Idea';
import { utilsStore } from 'store/models/UtilsStore';
import { votingComment } from 'store/models/VotingComment';

import { CURRENT_USER_ID, IS_PUBLIC_BOARD, PROVIDER_DUCALIS, UNSAVED_MODEL_ID, VIEWS } from 'utils/consts';
import delay from 'utils/delay';
import { sendToSentry } from 'utils/sentry';
import uniqBy from 'utils/uniqBy';

import { Issue } from './Issue';
import IssuesData from './IssuesData';
import { mainStore } from './MainStore';

function findIssue(issue, issueId, boardId) {
    const isSameBoard = !IS_PUBLIC_BOARD ? issue.boardId === Number(boardId) : issue.boardId === boardId;
    return issue.id === Number(issueId) && isSameBoard;
}

export class IssuesList {
    /**
     * @type {Idea[]}
     */
    ideas = [];
    allIssues = [];
    issuesData = new Map();
    loading = false;
    type = null;
    getNext = false;
    row = null;
    col = null;

    /**
     * @type {Issue|Idea}
     */
    activeIssue = null;
    questionIssue = null;
    hasQuery = false;
    params = {};

    collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

    constructor() {
        makeAutoObservable(this, {
            setActiveIssue: action,
            setActiveIssueById: action,
            setIdeas: action,
            updateIdea: action,
            clearLoading: action,

            collator: false,
        });
    }

    fillIssuesData(list) {
        list.forEach((data) => this.issuesData.set(`${data.id}-${data.boardId}`, new IssuesData(data)));
    }

    getInitialRow(count) {
        if (this.row) {
            return this.row < count ? this.row : count - 1;
        }
        return 0;
    }

    setIdeas(list) {
        this.ideas = list.map((el) => new Idea(el));
    }

    clearIdeasByBoardId(boardId) {
        this.ideas = this.ideas.filter((idea) => String(idea.board_id) !== String(boardId));
    }

    updateIdea(data, isNew) {
        let idea = this.ideas.find(({ id }) => id === data.id);
        if (idea) {
            idea.set(data);
        } else if (isNew) {
            idea = new Idea(data);
            this.ideas.push(idea);
        }
        if (idea && this.activeIssue instanceof Idea && this.activeIssue?.id === idea.id) {
            this.setActiveIssue(idea);
        }
    }

    fillIdeas(list, boards) {
        if (!boards) return;

        const filteredIdeas = this.ideas.filter((idea) => !boards.includes(idea.board_id));
        const ideas = list.map((raw) => {
            const idea = this.ideas.find((el) => el.id === raw.id);
            idea && idea.set(raw);
            return idea || new Idea(raw);
        });

        filteredIdeas.push(...ideas);
        this.ideas = filteredIdeas;

        if (this.activeIssue && this.activeIssue instanceof Idea) {
            const newActiveIssue = this.ideas.find((idea) => idea.id === this.activeIssue.id);
            this.activeIssue = newActiveIssue || null;
        }
    }

    fillData(list, boardId) {
        if (boardId) {
            const filteredIssues = this.allIssues.filter((el) => el.boardId !== boardId);
            const newIssues = list.map((rawIssue) => new Issue(rawIssue));
            filteredIssues.push(...newIssues);
            this.allIssues = filteredIssues;
        } else {
            this.allIssues = list.map((rawIssue) => new Issue(rawIssue));
        }

        if (mainStore.activeBoard && this.activeIssue && this.activeIssue instanceof Issue) {
            this.activeIssue = mainStore.activeBoard.issues.find((issue) => issue.id === this.activeIssue.id) || null;
        }
    }

    async createIssue() {
        const assignee_id =
            mainStore.activeBoard.filter && mainStore.activeBoard.filter.key === 'assignee.id'
                ? mainStore.activeBoard.filter.value
                : null;

        const issue = new Issue({
            id: UNSAVED_MODEL_ID,
            provider: PROVIDER_DUCALIS,
            tempid: Date.now(),
            boardId: mainStore.activeBoard.id,
            name: '',
            description: '',
            assignee_id: assignee_id || null,
            reporter_id: null,
            status_id: dictionaryStore.todoStatusId,
            type_id: dictionaryStore.defaultIssueTypeId,
            needFocus: true,
        });

        if (!this.allIssues.some((el) => el.id === issue.id)) {
            this.allIssues.push(issue);
            this.activeIssue = issue;
            if (mainStore.page === VIEWS.EVALUATION) utilsStore.toggleCardHidden(false);
        }
    }

    createIdea(statusId) {
        if (!CURRENT_USER_ID) {
            utilsStore.setHistoryAction({ create: null });
            return;
        }

        this.activeIssue = new Idea({
            id: UNSAVED_MODEL_ID,
            issue_id: null,
            tempId: Date.now(),
            boardId: mainStore.activeBoard.id,
            board_id: mainStore.activeBoard.id,
            allow_voting: IS_PUBLIC_BOARD,
            status_id: statusId || mainStore.activeBoard.reviewIdeaStatusId,
            name: '',
            description: '',
            author: mainStore.currentUser,
        });

        if (!IS_PUBLIC_BOARD) {
            this.activeIssue.save(this.activeIssue);
        } else {
            this.activeIssue.board.setUserVote(true, this.activeIssue.id, 1);
        }

        utilsStore.toggleCardHidden(false);
    }

    clearNavigation() {
        this.hasQuery = false;
        this.row = null;
        this.col = null;
        this.activeIssue = null;
    }

    changeFocus = ({ row, col, activeIssue, callback }) => {
        this.row = row;
        this.col = col;
        this.setActiveIssue(!activeIssue ? null : activeIssue);

        if (typeof callback === 'function') {
            callback();
        }
    };

    setActiveIssue(issue = null) {
        if (issue?.is_fake) return;

        if ((!issue && !this.activeIssue?.id) || this.activeIssue?.id !== issue?.id) {
            votingComment.clear();
        }
        this.activeIssue = issue;
    }

    setActiveIssueById(issueId) {
        if (this.activeIssue?.id !== issueId && mainStore.activeModel?.issues) {
            this.activeIssue = mainStore.activeModel.issues.find((el) => el.id === Number(issueId));
        }
        return this.activeIssue;
    }

    setActiveVotingIssueById(issueId, isInit) {
        if (isInit && window.idea) {
            this.updateIdea(window.idea, true);
        }

        let idea = issuesList.ideas.find((el) => el.id === Number(issueId)) || null;

        if (!idea) this.activeIssue = null;

        if (idea?.parent_id) {
            this.activeIssue = mainStore.activeBoard.allIdeas.find((el) => el.id === idea.parent_id) || null;
        } else {
            this.activeIssue = idea;
        }
    }

    fetchIssue({ issueId, id }) {
        let issue = this.allIssues.find((issue) => findIssue(issue, issueId, id));
        if (!issue) {
            sendToSentry('Not found issue', { issueId, id });
            return false;
        }
        if (issue.isDone) {
            sendToSentry('Try Open Done Issue', { id: issue.id });
            return false;
        }
        const row = mainStore.activeModel.issues.findIndex((el) => el.id === issue.id);
        this.changeFocus({ row, activeIssue: issue });
        delay(100).then(() => utilsStore.toggleCardHidden(false));
        return issue;
    }

    setQuestion({ issue, row = null }) {
        if (issue) {
            this.activeIssue = issue;
        }
        this.questionIssue = issue;
        this.row = row || this.row;
    }

    clearUnsaved() {
        this.allIssues = this.allIssues.filter((el) => el.id !== UNSAVED_MODEL_ID);
    }

    updateSingle(data, boardId, oldId = -1) {
        const needUpdateActiveIssue =
            [oldId, data.id].includes(this.activeIssue?.id) && this.activeIssue?.boardId === boardId;

        // Clear current new saving Issue
        const issues = this.allIssues.filter((el) => !el.needClear);
        runInAction(() => (this.allIssues = issues));

        let issue = this.allIssues.find((issue) => issue.id === data.id && issue.boardId === boardId);

        const rawData = { ...data, boardId };

        if (issue) {
            issue.fillModel(rawData);
        } else {
            issue = new Issue(rawData);
            this.allIssues.push(issue);
            const issueDataKey = `${rawData.id}-${rawData.boardId}`;
            if (!this.issuesData.has(issueDataKey)) {
                this.issuesData.set(issueDataKey, new IssuesData({ boardId: rawData.boardId }));
            }
        }

        if (needUpdateActiveIssue) {
            issue.tempid = this.activeIssue?.tempid;
            issue.needFocus = this.activeIssue?.needFocus;
            this.activeIssue = issue;
        }
    }

    removeSingle(issueId, boardId) {
        // Clear from collection
        if (this.allIssues.length) {
            this.allIssues = this.allIssues.filter((issue) => !findIssue(issue, issueId, boardId));
            issuesList.issuesData.delete(`${issueId}-${boardId}`);
        }

        if (this.activeIssue && issueId === this.activeIssue.id) {
            this.setActiveIssue(null);
        }

        // Clear from DB
        mainStore.db.removeRowDB([boardId, issueId], 'issues');
    }

    removeSingleVotingIssue(ideaId) {
        if (!this.ideas) return;

        const removingIdea = this.ideas.find((idea) => idea.id === ideaId);

        if (!removingIdea) return;

        // change activeIssue to next row
        if (this.idea && ideaId === this.idea.id) {
            const boardIdeas = this.ideas.filter(
                (idea) => idea.id !== ideaId && removingIdea.board_id === idea.board_id && !idea.parent_id,
            );
            const nextIndex = this.getInitialRow(boardIdeas.length);
            const newIssue = boardIdeas[nextIndex];
            this.changeFocus({ activeIssue: newIssue || null });
        }

        // Clear from collection
        this.ideas = this.ideas.filter((idea) => idea.id !== ideaId);
    }

    getIdeaById(ideaId) {
        return this.ideas.find((idea) => idea.id === ideaId);
    }

    get uniqIssues() {
        const uniqIds = uniqBy(
            this.allIssues.map((issue) => issue.id),
            (id) => id,
        );
        return uniqIds
            .map((id) => this.allIssues.find((issue) => issue.id === id))
            .sort((a, b) => this.collator.compare(a.name, b.name));
    }

    /**
     * @return {Issue|null}
     */
    get issue() {
        if (this.activeIssue instanceof Idea) {
            return this.activeIssue.parentIssue;
        }
        return this.activeIssue;
    }

    /**
     * @return {Idea|null}
     */
    get idea() {
        if (!this.activeIssue) return null;

        if (this.activeIssue instanceof Idea) {
            return this.activeIssue;
        }

        return this.activeIssue.ideas.find((idea) => idea.board_id === this.activeIssue.boardId);
    }
}

export const issuesList = new IssuesList();
