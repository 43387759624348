import React from 'react';

import { observer } from 'mobx-react-lite';

import { IS_PUBLIC_BOARD } from 'utils/consts';

import Flex from 'components/Flex';
import ProgressSpinner from 'components/ProgressSpinner';
import Tooltip from 'components/Tooltip';

import UnevaluatedUsersTooltipContent from './UnevaluatedUsersTooltipContent';

function VotingPercent({ issue }) {
    const progress = (
        <Flex gap={4} grow css={{ height: 32 }}>
            <ProgressSpinner progress={issue.voting_percent} size={16} />
            <span style={{ lineHeight: '20px' }}>{issue.voting_percent}%</span>
        </Flex>
    );

    if (IS_PUBLIC_BOARD) {
        return (
            <Tooltip
                align="top"
                content={
                    issue.voting_percent < 100
                        ? 'Task is not evaluated yet by all teammates'
                        : 'All board members evaluated the issue'
                }
            >
                {progress}
            </Tooltip>
        );
    }

    if (issue.voting_percent < 100) {
        return <Tooltip content={<UnevaluatedUsersTooltipContent issue={issue} />}>{progress}</Tooltip>;
    }

    return (
        <Tooltip position="top" content="All board members evaluated the issue">
            {progress}
        </Tooltip>
    );
}

export default observer(VotingPercent);
