import Axios from 'axios';
import groupBy from 'lodash/groupBy';
import { runInAction } from 'mobx';

import { rootApi } from 'api';
import { failRequest } from 'utils';

import criteriaStore from 'store/models/CriteriaStore';
import dictionaryStore from 'store/models/DictionaryStore';
import educationBanners from 'store/models/EducationBanners';
import { issuesList } from 'store/models/IssuesList';
import { ORG_DB_STORES } from 'store/updateOrgDB';

import { calcPerf } from 'utils/calcPerf';
import { CURRENT_USER_IS_ADMIN } from 'utils/consts';
import debugLog from 'utils/debugLog';
import delay from 'utils/delay';
import { prepareServerIssuesResponse } from 'utils/prepareServerIssuesResponse';

function yieldToMain() {
    return new Promise((resolve) => {
        setTimeout(resolve, 0);
    });
}

async function fillDbAfterFetch(
    db,
    {
        organization,
        boardsResponse,
        votedPercentsResponse,
        reportsResponse,
        usersResponse,
        platformsResponse,
        preparedList,
        issuesScore,
        commentsResponse,
        releaseNotesResponse,
        linkedIssuesResponse,
        headers,
        dictResponse,
        criteriaResponse,
        criteriaBoardsResponse,
    },
) {
    process.env.REACT_APP_ENV !== 'prod' && debugLog('START Fill DB after fetch');
    await db.saveOrganization(organization);
    await db.saveList(commentsResponse.data, 'comments');
    await db.saveList(linkedIssuesResponse.data, 'issuesLinks');
    await db.saveList(platformsResponse.data, 'platforms');
    await db.saveList(boardsResponse.data, 'boards');
    await db.saveList(votedPercentsResponse.data, 'votedPercents');
    await db.saveList(reportsResponse.data, 'reports');
    await db.saveList(usersResponse.data.users, 'users');
    await db.saveList(preparedList, 'issues');
    await db.saveList(issuesScore, 'issuesScore');
    await db.saveList(releaseNotesResponse.data, 'releaseNotes');
    await db.saveList(criteriaResponse.data, ORG_DB_STORES.criteria);
    await db.saveList(criteriaBoardsResponse.data, ORG_DB_STORES.criteriaBoards);
    await db.saveMeta(headers['x-version']);
    await dictionaryStore.fillDB(dictResponse.data);

    calcPerf('END Fill DB from fetch', window.globalStart, window.globalStart - window.beforeGlobalStart);
    window.beforeGlobalStart = performance.now();

    await yieldToMain();
    process.env.REACT_APP_ENV !== 'prod' && debugLog('END Fill DB after fetch');
}

export async function fetchAll() {
    try {
        process.env.REACT_APP_ENV !== 'prod' && debugLog('START fetch');

        calcPerf('Start fetch', window.globalStart, window.globalStart - window.beforeGlobalStart);
        window.beforeGlobalStart = performance.now();

        const requests = [
            // NEW
            '/storage/organization',
            '/storage/platforms',
            '/storage/users',
            '/storage/boards',
            '/storage/reports',
            '/storage/dictionaries',
            '/storage/voted-percents',
            '/storage/issues',
            '/storage/issues-scores',
            '/storage/requests',
            '/storage/voting-votes',
            '/storage/release-notes',
            '/storage/issues-links',
            '/storage/criteria',
            '/storage/boards-criteria',
            // OLD
            '/rest/organization/plan-metrics',
        ];

        const [
            // NEW
            orgResponse,
            platformsResponse,
            usersResponse,
            boardsResponse,
            reportsResponse,
            dictResponse,
            votedPercentsResponse,
            issuesResponse,
            issuesScoreResponse,
            commentsResponse,
            votingVotesResponse,
            releaseNotesResponse,
            linkedIssuesResponse,
            criteriaResponse,
            criteriaBoardsResponse,
            // OLD
            limitResponse,
        ] = await Axios.all(
            requests.map((url) =>
                rootApi.get(url, {
                    onDownloadProgress: (progressEvent) => {
                        if (!progressEvent.total) return;
                        this.setProgress(url, Math.round((progressEvent.loaded * 100) / progressEvent.total));
                    },
                }),
            ),
        );

        criteriaStore.fillData(criteriaResponse.data, criteriaBoardsResponse.data);

        calcPerf('End fetch', window.globalStart, window.globalStart - window.beforeGlobalStart);
        window.beforeGlobalStart = performance.now();

        process.env.REACT_APP_ENV !== 'prod' && debugLog('START fetch > fill');

        const { data: organization, headers } = orgResponse;
        const releaseNotesData = groupBy(releaseNotesResponse.data, 'board_id');

        this.organization.setOrganization(organization, limitResponse.data);
        this.platformsList.set(platformsResponse.data);
        dictionaryStore.fillCollection(dictResponse.data);
        this.fillAllVotedPercents(votedPercentsResponse.data);

        // USERS
        this.users.set(usersResponse.data.users || []);
        usersResponse.data.me && this.users.setCurrentUser(usersResponse.data.me);

        this.fillQuestions(commentsResponse.data);

        this.boardsList.set(boardsResponse.data);

        // Required - after boardsList.set
        const issuesScore = prepareServerIssuesResponse(issuesScoreResponse.data);
        issuesList.fillIssuesData(issuesScore);
        const preparedList = prepareServerIssuesResponse(issuesResponse.data);
        issuesList.fillData(preparedList);
        this.activeBoards.forEach((board) => {
            if (typeof releaseNotesData[board.id] !== 'undefined') {
                releaseNotesData[board.id].forEach((item) => {
                    board.updateReleaseNote(item);
                });
            }
        });
        this.setVotingVotes(votingVotesResponse.data);
        this.fillReports(reportsResponse.data);
        delay(0).then(async () => {
            await this.fetchIdeas();
            await this.fetchAnnounces();
            await this.fetchWatchers();
        });

        delay(0).then(() => educationBanners.fetchAll());
        process.env.REACT_APP_ENV !== 'prod' && debugLog('END fetch');
        this.setReady(true);

        runInAction(() => {
            linkedIssuesResponse.data.forEach((item) => this.linkedIssues.set(item.id, item));
        });

        calcPerf('End fill from fetch', window.globalStart, window.globalStart - window.beforeGlobalStart);
        window.beforeGlobalStart = performance.now();

        if (!CURRENT_USER_IS_ADMIN)
            delay(0).then(() =>
                fillDbAfterFetch(this.db, {
                    organization,
                    boardsResponse,
                    votedPercentsResponse,
                    reportsResponse,
                    usersResponse,
                    platformsResponse,
                    preparedList,
                    issuesScore,
                    commentsResponse,
                    releaseNotesResponse,
                    linkedIssuesResponse,
                    headers,
                    dictResponse,
                    criteriaResponse,
                    criteriaBoardsResponse,
                }),
            );
    } catch (e) {
        failRequest(e);

        delay(0).then(() => educationBanners.fetchAll());
        await this.fetchIdeas();
        await this.fetchAnnounces();
        delay(0).then(() => this.fetchWatchers());
    }
}
