import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { StHr } from 'atoms/StHr';

import { POPOVER_PROPS_DIV, PROVIDER_ASANA, PROVIDER_CLICKUP, PROVIDER_JIRA, PROVIDER_TRELLO } from 'utils/consts';
import logEvent from 'utils/logEvent';

import ButtonLink from 'components/ButtonLink';
import { CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import Flex from 'components/Flex';
import PlatformIcon from 'components/PlatformIcon';

function AddTrackerButton({ boardId }) {
    if (!mainStore.activeBoard?.isDucalis || !mainStore.currentUser?.isAdmin) {
        return null;
    }

    return (
        <>
            <StHr />
            <DarkTooltip {...POPOVER_PROPS_DIV} position="left" content="Connect tracker and link idea to it" fast>
                <ButtonLink
                    onClick={() => logEvent('Click - Fake Link to Task Tracker')}
                    to={`/integration/${boardId}`}
                    icon={CustomIconName.ARROW_RIGHT}
                    rightElm={
                        <Flex wrap css={{ width: 20 }} className="flex-shrink">
                            <PlatformIcon size={10} provider={PROVIDER_JIRA} />
                            <PlatformIcon size={10} provider={PROVIDER_ASANA} />
                            <PlatformIcon size={10} provider={PROVIDER_TRELLO} />
                            <PlatformIcon size={10} provider={PROVIDER_CLICKUP} />
                        </Flex>
                    }
                    minimal
                    block
                    title="Link to Task Tracker"
                    size={30}
                />
            </DarkTooltip>
        </>
    );
}

export default observer(AddTrackerButton);
