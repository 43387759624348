import React from 'react';

import { Position } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { getSprintStatusText } from 'utils';

import { issuesList } from 'store/models/IssuesList';

import { POPOVER_PROPS_DIV } from 'utils/consts';

import { Button } from 'components/Button';
import DarkTooltip from 'components/DarkTooltip';
import MetaItem from 'components/MetaItem';

function SprintInfo() {
    if (!issuesList.issue?.sprint) return null;

    const issue = issuesList.issue;

    return (
        <MetaItem title="Sprint" fillFree={issue.sprint.name?.length < 13}>
            <DarkTooltip {...POPOVER_PROPS_DIV} content={getSprintStatusText(issue.sprint)} position={Position.TOP}>
                <Button
                    noneInteractive
                    size={24}
                    as="div"
                    color={issue.sprintColor}
                    css={{ minWidth: 0, padding: '0 4px' }}
                    text={issue.sprint.name}
                />
            </DarkTooltip>
        </MetaItem>
    );
}

export default observer(SprintInfo);
