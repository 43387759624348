import { styled } from 'stitches.config';

const StyledDivider = styled('div', {
    zIndex: 12,
    width: 5,
    position: 'absolute',
    top: 0,
    bottom: 0,
    variants: {
        position: {
            left: {
                left: 0,
            },
            right: {
                right: 0,
            },
        },
    },
});

export default function Divider({ position = 'right', ...props }) {
    return <StyledDivider {...props} position={position} />;
}
