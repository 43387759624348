import React from 'react';

import { isOSX } from 'hooks/useHotKey';

import Flex from 'components/Flex';

function getNormalizeKey(key) {
    if (['alt', 'option'].includes(key)) {
        return isOSX ? '⌥' : 'Alt';
    }
    if (['mod', 'cmd', 'meta'].includes(key)) {
        return isOSX ? '⌘' : 'Ctrl';
    }
    if (key === 'shift') {
        return '⇧';
    }
    return key;
}

export default function Kbd({ children }) {
    return <kbd className="ff-case ff-ss02 t-cap">{getNormalizeKey(children)}</kbd>;
}

export function KeyCombo({ children }) {
    return (
        <Flex as="span" inline gap={2} css={{ paddingLeft: 4 }}>
            {children}
        </Flex>
    );
}
