import React from 'react';

import { observer } from 'mobx-react-lite';

import { utilsStore } from 'store/models/UtilsStore';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

export const TableSearchClearButton = observer(({ query = '', onClick }) => {
    if (query.length || utilsStore.isMobile) {
        return <Button minimal size={24} icon={CustomIconName.CROSS_M} onClick={() => onClick('')} />;
    }
    return null;
});
