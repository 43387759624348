export function groupBy(arr, getKeyFromItem) {
    const result = {};

    for (const item of arr) {
        const key = getKeyFromItem(item);

        if (result[key] == null) {
            result[key] = [];
        }

        result[key].push(item);
    }

    return result;
}
