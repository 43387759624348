import React, { useEffect, useState } from 'react';

import { Select } from '@blueprintjs/select';

import { EMPTY_ARRAY } from 'utils/consts';
import delay from 'utils/delay';
import highlightText from 'utils/highlightText';
import queryInText from 'utils/queryInText';

import Avatar from 'components/Avatar';
import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import VirtuosoList from 'components/VirtuosoList';

function activeItemIndex(list, active) {
    return list.findIndex(({ id }) => id === active?.id);
}

const itemRenderer = (item, { modifiers, handleClick, query = '' }) => {
    return (
        <Button
            onClick={handleClick}
            minimal
            block
            leftElm={<Avatar hideStatus user={item} size={18} />}
            text={highlightText(item.name, query)}
            rightElm={<em className="t-mutted">{item.email}</em>}
            active={modifiers.active}
        />
    );
};

export function UserQuestionSelector({
    tabIndex,
    user,
    users,
    onChange,
    children,
    canBeNull,
    shouldReturnFocusOnClose = true,
}) {
    const [items, setItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [activeItem, setActiveItem] = useState(user || undefined);

    useEffect(() => {
        const items = (users || EMPTY_ARRAY).filter((el) => !(el.status === 0 || el.id === -1));
        setItems(items);
        if (!user && !canBeNull) {
            delay(300).then(() => setOpen(true));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onSelect(selectUser) {
        setOpen(false);
        if (selectUser && selectUser.id === user?.id) {
            return;
        }
        onChange(selectUser);
    }

    let btn;
    if (children) {
        btn = (
            <div className="flex" onClick={() => setOpen(true)}>
                {children}
            </div>
        );
    } else {
        btn = (
            <Button
                tabIndex={tabIndex}
                onClick={() => setOpen(true)}
                size={24}
                border
                leftElm={(user && <Avatar user={user} size={18} />) || undefined}
                text={(user && (user.name || user.email)) || <em>Choose user...</em>}
                rightElm={<CustomIcon icon={CustomIconName.CHEVRON_DOWN} size={14} />}
            />
        );
    }

    return (
        <Select
            filterable
            onItemSelect={onSelect}
            itemPredicate={(query, { name, email }) => queryInText(`${name || ''} ${email || ''}`, query)}
            itemRenderer={itemRenderer}
            items={items}
            popoverProps={{
                shouldReturnFocusOnClose,
                minimal: true,
                enforceFocus: false,
                boundary: 'window',
                isOpen: open,
                captureDismiss: true,
                onClose: () => setOpen(false),
            }}
            activeItem={activeItem}
            onActiveItemChange={setActiveItem}
            resetOnQuery={false}
            itemListRenderer={(props) => (
                <VirtuosoList
                    width={340}
                    className="hide-v-scroll"
                    {...props}
                    sizeItem={32}
                    activeItemIndex={activeItemIndex}
                />
            )}
            inputProps={{
                className: 'custom-select-clear',
                leftIcon: <CustomIcon icon={CustomIconName.SEARCH} />,
            }}
        >
            {btn}
        </Select>
    );
}
