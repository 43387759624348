import React from 'react';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';

const StatsDetailsContent = ({ idea }) => {
    const { voters_read, registered_read, anon_read, total_read } = idea.vote_stats;

    return (
        <div>
            <p>
                Stats collection started after you change that idea’s status to Done on{' '}
                {DateTime.fromISO(idea.dateUpdate).toFormat('DD')}
            </p>
            <Flex gap={2}>
                <CustomIcon icon={CustomIconName.GRAPH} />
                <strong>{total_read} Viewers include:</strong>
            </Flex>
            <ul style={{ margin: 0, padding: '0 36px' }}>
                <li>Voters: {voters_read}</li>
                <li>Authorized, not voters: {registered_read - voters_read}</li>
                <li>Anonymous: {anon_read}</li>
            </ul>
        </div>
    );
};

export default observer(StatsDetailsContent);
