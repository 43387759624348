import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { failRequest } from 'utils';

import { mainStore } from 'store/models/MainStore';

import { DISABLE_SURVEY, PAYWALL_LIMITS } from 'utils/consts';
import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import UpgradePlanBtn from 'components/UpgradePlanBtn';

import { DASHBOARD_BTN_PROPS, SIDEBAR_BTN_PROPS } from './constants';

function AddBoardButton({ className, isSidebar }) {
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    const btnProps = isSidebar ? { ...SIDEBAR_BTN_PROPS } : { ...DASHBOARD_BTN_PROPS };

    if (mainStore.organization?.needBlockBoard) {
        return (
            <UpgradePlanBtn paywall={PAYWALL_LIMITS.BOARDS_LIMIT} text="to Create more Boards">
                <Button data-place="cr-board-btn" className={className} disabled {...btnProps} />
            </UpgradePlanBtn>
        );
    }

    async function createBoard() {
        logEvent('Create New Board');

        try {
            setLoading(true);
            const boardId = await mainStore.createDucalisBoard();
            if (DISABLE_SURVEY) {
                navigate(`/board/${boardId}/summary`);
            } else {
                navigate(`/board/${boardId}/description`);
            }
        } catch (e) {
            failRequest(e);
        } finally {
            setLoading(false);
        }
    }

    if (loading) {
        btnProps.icon = undefined;
        btnProps.disabled = true;
        btnProps.loading = true;
    }

    return <Button data-place="cr-board-btn" {...btnProps} onClick={() => createBoard()} />;
}

export default observer(AddBoardButton);
