export const LANG = [
    { id: 'en', label: 'English', progress: 100 },
    { id: 'af', label: 'Afrikaans', progress: 0 },
    { id: 'ar', label: 'العربية', progress: 0 },
    { id: 'ca', label: 'Català', progress: 0 },
    { id: 'zh', label: '简体中文', progress: 74 },
    { id: 'cs', label: 'Čeština', progress: 100 },
    { id: 'da', label: 'Dansk', progress: 100 },
    { id: 'nl', label: 'Nederlands', progress: 100 },
    { id: 'fi', label: 'Suomi', progress: 77 },
    { id: 'fr', label: 'Français', progress: 100 },
    { id: 'ka', label: 'ქართული', progress: 0 },
    { id: 'de', label: 'Deutsch', progress: 100 },
    { id: 'el', label: 'Ελληνικά', progress: 0 },
    { id: 'he', label: 'עברית', progress: 0 },
    { id: 'hi', label: 'हिन्दी', progress: 0 },
    { id: 'hu', label: 'Magyar', progress: 0 },
    { id: 'it', label: 'Italiano', progress: 100 },
    { id: 'ja', label: '日本語', progress: 2 },
    { id: 'ko', label: '한국어', progress: 0 },
    { id: 'no', label: 'Norsk bokmål', progress: 0 },
    { id: 'pl', label: 'Polski', progress: 100 },
    { id: 'pt', label: 'Português', progress: 100 },
    { id: 'br', label: 'Português brasileiro', progress: 100 },
    { id: 'ro', label: 'Română', progress: 0 },
    { id: 'ru', label: 'Русский', progress: 100 },
    { id: 'sr', label: 'Српски', progress: 0 },
    { id: 'es', label: 'Español', progress: 100 },
    { id: 'sv', label: 'Svenska', progress: 100 },
    { id: 'tr', label: 'Türkçe', progress: 0 },
    { id: 'uk', label: 'Українська', progress: 100 },
    { id: 'vi', label: 'Tiếng Việt', progress: 0 },
];
export const LANG_IDS = LANG.map((lang) => lang.id);

LANG.sort((a, b) => b.progress - a.progress);
