import React from 'react';

import Spinner from 'components/Spinner';

function getColor(value) {
    switch (true) {
        case value >= 75:
            return 'green';
        case value >= 40:
            return 'orange';
        default:
            return 'red';
    }
}

export const ProgressSpinner = ({ progress = 0, size }) => {
    const color = getColor(progress);
    return <Spinner color={color} size={size} value={progress / 100} />;
};
