import { runInAction } from 'mobx';

import api from 'api';

import { CURRENT_USER_ID } from 'utils/consts';
import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function commentQuestion({ message, question }) {
    logEvent('Add comment');
    runInAction(() => {
        question.messages.push({
            id: -1,
            author_id: CURRENT_USER_ID,
            message,
            created: new Date(),
        });
    });
    try {
        await api.post(
            `/requests/${question.id}/messages`,
            toUrl({ board_id: question.board_id, issue_id: question.issue_id, message })
        );
    } catch (error) {
        failRequest(error);
    }
}
