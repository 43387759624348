import { styled } from 'stitches.config';

export const StDesc = styled('div', {
    '*': {
        margin: '0',
        fontSize: 13,
        whiteSpace: 'normal',
        fontWeight: 'initial',
    },
});

export const StScroll = styled('div', {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 4,
    maxHeight: '60svh',
});

export const StSimilarIdeaCard = styled('a', {
    padding: 6,
    lineHeight: 1.33,
    height: 'auto',
    border: '1px solid var(--colors-grayA3)',
    color: '$tc1 !important',
    background: '$kanbanCardBg !important',

    variants: {
        mobile: {
            true: {
                maxWidth: '90vw',
            },
            false: {
                maxWidth: 400,
            },
        },
    },
});

export const StScrollHeader = styled('div', {
    position: 'sticky',
    padding: '2px 0 4px 6px ',
    top: 0,
    zIndex: 2,
    background: '$bg2',
    borderBottom: '1px solid $grayA3',
});
