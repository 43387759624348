import React from 'react';

import { observer } from 'mobx-react-lite';

import AiButton from 'modules/AiButton/AiButton';

import { AnnounceAIRewriteText } from './AnnounceAIRewriteText';

function AnnounceAIButton({ announce }) {
    const onGenerate = async (abortController) => {
        await announce.generateAIDescription(abortController.current);
        await announce.getNameByAI(abortController.current, true);
    };

    const onConfirm = () => {
        announce.update({
            description: announce.aiDescription,
            name: announce.aiName,
        });
    };

    return (
        <AiButton
            tooltipProps={{
                side: 'top',
                content: 'Ducalis AI rewrites the Public Idea Announcement',
            }}
            onGenerate={onGenerate}
            clearAIText={announce.clearAIText}
            buttonProps={{
                text: announce.isEmpty ? 'Write Announcement' : 'Rewrite Announcement',
                color: announce.isEmpty ? 'primary' : undefined,
            }}
            onConfirm={onConfirm}
        >
            {(props) => <AnnounceAIRewriteText announce={announce} small {...props} />}
        </AiButton>
    );
}

export default observer(AnnounceAIButton);
