import React, { useMemo } from 'react';

import { useHotkeys } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { useIntl } from 'react-intl';

import { VotesCountButtons } from 'pages/Board/Voting/components/VotesCount/VotesCountButtons';
import VotesCountTooltip from 'pages/Board/Voting/components/VotesCount/VotesCountTooltip';

import { utilsStore } from 'store/models/UtilsStore';

import DarkTooltip from 'components/DarkTooltip';

export const VotesCount = observer(({ issue, small }) => {
    const intl = useIntl();
    const canVote = issue.canVote;
    const canDownVote = issue.userLimit > 0;

    const hotkeys = useMemo(() => {
        const upvoteMsg = intl.formatMessage({
            id: 'shortcuts.upvote',
            defaultMessage: 'Upvote',
        });

        const unvoteMsg = intl.formatMessage({
            id: 'shortcuts.unvote',
            defaultMessage: 'Unvote',
        });

        return [
            {
                combo: 'q',
                global: true,
                disabled: !canVote && !utilsStore.showAuth,
                label: upvoteMsg,
                group: 'Issue',
                allowInInput: false,
                preventDefault: true,
                onKeyDown: () => {
                    issue.voting(true);
                },
            },
            {
                combo: 'w',
                global: true,
                disabled: !canDownVote && !utilsStore.showAuth,
                label: unvoteMsg,
                group: 'Issue',
                allowInInput: false,
                preventDefault: true,
                onKeyDown: () => {
                    issue.voting(false);
                },
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issue, canVote, canDownVote]);

    useHotkeys(hotkeys);

    return (
        <DarkTooltip
            fast
            content={
                <VotesCountTooltip
                    multiple={
                        issue.board.voting_settings?.multiple_upvotes &&
                        issue.board.voting_settings?.card_display_upvotes
                    }
                    votesLeft={issue.userMaxCountVotes - issue.userLimit}
                />
            }
            position="top"
            tabIndex={-1}
        >
            <VotesCountButtons small={small} canVote={canVote} canDownVote={canDownVote} issue={issue} />
        </DarkTooltip>
    );
});
