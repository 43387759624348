import { Extension } from '@tiptap/react';

export const SaveHotKeyExtension = Extension.create({
    addKeyboardShortcuts() {
        return {
            'Mod-Enter': () => {
                document.activeElement.blur();
                this.options?.action();
                return true;
            },
        };
    },
});
