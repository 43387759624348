import React from 'react';

import { observer } from 'mobx-react-lite';

import FIELD_TYPE from 'constants/FieldType';

import { Idea } from 'store/models/Idea';
import { Issue } from 'store/models/Issue';

import { FILTER_TYPES, PROVIDER_DUCALIS } from 'utils/consts';

import CellWrapper from 'components/CellWrapper';
import { prepareValueForCellView } from 'components/MasterTable/components/prepareValueForCellView';

import { AlignCriterionCell } from '../AlignCriterionCell/AlignCriterionCell';
import { COLUMNS } from '../Columns';
import HiddenContent from '../HiddenContent/HiddenContent';

function CellDataContent({ column, issue, rowIndex, disabled, hideTop }) {
    let formatColumn;
    const isDucalisField = column.ducalis_field && issue.provider === PROVIDER_DUCALIS;

    if (!column.format && isDucalisField) {
        const ducalisColumn = COLUMNS.find((customColumn) => customColumn.id === column.ducalis_field);
        formatColumn = ducalisColumn?.format;
    } else {
        formatColumn = column.format;
    }

    if (typeof formatColumn === 'function') {
        return formatColumn(issue, rowIndex, { disabled, hideTop });
    }

    let value;

    const columnId = column.field || column.id;
    if (issue instanceof Issue || issue instanceof Idea) {
        if ([FIELD_TYPE.alignment, FIELD_TYPE.criterion].includes(column.fieldType) && issue?.board?.hideScores) {
            return (
                <CellWrapper>
                    <HiddenContent revealAt={issue.board.revealDateString} />
                </CellWrapper>
            );
        }

        if (column.fieldType === FIELD_TYPE.alignment) {
            return <AlignCriterionCell criterionId={column.criterion_id} issue={issue} />;
        }

        const field =
            column.votingField ||
            column.criterion_id ||
            (issue.provider === PROVIDER_DUCALIS && column.ducalis_field) ||
            columnId;
        value = issue.getValueByField(field, column.type, column.fieldType);
    } else {
        value = issue?.[columnId];
    }

    if (value === undefined || value === null) {
        return <CellWrapper />;
    }

    const columnValue = prepareValueForCellView(value, column);
    return (
        <CellWrapper isNumber={column.type === FILTER_TYPES.INT}>
            <span title={column.type === FILTER_TYPES.CHECKBOX ? undefined : columnValue} className="t-crop">
                {columnValue}
            </span>
        </CellWrapper>
    );
}

export default observer(CellDataContent);
