import { CustomIconName } from 'components/CustomIcon';

export const CRITERION_FIELDS_NAME = {
    name: 'Name',
    description: 'Description',
    coefficient: 'Weight',
    estimation: 'Scores',
    type: 'Type',
    users: 'Estimators',
    facilitators: 'Facilitators',
    scale_min: 'Scale range min',
    scale_max: 'Scale range max',
    scale_type: 'Scale type',
    scale_series: 'Scale',
    votes_lifetime: 'Score expiration',
};

export const CRITERION_FIELDS_ICONS = {
    name: CustomIconName.CRITERIA,
    description: CustomIconName.TEXT_LONG,
    coefficient: CustomIconName.WEIGHT,
    estimation: CustomIconName.SERIES,
    type: CustomIconName.SORT,
    users: CustomIconName.USERS,
    facilitators: CustomIconName.FACILITATOR,
    scale_min: CustomIconName.SERIES,
    scale_max: CustomIconName.SERIES,
    scale_type: CustomIconName.SERIES,
    scale_series: CustomIconName.SERIES,
    votes_lifetime: CustomIconName.EXPIRATION,
};

export const FIELDS_NAME = {
    alignmentRed: 'Alignment Scale <b>Red Zone</b> for <b>Criteria Report</b>',
    alignmentYellow: 'Alignment Scale <b>Yellow Zone</b> for <b>Criteria Report</b>',
    userAlignmentRed: 'Alignment Scale <b>Red Zone</b> for <b>User Report</b>',
    userAlignmentYellow: 'Alignment Scale <b>Yellow Zone</b> for <b>User Report</b>',
    summaryAlignmentRed: 'Alignment Scale <b>Red Zone</b> for <b>General Report</b>',
    summaryAlignmentYellow: 'Alignment Scale <b>Yellow Zone</b> for <b>General Report</b>',
    name: '<b>Board Name</b>',
    emoji: '<b>Board Emoji</b>',
    public_alias: '<b>Public Board url folder</b>',
    top_priority: '<b># of Top Priority Issues</b>',
    formula: '<b>Board Formula</b>',
    formulaRound: '<b>Criteria Scores formula</b>',
    votesLifetime: '<b>Score Expiration Time</b>',
    slack_data: '<b>Slack</b>',
    offset: '<b>Days before next sprint</b>',
    time: '<b>Weekly Reminders Time</b>',
    rewardingMessage: '<b>Rewarding message</b>',
    devCycleDay: '<b>Planning day</b>',
    devCycleWeeks: '<b>Next meeting on</b>',
    ignore_formula: '<b>Impact</b>',
    facilitators: '<b>Facilitators</b>',
    customVoteMode: '<b>Final score change mode</b>',
    sendTeamReminderEmail: '<b>Send Reward message Email</b>',
    voting_slack_data: '<b>Voting Slack Notifications</b>',
    considerBlockers: '<b>Sorting based on Blockers"</b>',
};
