import React, { useCallback, useEffect, useState } from 'react';

import { ALLOW_RELATIVE_URLS, ENTER, ESC } from 'utils/consts';
import getKeyCode from 'utils/getKeyCode';
import { isAbsoluteUrl } from 'utils/validators';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import * as InputField from 'components/InputField/InputField';
import Popover from 'components/Popover';
import ToolBarButton from 'components/TextEditor/ToolBar/ToolBarButton';
import Tooltip from 'components/Tooltip';

export default function ToolBarInputLink({ editor, parentPref }) {
    const [open, setOpen] = useState(false);
    const [link, setLink] = useState();
    const [isNotValid, setIsNotValid] = useState(false);

    useEffect(() => {
        if (open) {
            editor.isActive('link') && setLink(editor.getAttributes('link').href.trim());
        } else if (editor.isFocused) {
            setOpen(false);
        }
    }, [open, editor]);

    const close = useCallback(() => {
        setOpen(false);
        setIsNotValid(false);
        setLink('');
        editor.commands.focus();
    }, [editor]);

    const onKeyDownInput = (e) => {
        const keyKode = getKeyCode(e);

        if ([ENTER, ESC].includes(keyKode)) {
            e.preventDefault();
            if (keyKode === ENTER) {
                if (!isAbsoluteUrl(link) && !ALLOW_RELATIVE_URLS) {
                    setIsNotValid(true);
                    return false;
                }
                editor.chain().focus().extendMarkRange('link').setLink({ href: link }).run();
            }
            close();
        } else if (isNotValid) {
            setIsNotValid(false);
        }
    };

    const linkAction = useCallback(() => {
        if (editor.isActive('link')) {
            editor.chain().focus().extendMarkRange('link').unsetLink().run();
        }
        close();
    }, [close, editor]);

    const handleChangeLink = ({ target }) => {
        setLink((target.value || '').trim());
    };

    return (
        <Popover
            side="top"
            align="end"
            contentProps={{ container: parentPref?.current?.closest?.('.bp5-dialog') || undefined }}
            content={
                <div style={{ width: 300 }}>
                    {isNotValid && (
                        <p className="t-err">Only full URLs of the page are supported. E.g. https://example.com</p>
                    )}
                    <Flex gap={8}>
                        <InputField.Root fill>
                            <InputField.Slot side="left">
                                <CustomIcon icon={CustomIconName.GLOBE} />
                            </InputField.Slot>
                            <InputField.Input
                                placeholder="Enter link"
                                autoFocus
                                value={link}
                                onChange={handleChangeLink}
                                onKeyDown={onKeyDownInput}
                            />
                        </InputField.Root>
                        <Tooltip content="Remove link" side="top">
                            <Button border icon={CustomIconName.TRASH} onClick={linkAction} />
                        </Tooltip>
                    </Flex>
                </div>
            }
        >
            <ToolBarButton
                title="Link"
                onClick={() => setOpen((state) => !state)}
                icon={CustomIconName.LINK}
                active={editor.isActive('link')}
                disabled={editor.isActive('code')}
            />
        </Popover>
    );
}
