import React from 'react';

import { observer } from 'mobx-react-lite';

import IssueCommentChild from 'components/IssueCommentChild';

function IssueCommentChildren({ comment, isIdea }) {
    return comment.childComments.map((message) => (
        <IssueCommentChild parent={comment} key={message.id} comment={message} isIdea={isIdea} />
    ));
}

export default observer(IssueCommentChildren);
