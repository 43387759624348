import React, { useEffect, useState } from 'react';

import { Select } from '@blueprintjs/select';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { defaultPopoverProps, POPOVER_PROPS_DIV, UNSAVED_MODEL_ID } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';

function itemRenderer(item, { handleClick, modifiers }) {
    return (
        <li key={item}>
            <Button
                block
                disabled={modifiers.disabled}
                minimal
                active={modifiers.active}
                onClick={handleClick}
                text={item}
            />
        </li>
    );
}

const NOT_INTERESTED = <FormattedMessage id="votes.not_interested" defaultMessage="Not interested" />;

function VotesSelector({ idea, children }) {
    const votesLabels = idea.board.voting_settings.votesLabels;
    const [items, setItems] = useState([NOT_INTERESTED, ...idea.board.voting_settings.votesLabels]);

    useEffect(() => {
        setItems([NOT_INTERESTED, ...votesLabels]);
    }, [votesLabels]);

    if (idea.isDone || !idea.board.voting_settings?.multiple_upvotes) {
        return null;
    }

    const activeIndex = Math.min(idea.userLimit, idea.board.voting_settings.votesLabels.length);
    const active = items[activeIndex];

    function onItemSelect(e) {
        const value = items.indexOf(e);
        if (value === activeIndex) return;
        idea.voting(value > 0, value);
    }

    return (
        <>
            {children}
            <Flex>
                <Select
                    className="b-minimize"
                    popoverProps={{
                        ...POPOVER_PROPS_DIV,
                        ...defaultPopoverProps,
                        position: 'bottom-left',
                    }}
                    filterable={false}
                    itemDisabled={idea.id === UNSAVED_MODEL_ID ? (el) => el === NOT_INTERESTED : undefined}
                    items={items}
                    itemRenderer={itemRenderer}
                    onItemSelect={onItemSelect}
                    activeItem={active}
                >
                    <Button
                        size={28}
                        border
                        text={active}
                        rightElm={<CustomIcon icon={CustomIconName.CHEVRON_DOWN} size={14} />}
                    />
                </Select>
            </Flex>
        </>
    );
}

export default observer(VotesSelector);
