import React from 'react';

import AddIssueCell from './AddIssueCell';
import Row from './Row';

function getKey(id, boardId, key) {
    if (id) {
        return boardId ? `${boardId}-${id}-${key}` : `${id}-${key}`;
    }
    return key;
}

export function rowRenderer(props, { cnt, ...extraParams }) {
    const newProps = { ...props, ...extraParams };
    if (props.index === cnt) {
        return <AddIssueCell key={props.key} style={props.style} disabled={props.disabled} />;
    }
    const key = getKey(props.rowData?.id, props.rowData?.board?.id, props.key);
    return <Row {...newProps} id={key} key={key} />;
}
