import React from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { styled } from 'stitches.config';

import { PROVIDER_GITHUB, PROVIDER_LINEAR, PROVIDER_TRELLO } from 'utils/consts';

import ColorLabel from 'components/ColorLabel';
import Dot from 'components/Dot';
import Flex from 'components/Flex';

const StPlatformColorLabel = styled('div', {
    padding: '0 4px',
    height: 20,
    maxHeight: 20,
    minHeight: '20px !important',

    '&:after': {
        content: '',
        backgroundColor: 'currentColor',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        borderRadius: 4,
        pointerEvents: 'none',
        zIndex: -1,
        opacity: 0.1,
    },
});

export const DictionaryValueItem = observer(
    ({ tag, children, iconItem, className, onClick, isLabel, isVoting, id, provider, hideColor, ...props }) => {
        const interactive = typeof onClick === 'function';

        const colorClear = isVoting && props.color;

        if (colorClear && isLabel) {
            return (
                <ColorLabel
                    {...props}
                    color={colorClear}
                    interactive={interactive}
                    onClick={onClick ? () => onClick(id) : undefined}
                />
            );
        }

        const classes = classNames('p-r', className, { interactive });

        if (props.color && [PROVIDER_GITHUB, PROVIDER_TRELLO, PROVIDER_LINEAR].includes(provider)) {
            const color = provider === PROVIDER_GITHUB ? `#${props.color}` : props.color;

            return (
                <StPlatformColorLabel gap={4} className={classes} as={Flex} css={{ '&:after': { color } }}>
                    {props.color && <Dot color={props.color} />}
                    {props.name && (
                        <div className="t-crop" title={props.name}>
                            {props.name}
                        </div>
                    )}
                </StPlatformColorLabel>
            );
        }

        return (
            <Flex gap={4} className={classes} grow={!tag}>
                {!hideColor && props.color && <Dot color={props.color} />}
                {iconItem}
                <div className="t-crop" title={props.name}>
                    {props.name}
                </div>{' '}
                {children}
            </Flex>
        );
    },
);
