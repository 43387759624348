import Axios from 'axios';

import { storageApi } from 'api';
import { failRequest } from 'utils';

import { getPages } from 'store/models/utils/getUrlByPage';
import { ORG_DB_STORES } from 'store/updateOrgDB';

import debugLog from 'utils/debugLog';

export async function fetchAnnounces() {
    try {
        const boardsIds =
            this.boardsList.activeBoardsWithAccess
                ?.filter((board) => board.isVoting)
                ?.map((board) => board.id)
                ?.map((id) => id) || [];

        const responses = await Axios.all(boardsIds.map((board) => storageApi.get('announces', { params: { board } })));
        const pagesRequests = boardsIds.reduce((res, boardId, index) => {
            const headers = responses[index]?.headers;
            const pages = getPages({ headers });
            pages.forEach((page) => res.push({ board: boardId, page }));
            return res;
        }, []);

        const list = boardsIds.reduce((res, boardId, index) => {
            const list = responses[index]?.data || [];
            res.push(...list);
            return res;
        }, []);

        let announcesList;
        if (pagesRequests.length) {
            const responsesPages = await Axios.all(
                pagesRequests.map((params) => storageApi.get('announces', { params }))
            );

            announcesList = pagesRequests.reduce(
                (res, url, index) => {
                    const list = responsesPages[index]?.data || [];
                    res.push(...list);
                    return res;
                },
                [...list]
            );
        }

        process.env.REACT_APP_ENV !== 'prod' && debugLog('Before updateAnnounces');

        this.updateAnnounces(announcesList || list);

        process.env.REACT_APP_ENV !== 'prod' && debugLog('After updateAnnounces');

        await this.db.saveList(announcesList || list, ORG_DB_STORES.announces);
    } catch (error) {
        failRequest(error);
    }
}
