import React, { forwardRef } from 'react';

import { Classes } from '@blueprintjs/core';
import classNames from 'classnames';

import { styled } from 'stitches.config';

import { colorsVariant } from 'utils/colorsVariant';
import { INSTANCE_PREFIX } from 'utils/consts';

import { CustomIconName } from 'components/CustomIcon/index';

import { renderSvgPaths } from './renderSvgPaths';

import styles from './CustomIcon.module.sass';

const StIcon = styled('span', {
    variants: {
        color: {
            ...colorsVariant.solid,
            accent: {
                color: 'var(--colors-accent-text,  $yellowA11)',
            },
            primary: {
                color: '$primary11',
            },
        },
    },
});
const CustomIcon = forwardRef(function CustomIcon(
    { id, icon, size = 18, className, tagName = 'span', rotate, style = {}, intent, viewBox = 20, ...props },
    ref,
) {
    if (typeof icon !== 'string') {
        return null;
    }

    const iconName = INSTANCE_PREFIX && icon === CustomIconName.DUCALIS ? CustomIconName.PRIOPLAN : icon;
    const paths = renderSvgPaths(iconName);
    const intClass = intent ? Classes.intentClass(intent) : undefined;
    const classes = classNames(styles.icon, className, `bp5-icon--${iconName}`, intClass, {
        [styles.rotate]: rotate,
    });

    const svgViewBox = viewBox === 20 ? '0 0 20 20' : '0 0 24 24';

    return (
        <StIcon ref={ref} as={tagName} className={classes} icon={iconName} id={id} css={style} {...props}>
            <svg fill="currentColor" data-icon={iconName} width={size} height={size} viewBox={svgViewBox} role="img">
                {paths}
            </svg>
        </StIcon>
    );
});

export default CustomIcon;
