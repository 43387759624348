import React from 'react';

import { Overlay } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

import styles from './CustomImageFullscreen.module.sass';

function CustomImageFullscreen({ children, onClose, imageSrc, imageAlt }) {
    if (!imageSrc) {
        return null;
    }

    return (
        <Overlay isOpen={true} onClose={onClose}>
            <Button
                onClick={onClose}
                title="Close (esc)"
                className={styles.closeBtn}
                icon={CustomIconName.X_CROSS}
                minimal
                border
            />
            <div className={styles.wrapper}>
                <img className={styles.image} src={imageSrc} alt={imageAlt} />
                {children}
            </div>
        </Overlay>
    );
}

export default observer(CustomImageFullscreen);
