import groupBy from 'lodash/groupBy';
import { observer } from 'mobx-react-lite';

import { ChecklistItem } from 'modules/InsideIssue/components/IssueChecklists/ChecklistItem';

import { CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Space from 'components/Space';
import { StripeDotSubtitle } from 'components/Stripe';
import StripeWithSavedState from 'components/StripeWithSavedState/StripeWithSavedState';

const IssueLinkedIssuesGroup = observer(({ group, data }) => {
    return (
        <Flex column align="none">
            <div className="t-size-s t-mutted" style={{ marginBottom: 6 }}>
                {group}:
            </div>
            {data.map((item) => (
                <ChecklistItem key={item.issue.key} item={item.issue} rightElement={item.issue.status} />
            ))}
        </Flex>
    );
});

function IssueLinkedIssues({ issue }) {
    if (!issue.issueLinks?.length) return null;

    const issuesGroup = groupBy(issue.issueLinks, 'type');

    return (
        <StripeWithSavedState
            storeKey="LNISS"
            icon={CustomIconName.LINK}
            title="Linked issues"
            subTitle={<StripeDotSubtitle>{issue.issueLinks.length}</StripeDotSubtitle>}
        >
            <Space height={6} />
            {Object.entries(issuesGroup).map(([group, data]) => (
                <IssueLinkedIssuesGroup key={group} data={data} group={group} />
            ))}
            <Space height={6} />
        </StripeWithSavedState>
    );
}

export default observer(IssueLinkedIssues);
