import Axios from 'axios';

import { rootApi } from 'api';
import { failRequest } from 'utils';

import dictionaryStore from 'store/models/DictionaryStore';
import { Idea } from 'store/models/Idea';
import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';

import { CURRENT_USER_ID, IS_IFRAME } from 'utils/consts';

export async function fetchVoting() {
    try {
        const boardsUrl = IS_IFRAME
            ? `/storage/boards?public_boards=${window.publicBoard?.public_id}`
            : '/storage/boards';

        const requests = [
            '/storage/main',
            boardsUrl,
            IS_IFRAME ? `/storage/dictionaries?publicId=${window.publicBoard?.public_id}` : '/storage/dictionaries',
            '/storage/votes',
        ];

        const [mainResponse, boardResponse, dictResponse, votesResponse] = await Axios.all(
            requests.map((url) => rootApi.get(url)),
        );

        dictionaryStore.fillCollection(dictResponse.data);

        this.currentUser.setUser({
            id: CURRENT_USER_ID,
            ...mainResponse.data.me,
        });
        this.organization.setOrganization(mainResponse.data.organization);
        this.boardsList.set(boardResponse.data.map((board) => ({ ...board, id: board.public_id })));

        if (window.idea) {
            utilsStore.toggleCardHidden(false);
            issuesList.setActiveIssue(new Idea({ ...window.idea, boardId: window.publicBoard.public_id }));
        }

        mainStore.setVotingVotes(votesResponse.data);

        this.setReady();
    } catch (error) {
        failRequest(error);
    }
}
