import Axios from 'axios';

import { storageApi } from 'api';
import { failRequest } from 'utils';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';
import { getPages } from 'store/models/utils/getUrlByPage';

export async function fetchBoardIdeas(boardId = null) {
    const board = mainStore.activeBoards.find((board) => String(board.id) === String(boardId));

    if (!board?.hasAccess) {
        issuesList.clearIdeasByBoardId(boardId);
        return;
    }

    try {
        const { data: list, headers } = await storageApi.get('/ideas', { params: { board: boardId } });

        const pages = getPages({ headers });

        if (pages.length) {
            const responsesPages = await Axios.all(
                pages.map((page) => storageApi.get('/ideas', { params: { board: boardId, page } })),
            );
            const ideasList = responsesPages.map(({ data }) => data).flat();
            list.push(...ideasList);
        }
        issuesList.fillIdeas(list, [boardId]);
    } catch (error) {
        failRequest(error);
    }
}
