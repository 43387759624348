import React from 'react';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import IssueCommentMeta from 'components/IssueCommentMeta';

function IssueCommentDate({ date }) {
    return (
        <IssueCommentMeta itemProp="datePublished" content={date}>
            {date
                ? DateTime.fromISO(date, { setZone: true }).setLocale(mainStore.activeBoard?.language).toRelative()
                : null}
        </IssueCommentMeta>
    );
}

export default observer(IssueCommentDate);
