import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';

import { utilsStore } from 'store/models/UtilsStore';

import delay from 'utils/delay';

import Tooltip from 'components/Tooltip';

const VoteInputTooltipSizeWrapper = observer(({ children }) => {
    if (utilsStore.cardIsHidden) return children;

    const fullWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const sidebarWidth = utilsStore.isSlim ? 100 : 230;
    const maxWidth = fullWidth - utilsStore.settings.issueWidth - sidebarWidth - 20;

    return <div style={{ maxWidth }}>{children}</div>;
});

function VoteInputTooltipWrapper({ tooltip, focus, children }) {
    const [open, setOpen] = useState(focus);

    useEffect(() => {
        setOpen((state) => {
            if (state && !focus) {
                delay(200).then(() => setOpen(false));
                return state;
            }
            return focus;
        });
    }, [focus]);

    return (
        <Tooltip
            disableHoverableContent={false}
            contentClassName="content-sizing content-sizing--600"
            side="bottom"
            align="start"
            open={open}
            content={<VoteInputTooltipSizeWrapper>{tooltip}</VoteInputTooltipSizeWrapper>}
        >
            {children}
        </Tooltip>
    );
}
VoteInputTooltipWrapper.displayName = 'VoteInputTooltipWrapper';

export default observer(VoteInputTooltipWrapper);
