import React from 'react';

import { observer } from 'mobx-react-lite';

import CreateAnnounceButton from 'modules/IdeaAnnounce/CreateAnnounceButton';

import { POPOVER_PROPS_DIV, UNSAVED_MODEL_ID } from 'utils/consts';

import DarkTooltip from 'components/DarkTooltip';

function ManualCreateAnnounceButton({ idea }) {
    async function createWithoutAI() {
        await idea.createIdeaAnnounce({ name: idea.name });
    }

    return (
        <DarkTooltip
            {...POPOVER_PROPS_DIV}
            position="left"
            popoverClassName="limit limit-300"
            content="Create a public Idea Announcement for Future Release Notes"
        >
            <CreateAnnounceButton
                needLoader
                disabled={idea?.id === UNSAVED_MODEL_ID}
                onClick={createWithoutAI}
                useAI={false}
            />
        </DarkTooltip>
    );
}

export default observer(ManualCreateAnnounceButton);
