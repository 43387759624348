import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';

import { StHr } from 'atoms/StHr';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';

import IdeaMoveActions from './IdeaMoveActions';

export const IdeaMenuContent = observer(({ idea, onClick }) => {
    if (mainStore.currentUser?.isViewer) {
        return (
            <Flex column align="none" css={{ width: 252, padding: 4 }} gap={4}>
                <Button
                    block
                    className="bp5-popover-dismiss"
                    minimal
                    icon={CustomIconName.LINK}
                    onClick={(e) => onClick('copy-public', e)}
                    text="Copy Public Idea link"
                />
                <Button
                    block
                    className="bp5-popover-dismiss"
                    minimal
                    icon={CustomIconName.LINK}
                    onClick={(e) => onClick('copy', e)}
                    text="Copy Internal Idea link"
                />
            </Flex>
        );
    }

    const hasIssue = !!idea.issue_id;
    return (
        <Flex column align="none" css={{ width: 252, padding: 4 }} gap={4}>
            {idea.allow_voting ? (
                <>
                    <Button
                        block
                        className="bp5-popover-dismiss"
                        minimal
                        icon={CustomIconName.LINK}
                        onClick={(e) => onClick('copy-public', e)}
                        text="Copy Public Idea link"
                    />
                    <Button
                        block
                        className="bp5-popover-dismiss"
                        minimal
                        icon={CustomIconName.LINK}
                        onClick={(e) => onClick('copy', e)}
                        text="Copy Internal Idea link"
                    />
                    <StHr />
                    <IdeaMoveActions idea={idea} />
                    <StHr />
                    <Button
                        block
                        className="bp5-popover-dismiss"
                        minimal
                        icon={CustomIconName.MERGE}
                        onClick={() => utilsStore.setOpenMergeIdeas(idea)}
                        text="Merge ideas"
                    />
                    <Button
                        block
                        className="bp5-popover-dismiss"
                        minimal
                        icon={CustomIconName.EYE_SLASH}
                        onClick={(e) => onClick('unpublish', e)}
                        text="Unpublish Idea"
                    />
                </>
            ) : (
                <>
                    <Button
                        block
                        className="bp5-popover-dismiss"
                        minimal
                        icon={CustomIconName.LINK}
                        onClick={(e) => onClick('copy', e)}
                        text="Copy Internal Idea link"
                    />
                    <StHr />
                    <IdeaMoveActions idea={idea} />
                    <StHr />
                </>
            )}
            {hasIssue ? (
                <Button
                    block
                    className="bp5-popover-dismiss"
                    minimal
                    icon={CustomIconName.UNLINK}
                    onClick={(e) => onClick('unlink', e)}
                    text="Unlink from backlog"
                />
            ) : null}
            <Button
                block
                className="bp5-popover-dismiss"
                minimal
                icon={CustomIconName.BACKUP}
                onClick={() => utilsStore.setOpenIdeaHistory(idea)}
                text="Backup history"
            />
            {idea.allow_voting || hasIssue ? <StHr /> : null}
            <Button
                block
                className="bp5-popover-dismiss"
                minimal
                icon={CustomIconName.TRASH}
                onClick={(e) => onClick('remove', e)}
                text="Remove Idea permanently"
            />
        </Flex>
    );
});
