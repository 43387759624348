import React from 'react';

import { Popover } from '@blueprintjs/core';

import { defaultPopoverProps, POPOVER_PROPS_DIV } from 'utils/consts';
import openChat from 'utils/openChat';

import { AnchorButton, Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import HotkeysHelper from 'components/Hotkeys';
import QuickStartLink from 'components/QuickStartLink';

const HelpMenuContent = () => {
    return (
        <Flex column align="none" css={{ padding: 4 }}>
            <HotkeysHelper />
            <AnchorButton
                minimal
                block
                target="_blank"
                href="https://help.ducalis.io/en/ "
                text="Help Center"
                icon={CustomIconName.BOOK}
            />
            <QuickStartLink />
            <AnchorButton
                minimal
                block
                target="_blank"
                href="https://help.ducalis.io/en/articles/4231408-data-protection"
                text="Data Protection"
                icon={CustomIconName.SHIELD}
            />
            {window.$crisp && (
                <Button block minimal onClick={openChat} icon={CustomIconName.COMMENT} text="Chat to support" />
            )}
        </Flex>
    );
};

export default function HelpMenu({ position = 'right-bottom', text }) {
    return (
        <Popover
            {...POPOVER_PROPS_DIV}
            {...defaultPopoverProps}
            interactionKind="hover"
            content={<HelpMenuContent />}
            position={position}
        >
            <Button block minimal size={34} icon={CustomIconName.HELP} text={text} />
        </Popover>
    );
}
