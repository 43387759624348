import React from 'react';

import { observer } from 'mobx-react-lite';

import alertStore from 'store/models/Alert';
import { Idea } from 'store/models/Idea';

import { CURRENT_USER_ID, POPOVER_PROPS_DIV } from 'utils/consts';

import { Button } from 'components/Button';
import DarkTooltip from 'components/DarkTooltip';

function ResolveBtn({ comment }) {
    if (comment.issue instanceof Idea) {
        return null;
    }

    const usersIds = comment.users.filter(({ resolved }) => !resolved).map(({ user_id }) => user_id);
    if (!usersIds.includes(CURRENT_USER_ID) && comment.assignee_id !== CURRENT_USER_ID) {
        return null;
    }

    const isAssignee = comment.assignee_id === CURRENT_USER_ID;

    function onResolve() {
        if (usersIds.length === 1 || comment.assignee_id === CURRENT_USER_ID) {
            alertStore.confirm({
                message: `Do you really want to close this question? It'll be resolved for everybody in this discussion.\nThe issue will be moved to Evaluation section, if it hasn’t been evaluated yet.`,
                onConfirm: () => comment.issue.resolveRequest({ id: comment.id, remove_votes: false }),
                confirmButtonText: 'Yes',
            });
        } else {
            comment.issue.resolveRequest({ id: comment.id, remove_votes: false });
        }
    }

    return (
        <DarkTooltip {...POPOVER_PROPS_DIV} content={isAssignee ? 'Question clarified for all' : 'Question clarified'}>
            <Button block minimal className="bp5-popover-dismiss" onClick={onResolve} text="Resolve" />
        </DarkTooltip>
    );
}

export default observer(ResolveBtn);
