import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { issuesList } from 'store/models/IssuesList';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';

function IdeaCardEditButton() {
    if (!issuesList.idea) return null;

    // TODO: https://concertwithme.atlassian.net/browse/DCLS-9041
    // if (mainStore.currentUser?.isAdmin) {
    //     return (
    //         <Tooltip content="Open idea inside admin view">
    //             <AnchorButton
    //                 size={28}
    //                 text="Admin view"
    //                 rightIcon={CustomIconName.EXTERNAL_LINK}
    //                 target="_blank"
    //                 href={`${mainStore.activeBoard?.internalGo}?path=/public-ideas/issue-${issuesList.idea.id}`}
    //             />
    //         </Tooltip>
    //     );
    // }

    if (!issuesList.idea.isEditable) return null;

    return (
        <DarkTooltip position="top-right" content={<FormattedMessage id="btn.edit" defaultMessage="Edit" />} fast>
            <Button border onClick={issuesList.idea.toggleEditMode} size={28} icon={CustomIconName.EDIT} />
        </DarkTooltip>
    );
}

export default observer(IdeaCardEditButton);
