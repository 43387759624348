import React, { useEffect, useRef, useState } from 'react';

import { Button, Collapse } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { Idea } from 'store/models/Idea';
import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';
import { votingComment } from 'store/models/VotingComment';

import { CURRENT_USER_ID, IS_VOTING_BOARD } from 'utils/consts';
import delay from 'utils/delay';

import IssueCommentAssignee from 'components/IssueCommentAssignee';
import IssueCommentAuthor from 'components/IssueCommentAuthor';
import IssueCommentBody from 'components/IssueCommentBody';
import IssueCommentChildren from 'components/IssueCommentChildren';
import IssueCommentDate from 'components/IssueCommentDate';
import IssueCommentJoinCount from 'components/IssueCommentJoinCount';
import IssueCommentMenu from 'components/IssueCommentMenu';
import IssueCommentReply from 'components/IssueCommentReply';
import IssueCommentText from 'components/IssueCommentText';
import JoinBtn from 'components/JoinBtn';

import styles from './IssueComment.module.sass';

/**
 *
 * @param {Comment} comment
 * @returns {JSX.Element}
 */
function IssueComment({ comment }) {
    const [open, setOpen] = useState(false);
    const replyRef = useRef(null);
    const endRef = useRef(null);

    useEffect(() => {
        open && delay(200).then(() => replyRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' }));
    }, [open, replyRef]);

    useEffect(() => {
        if (comment.issue?.scrollCommentId === comment.id) {
            endRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            comment.issue.clearScrollId();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endRef, comment.id, comment.issue?.scrollCommentId]);

    const canReply = comment.open;

    function onReply() {
        if (IS_VOTING_BOARD) {
            if (!CURRENT_USER_ID) {
                utilsStore.setHistoryAction({ comment: { id: comment.issue?.id, comment_id: comment.id } });
                return;
            }
            votingComment.replyTo(comment);
        } else if (comment.issue instanceof Idea) {
            votingComment.replyTo(comment);
        } else {
            setOpen((state) => !state);
        }
    }

    let showMenu;
    if (IS_VOTING_BOARD) {
        showMenu = comment.user?.id === mainStore.currentUser?.id;
    } else {
        showMenu =
            !mainStore.currentUser?.isViewer ||
            comment.author_id === mainStore.currentUser?.id ||
            comment.user?.id === mainStore.currentUser?.voting_user_id;
    }

    const isIdea = comment?.issue instanceof Idea;

    return (
        <>
            <div>
                <div className={styles.comment} itemProp="comment" itemType="https://schema.org/Comment" itemScope>
                    <div className="flex flex-a-center">
                        <IssueCommentAuthor
                            isIdea={isIdea}
                            author={comment.author}
                            users={comment.authors}
                            usersList={comment.users}
                        />
                        <IssueCommentAssignee comment={comment}>
                            <div className="w-20 t-c t-size-s">→</div>
                        </IssueCommentAssignee>
                        <div className="flex-grow" />
                        {showMenu && <IssueCommentMenu classNameBtn={styles.menuBtn} comment={comment} />}
                    </div>

                    <IssueCommentBody>
                        <IssueCommentText
                            isIdea={isIdea}
                            issue={comment.issue}
                            comment={comment}
                            canEdit={comment.canEdit && comment.open}
                        />

                        <div className="flex flex-a-center">
                            <IssueCommentDate date={comment.created} />
                            <IssueCommentJoinCount count={comment.joinCount} />
                            {canReply && (
                                <Button
                                    text={<FormattedMessage id="comment.reply" defaultMessage="Reply" />}
                                    small
                                    minimal
                                    onClick={onReply}
                                />
                            )}
                            {comment.open && !IS_VOTING_BOARD && !mainStore.currentUser?.isViewer && (
                                <>
                                    <div className="flex-grow" />
                                    <JoinBtn className={styles.menuBtn} comment={comment} />
                                </>
                            )}
                        </div>
                    </IssueCommentBody>
                </div>

                <IssueCommentBody>
                    <IssueCommentChildren comment={comment} isIdea={isIdea} />
                </IssueCommentBody>

                {canReply && (
                    <Collapse isOpen={open}>
                        <IssueCommentReply open={open} onClose={() => setOpen(false)} comment={comment} />
                        <div ref={replyRef} />
                    </Collapse>
                )}
            </div>
            <div ref={endRef} />
        </>
    );
}

export default observer(IssueComment);
