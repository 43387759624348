import { issuesList } from 'store/models/IssuesList';

import { UP, DOWN } from 'utils/consts';
import getKeyCode from 'utils/getKeyCode';

function setFocusToFirstCell(event) {
    if (
        event.target.getAttribute('contenteditable') === 'true' ||
        event.target.tagName === 'TEXTAREA' ||
        /bp5-input/.test(event.target.className)
    ) {
        return;
    }
    const keyCode = getKeyCode(event);
    if ([UP, DOWN].indexOf(keyCode) !== -1) {
        const { row, col } = issuesList;
        const el = document.querySelector(`input[data-row="${row || 0}"][data-col="${col || 5}"]`);
        el && el.focus();
    }
}

export function globalNavigation(set = true) {
    if (set) {
        document.addEventListener('keydown', setFocusToFirstCell, true);
    } else {
        document.removeEventListener('keydown', setFocusToFirstCell, true);
    }
}
