import React, { useCallback } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { GhostNotification } from 'components/AppToaster';
import { Button } from 'components/Button';
import Flex from 'components/Flex';

export default function useUndoNotification() {
    let navigate = useNavigate();

    return useCallback(
        (message, undoAction = undefined, linkAction = undefined, onDismiss) => {
            const className = classNames('ghost-action', {
                'b-minimize': !undoAction,
            });

            function linkHandleAction(e) {
                const link = e.target.getAttribute('href');
                if (link && /^\//.test(link) && !e.metaKey) {
                    e.preventDefault();
                    navigate(link);
                    GhostNotification.clear();
                    linkAction && linkAction(link);
                    return false;
                }
            }

            function undoHandleClick() {
                undoAction && undoAction();
                GhostNotification.clear();
            }

            const content =
                typeof message === 'string' ? (
                    <div
                        className="flex-grow"
                        onClick={linkHandleAction}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                ) : (
                    <div onClick={linkHandleAction}>{message}</div>
                );

            GhostNotification.show({
                message: undoAction ? (
                    <Flex gap={8}>
                        {content}
                        <Button className="flex-shrink" size={24} text="Undo" onClick={undoHandleClick} />
                    </Flex>
                ) : (
                    content
                ),
                className,
                timeout: 4000,
                onDismiss: onDismiss,
            });
        },
        [navigate],
    );
}
