import React, { useEffect, useRef } from 'react';

import { Popover } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { POPOVER_AI_PROPS, POPOVER_PROPS_DIV } from 'utils/consts';

const RIGHT_CSS = { position: 'absolute', top: 0, right: 0 };
const LEFT_CSS = { position: 'absolute', top: 0, left: 8 };

const PopoverAIText = ({ onClose, open, content, side = 'right', ...props }) => {
    const boundary = useRef(null);
    const ref = useRef(null);

    useEffect(() => {
        boundary.current = document.querySelector('div[data-radix-scroll-area-viewport] > div');
    }, []);

    return (
        <div
            ref={ref}
            style={side === 'right' ? RIGHT_CSS : LEFT_CSS}
            className={mainStore.currentUser?.showMegaVideoBanner ? 'ai-portal-text' : undefined}
        >
            <Popover
                {...POPOVER_PROPS_DIV}
                {...POPOVER_AI_PROPS}
                {...props}
                boundary={boundary.current}
                onClose={onClose}
                isOpen={open}
                content={content}
                position={side === 'right' ? 'bottom-right' : 'bottom-left'}
            >
                <span />
            </Popover>
        </div>
    );
};
PopoverAIText.displayName = 'PopoverAIText';

export default observer(PopoverAIText);
