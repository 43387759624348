import React from 'react';

import { observer } from 'mobx-react-lite';

import DoneVotesCount from 'pages/Board/Voting/components/VotesCount/DoneVotesCount';
import { VotesCount } from 'pages/Board/Voting/components/VotesCount/VotesCount';

import { issuesList } from 'store/models/IssuesList';

function IdeaCardVotes() {
    if (!issuesList.idea) return null;
    if (issuesList.idea.isDone) return <DoneVotesCount issue={issuesList.idea} />;

    return <VotesCount issue={issuesList.idea} />;
}

export default observer(IdeaCardVotes);
