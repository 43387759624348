import React, { useContext, useMemo } from 'react';

import { useHotkeys } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { logEvent } from 'utils';

import { itemRenderer } from 'modules/InsideIssue/components/BlocksOmnibar/itemRenderer';
import { IssueOmnibarContext } from 'modules/IssueCard/IssueOmnibarContext';

import { Idea } from 'store/models/Idea';
import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Omnibar from 'components/Omnibar';
import PlatformIcon from 'components/PlatformIcon';
import Space from 'components/Space';
import VirtuosoList from 'components/VirtuosoList';

function activeItemIndex(list, active) {
    return list.findIndex(({ id }) => id === active?.id);
}

function BlocksOmniBar() {
    const issue = issuesList.activeIssue instanceof Idea ? issuesList.activeIssue.parentIssue : issuesList.activeIssue;

    const { openBlocks, setOpenBLocks } = useContext(IssueOmnibarContext);
    const hasIssue = !!issue && !issuesList.questionIssue;

    const hotkeys = useMemo(() => {
        if (hasIssue) {
            return [
                {
                    combo: 'X',
                    global: true,
                    label: 'Mark as blocking',
                    group: 'Issue',
                    onKeyUp: () => {
                        setOpenBLocks(2);
                    },
                },
                {
                    combo: 'B',
                    global: true,
                    label: 'Mark as blocked by',
                    group: 'Issue',
                    onKeyUp: () => {
                        setOpenBLocks(1);
                    },
                },
                {
                    combo: 'L',
                    global: true,
                    label: 'Relates to',
                    group: 'Issue',
                    onKeyUp: () => {
                        setOpenBLocks(3);
                    },
                },
            ];
        }
        return [];
    }, [hasIssue, setOpenBLocks]);

    useHotkeys(hotkeys);

    if (!openBlocks) {
        return null;
    }

    function onItemSelect(blockIssue) {
        if (openBlocks === 1) {
            issue.linking(blockIssue);
        } else if (openBlocks === 2) {
            blockIssue.linking(issue);
        } else {
            issue.linking(blockIssue, true);
        }

        const action = openBlocks === 1 ? 'blockedby' : openBlocks === 2 ? 'blocking' : 'relates';
        logEvent(`Confirm - ${action}`);

        close();
    }

    function close() {
        setOpenBLocks(0);
    }

    const blocksIds = mainStore.blocksIssues.get(issue.id)?.map((el) => el.id) ?? [];
    const blockedByIds = mainStore.blockedByIssues.get(issue.id)?.map((el) => el.id) ?? [];
    const linked = mainStore.linked.get(issue.id)?.map((el) => el.id) ?? [];
    const linkedBy = mainStore.linkedBy.get(issue.id)?.map((el) => el.id) ?? [];

    const items = issuesList.uniqIssues.filter(
        (el) =>
            !el.isDone &&
            el.id !== issue.id &&
            !blocksIds.includes(el.id) &&
            !blockedByIds.includes(el.id) &&
            !linked.includes(el.id) &&
            !linkedBy.includes(el.id),
    );

    const action = {
        1: 'Mark as blocked by',
        2: 'Mark as blocking',
        3: 'Relates to',
    }[openBlocks];

    const actionIcon = {
        1: CustomIconName.BLOCKED_BY,
        2: CustomIconName.BLOCKS,
        3: CustomIconName.REFERENCED,
    }[openBlocks];

    return (
        <Omnibar
            onClose={close}
            popoverProps={{ minimal: true, popoverClassName: 'select-600' }}
            filterable
            itemPredicate={(query, item) => item.hasText(query)}
            items={items}
            onItemSelect={onItemSelect}
            itemListRenderer={(props) => (
                <VirtuosoList
                    noResults={<Button minimal block disabled={true} text="No results." />}
                    width="100%"
                    className="hide-v-scroll"
                    {...props}
                    sizeItem={42}
                    activeItemIndex={activeItemIndex}
                />
            )}
            inputProps={{
                placeholder:
                    openBlocks &&
                    {
                        1: 'Search for issue to make blocked by…',
                        2: 'Search for issue to make as blocking…',
                        3: 'Search for issue to relates to…',
                    }[openBlocks],
                autoFocus: true,
                rightElement: null,
            }}
            itemRenderer={itemRenderer}
            isOpen={openBlocks > 0}
        >
            <Flex gap={4} className="t-mutted" style={{ paddingBottom: 3 }}>
                <PlatformIcon provider={issue.provider} />
                <div className="t-crop">{issue.name}</div>
                <Space width={2} />
                <CustomIcon icon={actionIcon} />
                <div className="flex-shrink">{action}</div>
                <div className="flex-grow"></div>
                <Button minimal border icon={CustomIconName.CROSS_M} onClick={close} />
            </Flex>
        </Omnibar>
    );
}

export default observer(BlocksOmniBar);
