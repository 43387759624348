import React from 'react';

import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';

import MetaItem from 'components/MetaItem';

function LinearCycle() {
    const issue = issuesList.issue;

    if (!issue.customFields?.cycle) return null;

    return <MetaItem title="Cycle">{issue.customFields?.cycle}</MetaItem>;
}

export default observer(LinearCycle);
