import React from 'react';

import { Button } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import alertStore from 'store/models/Alert';
import { Idea } from 'store/models/Idea';
import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { StLineThrough } from 'atoms/StLineThrough';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Space from 'components/Space';
import Tooltip from 'components/Tooltip';

import { StLightBoldText } from './StLightBoldText';
import { StLightText } from './StLightText';

const RewriteValueTooltip = observer(({ value, vote, criterion }) => {
    const user = vote && mainStore.users.usersIds.get(vote.user_id);

    return (
        <>
            <Flex tagName={StLightBoldText} gap={4}>
                <span>{value}</span>
                <span>-></span>
                <span>{vote.value}</span>
            </Flex>
            <div>
                <StLightText>{user?.name}</StLightText> edited <StLightText>{criterion.name}</StLightText> final score
                on <StLightText>{DateTime.fromISO(vote.updated).toFormat('DD')}</StLightText>
            </div>
        </>
    );
});

export const RewriteValue = observer(({ disabled, criterion, value, vote }) => {
    const valueElm = (
        <Flex gap={6}>
            <div className="flex-grow" />
            <CustomIcon size={16} className="o-4" icon={CustomIconName.RESET} />
            <StLineThrough>{value}</StLineThrough>
        </Flex>
    );

    if (!issuesList.activeIssue.board.userCanChangeCriteriaFinalScore(mainStore.currentUser.id, criterion.id)) {
        return (
            <Tooltip
                contentClassName="limit limit-280"
                side="right"
                content={<RewriteValueTooltip value={value} criterion={criterion} vote={vote} />}
            >
                {valueElm}
            </Tooltip>
        );
    }

    if (disabled) return valueElm;

    function handleClick() {
        alertStore.confirm({
            message: 'Are you sure you want to undo edit the Final score?',
            onConfirm: rollBack,
            confirmButtonText: 'Undo',
        });
    }

    async function rollBack() {
        if (issuesList.activeIssue instanceof Idea) {
            await issuesList.activeIssue.parentIssue.vote({ criterion: criterion, vote: null, custom: true });
        } else {
            await issuesList.activeIssue.vote({ criterion: criterion, vote: null, custom: true });
        }
    }

    return (
        <Tooltip
            disableHoverableContent={false}
            contentClassName="limit limit-280"
            side="right"
            content={
                <div>
                    <Space height={8} />
                    <RewriteValueTooltip value={value} criterion={criterion} vote={vote} />
                    <Space height={8} />
                    <Button
                        small
                        onClick={handleClick}
                        icon={<CustomIcon icon={CustomIconName.RESET} className="o-6" />}
                        text="Undo Manual Edit"
                    />
                </div>
            }
        >
            {valueElm}
        </Tooltip>
    );
});
