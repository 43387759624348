import { SERVICE_NAMESPACE, APP_STORAGE_KEYS } from 'utils/consts';

let hasLocalStorage = 'localStorage' in window;
let ls, testKey;

if (hasLocalStorage) {
    testKey = 'react-localstorage.mixin.test-key';
    try {
        ls = window.localStorage;
        ls.setItem(testKey, 'foo');
        ls.removeItem(testKey);
    } catch (e) {
        hasLocalStorage = false;
        console.warn('localStorage not found. Component state will not be stored to localStorage.');
    }
}

const LsHelper = {
    exist(store_key) {
        return this.getStore(store_key) !== null;
    },

    set(key, value) {
        if (!hasLocalStorage) return;
        ls.setItem(key, JSON.stringify(value));
    },

    get(key, defValue = null) {
        if (!hasLocalStorage) return;
        const store = ls.getItem(key);
        return (store && JSON.parse(store)) ?? defValue;
    },

    remove(key) {
        if (!hasLocalStorage) return;
        ls.removeItem(key);
    },
};

const migrateLocalStorage = (appStorage) => {
    if (!hasLocalStorage) return;
    appStorage.remove('settings.path.');
    if (appStorage.get('migrate')) return;
    for (let key in localStorage) {
        switch (true) {
            case /^ducalis_sort_\d+/.test(key):
            case /^ducalis_view_sort_\d+/.test(key):
                appStorage.set(
                    `${APP_STORAGE_KEYS.board_sort}${key.replace(/ducalis_sort_|ducalis_view_sort_/, '')}`,
                    LsHelper.get(key),
                );
                LsHelper.remove(key);
                break;
            case /^ducalis_sort_r_/.test(key):
            case /^ducalis_view_sort_r_/.test(key):
                appStorage.set(
                    `${APP_STORAGE_KEYS.report_sort}${key.replace(/ducalis_sort_|ducalis_view_sort_/, '')}`,
                    LsHelper.get(key),
                );
                LsHelper.remove(key);
                break;
            case 'col-sm-with-v2' === key:
                appStorage.set(APP_STORAGE_KEYS.columns_width, LsHelper.get(key));
                LsHelper.remove(key);
                break;
            case /^ducalis_knb_/.test(key):
                appStorage.set(
                    `${APP_STORAGE_KEYS.kanban_column}.${key.replace(/ducalis_knb_/, '')}`,
                    LsHelper.get(key),
                );
                LsHelper.remove(key);
                break;
            case '_app_timezones' === key:
                appStorage.set(APP_STORAGE_KEYS.timezones, LsHelper.get(key).timezones);
                LsHelper.remove(key);
                break;
            case /^_dcls_vmb_/.test(key):
                appStorage.set(`${APP_STORAGE_KEYS.video_banner}${key.replace(/_dcls_vmb_/, '')}`, LsHelper.get(key));
                LsHelper.remove(key);
                break;
            case '_dcls-u-colors' === key:
                appStorage.set(APP_STORAGE_KEYS.user_colors, LsHelper.get(key));
                LsHelper.remove(key);
                break;
            case ['_dcls-SBTSK-open', '_dcls-QOP-open', '_dcls-QCL-open', '_dcls-LNISS-open'].includes(key):
                const type = key.match(/_dcls-(SBTSK|LNISS|QCL|QOP)-open/i);
                appStorage.set(`${APP_STORAGE_KEYS.stripe_open}${type[1]}`, LsHelper.get(key));
                LsHelper.remove(key);
                break;
            case '_iss_kw_store_' === key:
                appStorage.set(APP_STORAGE_KEYS.issue_card_width, LsHelper.get(key));
                LsHelper.remove(key);
                break;
            case /^_dashboard-folder-/.test(key):
                const folder = key.match(/_dashboard-folder-(\d+)-(\d+)/i);
                appStorage.set(`${APP_STORAGE_KEYS.dashboard_folder}${folder[1]}.${folder[2]}`, LsHelper.get(key));
                LsHelper.remove(key);
                break;
            case /^ducalis_bkp_/.test(key):
                appStorage.set(`${APP_STORAGE_KEYS.board_form}${key.replace(/ducalis_bkp_/, '')}`, LsHelper.get(key));
                LsHelper.remove(key);
                break;
            case /^_b_tf_/.test(key):
                appStorage.set(`${APP_STORAGE_KEYS.board_form}${key.replace(/_b_tf_/, '')}`, LsHelper.get(key));
                LsHelper.remove(key);
                break;
            case /^_banner_m_/.test(key):
                const data = key.match(/_banner_m_(\d+)(_(\d+))?/i);
                if (data[3]) {
                    appStorage.set(`${APP_STORAGE_KEYS.aside_banner}${data[1]}.${data[3]}`, LsHelper.get(key));
                } else {
                    appStorage.set(`${APP_STORAGE_KEYS.aside_banner}${data[1]}`, LsHelper.get(key));
                }
                LsHelper.remove(key);
                break;
            case '_st_' === key:
                appStorage.set(APP_STORAGE_KEYS.settings_path, LsHelper.get(key));
                LsHelper.remove(key);
                break;
            case '_vb_action_' === key:
                appStorage.set(APP_STORAGE_KEYS.voting_action, LsHelper.get(key));
                LsHelper.remove(key);
                break;
            case '_brd_stg_sync_' === key:
                appStorage.set(APP_STORAGE_KEYS.board_sync, LsHelper.get(key));
                LsHelper.remove(key);
                break;
            case '_board_int_' === key:
                appStorage.set(APP_STORAGE_KEYS.board_init, LsHelper.get(key));
                LsHelper.remove(key);
                break;
            case 'cw-cnfg-v1' === key:
                appStorage.set(APP_STORAGE_KEYS.config, LsHelper.get(key));
                LsHelper.remove(key);
                break;
            default:
                break;
        }
    }

    appStorage.set('migrate', 1);
};

class AppStorage {
    appName = '';
    hasStorage = hasLocalStorage;
    /**
     * @constructor
     * @param {String} appName      Name of the application
     */
    constructor(appName) {
        this.appName = appName;
    }

    /**
     * Returns the final key used for storing the
     * value in the `localStorage`.
     *
     * @param  {String} key     Storage key
     * @return {String} The generated key name for the app
     */
    getKey(key) {
        return `${this.appName}.${key}`;
    }

    /**
     * Sets a given key and value in the `localStorage`.
     * Uses lz-string compression before storage.
     *
     * @param {String} key  Storage key
     * @param {any} value   Value to store
     */
    set(key, value) {
        if (!this.hasStorage) return;

        const itemKey = this.getKey(key);
        try {
            const val = JSON.stringify(value);
            if (typeof val !== 'undefined') {
                localStorage.setItem(itemKey, val);
            }
        } catch (ex) {
            console.warn(`something went wrong while trying to store value for ${key}.`, ex); // eslint-disable-line no-console
        }
    }

    /**
     * Retrieves the value from the `localStorage`.
     *
     * @param {string} key  Storage key
     * @param {any} [defValue]  Default value if storage doesn't exist
     * @return {any}      Stored Value
     */
    get(key, defValue = null) {
        if (!this.hasStorage) return defValue;
        const itemKey = this.getKey(key);
        const item = localStorage.getItem(itemKey);

        if (item === null) return defValue;

        let val;
        try {
            val = JSON.parse(item);
        } catch (ex) {
            val = defValue;
        }

        return val;
    }

    /**
     * Removes the given key(s) from the storage.
     *
     * @param  {String|Array<String>} keys  Key(s) to remove from storage
     */
    remove(keys) {
        if (!this.hasStorage) return;
        if (!Array.isArray(keys)) keys = [keys]; // eslint-disable-line no-param-reassign
        keys.forEach((k) => localStorage.removeItem(this.getKey(k)));
    }
}

const appStorage = new AppStorage(SERVICE_NAMESPACE);
// TODO: remove after 20 July 2024
migrateLocalStorage(appStorage);

export default appStorage;
