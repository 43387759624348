import React from 'react';

import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';

import MetaItem from 'components/MetaItem';
import UserBlock from 'components/UserBlock';

function Reporter() {
    const issue = issuesList.issue;

    return (
        <MetaItem title="Reporter">
            <UserBlock issue={issue} field="reporter" editable={issue.isEditable} user={issue.reporter} />
        </MetaItem>
    );
}

export default observer(Reporter);
