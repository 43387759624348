import React from 'react';

import ImageTextViewer from 'components/ImageTextViewer';

import { ImgThumb } from './ImgThumb';

export const SimpleImg = ({ src, alt }) => {
    return (
        <ImageTextViewer>
            <ImgThumb file={src} alt={alt} index={0} thumbnail={src} onClick={() => false} />
        </ImageTextViewer>
    );
};
