import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { makeAutoObservable } from 'mobx';

import api from 'api';
import { failRequest } from 'utils';

import { CURRENT_USER_ID, USER_ROLE, USER_STATUS, ROLES_GROUPS } from 'utils/consts';
import { formatDateTimeToDate } from 'utils/formatDateTimeToDate';

import { mainStore } from './MainStore';
import { User } from './User';

function orderUsers(users) {
    return orderBy(
        users,
        [
            (user) => (user.id === CURRENT_USER_ID ? 1 : user.is_owner ? 2 : 3),
            (user) => (user.status === USER_STATUS.active ? 1 : user.status === USER_STATUS.pending ? 2 : 3),
            'name',
        ],
        ['asc', 'asc', 'asc'],
    );
}

export default class Users {
    usersIds = new Map();
    activities = new Map();
    guest = new User({ id: 0 });

    constructor() {
        makeAutoObservable(this);
    }

    set = (users) => {
        if (this.usersIds.size === 0) {
            users.map((el) => this.update(el, true));
        }

        // clear
        const allIds = users.map((user) => user.id);
        const oldIds = Array.from(this.usersIds.keys());
        oldIds.forEach((result, id) => {
            if (!allIds.includes(id)) {
                this.usersIds.delete(id);
            }
        });

        // fill/update
        users.map((el) => this.update(el, true));
    };

    get users() {
        return Array.from(this.usersIds.values());
    }

    get currentUser() {
        return CURRENT_USER_ID ? this.usersIds.get(CURRENT_USER_ID) : this.guest;
    }

    get activeUsers() {
        return this.users.filter((user) => user.status === USER_STATUS.active);
    }

    get activeOrderedUsers() {
        return orderUsers(this.activeUsers);
    }

    get activeUsersWithoutViewers() {
        return this.activeOrderedUsers.filter((user) => user.role !== USER_ROLE.Viewer);
    }

    get activeUsersWithoutMe() {
        return this.activeUsers.filter((el) => el.id !== CURRENT_USER_ID);
    }

    getGroupsUsersByRole(list) {
        const groups = groupBy(list, (user) => ROLES_GROUPS[user.role]);

        if (groups.Admins === undefined) groups.Admins = [];
        if (groups.Members === undefined) groups.Members = [];
        if (groups.Viewers === undefined) groups.Viewers = [];

        groups.Pending = list.filter((user) => user.status === USER_STATUS.pending);

        return groups;
    }

    get orderedUsers() {
        const users = this.users.filter((user) => [USER_STATUS.active, USER_STATUS.pending].includes(user.status));
        return orderUsers(users);
    }

    get removedUsers() {
        const users = this.users.filter((user) => ![USER_STATUS.active, USER_STATUS.pending].includes(user.status));
        return orderUsers(users);
    }

    get owner() {
        return this.users.find((el) => el.is_owner);
    }

    get adminsStringList() {
        return this.activeUsers
            .filter((user) => user.role === USER_ROLE.Admin)
            .map((user) => user.name)
            .join(', ');
    }

    get adminsEmailsList() {
        return this.activeUsers
            .filter((user) => user.role === USER_ROLE.Admin)
            .map((user) => user.email)
            .join(', ');
    }

    getActiveUsersWithoutIds(usersIds = [], ignoreViewer = false) {
        const usersList = ignoreViewer ? this.users.filter((user) => !user.isViewer) : this.users;
        const filteredList = usersList.filter(
            (user) => !usersIds.includes(user.id) && [USER_STATUS.active, USER_STATUS.pending].includes(user.status),
        );

        return orderUsers(filteredList);
    }

    getActiveUsersByIds(ids) {
        return ids.reduce((res, userId) => {
            const user = mainStore.users.usersIds.get(userId);
            if (user && user.status !== USER_STATUS.removed) {
                res.push(user);
            }
            return res;
        }, []);
    }

    update(data, isNew) {
        const user = this.usersIds.get(data.id);
        if (user) {
            if (user.id === mainStore.currentUser?.id && data.status === USER_STATUS.removed) {
                mainStore.db.dropDB();
                return (window.location.href = '/login/logout');
            }
            user.fillModel(data);
        } else if (isNew) {
            this.usersIds.set(data.id, new User(data));
        }
    }

    removeSingle(userId) {
        if (this?.currentUser?.id === userId) {
            mainStore.db.dropDB();
            return (window.location.href = '/login/logout');
        }
        this.usersIds.delete(userId);
    }

    updateActivities(data) {
        this.activities.clear();
        if (!Array.isArray(data) && data) {
            Object.entries(data).forEach(([userId, history]) => {
                this.activities.set(Number(userId), history);
            });
        }
    }

    getActivities = async (date, aggr = 'day') => {
        try {
            const requestParams = { aggr };
            if (date) {
                requestParams.from = formatDateTimeToDate(date[0]);
                requestParams.to = formatDateTimeToDate(date[1]);
            }
            const { data } = await api.get('/users/activity-logs', { params: requestParams });
            this.updateActivities(data);
        } catch (e) {
            failRequest(e);
        }
    };

    setCurrentUser = (data) => {
        if (this.currentUser) {
            this.currentUser.fillModel(data);
        } else {
            this.usersIds.set(data.id, new User(data));
        }
    };
}
