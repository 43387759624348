import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import CloseBtn from 'modules/InsideIssue/components/CloseBtn';
import IssueHeader from 'modules/InsideIssue/components/IssueHeader';
import IdeaCardComments from 'modules/PublicVotingIssue/IdeaCardComments';

import { issuesList } from 'store/models/IssuesList';
import { utilsStore } from 'store/models/UtilsStore';

import Flex from 'components/Flex';
import IdeaCommentForm from 'components/IdeaCommentForm';
import IssueName from 'components/IssueName';
import Space from 'components/Space';

import CopyIdeaLinkButton from './CopyIdeaLinkButton';
import IdeaCardEditButton from './IdeaCardEditButton';
import * as IdeaCardMetaTags from './IdeaCardMetaTags';
import IdeaCardStatus from './IdeaCardStatus';
import IdeaCardVotes from './IdeaCardVotes';
import PublicVotingIssueReadOnlyBody from './PublicVotingIssueReadOnlyBody';
import VotesSelector from './VotesSelector';

import styles from './PublicVotingIssue.module.sass';

function ReadOnlyView() {
    const ideaId = issuesList.idea?.id;

    useEffect(() => {
        ideaId && issuesList.idea.readIdea();
        ideaId && issuesList.idea.getAnnounce();
    }, [ideaId]);

    if (!ideaId) return null;

    return (
        <>
            <IdeaCardMetaTags.MetaUrl />
            <IdeaCardMetaTags.MetaVotes />
            <IdeaCardMetaTags.MetaImage />
            <IssueHeader sticky>
                <Flex gap={8}>
                    <IdeaCardVotes />
                    <div className="flex-grow" />
                    <IdeaCardStatus />
                    <div className="flex-grow" />
                    <IdeaCardEditButton />
                    <CopyIdeaLinkButton />
                    <CloseBtn onClick={() => utilsStore.toggleCardHidden()} />
                </Flex>
                <VotesSelector idea={issuesList.idea}>
                    <Space height={12} />
                </VotesSelector>
                <Space height={13} />
                <IssueName issue={issuesList.idea} />
            </IssueHeader>

            <Space height={3} />

            <PublicVotingIssueReadOnlyBody />

            <IdeaCardComments />

            <div className="flex-grow" />

            <Space height={20} />

            <div className={styles.stickyFooter}>
                {!utilsStore.cardIsHidden && <IdeaCommentForm issue={issuesList.idea} />}
            </div>
        </>
    );
}

export default observer(ReadOnlyView);
