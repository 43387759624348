import React from 'react';

import DotDivider from 'components/DotDivider';
import Kbd, { KeyCombo } from 'components/Kbd';

export const NextCellTooltipAction = () => (
    <>
        Next cell
        <DotDivider />
        <KeyCombo>
            <Kbd>tab</Kbd>
            <span>or</span>
            <Kbd>enter</Kbd>
        </KeyCombo>
    </>
);
