import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useHotkeys } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import CustomImageFullscreen from 'components/CustomImageFullscreen';

function ImagePreview({ images, isOpen, selectedIndex = 0, onClose }) {
    const [imageSrc, setImageSrc] = useState(null);
    const [index, setIndex] = useState(Number(selectedIndex) || 0);
    const maxIndex = images.length - 1;

    const nextImage = useCallback(() => {
        setIndex((state) => (state + 1 > maxIndex ? 0 : state + 1));
    }, [maxIndex]);

    const prevImage = useCallback(() => {
        setIndex((state) => (state - 1 < 0 ? maxIndex : state - 1));
    }, [maxIndex]);

    const hotkeys = useMemo(() => {
        if (isOpen) {
            return [
                {
                    combo: `right`,
                    global: true,
                    label: 'Next image',
                    group: 'Issue',
                    onKeyDown: nextImage,
                },
                {
                    combo: `left`,
                    label: 'Prev image',
                    global: true,
                    group: 'Issue',
                    onKeyDown: prevImage,
                },
                {
                    combo: 'esc',
                    label: 'Close image',
                    global: true,
                    group: 'Issue',
                    onKeyDown: onClose,
                },
            ];
        }
        return [];
    }, [nextImage, prevImage, isOpen, onClose]);

    useHotkeys(hotkeys);

    useEffect(() => {
        const numberIndex = Number(selectedIndex) || 0;
        setIndex((state) => (state !== numberIndex ? numberIndex : state));
    }, [selectedIndex]);

    useEffect(() => {
        setImageSrc((state) => images?.[index]?.source || state);
    }, [index, images]);

    if (isOpen && imageSrc) {
        return <CustomImageFullscreen onClose={onClose} imageSrc={imageSrc} />;
    }

    return null;
}

export default observer(ImagePreview);
