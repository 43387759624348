import { mergeAttributes } from '@tiptap/core';
import Link from '@tiptap/extension-link';

export default Link.extend({
    renderHTML({ HTMLAttributes }) {
        const attr = mergeAttributes(this.options.HTMLAttributes, HTMLAttributes);
        if (this.options.clearDomainRel && attr.href.includes(this.options.mainDomain)) {
            attr.rel = undefined;
        }
        return ['a', attr, 0];
    },
});
