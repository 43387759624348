import { styled } from 'stitches.config';

export const StInput = styled('div', {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    padding: '0 3px',
    borderRadius: 4,
    border: 0,
    backgroundColor: '$blackA3',
    boxShadow: '0 0 0 1px var(--colors-grayA4) inset',
    transition: 'box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out',

    code: {
        fontFeatureSettings: 'normal',
        fontVariationSettings: 'normal',
        fontSize: 11,
        whiteSpace: 'nowrap',
        whiteSpaceCollapse: 'break-spaces',
        padding: 6,
        overflow: 'auto',
        borderRadius: 4,
        width: '100%',

        backgroundColor: '$code-bg',
        color: '$code-text',

        '.code-title': {
            color: 'var(--colors-code-title, #d2a8ff)',
        },
        '.code-regexp, .code-string': {
            color: 'var(--colors-code-string, #a5d6ff)',
        },
        '.code-code, .code-comment, .code-formula': {
            color: 'var(--colors-code-comment, #8b949e)',
        },
        '.code-attr, .code-attribute, .code-literal, .code-number, .code-variable': {
            color: 'var(--colors-code-attr, #79c0ff)',
        },
        '.code-keyword': {
            color: 'var(--colors-code-keyword, #ff7b72)',
        },
        '.code-name': {
            color: 'var(--colors-code-name, #7ee787)',
        },
    },

    '.tiptap[contenteditable="true"]': {
        padding: 6,
        outline: 'none !important',
    },

    'textarea, input': {
        appearance: 'none',
        all: 'unset',
        display: 'block',
        width: 'stretch',
        textIndent: 'initial',
        border: 0,
        background: 'none',
        boxShadow: 'none',
        margin: 0,
        font: 'unset',
        fontWeight: 'normal',
        fontStyle: 'normal',
        cursor: 'text',
        minWidth: 0,

        '&::-webkit-inner-spin-button': {
            position: 'absolute',
            top: 4,
            bottom: 4,
            right: 1,
            margin: 'auto',
            transformOrigin: 'right center',
            cursor: 'pointer',
        },

        '&:focus,&:hover': {
            '&::-webkit-inner-spin-button': {
                opacity: 0.6,
            },
        },

        '&::placeholder': {
            color: '$tc2',
            opacity: 0.5,
            userSelect: 'none',
        },
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    },

    textarea: {
        borderRadius: 4,
        padding: '6px 6px',

        '&:focus': {
            outline: 'none !important',
        },
    },

    input: {
        height: 30,
        lineHeight: '30px',
        padding: '0 6px',
    },

    '&:not([data-invalid="true"]):has(input:enabled:not(:focus):not(:invalid)):hover,&:not([data-invalid="true"]):has(textarea:enabled:not(:focus):not(:invalid)):hover,&:has(.tiptap[contenteditable="true"]:not(:focus)):hover':
        {
            backgroundColor: '$blackA4',
            boxShadow: '0 0 0 1px var(--colors-grayA6) inset',
        },

    '&:has(input:focus:not(:read-only)),&:has(textarea:focus:not(:read-only)),&:has(.tiptap[contenteditable="true"]:focus)':
        {
            backgroundColor: '$blackA4',
            boxShadow: '0 0 0 1px var(--colors-primary8) inset',
        },

    '&:has(input:invalid),&:has(textarea:invalid),&[data-invalid="true"]': {
        boxShadow: '0 0 0 1px var(--colors-red6) inset',
        color: '$red11',

        '&:hover': {
            boxShadow: '0 0 0 1px var(--colors-red8) inset',
        },

        '&:has(input:focus),&:has(textarea:focus)': {
            boxShadow: '0 0 0 1px var(--colors-red8) inset',
        },
    },

    '&:has(input:disabled),&:has(textarea:disabled)': {
        cursor: 'not-allowed',
        opacity: 0.6,

        input: {
            opacity: 0.5,
            pointerEvents: 'none',
        },
    },

    variants: {
        fill: {
            true: {
                flexGrow: 1,
            },
        },
    },
});
