import React from 'react';

import { observer } from 'mobx-react-lite';

import { Labels } from 'modules/InsideIssue/components';
import IssueBlockWrapper from 'modules/InsideIssue/components/IssueBlockWrapper';
import UpdatedDate from 'modules/PublicIdeaStripe/components/UpdatedDate';

import { issuesList } from 'store/models/IssuesList';

import Flex from 'components/Flex';
import IdeaAuthor from 'components/IdeaAuthor';
import ImageTextViewer from 'components/ImageTextViewer';
import Space from 'components/Space';

function PublicVotingIssueReadOnlyBody() {
    return (
        <IssueBlockWrapper>
            <ImageTextViewer>
                <div
                    itemProp="text"
                    className="typography"
                    dangerouslySetInnerHTML={{ __html: issuesList.idea.description }}
                />
            </ImageTextViewer>
            <Space height={16} />

            <Flex spaceBetween gap={8} align="none">
                <Labels
                    issue={issuesList.idea}
                    editable={false}
                    labels={issuesList.idea.labelsList}
                    className="flex-wrap"
                />
                <UpdatedDate className="t-mutted" date={issuesList.idea.last_update} />
            </Flex>

            <Space height={16} />

            <IdeaAuthor idea={issuesList.idea} />
        </IssueBlockWrapper>
    );
}

export default observer(PublicVotingIssueReadOnlyBody);
