import React from 'react';

import { FormGroup, InputGroup } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

export const VoteDisableInput = observer(({ criterion, id, issue }) => (
    <FormGroup label={criterion.name} key={criterion.id} inline disabled>
        <InputGroup disabled large autoFocus={id === 0} fill value={issue?.userVotes?.get(criterion.id) ?? ''} />
    </FormGroup>
));
