import React from 'react';

import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import PublishButton from 'pages/Board/Voting/MobileVoting/FullIdea/PublishButton';

import IssueBlockWrapper from 'modules/InsideIssue/components/IssueBlockWrapper';
import IssueHeader from 'modules/InsideIssue/components/IssueHeader';
import CloseEditModeButton from 'modules/PublicVotingIssue/CloseEditmodeButton/CloseEditModeButton';
import PublicVotingIssueBody from 'modules/PublicVotingIssue/PublicVotingIssueEditBody';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Space from 'components/Space';

const VisibilityIdeaStatus = observer(({ children }) => {
    return (
        <Flex gap={4} className="t-mutted" align="top">
            <CustomIcon icon={CustomIconName.EYE} />
            <span className="t-size-s">{children}</span>
        </Flex>
    );
});

const VisibilityDescription = observer(() => {
    if (mainStore.activeBoard.voting_settings?.is_private) {
        return (
            <FormattedMessage
                defaultMessage="Will be visible to a limited number of users set by the organization"
                id="desc.idea-private"
            />
        );
    }

    return <FormattedMessage defaultMessage="Will be visible to anybody on the web" id="desc.idea-public" />;
});

function EditView() {
    return (
        <>
            <IssueHeader>
                <Space height={16} />
                <Flex spaceBetween gap={10}>
                    <FormattedMessage id="voting_issue.new_idea_title" defaultMessage="Suggest your idea" />
                    <CloseEditModeButton />
                </Flex>
            </IssueHeader>

            <div className="flex-grow b-oa hide-v-scroll">
                <Space height={5} />
                <PublicVotingIssueBody issue={issuesList.idea} />
                <Space height={2} />
            </div>

            <IssueBlockWrapper>
                <div className="issue-details-meta">
                    <Space height={16} />

                    <Flex gap={10} column align="none">
                        <VisibilityIdeaStatus>
                            <VisibilityDescription />
                        </VisibilityIdeaStatus>
                        <PublishButton idea={issuesList.idea} />
                    </Flex>

                    <Space height={16} />
                </div>
            </IssueBlockWrapper>
        </>
    );
}

export default observer(EditView);
