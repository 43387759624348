import React from 'react';

import classNames from 'classnames';

import styles from './IssueHeader.module.sass';

export default function IssueHeader({ children, sticky }) {
    const classes = classNames(styles.header, {
        [styles.sticky]: sticky,
    });

    return <div className={classes}>{children}</div>;
}
