import React from 'react';

import styles from './IssueCommentBody.module.sass';

function IssueCommentBody({ children }) {
    if (!children) {
        return null;
    }

    return <div className={styles.body}>{children}</div>;
}

export default IssueCommentBody;
