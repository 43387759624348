import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import TextAIPreview from 'components/TextAIPreview';

export const AnnounceAIRewriteText = observer(({ announce, onConfirm, onCancel, loading, onRepeat, onStop }) => {
    useEffect(() => {
        return () => {
            announce.clearAIText();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TextAIPreview
            small
            title={announce.aiName}
            onRepeat={onRepeat}
            onStop={onStop}
            text={announce.aiDescription}
            onConfirm={onConfirm}
            onCancel={onCancel}
            loading={loading}
            rewriteButtonText="Rewrite Announcement"
            saveButtonText="Save Announcement"
        />
    );
});
