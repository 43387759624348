import { makeAutoObservable, observable } from 'mobx';

class ColumnsStore {
    columns = observable.map();

    constructor() {
        makeAutoObservable(this, {
            columns: observable.shallow,
        });
    }

    fillColumns(columns) {
        columns.forEach((column) => {
            if (!this.columns.has(String(column.field_id))) {
                this.columns.set(String(column.field_id), { ...column, id: column.field_id });
            } else {
                const oldColumn = this.columns.get(String(column.field_id));
                if (oldColumn.name !== column.name) Object.assign(oldColumn, column);
            }
        });
    }

    getColumnById(columnId) {
        return this.columns.get(String(columnId));
    }
}

const columnsStore = new ColumnsStore();

export default columnsStore;
