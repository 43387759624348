import React from 'react';

import { observer } from 'mobx-react-lite';

import TextAIPreview from 'components/TextAIPreview';

export const TextEl = observer(({ idea, onConfirm, onCancel, loading, onRepeat, onStop, small }) => {
    return (
        <TextAIPreview
            title={idea.aiName}
            small={small}
            onRepeat={onRepeat}
            onStop={onStop}
            text={idea.aiText}
            onConfirm={onConfirm}
            onCancel={onCancel}
            loading={loading}
            saveButtonText="Replace"
        />
    );
});
