import React from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { Idea } from 'store/models/Idea';
import { mainStore } from 'store/models/MainStore';

import { IS_PUBLIC_BOARD } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import { UserQuestionSelector } from 'components/QuestionForm/UserQuestionSelector';

import styles from './IssueCommentAssignee.module.sass';

function IssueCommentAssignee({ comment, children }) {
    if (IS_PUBLIC_BOARD || comment.issue instanceof Idea) {
        return null;
    }
    const classes = classNames('t-w-sb t-size-s', {
        interactive: comment.canEdit,
        [styles.interactive]: comment.canEdit,
        'o-4': !comment.assignee,
    });

    if (mainStore.currentUser?.isViewer) {
        return (
            <>
                {children}
                <div className={classes}>{comment.assignee?.name || 'Unassigned'}</div>
            </>
        );
    }

    function setUser(user) {
        comment.issue.updateRequest({ request: comment, assignee_id: user.id });
    }

    return (
        <>
            {children}
            <UserQuestionSelector
                onChange={setUser}
                users={mainStore.users.activeUsersWithoutMe}
                user={comment.assignee}
                canBeNull
            >
                <Button
                    text={comment.assignee?.name || 'Unassigned'}
                    size={24}
                    border
                    rightElm={<CustomIcon icon={CustomIconName.CHEVRON_DOWN} size={14} />}
                />
            </UserQuestionSelector>
        </>
    );
}

export default observer(IssueCommentAssignee);
