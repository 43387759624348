import React from 'react';

import { FormattedMessage } from 'react-intl';

export const COMBOS = {
    moveAcrossIssues: {
        label: <FormattedMessage id="shortcuts.move_across_issues" defaultMessage="Move across issues" />,
        combo: 'up+down',
        group: 'Board',
    },
    moveAcrossIssuesFM: {
        label: <FormattedMessage id="shortcuts.move_across_issues" defaultMessage="Move across issues" />,
        combo: 'up+down',
        group: 'Focus Mode',
    },
    copyIssueLink: {
        label: <FormattedMessage id="shortcuts.copy_issue_link" defaultMessage="Copy Issue Link" />,
        combo: 'mod+shift+,',
        group: 'Issue',
    },
    nextCriterion: {
        label: 'Next criterion',
        combo: 'tab + enter + right',
        group: 'Table',
    },
    nextCriterionFM: {
        label: 'Next criterion',
        combo: 'tab + enter + right',
        group: 'Focus Mode',
    },
    previousCriterion: {
        label: 'Previous criterion',
        combo: 'shift + tab',
        group: 'Table',
    },
    previousCriterionFM: {
        label: 'Previous criterion',
        combo: 'shift + tab',
        group: 'Focus Mode',
    },
    nextCell: {
        label: 'Next cell',
        combo: 'tab+enter',
        group: 'Table',
    },
    previousCell: {
        label: 'Previous cell',
        combo: 'shift+tab',
        group: 'Table',
    },
    moveAcrossCells: {
        label: 'Move across cells',
        combo: 'left+right+up+down',
        group: 'Table',
    },
    focusMode: {
        label: 'Focus Mode',
        combo: 'alt+f',
        group: 'Table',
    },
    createIdea: {
        label: <FormattedMessage id="shortcuts.create_idea" defaultMessage="Create new Idea" />,
        combo: 'alt+n',
        group: 'Board',
    },
    search: {
        label: (
            <h4 style={{ marginBottom: 0 }} className="bp5-heading ma">
                Search
            </h4>
        ),
        combo: '/',
        group: ' ',
    },
    fastInput1: {
        label: (
            <>
                One digit score <em>(e.g., 2)</em>
            </>
        ),
        combo: '0 ... 9',
        group: 'Fast Input ⚡  Auto jump to next cell',
    },
    fastInput2: {
        label: (
            <>
                Multi digits score <em>(e.g., 95)</em>
            </>
        ),
        combo: '0 ... 9+Enter',
        group: 'Fast Input ⚡  Auto jump to next cell',
    },
};
