import React, { useCallback, useEffect, useState } from 'react';

import ImageWrapper from 'components/ImageWrapper';
import ImageError from 'components/IssueDescription/components/ImageError';

import { AttachLink } from './';

export function ImageEl({ small, thumbnail, file, disableWrapper, disabled, onClick, alt }) {
    const [status, setStatus] = useState(0);

    useEffect(() => {
        status === -1 && disableWrapper && disableWrapper();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    const imgBl = useCallback(() => {
        function onError() {
            setStatus(-1);
        }

        if (status === -1) {
            if (file) {
                return <AttachLink url={file} text={file} />;
            }
            return <ImageError small={small} />;
        }
        return (
            <ImageWrapper onClick={onClick} aria-disabled={disabled}>
                <img alt={alt || ''} src={thumbnail} loading="lazy" onError={onError} onLoad={() => setStatus(1)} />
            </ImageWrapper>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled, small, status, thumbnail, file]);

    return imgBl();
}
