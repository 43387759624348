import React from 'react';

import { observer } from 'mobx-react-lite';

import { CriterionScore } from 'pages/Board/AlignmentList/components/CriterionScore';

function FinalScoreTableSuperScore({ criteria, votes }) {
    return criteria.map((criterion) => <CriterionScore key={criterion.id} criterion={criterion} votes={votes} />);
}

export default observer(FinalScoreTableSuperScore);
