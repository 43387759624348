import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { PAYWALL_LIMITS } from 'utils/consts';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import Flex from 'components/Flex';
import Space from 'components/Space';

import StatsDetailsContent from './StatsDetailsContent';

function StatsDetails({ idea, onlyContent }) {
    if (!mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.VOTING_ANALYTICS)) {
        return null;
    }

    if (onlyContent) {
        return <StatsDetailsContent idea={idea} />;
    }

    return (
        <div>
            <DarkTooltip popoverClassName="limit limit-300" content={<StatsDetailsContent idea={idea} />}>
                <Flex gap={2} className="t-mutted t-size-s">
                    <span>How that stat calculated</span>
                    <CustomIcon size={16} icon={CustomIconName.HELP} />
                </Flex>
            </DarkTooltip>
            <Space height={12} />
        </div>
    );
}

export default observer(StatsDetails);
