import React from 'react';

import ImageTextViewer from 'components/ImageTextViewer';
import TextEditor from 'components/TextEditor';

export default function ReadOnlyDescription({ description, hasMention, className, itemProp }) {
    if (!description) {
        return null;
    }
    return (
        <ImageTextViewer itemProp={itemProp}>
            <TextEditor
                allowYoutube
                className={className}
                hasMention={hasMention}
                description={description || ''}
                readOnly
            />
        </ImageTextViewer>
    );
}
