import React, { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';
import { votingComment } from 'store/models/VotingComment';

import { StTextEditor } from 'atoms/StTextEditor';

import { CURRENT_USER_ID, IS_VOTING_BOARD } from 'utils/consts';
import delay from 'utils/delay';

import { Button } from 'components/Button';
import Flex from 'components/Flex';
import { TOOLBAR_ACTIONS } from 'components/TextEditor/ToolBar/constants';

import styles from './IdeaCommentForm.module.sass';

const IDEA_COMMENT_EDITOR_ACTIONS = [
    [TOOLBAR_ACTIONS.bold, TOOLBAR_ACTIONS.italic],
    [TOOLBAR_ACTIONS.link],
    [TOOLBAR_ACTIONS.clear],
];

function IdeaCommentForm({ issue, title, className }) {
    const [isFocus, setIsFocus] = useState(false);
    const wrapperRef = useRef();

    const intl = useIntl();
    const messages = defineMessages({
        placeholder: {
            id: 'comment.placeholder',
            defaultMessage: 'Add your comment about it...',
        },
    });
    const placeholder = intl.formatMessage(messages.placeholder);

    useEffect(() => {
        return () => {
            utilsStore.toggleFocusComment(false);
            votingComment.clear();
        };
    }, []);

    const focusEditor = useCallback(() => {
        delay(100).then(() => {
            const editor = wrapperRef.current?.querySelector('.ProseMirror')?.editor;
            document.activeElement?.blur();
            editor && editor.commands.focus('end');
        });
    }, []);

    const hasComment = votingComment.comment?.id;

    useEffect(() => {
        hasComment && votingComment.isReply && focusEditor();
    }, [hasComment, focusEditor]);

    function sendMessage(message) {
        if (!CURRENT_USER_ID) {
            utilsStore.setHistoryAction({ comment: { comment_id: votingComment.comment?.id, message, id: issue.id } });
            return;
        }
        if (message) {
            issue.commentQuestion({ message, question: votingComment.comment });
        }
        votingComment.clear();
    }

    const classesForm = classNames(className, styles.form, {
        [styles.focus]: isFocus || votingComment.message.length > 0,
    });

    let subText;
    if (mainStore.currentUser?.isAdmin || !IS_VOTING_BOARD) {
        subText = votingComment.comment?.id ? (
            <FormattedMessage
                id="comment.subtext.admin.parent"
                defaultMessage="Everyone in the thread and mentioned users will be notified."
            />
        ) : (
            <FormattedMessage
                id="comment.subtext.admin.main"
                defaultMessage="All who upvoted and mentioned users will be notified."
            />
        );
    } else {
        subText = votingComment.comment?.id ? (
            <FormattedMessage
                id="comment.subtext.user.parent"
                defaultMessage="Everyone in the thread, admins and mentioned users will be notified."
            />
        ) : (
            <FormattedMessage
                id="comment.subtext.user.main"
                defaultMessage="Only admins and mentioned users will be notified."
            />
        );
    }

    return (
        <div ref={wrapperRef} className={classesForm} data-focus={isFocus}>
            {title}
            <StTextEditor
                clear
                isComment
                actions={IDEA_COMMENT_EDITOR_ACTIONS}
                onFocus={setIsFocus}
                hotKeySave
                hasMention
                hasPublicMention
                placeholder={placeholder}
                onChange={votingComment.setMessage}
                sendMethod={() => sendMessage(votingComment.message)}
                description={votingComment.message}
                autoFocus={!!votingComment.comment?.id || utilsStore.isFocusComment}
            >
                <Flex grow gap={8} spaceBetween className={styles.footer}>
                    <div className="o-4 t-size-s" style={{ lineHeight: 1.1 }}>
                        {subText}
                    </div>
                    <Button
                        className="flex-shrink"
                        color="accent"
                        border
                        onClick={() => sendMessage(votingComment.message)}
                        text={<FormattedMessage id="comment.send" defaultMessage="Send" />}
                        size={24}
                    />
                </Flex>
            </StTextEditor>
        </div>
    );
}

export default observer(IdeaCommentForm);
