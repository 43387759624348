import React from 'react';

import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';

import Tooltip from 'components/Tooltip';

function IdeaCardStatus() {
    if (!issuesList.idea?.statusName) return null;

    return (
        <Tooltip content={issuesList.idea.statusName} side="top">
            <div className="t-mutted t-size-s t-crop">{issuesList.idea.innerStatus.name}</div>
        </Tooltip>
    );
}

export default observer(IdeaCardStatus);
