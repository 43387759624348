import React from 'react';

import { Position, Popover } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { defaultPopoverProps, POPOVER_PROPS_DIV } from 'utils/consts';

import { Button } from 'components/Button';
import { CurrentUser } from 'components/CurrentUser';

import { UserMenuPopoverContent } from './UserMenuPopoverContent';

const UserMenu = observer(({ position = Position.RIGHT_BOTTOM }) => {
    return (
        <Popover
            {...POPOVER_PROPS_DIV}
            {...defaultPopoverProps}
            interactionKind="hover"
            content={<UserMenuPopoverContent />}
            position={position}
        >
            <Button minimal size={34} leftElm={<CurrentUser className="interactive" size={18} />} />
        </Popover>
    );
});

export default UserMenu;
