import React from 'react';

import Flex from 'components/Flex';

import { styled } from '../../stitches.config';
import { CustomIcon, CustomIconName } from '../CustomIcon';
import DarkTooltip from '../DarkTooltip';

const StHelpIcon = styled('div', {
    opacity: 0,
    transition: 'opacity .2s',
});

const StMetaItem = styled(Flex, {
    '&:hover': {
        [StHelpIcon]: {
            opacity: 0.6,
        },
    },
});

export default function MetaItem({ title, children, inline, fillFree, tooltip }) {
    const titleElm = title ? <div className="t-mutted">{title}</div> : null;

    const style = {};
    if (!inline) {
        style.height = 24;
        style.lineHeight = '24px';
    }

    return (
        <StMetaItem column={!inline} gap={8} align="none" css={{ minWidth: fillFree ? 'auto' : '41px !important' }}>
            {tooltip ? (
                <Flex gap={2}>
                    {titleElm}{' '}
                    <DarkTooltip popoverClassName="limit limit-300" position="top" content={tooltip}>
                        <StHelpIcon as={CustomIcon} icon={CustomIconName.HELP_ALT} size={14} />
                    </DarkTooltip>
                </Flex>
            ) : (
                titleElm
            )}
            {inline ? children : <div style={style}>{children}</div>}
        </StMetaItem>
    );
}
