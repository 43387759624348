import React from 'react';

import { InputGroup, Overlay } from '@blueprintjs/core';
import { QueryList } from '@blueprintjs/select';

import { StHr } from 'atoms/StHr';

import Space from 'components/Space';

import styles from './Omnibar.module.sass';

export const Omnibar = ({ children, footer, content, ...props }) => {
    const { isOpen, inputProps, overlayProps, ...restProps } = props;
    const handleOverlayClose = (event) => {
        overlayProps?.onClose?.(event);
        props.onClose?.(event);
    };

    const renderQueryList = (listProps) => {
        const { handleKeyDown, handleKeyUp, handleQueryChange } = listProps;

        if (content) {
            return (
                <Overlay hasBackdrop={true} isOpen={isOpen} className={styles.overlay} onClose={handleOverlayClose}>
                    <div className={styles.omnibar}>
                        {children}
                        {content}
                    </div>
                </Overlay>
            );
        }

        return (
            <Overlay hasBackdrop={true} isOpen={isOpen} className={styles.overlay} onClose={handleOverlayClose}>
                <div className={styles.omnibar} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
                    {children}
                    <InputGroup
                        autoFocus={true}
                        large={true}
                        placeholder="Search..."
                        {...inputProps}
                        onChange={handleQueryChange}
                        value={listProps.query}
                    />
                    {listProps.itemList}
                    {footer && (
                        <footer
                            style={{
                                background: 'inherit',
                                position: 'sticky',
                                bottom: 0,
                                paddingBottom: 12,
                                marginBottom: -12,
                            }}
                        >
                            <StHr />
                            <Space height={12} />
                            {footer}
                        </footer>
                    )}
                </div>
            </Overlay>
        );
    };
    return <QueryList {...restProps} renderer={renderQueryList} />;
};
