import { makeAutoObservable } from 'mobx';

import { mainStore } from 'store/models/MainStore';

import { LANG } from 'components/LangDropdown/constants';

/**
 * Enum for dialog-state status.
 * @readonly
 * @enum {number}
 */
export const DIALOG_STATUS = {
    copyIssue: 1,
    moveIssue: 2,
    copyIdea: 3,
    moveIdea: 4,
};

class ModelCopyMoveStore {
    targetBoard = null;
    status = null;
    type = null;
    issue = true;
    ideas = [];
    votes = true;
    translate = true;
    comments = true;

    model = null;
    dialogType = 0;

    validation = null;

    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Open Dialog and set model
     *
     * @param {number} dialogType - DIALOG_STATUS value
     * @param {Issue|Idea} model
     */
    openDialog({ dialogType, model }) {
        this.dialogType = dialogType;
        this.model = model;
        this.votes = DIALOG_STATUS.moveIdea === dialogType;
        this.comments = DIALOG_STATUS.moveIdea === dialogType;
        this.ideas = (DIALOG_STATUS.moveIssue === dialogType && model.ideas?.map((idea) => idea.id)) || [];
    }

    setValidation = (data) => {
        this.validation = data;
    };

    setStatus = (status) => {
        this.status = status;
    };

    setType = (type) => {
        this.type = type;
    };

    setTargetBoard = (board) => {
        this.targetBoard = board;
    };

    toggleIssue = (status) => {
        this.issue = status;
    };

    toggleIdea = (status, ideId) => {
        if (status) {
            this.ideas.push(ideId);
        } else {
            this.ideas = this.ideas.filter((id) => id !== ideId);
        }
    };

    toggleVotes = (status) => {
        this.votes = status;
    };

    toggleTranslate = (status) => {
        this.translate = status;
    };

    toggleComments = (status) => {
        this.comments = status;
    };

    clear = () => {
        this.targetBoard = null;
        this.status = null;
        this.issue = true;
        this.ideas = [];
        this.votes = true;
        this.comments = true;
        this.translate = true;
        this.model = null;
        this.validation = null;
        this.dialogType = 0;
    };

    getIdeaRequestData = () => {
        return {
            move: this.move,
            targetBoard: this.targetBoard.id,
            status: this.status?.id,
            issue: this.model.parentIssue ? this.issue : null,
            votes: this.votes,
            translate: this.translate,
            comments: this.comments,
        };
    };

    getIssueRequestData = () => {
        return {
            move: this.move,
            targetBoard: this.targetBoard.id,
            status: this.status?.id,
            type: this.type?.id,
            ideasIDs: this.move && this.model.ideas ? this.ideas : null,
        };
    };

    // Getters

    get move() {
        return [DIALOG_STATUS.moveIdea, DIALOG_STATUS.moveIssue].includes(this.dialogType);
    }

    get isIdea() {
        return [DIALOG_STATUS.moveIdea, DIALOG_STATUS.copyIdea].includes(this.dialogType);
    }

    get boardMeta() {
        if (!this.model) return {};
        return this.isIdea ? this.model.board.voting_settings : this.model.board;
    }

    get targetBoardMeta() {
        if (!this.targetBoard?.id) return null;

        if (this.isIdea) {
            return {
                emoji: this.targetBoard.voting_settings.emoji,
                name: this.targetBoard.voting_settings.name,
            };
        }
        return { emoji: this.targetBoard.emoji, name: this.targetBoard.name };
    }

    get boardsList() {
        if (!this.model) return [];
        const boards = this.isIdea
            ? mainStore.boardsList.activeVotingBoardsWithAccess
            : mainStore.boardsList.activeBoardsWithAccess;
        if (this.move) {
            return boards.filter((board) => board.id !== this.model.board.id && board.hasAccess);
        }
        return boards.filter((board) => board.hasAccess);
    }

    get boardLanguage() {
        const langCode = this.targetBoard.voting_settings.language || 'en';
        return LANG.find((lang) => lang.id === langCode);
    }

    get validationLanguage() {
        if (!this.validation?.language) return undefined;
        const languageNames = new Intl.DisplayNames([this.validation.language], {
            type: 'language',
        });
        const languageName = languageNames.of(this.validation.language);
        return languageName.charAt(0).toUpperCase() + languageName.slice(1);
    }
}

export default new ModelCopyMoveStore();
