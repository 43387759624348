import React, { useState } from 'react';

import { Select } from '@blueprintjs/select';

import { POPOVER_PROPS_DIV } from 'utils/consts';
import queryInText from 'utils/queryInText';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import UserListItem from 'components/UserListItem';
import VirtuosoList from 'components/VirtuosoList';

function itemRenderer(item, props, active) {
    return <UserListItem item={item} {...props} active={active} />;
}

export function UserSelectorBase({ user, items, onSelect, children, disabled }) {
    const [activeItem, setActiveItem] = useState(user || undefined);

    const handleSelect = (newUser) => {
        setActiveItem(newUser);
        onSelect(newUser);
    };

    function activeItemIndex(list, active) {
        return list.findIndex(({ id }) => id === active?.id);
    }

    return (
        <Select
            disabled={disabled}
            activeItem={activeItem}
            onActiveItemChange={setActiveItem}
            onItemSelect={handleSelect}
            itemPredicate={(query, { name, email }) => queryInText(`${name || ''} ${email || ''}`, query)}
            itemRenderer={(item, props) => itemRenderer(item, props, item.id === user?.id)}
            items={items}
            resetOnQuery={false}
            popoverProps={{
                ...POPOVER_PROPS_DIV,
                minimal: true,
                position: 'bottom-left',
                shouldReturnFocusOnClose: true,
            }}
            itemListRenderer={(props) => (
                <VirtuosoList
                    noResults={<Button minimal block disabled={true} text="No users to show" />}
                    className="hide-v-scroll"
                    {...props}
                    sizeItem={32}
                    activeItemIndex={activeItemIndex}
                />
            )}
            inputProps={{
                className: 'custom-select-clear',
                leftIcon: <CustomIcon icon={CustomIconName.SEARCH} />,
            }}
        >
            {children}
        </Select>
    );
}
