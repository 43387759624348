import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useHotkeys } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import alertStore from 'store/models/Alert';
import { Idea } from 'store/models/Idea';

import { StTextEditor } from 'atoms/StTextEditor';

import delay from 'utils/delay';
import { generateDefaultMention } from 'utils/generateDefaultMention';

import { Button } from 'components/Button';
import { TOOLBAR_ACTIONS } from 'components/TextEditor/ToolBar/constants';

import styles from './IssueCommentReply.module.sass';

const ISSUE_COMMENT_EDITOR_ACTIONS = [
    [TOOLBAR_ACTIONS.bold, TOOLBAR_ACTIONS.italic],
    [TOOLBAR_ACTIONS.link],
    [TOOLBAR_ACTIONS.clear],
];

function IssueCommentReply({ comment, onClose, open }) {
    const isVotingIssue = comment.issue instanceof Idea;
    const replyWhom = comment.author || comment.authors[0]?.user;
    const defaultValue = useRef(`<p>${generateDefaultMention(replyWhom, isVotingIssue)}&nbsp</p>`).current;
    const wrapperRef = useRef();
    const [uid, setUid] = useState(Date.now());
    const [text, setText] = useState('');

    const focusEditor = useCallback(() => {
        delay(100).then(() => {
            const editor = wrapperRef.current?.querySelector('.ProseMirror')?.editor;
            document.activeElement?.blur();
            editor && editor.commands.focus('end');
        });
    }, []);

    useEffect(() => {
        open && focusEditor();
    }, [open, focusEditor]);

    const hotkeys = useMemo(() => {
        return [
            {
                combo: 'esc',
                global: true,
                disabled: !open,
                label: 'Close reply',
                group: 'Issue',
                allowInInput: true,
                preventDefault: true,
                onKeyDown: () => {
                    if (text) {
                        alertStore.confirm({
                            message: 'Are you sure you want to discard the changes? ',
                            onConfirm: onClose,
                        });
                    } else {
                        onClose();
                    }
                },
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, text]);
    useHotkeys(hotkeys);

    function sendMessage(message) {
        if (message) {
            comment.issue.commentQuestion({ question: comment, message });
            setText('');
            setUid(Date.now());
            onClose?.();
        }
    }

    return (
        <div className={styles.reply} key={uid} ref={wrapperRef}>
            <StTextEditor
                actions={ISSUE_COMMENT_EDITOR_ACTIONS}
                hotKeySave
                hasMention
                placeholder="Add your comment about it..."
                defaultValue={defaultValue}
                onChange={setText}
                sendMethod={() => sendMessage(text)}
                autoFocus
            >
                <Button
                    size={24}
                    onClick={() => sendMessage(text)}
                    color="primary"
                    border
                    text="Send"
                    className={styles.btn}
                />
            </StTextEditor>
        </div>
    );
}

export default observer(IssueCommentReply);
