import { forwardRef } from 'react';

import { StButton } from 'atoms/StButton';

import { CustomIcon } from 'components/CustomIcon';
import Spinner from 'components/Spinner';

/**
 * @typedef ButtonProperty
 * @type {object}
 * @property {string} [icon]
 * @property {boolean} [disabled]
 * @property {string} [rightIcon]
 * @property {React.ReactNode} [leftElm]
 * @property {React.ReactNode} [rightElm]
 * @property {string|React.ReactNode} [text=]
 * @property {string|React.ReactNode} [children]
 * @property {boolean} [active]
 * @property {boolean} [minimal]
 * @property {boolean} [loading]
 * @property {boolean} [spinnerSize]
 * @property {number} [size]
 * @property {string} [color]
 * @property {boolean} [block]
 * @property {string|React.ReactNode} [as]
 * @property {function} [onClick]
 * @property {boolean} [border]
 */

/** @type {ButtonProperty} */

/**
 * @type {ButtonProperty} props
 * @type {RefAttributes} forwardedRef
 *
 * @return {JSX.Element}
 */
function Button(
    {
        active = false,
        icon,
        leftElm,
        rightElm,
        rightIcon,
        text,
        children,
        disabled,
        onClick,
        spinnerSize = 18,
        btnRef,
        textTitle,
        iconSize,
        iconProps,
        ...props
    },
    forwardedRef,
) {
    const content = text ?? children;

    const hasIcon = !!(icon || leftElm);
    const hasRightIcon = !!(rightIcon || rightElm);
    const iconContent = [undefined, null, false].includes(content) && hasIcon + hasRightIcon === 1;

    const disabledBtn = disabled || props.loading;
    const isFakeButton = !props.as && disabledBtn;

    return (
        <StButton
            as={isFakeButton ? 'span' : undefined}
            onClick={disabledBtn ? undefined : onClick}
            disabled={disabledBtn}
            role="button"
            type="button"
            active={active}
            {...props}
            iconContent={iconContent}
            ref={forwardedRef || btnRef}
            aria-pressed={props.active}
        >
            {props.loading && <Spinner size={spinnerSize} />}
            {!props.loading && icon && <CustomIcon size={iconSize} className="icon-btn" icon={icon} {...iconProps} />}
            {!props.loading && leftElm}
            {content && (
                <span title={textTitle} className="t-crop flex-grow">
                    {content}
                </span>
            )}
            {rightElm}
            {rightIcon && <CustomIcon size={iconSize} className="icon-btn" icon={rightIcon} {...iconProps} />}
        </StButton>
    );
}

export default forwardRef(Button);

Button.defaultProps = {
    center: false,
};

Button.displayName = 'Button';
