import React from 'react';

import classNames from 'classnames';

import styles from './ImageWrapper.module.sass';

export default function ImageWrapper(props) {
    const className = classNames(props.className, styles.wrapper);

    return <span {...props} className={className} />;
}
