import React from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import useDisabledStatus from 'hooks/useDisabledStatus';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Kbd from 'components/Kbd';

import styles from './AddIssueCell.module.sass';

function AddIssueCell({ style, disabled }) {
    const isDisabledActivity = useDisabledStatus();

    if (
        !mainStore.activeBoard.isNotFiltered ||
        issuesList.getNext ||
        mainStore.activeBoard.taskLoader ||
        mainStore.currentUser?.isViewer
    ) {
        return null;
    }

    const classes = classNames('ReactVirtualized__Table__row', styles.row);
    const classesBtn = classNames('flex flex-a-center gap-2', styles.btn, {
        'bp5-disabled': isDisabledActivity,
    });

    const blocked = issuesList.activeIssue?.id === -1;

    return (
        <div
            data-place="add-issue-cell"
            className={classes}
            role="button"
            onClick={() => !disabled && issuesList.createIssue()}
            style={style}
            key="add-new-issue"
            aria-disabled={blocked || disabled}
        >
            <Flex gap={20}>
                <div className={classesBtn}>
                    <CustomIcon icon={CustomIconName.PLUS} />
                    <span>New issue</span>
                </div>
                <Flex gap={4} css={{ padding: 4 }} className={styles.hotkeys}>
                    <Kbd>alt</Kbd>
                    <Kbd>n</Kbd>
                </Flex>
            </Flex>
        </div>
    );
}

export default observer(AddIssueCell);
