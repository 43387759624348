import { CustomIcon } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Space from 'components/Space';

export const BlockTitle = ({ icon, title, classNameIcon }) => {
    return (
        <Flex gap={4} className="flex-shrink">
            <CustomIcon icon={icon} className={classNameIcon} />
            <span className="o-6 flex-shrink">{title}</span>
            <Space width={2} />
        </Flex>
    );
};
