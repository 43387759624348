import React from 'react';

import { observer } from 'mobx-react-lite';

import InsideIssueAnnounce from 'modules/IdeaAnnounce';
import { EmptyHeaderMeta } from 'modules/InsideIssue/components/HeaderMeta/EmptyHeaderMeta';
import * as InsideIssueComponent from 'modules/InsideIssue/components/index';
import IssueHeader from 'modules/InsideIssue/components/IssueHeader';
import PublicIdeaStripe from 'modules/PublicIdeaStripe';
import * as IdeaStripeComponents from 'modules/PublicIdeaStripe/components';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { StHr } from 'atoms/StHr';

import { UNSAVED_MODEL_ID } from 'utils/consts';

import DictionaryRemoveAlert from 'components/DictionaryRemoveAlert';
import Space from 'components/Space';

function UnlinkedPublicIdea({ onHide }) {
    return (
        <>
            <IssueHeader sticky>
                <EmptyHeaderMeta dataElm={<IdeaStripeComponents.SingleVotingActions issue={issuesList.idea} />}>
                    <InsideIssueComponent.CloseBtn onClick={onHide} />
                </EmptyHeaderMeta>
                <StHr />
            </IssueHeader>

            <Space height={8} />

            <InsideIssueComponent.IssueBlockWrapper>
                {issuesList.idea?.id !== UNSAVED_MODEL_ID && (
                    <>
                        <InsideIssueAnnounce />
                        <Space height={8} />
                    </>
                )}
                <PublicIdeaStripe isOpen showVotes idea={issuesList.idea} editable={!mainStore.currentUser?.isViewer} />
                <Space height={60} />
                <Space height={10} />
            </InsideIssueComponent.IssueBlockWrapper>

            {!mainStore.currentUser?.isViewer && <DictionaryRemoveAlert board={issuesList.idea.board} />}
        </>
    );
}

export default observer(UnlinkedPublicIdea);
