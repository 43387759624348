import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';

import useUndoNotification from 'hooks/useUndoNotification';

import delay from 'utils/delay';

import { Button } from 'components/Button';
import DarkTooltip from 'components/DarkTooltip';
import Flex from 'components/Flex';
import IdeaPublishNotification from 'components/IdeaPublishNotification';

function DraftFooter({ idea }) {
    const [disabled, setDisabled] = useState(false);
    const notify = useUndoNotification();

    const handleClickPublish = () => {
        setDisabled(true);
        delay(300).then(() => makePublic());
    };

    const makePublic = async () => {
        notify(<IdeaPublishNotification name={idea.name} href={idea.href} status={idea.innerStatus} />);
        await idea.update({ allow_voting: true });
        setDisabled(false);
    };

    function onCancel() {
        idea.remove();
    }

    let publishBtn;
    if (!idea.name) {
        publishBtn = (
            <DarkTooltip position="top" fast content="Please fill out the Idea Name">
                <Button as="div" className="flex-shrink" size={24} disabled border color="primary" text="Publish" />
            </DarkTooltip>
        );
    } else {
        publishBtn = (
            <Button
                disabled={disabled}
                className="flex-shrink"
                size={24}
                border
                onClick={handleClickPublish}
                color="primary"
                text="Publish"
            />
        );
    }

    return (
        <Flex gap={20} css={{ marginTop: 16, marginBottom: 4 }}>
            {publishBtn}
            <div className="flex-grow o-6 t-size-s">
                People at{' '}
                <a className="color-link" target="_blank" href={idea.board?.votingLink}>
                    voting board
                </a>{' '}
                can read, upvote and comment that part of issue.
            </div>
            <Button border className="flex-shrink" size={24} onClick={onCancel} text="Remove" />
        </Flex>
    );
}

export default observer(DraftFooter);
