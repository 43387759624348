import * as React from 'react';

import { styled } from 'stitches.config';

import { StInput } from 'atoms/StInput';

const InputFieldRoot = React.forwardRef(({ children, className, color, ...rootProps }, forwardedRef) => {
    return (
        <StInput
            ref={forwardedRef}
            data-accent-color={color}
            className={className}
            onPointerDown={(event) => {
                const target = event.target;
                if (target?.tagName === 'BUTTON' || target?.closest('input, button, a')) return;

                const input = event.currentTarget.querySelector('input');
                if (!input) return;
                const cursorPosition = 0;

                requestAnimationFrame(() => {
                    // Only some input types support this, browsers will throw an error if not supported
                    // See: https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/setSelectionRange#:~:text=Note%20that%20according,not%20support%20selection%22.
                    try {
                        input.setSelectionRange(cursorPosition, cursorPosition);
                    } catch (e) {}
                    input.focus();
                });
            }}
            {...rootProps}
        >
            {children}
        </StInput>
    );
});
InputFieldRoot.displayName = 'InputField.Root';

const StInputFieldInput = styled('input', {
    minHeight: 30,
});
const InputFieldInput = React.forwardRef((inputProps, forwardedRef) => {
    return (
        <StInputFieldInput data-1p-ignore="" spellCheck="false" {...inputProps} className="reset" ref={forwardedRef} />
    );
});
InputFieldInput.displayName = 'InputField.Input';

const StInputFieldSlot = styled('div', {
    '&[data-side="left"]': {
        marginLeft: 2,
    },
    '&[data-side="right"]': {
        marginRight: 2,
    },
});
const InputFieldSlot = React.forwardRef(({ className, color, side, ...slotProps }, forwardedRef) => {
    return (
        <StInputFieldSlot
            data-accent-color={color}
            data-side={side}
            {...slotProps}
            ref={forwardedRef}
            className={className}
        />
    );
});
InputFieldSlot.displayName = 'InputField.Slot';

export { InputFieldRoot as Root, InputFieldSlot as Slot, InputFieldInput as Input };
