import React from 'react';

import { observer } from 'mobx-react-lite';

import BlocksIssueItem from './BlocksIssueItem';

export const BlocksList = observer(({ list, onRemove, title, removeTooltip }) => {
    if (!list?.length) return null;

    return list.map((item) => (
        <BlocksIssueItem title={title} removeTooltip={removeTooltip} onRemove={onRemove} key={item.id} item={item} />
    ));
});
