import { runInAction } from 'mobx';

import api from 'api';
import { toUrl } from 'utils';

import { mainStore } from 'store/models/MainStore';

import { VIEWS } from 'utils/consts';
import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';

export async function resumeEvaluation(requestParams) {
    logEvent('Resume evaluation');
    if (mainStore.page === VIEWS.SCORES) {
        runInAction(() => {
            this.isRemoved = true;
        });
    }

    try {
        await api.delete(`${this.apiCurrentEndpoint}/vote-skip`, { data: toUrl(requestParams) });
        if (requestParams?.user_id === mainStore.currentUser.id) {
            runInAction(() => {
                this.externalData.unvoted = true;
                this.externalData.isUserNotAllVoted = true;
                this.externalData.skipped = false;
            });
        }
    } catch (e) {
        failRequest(e);
    } finally {
        runInAction(() => {
            this.isRemoved = false;
        });
    }
}
