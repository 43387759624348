import { getNextSelector } from './getNextSelector';

export const onKeyDown = (event, selected, tabIndex, setSelected, singleFastInput) => {
    const isCurrentValue = event.target.value !== '' && singleFastInput && +event.key === +event.target.value;
    const selector = getNextSelector(event, tabIndex, selected, isCurrentValue);

    setSelected(false);

    const el = document.querySelector(selector);
    if (el) {
        event.stopPropagation();
        event.target.blur();
        setTimeout(() => el.focus(), 0);
    } else if (selector) {
        event.target.blur();
    }
};
