import { useEffect } from 'react';

import { mainStore } from 'store/models/MainStore';

const PageTitle = (props) => {
    useEffect(() => {
        mainStore.updatePageTitle(props);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default PageTitle;
