import { mergeAttributes } from '@tiptap/core';
import Mention from '@tiptap/extension-mention';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { CustomMentionContent } from '../CustomMentionContent';

const CustomMention = Mention.extend({
    name: 'customMention',

    renderHTML({ node, HTMLAttributes }) {
        return ['span', mergeAttributes(HTMLAttributes), `@${node.attrs.name}`];
    },
    parseHTML() {
        return [
            {
                tag: `span[data-user-id]`,
            },
        ];
    },
    addNodeView() {
        return ReactNodeViewRenderer(CustomMentionContent);
    },
    addOptions() {
        return {
            ...this.parent?.(),
        };
    },
    addAttributes() {
        return {
            class: {
                default: 'mention',
            },
            id: {
                parseHTML: (element) => Number(element.getAttribute('data-user-id')),
                renderHTML: (attributes) => ({ 'data-user-id': attributes.id }),
            },
            avatar: {
                default: null,
            },
            name: {
                default: null,
            },
        };
    },
    addKeyboardShortcuts() {
        return {
            Backspace: () =>
                this.editor.commands.command(({ tr, state }) => {
                    let isMention = false;
                    const { selection } = state;
                    const { empty, anchor } = selection;

                    if (!empty) {
                        return false;
                    }

                    state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
                        if (node.type.name === this.name) {
                            isMention = true;
                            tr.insertText('', pos, pos + node.nodeSize);

                            return false;
                        }
                    });

                    return isMention;
                }),
        };
    },
});

export default CustomMention;
