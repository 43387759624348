import React from 'react';

export default function IdeaPublishNotification({ name, href, status }) {
    return (
        <>
            Public description of "<b>{name}</b>" is moved to {status.name} status and it is visible for your customers.
            Find it on{' '}
            <a target="_blank" href={`${href}`}>
                Voting Board
            </a>
        </>
    );
}
