import React from 'react';

import { observer } from 'mobx-react-lite';

import dictionaryStore from 'store/models/DictionaryStore';

import { DICTIONARY_LABELS_VOTING, DICTIONARY_STATUSES_VOTING, EMPTY_ARRAY } from 'utils/consts';
import logEvent from 'utils/logEvent';

import DictionaryMultiSelect from './index';

function IssueDictionaryMultiSelect({ issue, dictionary }) {
    if (!issue) return null;

    function onRemove(item) {
        logEvent(`Remove ${dictionary} item`);
        issue.removeLabel(item);
    }

    function onAdd(value) {
        logEvent(`Add ${dictionary} item to issue`);
        issue.setProps({ dictionary, value });
    }

    async function onCreate(name) {
        logEvent(`Create ${dictionary} item`);
        if ([DICTIONARY_STATUSES_VOTING, DICTIONARY_LABELS_VOTING].includes(dictionary)) {
            await issue.board.createDictItem({ issue, dictionary, name });
        } else {
            await dictionaryStore.createDictItem({ issue, dictionary, name });
        }
    }

    return (
        <DictionaryMultiSelect
            dictionary={dictionary}
            activeList={issue.getFieldValueByDictionary(dictionary) || EMPTY_ARRAY}
            list={issue.getDictByName(dictionary)}
            onRemove={onRemove}
            board={issue.board}
            onCreate={onCreate}
            onAdd={onAdd}
            btnProps={{ text: 'Add Labels' }}
        />
    );
}

export default observer(IssueDictionaryMultiSelect);
