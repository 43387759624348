import React from 'react';

import { observer } from 'mobx-react-lite';

import { utilsStore } from 'store/models/UtilsStore';

import { StHr } from 'atoms/StHr';

import { ANNOUNCE_STATUS } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Tooltip from 'components/Tooltip';

import AnnounceAITextApprove from '../AnnounceAITextApprove';
import AnnounceClearButton from '../AnnounceClearButton';

function AnnounceMenuContent({ announce }) {
    return (
        <Flex column align="none" css={{ width: 252, padding: 4 }} gap={4}>
            {announce.is_active && announce.text_status === ANNOUNCE_STATUS.APPROVED ? (
                <Button
                    className="bp5-popover-dismiss"
                    block
                    minimal
                    text="Copy Announcement link"
                    icon={CustomIconName.LINK}
                    onClick={announce.copyLink}
                />
            ) : (
                <Tooltip content="Announcement did not public yet" side="left">
                    <Button
                        disabled
                        block
                        minimal
                        text="Copy Announcement link"
                        icon={CustomIconName.LINK}
                        onClick={announce.copyLink}
                    />
                </Tooltip>
            )}
            <AnnounceAITextApprove
                announce={announce}
                block
                border={false}
                minimal
                size={undefined}
                className="bp5-popover-dismiss"
            />
            {announce.historyObject ? (
                <Button
                    className="bp5-popover-dismiss"
                    block
                    minimal
                    text="Change history"
                    icon={CustomIconName.BACKUP}
                    onClick={() => utilsStore.setOpenAnnounceHistory(announce)}
                />
            ) : null}
            <StHr />
            <AnnounceClearButton
                announce={announce}
                block
                minimal
                text="Clear the announcement"
                className="bp5-popover-dismiss"
            />
        </Flex>
    );
}

export default observer(AnnounceMenuContent);
