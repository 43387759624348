import React from 'react';

import { observer } from 'mobx-react-lite';

import { ANNOUNCE_STATUS } from 'utils/consts';

import Flex from 'components/Flex';

function IdeaAnnounceContentDescription({ announce }) {
    return (
        <Flex align="top" gap={8} css={{ marginBottom: 4 }}>
            {announce.text_status !== ANNOUNCE_STATUS.APPROVED && (
                <div className="t-mutted">
                    Needs the draft approval. When the idea's status is marked as "
                    {announce.idea.board.doneIdeaStatusName}" (Current "{announce.idea.statusName}") will be visible in
                    Email, Widget, and Web.
                </div>
            )}
            {ANNOUNCE_STATUS.APPROVED === announce.text_status && (
                <div className="t-mutted">
                    When the idea's status is marked as "{announce.idea.board.doneIdeaStatusName}" (Current "
                    {announce.idea.statusName}") will be visible in Email, Widget, and Web.
                </div>
            )}
        </Flex>
    );
}

export default observer(IdeaAnnounceContentDescription);
