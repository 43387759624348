import React from 'react';

import ReactDOM from 'react-dom';

import { mainStore } from 'store/models/MainStore';

import copyText from 'utils/copyText';
import delay from 'utils/delay';
import logEvent from 'utils/logEvent';

import { showToaster } from 'components/AppToaster';
import DomTooltip from 'components/DomTooltip';
import Notification from 'components/Notification';

if (window.$crisp) {
    function getCrispUser() {
        const isCurrentEmail = window.$crisp.get('user:email') === mainStore.currentUser?.email;
        const nickname = window.$crisp.get('user:nickname');
        const company = window.$crisp.get('user:company');
        const session = window.$crisp.get('session:identifier');

        return { nickname, isCurrentEmail, company, session };
    }
    function callbackMessageSent() {
        logEvent('callbackMessageSent', getCrispUser());
    }

    function callbackChatOpened() {
        logEvent('callbackChatOpened', getCrispUser());
        delay(1000).then(() => logEvent('callbackChatOpened:delay', getCrispUser()));
    }

    function callbackChatClosed() {
        logEvent('callbackChatClosed', getCrispUser());
    }

    function callbackUserEmailChanged() {
        logEvent('callbackUserEmailChanged', getCrispUser());
    }

    window.$crisp.push(['safe', true]);

    window.$crisp.push(['on', 'chat:opened', callbackChatOpened]);
    window.$crisp.push(['on', 'message:sent', callbackMessageSent]);
    window.$crisp.push(['on', 'chat:closed', callbackChatClosed]);
    window.$crisp.push(['on', 'user:email:changed', callbackUserEmailChanged]);
}

if (window.server_error) {
    showToaster({
        message: window.server_error,
        intent: 'danger',
        timeout: 0,
    });
    window.server_error = null;
}
if (window.invalid_invite_email || window.change_email_error) {
    showToaster({
        message: window.invalid_invite_email || window.change_email_error,
        intent: 'danger',
        timeout: 0,
    });
    window.invalid_invite_email = null;
    window.change_email_error = null;
}

(function () {
    const list = document.querySelectorAll('.js--tooltip');
    if (list.length > 0) {
        for (let el of list) {
            const content = el.getAttribute('data-content') || null;
            if (content) {
                ReactDOM.render(<DomTooltip content={content} inner={el.innerHTML} />, el);
            }
        }
    }
})();

window.copyText = copyText;
window.Notification = Notification;
