import api from 'api';

import { CURRENT_USER_IS_ADMIN } from 'utils/consts';
import { sendToSentry } from 'utils/sentry';

export async function analyticsRead() {
    if (this.id === -1 || CURRENT_USER_IS_ADMIN) return false;

    try {
        await api.post(`${this.board.apiEndpoint}/activity-log`);
    } catch (e) {
        sendToSentry('Fail send Read Issue', { id: this.id });
    }
}
