import React, { useMemo } from 'react';

import { useHotkeys } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';

import { utilsStore } from 'store/models/UtilsStore';

import { IS_VOTING_BOARD, POPOVER_PROPS_DIV } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import DotDivider from 'components/DotDivider';
import Kbd from 'components/Kbd';

export default function CloseBtn({ onClick }) {
    const canHide = typeof onClick === 'function';

    if (!canHide) {
        return null;
    }

    return utilsStore.isMobile ? <MobileBtn onClick={onClick} /> : <DesktopBtn onClick={onClick} />;
}

function DesktopBtn({ onClick }) {
    const hotkeys = useMemo(
        () => [
            {
                combo: ']',
                global: true,
                label: 'Hide/Show Issue Card',
                group: 'Issue',
                allowInInput: true,
                onKeyDown: (e) => {
                    if (
                        (['INPUT', 'TEXTAREA'].indexOf(e.target.tagName) !== -1 &&
                            e.target.className !== 'cellInput') ||
                        /ProseMirror/.test(e.target.className)
                    ) {
                        return false;
                    }
                    e.preventDefault();
                    utilsStore.toggleCardHidden();
                },
            },
        ],
        []
    );
    useHotkeys(hotkeys);

    return (
        <DarkTooltip
            {...POPOVER_PROPS_DIV}
            position="top-left"
            content={
                <>
                    <FormattedMessage id="shortcuts.hide_desc" />
                    <DotDivider />
                    <Kbd>]</Kbd>
                </>
            }
        >
            <Button
                block
                border
                minimal={!IS_VOTING_BOARD}
                data-test="close-btn"
                size={IS_VOTING_BOARD ? 28 : 24}
                icon={CustomIconName.COLLAPSE_RIGHT}
                onClick={onClick}
            />
        </DarkTooltip>
    );
}

const MobileBtn = observer(({ onClick }) => {
    return (
        <Button
            minimal={!IS_VOTING_BOARD}
            border={IS_VOTING_BOARD}
            data-test="close-btn"
            size={28}
            icon={CustomIconName.X_CROSS}
            onClick={onClick}
        />
    );
});
