import { Plugin } from 'prosemirror-state';

import insertFilesIntoView from 'components/TextEditor/insertFilesIntoView';

/**
 * function for image drag n drop + past from clipboard
 */
export const uploadImagePlugin = new Plugin({
    props: {
        handleDOMEvents: {
            paste(view, event) {
                if (!event.currentTarget) return;

                const filesList = Array.from(event.clipboardData?.items || []);
                const formData = new FormData();
                let hasFiles = false;

                filesList.forEach((item) => {
                    const file = item.getAsFile();
                    !hasFiles && (hasFiles = !!file);
                    formData.append('file[]', item.getAsFile());
                });

                if (!hasFiles) return;

                event.preventDefault();

                insertFilesIntoView(view, formData);
                return false;
            },
            drop(view, event) {
                if (!event.dataTransfer?.files?.length) return false;
                if (event.dataTransfer.files.length < event.dataTransfer.items.length) return false;
                event.preventDefault();

                const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });

                const filesList = Array.from(event?.dataTransfer?.files);
                const formData = new FormData();
                filesList.forEach((file) => formData.append('file[]', file));

                insertFilesIntoView(view, formData, undefined, coordinates);
                return false;
            },
        },
    },
});
