import React, { useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import criteriaStore from 'store/models/CriteriaStore';
import { isSingleNumberCriterion } from 'store/models/utils';
import { utilsStore } from 'store/models/UtilsStore';

import CriterionDesc from 'components/CriterionDesc/CriterionDesc';

import { NextCellTooltipAction } from './NextCellTooltipAction';
import { onBlur } from './onBlur';
import { onChange } from './onChange';
import { onFocus } from './onFocus';
import { onKeyDown } from './onKeyDown';
import VoteInputTooltipWrapper from './VoteInputTooltipWrapper';

import styles from './VoteInput.module.sass';

export const VoteInput = observer(({ columnData, showTooltips, columnIndex, issue, rowIndex }) => {
    const tabIndex = rowIndex * issue.board.evaluationCriteriaCount + columnData.tabIndex;
    const value = issue.userVotes?.get(columnData.criterion_id) ?? '';
    const criterion = criteriaStore.criteriaIds.get(columnData.criterion_id);
    const [vote, setVote] = useState(value);
    const [inputValue, setInputValue] = useState(String(value));
    const [focus, setFocus] = useState(false);

    useEffect(() => {
        setInputValue((state) => (String(value) !== state ? String(value) : state));
    }, [value]);

    const isFail = issue.failCells.find((el) => el.criterion_id === columnData.criterion_id);

    const id = `i${rowIndex}${columnIndex}`;
    const settings = utilsStore.settings;

    const classes = classNames('flex-grow', {
        [styles.isFail]: !!isFail,
    });

    const isSingleNumber = useMemo(
        () =>
            isSingleNumberCriterion({
                type: criterion.scale_type,
                min: criterion.scale_min,
                max: criterion.scale_max,
                series: criterion.scale_series,
            }),
        [criterion.scale_type, criterion.scale_min, criterion.scale_max, criterion.scale_series],
    );
    const needAction = settings.fastInput && !isSingleNumber;
    const singleFastInput = settings.fastInput && isSingleNumber;

    const content = (
        <form className={classes} onSubmit={(e) => e.preventDefault()}>
            <input
                data-1p-ignore=""
                className={styles.input}
                data-kbd={needAction}
                autoComplete="off"
                data-col={columnIndex}
                data-row={rowIndex}
                data-y={issue.id}
                data-x={columnData.criterion_id}
                id={id}
                onKeyDown={onKeyDown(rowIndex, columnIndex, focus, setFocus, singleFastInput)}
                onBlur={(event) => {
                    setFocus(false);
                    onBlur(event, rowIndex, criterion, issue, vote, setVote, columnIndex);
                }}
                onFocus={(event) => {
                    setFocus(true);
                    onFocus(event);
                }}
                onChange={onChange(issue, criterion, setFocus, singleFastInput, setInputValue)}
                tabIndex={tabIndex}
                value={inputValue}
            />
        </form>
    );

    if (settingsStore.isOpen) {
        return content;
    }

    if (showTooltips) {
        return (
            <VoteInputTooltipWrapper
                focus={focus}
                tooltip={
                    <CriterionDesc
                        boardId={issue.board?.id}
                        criterionId={columnData.criterion_id}
                        needAction={needAction}
                        canSkip={issue.unvoted}
                    />
                }
            >
                {content}
            </VoteInputTooltipWrapper>
        );
    } else if (needAction) {
        return (
            <VoteInputTooltipWrapper focus={focus} tooltip={<NextCellTooltipAction />}>
                {content}
            </VoteInputTooltipWrapper>
        );
    }
    return content;
});
