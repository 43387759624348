import React from 'react';

import { observer } from 'mobx-react-lite';

import { POPOVER_PROPS_DIV } from 'utils/consts';

import Avatar from 'components/Avatar';
import DarkTooltip from 'components/DarkTooltip';

import styles from './VotingIssueUser.module.sass';

function VotingIssueUser({ item }) {
    const tooltip = (
        <>
            <div>
                {item.name} {item.email}
            </div>
            {item.company && <div>Company: {item.company}</div>}
            <div>Votes: {item.count}</div>
        </>
    );

    return (
        <div className="p-r interactive">
            <DarkTooltip {...POPOVER_PROPS_DIV} position="top" content={tooltip}>
                <Avatar size={24} user={item} />
            </DarkTooltip>
            <span className={styles.tag}>{item.count}</span>
        </div>
    );
}

export default observer(VotingIssueUser);
