import React, { useState } from 'react';

import { Popover } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { PAYWALL_LIMITS, POPOVER_PROPS_DIV } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import UpgradePlanBtn from 'components/UpgradePlanBtn';

import { AddHandleVotePopoverContent } from './AddHandleVotePopoverContent';

import styles from './AddHandleVote.module.sass';

function AddHandleVote({ issue }) {
    const [open, setOpen] = useState(false);

    if (!mainStore.currentUser?.isAdmin) {
        return null;
    }

    if (!mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.VOTING_VOTE_BEHALF)) {
        return (
            <div className={styles.wrapper}>
                <UpgradePlanBtn
                    paywall={PAYWALL_LIMITS.VOTING_VOTE_BEHALF}
                    text="to unlock this feature."
                    helpArticle="voting-board/#creating-an-idea"
                    helpArticleText="Voting on Behalf"
                >
                    <Button size={24} border icon={CustomIconName.PLUS} disabled />
                </UpgradePlanBtn>
            </div>
        );
    }

    return (
        <Popover
            {...POPOVER_PROPS_DIV}
            className={styles.wrapper}
            onClose={() => setOpen(false)}
            minimal
            position="bottom"
            isOpen={open}
            content={<AddHandleVotePopoverContent issue={issue} onClose={() => setOpen(false)} />}
        >
            <DarkTooltip content="Vote on Behalf of a User" {...POPOVER_PROPS_DIV}>
                <Button
                    block
                    size={24}
                    border
                    icon={CustomIconName.PLUS}
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpen((state) => !state);
                    }}
                />
            </DarkTooltip>
        </Popover>
    );
}

export default observer(AddHandleVote);
