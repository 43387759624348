import React from 'react';

import { observer } from 'mobx-react-lite';

import { DICTIONARY_STATUSES_VOTING } from 'utils/consts';

import DarkTooltip from 'components/DarkTooltip';
import DictionaryValueItem from 'components/DictionaryValueItem';
import DictionaryValueSelect from 'components/DictionaryValueSelect';
import MetaItem from 'components/MetaItem';

function Status({ issue, disabled }) {
    if (!disabled) {
        return (
            <MetaItem>
                <DictionaryValueSelect dictionary={DICTIONARY_STATUSES_VOTING} issue={issue} />
            </MetaItem>
        );
    }
    const status = issue.statusObj;

    if (!status) return null;

    return (
        <DarkTooltip position="top-right" content="Status" rootElementTag={false}>
            <MetaItem>
                <DictionaryValueItem {...status} tag />
            </MetaItem>
        </DarkTooltip>
    );
}

export default observer(Status);
