const escapeRegExp = (string) => {
    if (typeof string !== 'string') {
        return string;
    }
    // eslint-disable-next-line no-useless-escape
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

export default (filename) =>
    new RegExp(
        `!${escapeRegExp(filename.replace(/_/g, ':'))}(|.+?)!|!${escapeRegExp(filename)}(|.+?)!|\\[\\^${escapeRegExp(
            filename
        )}\\]`,
        'g'
    );
