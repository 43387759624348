import { runInAction } from 'mobx';

import api from 'api';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { VIEWS } from 'utils/consts';
import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';

export async function skipEvaluation() {
    logEvent('Skip evaluation');

    if (mainStore.page === VIEWS.EVALUATION) {
        runInAction(() => {
            this.isRemoved = true;
        });

        this.board.getNextIssue(issuesList.row);
    }

    try {
        await api.post(`${this.apiCurrentEndpoint}/vote-skip`);

        runInAction(() => {
            this.externalData.unvoted = this.externalData.isUserNotAllVoted = false;
            this.externalData.skipped = true;
        });
    } catch (e) {
        failRequest(e);
    } finally {
        runInAction(() => {
            this.isRemoved = false;
        });
    }
}
