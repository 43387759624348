import React from 'react';

import { observer } from 'mobx-react-lite';

import modelCopyMoveStore, { DIALOG_STATUS } from 'store/models/modelCopyMoveStore';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

const IdeaMoveActions = ({ idea }) => {
    return (
        <>
            <Button
                block
                className="bp5-popover-dismiss"
                minimal
                icon={CustomIconName.COPY}
                onClick={() => modelCopyMoveStore.openDialog({ dialogType: DIALOG_STATUS.copyIdea, model: idea })}
                text="Copy idea"
            />
            <Button
                block
                className="bp5-popover-dismiss"
                minimal
                icon={CustomIconName.MOVE}
                onClick={() => modelCopyMoveStore.openDialog({ dialogType: DIALOG_STATUS.moveIdea, model: idea })}
                text="Move idea"
            />
        </>
    );
};

export default observer(IdeaMoveActions);
