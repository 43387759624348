import React, { forwardRef } from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { PAYWALL_LIMITS, POPOVER_PROPS_DIV } from 'utils/consts';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Tooltip from 'components/Tooltip';
import UpgradePlanBtn from 'components/UpgradePlanBtn';

const StatisticTitleItem = forwardRef(({ icon, count, text, percent, ...props }, forwardedRef) => {
    return (
        <Flex gap={2} ref={forwardedRef} {...props}>
            <CustomIcon icon={icon} />
            <span>
                <strong>
                    {count}
                    {percent && '%'}
                </strong>{' '}
                {text}
            </span>
        </Flex>
    );
});

const StatisticTitleContent = observer(function StatisticTitleContent({ idea }) {
    const { awareness = 0, voters_count = 0, total_read = 0 } = idea?.vote_stats || {};

    return (
        <Flex gap={12}>
            <Tooltip
                content="Awareness - Shows the percentage of voters who read the idea after it was moved to Done."
                contentClassName="limit limit-300"
                side="top"
                align="end"
            >
                <StatisticTitleItem icon={CustomIconName.BULLHORN} count={awareness} text="Awareness" percent />
            </Tooltip>
            <StatisticTitleItem icon={CustomIconName.TEAM_ALT} count={voters_count} text="Voters" />
            <StatisticTitleItem icon={CustomIconName.GRAPH} count={total_read} text="Viewers" />
        </Flex>
    );
});

function StatisticTitle({ idea }) {
    if (!mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.VOTING_ANALYTICS)) {
        return (
            <UpgradePlanBtn
                {...POPOVER_PROPS_DIV}
                paywall={PAYWALL_LIMITS.VOTING_ANALYTICS}
                text="to unlock this feature."
                helpArticleText="Public Ideas Analytics"
                helpArticle="public-ideas-analytics"
            >
                <Flex gap={12}>
                    <StatisticTitleItem icon={CustomIconName.BULLHORN} count="?" text="Awareness" percent />
                    <StatisticTitleItem icon={CustomIconName.TEAM_ALT} count="?" text="Voters" />
                    <StatisticTitleItem icon={CustomIconName.GRAPH} count="?" text="Viewers" />
                    <CustomIcon style={{ color: 'var(--colors-yellowA11)' }} icon={CustomIconName.UPGRADE} />
                </Flex>
            </UpgradePlanBtn>
        );
    }

    return <StatisticTitleContent idea={idea} />;
}

export default observer(StatisticTitle);
