import React from 'react';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';

function ViewStatus({ last_status_update }) {
    const date = last_status_update ? DateTime.fromISO(last_status_update).toFormat('DD') : '...';

    return (
        <DarkTooltip fast content={`User read the idea after it was moved to Done on ${date}`}>
            <CustomIcon className="o-4" icon={CustomIconName.TICK_CIRCLE} />
        </DarkTooltip>
    );
}

export default observer(ViewStatus);
