import { forwardRef } from 'react';

import { observer } from 'mobx-react-lite';

import { logEvent } from 'utils';

import alertStore from 'store/models/Alert';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

const AnnounceClearButton = forwardRef(function AnnounceClearButton({ announce, ...props }, ref) {
    const clearAnnounce = () => {
        logEvent('Clear Announce Click');
        alertStore.confirm({
            message: 'Are you sure? This Announcement will be cleared.',
            confirmButtonText: 'Clear',
            onConfirm: () => announce.clear(),
        });
    };

    return <Button onClick={clearAnnounce} icon={CustomIconName.CLEAR} ref={ref} {...props} />;
});

export default observer(AnnounceClearButton);
