import React from 'react';

import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';
import { utilsStore } from 'store/models/UtilsStore';

import { ISSUE_CARD_POSITION } from 'utils/consts';

import styles from './FreeSpace.module.sass';

// Space from right side
const EXTRA_SPACE = 40;

function FreeSpace({ height, position, width }) {
    const style = { height };

    const issueWidth = utilsStore.settings.issueWidth - EXTRA_SPACE;

    if (issuesList.activeIssue && !utilsStore.cardIsHidden) {
        if (
            position === ISSUE_CARD_POSITION.LEFT || // Evaluation + Scores screens
            utilsStore.settings.issueHiddenTop || // Don`t show Issue Card
            issueWidth < width // Free space is bigger then needed
        ) {
            return null;
        }
        const finalWidth = issueWidth - Math.max(width, 0);
        style.width = style.minWidth = style.maxWidth = finalWidth;
    }

    return <div className={styles.dialogPlaceholder} style={style} />;
}

export default observer(FreeSpace);
