import React, { useState } from 'react';

import { Collapse } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import DropDownLink from 'components/DropDownLink';
import Space from 'components/Space';

import MergedIssue from './MergedIssue';

import styles from './MergedIssues.module.sass';

export const MergedIssues = observer(({ issue }) => {
    const [open, setOpen] = useState(false);

    if (!issue.mergedIssues || issue.mergedIssues.length === 0) {
        return null;
    }
    return (
        <>
            <Space height={20} />

            <DropDownLink onClick={setOpen} title={`Merged ideas (${issue.mergedIssues.length})`} open={open} />

            <Collapse isOpen={open} className={styles.collapse}>
                {issue.mergedIssues.map((mergedIssue) => (
                    <MergedIssue issue={mergedIssue} key={mergedIssue.id} boardId={issue.boardId} />
                ))}
            </Collapse>
        </>
    );
});
