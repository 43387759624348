import api from 'api';

import dictionaryStore from 'store/models/DictionaryStore';

import { ColorsVariants } from 'atoms/Colors';

import { IS_VOTING_BOARD } from 'utils/consts';
import failRequest from 'utils/failRequest';
import getRandom from 'utils/getRandom';
import toUrl from 'utils/toUrl';

export async function createDictItem({ dictionary, name, issue }) {
    const index = getRandom(ColorsVariants.length) - 1;
    const requestData = { name, color: ColorsVariants[index] };

    try {
        const { data } = await api.post(`${this.apiEndpoint}/${dictionary}`, toUrl(requestData));
        if (IS_VOTING_BOARD) {
            dictionaryStore.IdeaLabel.set(data.id, data);
        }

        issue?.setProps({ dictionary, value: data });
        this.filter?.form && this.filter?.getForm();

        return data;
    } catch (error) {
        failRequest(error);
    }
}
