import React from 'react';

import { Node, mergeAttributes } from '@tiptap/core';
import { NodeViewWrapper } from '@tiptap/react';

export function VideoComponent({ node }) {
    return (
        <NodeViewWrapper as="video" preload="metadata" controls>
            <source src={node.attrs.src} type="video/mp4" />
        </NodeViewWrapper>
    );
}

export default Node.create({
    name: 'video',

    content: 'block*',
    inline: false,
    group: 'block',
    draggable: true,
    atom: true,

    parseHTML() {
        return [
            {
                tag: ['video'],
            },
        ];
    },
    addAttributes() {
        return {
            src: {
                default: null,
            },
            preload: {
                default: 'metadata',
            },
            controls: {
                default: '',
            },
        };
    },
    renderHTML({ HTMLAttributes }) {
        return ['video', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
    // addNodeView() {
    //     return ReactNodeViewRenderer(VideoComponent);
    // },
    addCommands() {
        return {
            setVideo:
                (options) =>
                ({ commands }) => {
                    return commands.insertContent({
                        type: this.name,
                        attrs: options,
                    });
                },
        };
    },
});
