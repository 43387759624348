import api, { rootApi, storageApi } from 'api';

import { APP_VERSION, IS_PUBLIC_BOARD } from 'utils/consts';

import { AppToaster } from 'components/AppToaster';

import logEvent from './logEvent';

const checkNewVersion = (utilsStore) => (response) => {
    const version = response.headers['x-version'];

    if (version && version !== APP_VERSION) {
        utilsStore.newVersionAlert();
    }
    return response;
};

const checkErrorStatus = (error) => {
    switch (error.response?.status) {
        case 503:
            AppToaster.show({
                message: "We're undergoing a bit of scheduled maintenance. Please try again or reload the page",
                intent: 'warning',
                timeout: 7000,
            });
            return logEvent('Scheduled maintenance');
        case 401:
            if (IS_PUBLIC_BOARD) {
                return Promise.reject(error);
            }
            logEvent('Login Redirect');
            if (process.env.NODE_ENV !== 'production') {
                return Promise.reject(error);
            }
            return (window.location.href = '/login');
        default:
            return Promise.reject(error);
    }
};

export default function setApiInterceptors(utilsStore) {
    const versionFn = checkNewVersion(utilsStore);
    api.interceptors.response.use(versionFn, checkErrorStatus);
    storageApi.interceptors.response.use(versionFn, checkErrorStatus);
    rootApi.interceptors.response.use(versionFn, checkErrorStatus);
}
