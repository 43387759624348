import React from 'react';

import { observer } from 'mobx-react-lite';

import { CRITERION_TYPE } from 'constants/Criterion';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';

/**
 * Icon name by criterion type
 */
const CRITERION_ICON_TYPE = {
    [CRITERION_TYPE.value]: CustomIconName.ARROW_UP,
    [CRITERION_TYPE.effort]: CustomIconName.ARROW_DOWN,
    [CRITERION_TYPE.ignore]: CustomIconName.STOP,
    [CRITERION_TYPE.multiplier]: CustomIconName.MULTIPLIER,
};

/**
 * Icon color by criterion type
 */
const CRITERION_COLOR_TYPE = {
    [CRITERION_TYPE.value]: 'green',
    [CRITERION_TYPE.effort]: 'tomato',
    [CRITERION_TYPE.ignore]: 'gray',
    [CRITERION_TYPE.multiplier]: 'purple',
};

const CriterionTypeIcon = React.forwardRef(function CriterionTypeIcon({ type, ...props }, ref) {
    const icon = CRITERION_ICON_TYPE[type];
    const color = CRITERION_COLOR_TYPE[type];

    return <CustomIcon color={color} icon={icon} {...props} ref={ref} />;
});

export default observer(CriterionTypeIcon);
