import React from 'react';

import { observer } from 'mobx-react-lite';

import Avatar from 'components/Avatar';
import DarkTooltip from 'components/DarkTooltip';
import Flex from 'components/Flex';

export const User = observer(({ user, accountId, isEditor = false }) => {
    let userView = (
        <>
            {user?.avatar && <Avatar hideStatus size={14} user={user} as="span" />} {user?.name || accountId}
        </>
    );

    if (!user?.email) {
        return isEditor ? userView : <span className="mention">{userView}</span>;
    }

    return (
        <DarkTooltip
            position="top"
            content={
                <Flex gap={6}>
                    {user.avatar && <Avatar hideStatus size={28} user={user} />}
                    <div>
                        <div>{user.name}</div>
                        {user.email && <div className="o-6">{user.email}</div>}
                    </div>
                </Flex>
            }
        >
            <Flex gap={4}>{userView}</Flex>
        </DarkTooltip>
    );
});
