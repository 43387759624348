/**
 * Calculate full width of table and width for Issue overlay
 *
 * @param {Array} fields
 */
export function getAnotherWidth(fields) {
    return fields.reduce(
        (res, field) => {
            res.overlay += typeof field.id === 'string' && field.id !== 'q' ? field.width : 0;
            res.full += field.width;
            return res;
        },
        { overlay: 0, full: 0 },
    );
}

/**
 * Generate field item for table width data
 *
 * @param {String|Number} title
 */
export function calcFieldSize(title) {
    return Math.max(title.toString().length * 7 + 26, 70);
}

/**
 * Generate field item for table width data
 *
 * @param {Object} column
 */
export function createFieldSize(column) {
    const id = getColumnId(column);
    const width = column.width || calcFieldSize(column?.name ?? '');
    return { id, width };
}

/**
 * Generate table width data
 *
 * @param {Array} columns
 */
export function getSmartColumnWidth(columns = []) {
    const fields = columns.map(createFieldSize);
    const width = getAnotherWidth(fields);
    const hash = getColumnsHash(columns);

    return { fields, width, hash };
}

/**
 * Generate localStoreage key - table name +  boardId
 *
 * @param {string} tableName
 * @param {Number|String} boardId
 */
export function getTableKey(tableName, boardId = -1) {
    return `${tableName}~${boardId}`;
}

/**
 * Update current table width data if columns count is changed
 *
 * @param {Array} fields - old table width data
 * @param {Array} columns - list of table columns
 */
export function updateTableWidth(fields, columns) {
    const newFields = columns.map((column) => {
        const field = fields.find((f) => f.id === getColumnId(column));
        if (field) {
            return field;
        }
        return createFieldSize(column);
    });
    const width = getAnotherWidth(newFields);
    const hash = getColumnsHash(columns);

    return { fields: newFields, width, hash };
}

export function getColumnId(column) {
    return column.field || (column.user && `user-${column.user.id}`) || column.id;
}

/**
 * Generate uniq name for fields width collection
 *
 * @param {Object} columns
 *
 * @returns {string}
 */
export function getColumnsHash(columns) {
    return columns
        .map((column) => getColumnId(column))
        .sort()
        .join('-');
}
