import React, { memo, useMemo } from 'react';

import { Spinner, useHotkeys } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { styled } from 'stitches.config';

import { utilsStore } from 'store/models/UtilsStore';

import { StHr } from 'atoms/StHr';

import { Button } from 'components/Button';
import Flex from 'components/Flex';
import Kbd from 'components/Kbd';
import ReadOnlyDescription from 'components/ReadOnlyDescription';
import Space from 'components/Space';

const StopButton = memo(({ onStop }) => {
    const hotkeys = useMemo(
        () => [
            {
                combo: 'esc',
                global: true,
                label: 'Stop AI',
                group: 'Board',
                allowInInput: true,
                preventDefault: true,
                stopPropagation: true,
                onKeyUp: onStop,
            },
        ],
        [onStop],
    );
    useHotkeys(hotkeys);

    return <Button onClick={onStop} size={24} border text="Stop writing" rightElm={<Kbd>Esc</Kbd>} />;
});

const StTitle = styled('div', {
    margin: '0 0 16px',
    fontSize: 24,
    fontWeight: 700,
});

const TextAIPreview = ({
    title,
    text,
    onConfirm,
    onCancel,
    onRepeat,
    onStop,
    loading,
    small,
    saveButtonText = 'Save',
    rewriteButtonText = 'Rewrite idea',
    ...props
}) => {
    return (
        <div
            data-place="ai-dialog"
            style={{ padding: 8, width: utilsStore.settings.issueWidth - (small ? 54 : 36) }}
            {...props}
        >
            <Flex gap={6} css={{ marginBottom: 8 }}>
                {(loading && (
                    <>
                        <StopButton size={24} onStop={onStop} data-place="ai-dialog-stop" />
                        <div className="flex-grow" />
                        <Spinner size={18} />
                    </>
                )) || (
                    <>
                        <Button
                            size={24}
                            disabled={loading}
                            color="accent"
                            border
                            text={saveButtonText}
                            onClick={onConfirm}
                            data-place="ai-dialog-confirm"
                        />
                        <Button
                            data-place="ai-dialog-rewrite"
                            size={24}
                            disabled={loading}
                            border
                            text={rewriteButtonText}
                            onClick={onRepeat}
                        />
                        <div className="flex-grow" />
                        <Button
                            size={24}
                            disabled={loading}
                            border
                            minimal
                            data-place="ai-dialog-cancel"
                            text="Cancel"
                            onClick={onCancel}
                        />
                    </>
                )}
            </Flex>
            <StHr />
            <Space height={8} />
            <div style={{ minHeight: 20 }} data-place="ai-dialog-content">
                {title ? <StTitle>{title}</StTitle> : null}
                <ReadOnlyDescription description={text} />
            </div>
            <Space height={8} />
        </div>
    );
};

export default observer(TextAIPreview);
