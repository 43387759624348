import React from 'react';

import { Dialog } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';

import QuestionForm from 'components/QuestionForm';

const QuestionDialog = () => {
    if (!issuesList.questionIssue) return null;

    const issue = issuesList.questionIssue;

    return (
        <Dialog isOpen={true} className="limit limit-600" enforceFocus autoFocus>
            <QuestionForm defaultUser={issue.reporterUser} />
        </Dialog>
    );
};

export default observer(QuestionDialog);
