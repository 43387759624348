import React, { useCallback } from 'react';

import useUndoNotification from 'hooks/useUndoNotification';

import isUnsavedIssue from 'utils/isUnsavedIssue';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import Kbd, { KeyCombo } from 'components/Kbd';

export default function RemoveBtn({ issue }) {
    const notify = useUndoNotification();
    const votingIssueLink = issue?.idea?.internalHref;

    const infoMessage = useCallback(() => {
        votingIssueLink &&
            notify(
                <>
                    Issue <b>{issue.name}</b> was removed. Public part is still visible on Voting Board.
                </>
            );
    }, [issue?.name, notify, votingIssueLink]);

    if (isUnsavedIssue(issue)) {
        return null;
    }

    return (
        <DarkTooltip position="left" fast content="Permanently delete">
            <Button
                block
                icon={CustomIconName.TRASH}
                onClick={() => issue.deleteFromBoard(infoMessage)}
                minimal
                className="bp5-popover-dismiss"
                text="Remove Issue permanently"
                rightElm={
                    <KeyCombo>
                        <Kbd>alt</Kbd>
                        <Kbd>del</Kbd>
                    </KeyCombo>
                }
            />
        </DarkTooltip>
    );
}
