import React from 'react';

import { observer } from 'mobx-react-lite';

import Avatar from 'components/Avatar';
import Flex from 'components/Flex';

const IssueTooltipItem = ({ name, children }) => (
    <Flex gap={4} as="p">
        <b className="flex-shrink">{name}:</b> {children}
    </Flex>
);

export const IssueTooltip = observer(({ issue }) => {
    return (
        <>
            <p>{issue.fullName}</p>
            {issue.assignee && (
                <IssueTooltipItem name="Assignee">
                    <Avatar size={16} user={issue.assignee} /> {issue.assignee.name}
                </IssueTooltipItem>
            )}
            <IssueTooltipItem name="Board">{issue.board.fullName}</IssueTooltipItem>
            <IssueTooltipItem name="Priority Rank">{issue.row_number || '-'}</IssueTooltipItem>
        </>
    );
});
