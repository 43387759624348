import React from 'react';

import { observer } from 'mobx-react-lite';

import AiButton from 'modules/AiButton/AiButton';
import { TextEl } from 'modules/PublicIdeaStripe/components/AIButtons/TextEl';

const AIButtons = ({ idea }) => {
    const onGenerate = async (abortController) => {
        await idea.generateAI(abortController.current);
        await idea.getTitleByAI(abortController.current, true);
    };

    const onConfirm = () => {
        idea.update({ description: idea.aiText, name: idea.aiName });
    };

    return (
        <AiButton
            side="left"
            tooltipProps={{
                side: 'left',
                content:
                    'Ducalis AI rewrites the Public Idea based on the Language and Template settings of the voting board',
            }}
            onGenerate={onGenerate}
            clearAIText={idea.clearAIText}
            buttonProps={{ text: 'Rewrite Idea', 'data-place': 'idea-rewrite-ai' }}
            onConfirm={onConfirm}
        >
            {(props) => <TextEl idea={idea} small {...props} />}
        </AiButton>
    );
};

export default observer(AIButtons);
