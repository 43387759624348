import React from 'react';

import { observer } from 'mobx-react-lite';

import { CriterionEffortIcon, CriterionValueIcon } from 'components/CriterionTypeIcon';
import Flex from 'components/Flex';

const styleHeight = { height: 30 };

function IssueMatrixData({ issue }) {
    return (
        <Flex gap={20}>
            <Flex style={styleHeight}>
                <span className="o-6">Value</span>
                <CriterionValueIcon />
                {issue.value}
            </Flex>
            <Flex style={styleHeight}>
                <span className="o-6">Effort</span>
                <CriterionEffortIcon />
                {issue.effort}
            </Flex>
        </Flex>
    );
}

export default observer(IssueMatrixData);
