import { FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';


import { ENTER } from 'utils/consts';
import getKeyCode from 'utils/getKeyCode';
import { isEmail } from 'utils/validators';

import { showToaster } from 'components/AppToaster';
import { Button } from 'components/Button';

import LoginError from '../LoginError';

const EmailInput = ({ inputRef, isMobile, defaultValue = '', onSend, clearError, errorText = '', label }) => {
    const intl = useIntl();

    function emailOnKeyDown(e) {
        if (getKeyCode(e) === ENTER) handleSend();
    }

    const message = defineMessages({
        emailInvalid: {
            id: 'auth.email_invalid',
            defaultMessage: 'Email is invalid',
        },
    });
    const emailInvalid = intl.formatMessage(message.emailInvalid);

    function handleSend() {
        const value = inputRef.current.value;
        if (isEmail(value)) {
            onSend(value);
        } else {
            showToaster({
                message: emailInvalid,
                intent: Intent.DANGER,
                timeout: 4000,
            });
        }
    }

    return (
        <div style={{ width: '100%', maxWidth: 375 }}>
            <FormGroup
                fill
                label={label || <FormattedMessage id="global.email" defaultMessage="Email Address" />}
                style={{ marginBottom: 8 }}
            >
                <InputGroup
                    type="email"
                    name="email"
                    data-place="auth-email"
                    inputRef={inputRef}
                    placeholder="name@example.com"
                    fill
                    autoFocus={!isMobile}
                    defaultValue={defaultValue}
                    onKeyDown={emailOnKeyDown}
                    onChange={clearError}
                />
            </FormGroup>
            <LoginError errorText={errorText} />
            <Button
                onClick={handleSend}
                disabled={!!errorText}
                center
                border
                color="accent"
                block
                text={<FormattedMessage id="auth.with_email" defaultMessage="Continue with Email" />}
            />
        </div>
    );
};

export default observer(EmailInput);
