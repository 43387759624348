import { uuidv4 } from 'utils/uuidv4';

import { CustomIconName } from 'components/CustomIcon';

import { getBooleanRequestParam } from './getRequestParam';
import getSessionId from './getSessionId';
import pageInIframe from './pageInIframe';

export const TAB = 9;
export const DOWN = 40;
export const UP = 38;
export const RIGHT = 39;
export const ENTER = 13;
export const LEFT = 37;
export const ESC = 27;

export const APP_VERSION = process.env.REACT_APP_VERSION || window.version;
export const INSTANCE_PREFIX = window.instancePrefix || '';
export const SERVICE_NAME = INSTANCE_PREFIX ? 'PrioPlan' : 'Ducalis';
export const SERVICE_NAMESPACE = INSTANCE_PREFIX ? 'prioplan' : 'ducalis';
export const SERVICE_DOMAIN_NAME = INSTANCE_PREFIX ? 'Prioplan.app' : 'Ducalis.io';
export const ENV = process.env.REACT_APP_ENV || window.env || 'localhost';
export const IS_DEV =
    process.env.REACT_APP_ENV === 'dev' ||
    process.env.REACT_APP_ENV === 'localhost' ||
    process.env.REACT_APP_ENV === 'stage';
export const CURRENT_USER_ID = window.uID || (window.isWidgetUser ? -1 : undefined);
export const ANALYTICS_CURRENT_USER_ID = `${INSTANCE_PREFIX}${CURRENT_USER_ID}`;
export const IS_WIDGET_USER = window.isWidgetUser || false;
export const CURRENT_USER = window.user;
export const CURRENT_USER_IS_ADMIN = window.isA || false;
export const CURRENT_USER_IS_OWNER = window.isOwner || false;
export const CURRENT_ORG_ID = window.oId || null;
export const CURRENT_ORG_PUBLIC_ID = window.opId || null;
export const PLATFORM_CREATION_ID = window.loadedPlatforms && window.loadedPlatforms.map((el) => el.id);
export const AMPLITUDE_KEY = window.amplKey || null;
export const IS_PUBLIC_BOARD = !!window.publicBoard || !!window.votingOrganization;
export const IS_VOTING_BOARD =
    IS_PUBLIC_BOARD && window.votingOrganization && (!window.publicBoard || window.publicBoard.stage === 'voting');
export const IS_SHARING_BOARD = IS_PUBLIC_BOARD && !IS_VOTING_BOARD;
export const MAIN_SITE_URL = window.mainSiteUrl || 'https://ducalis.io';
export const IS_WEB_BOT = window.isBot || false;
export const DOMAIN_NAME = window.location.hostname.split('.').slice(-2).join('.');
export const REMOVE_NOFOLLOW_LINK = IS_VOTING_BOARD && !document.querySelector('[content="noindex,nofollow"]');
export const FREE_DOWNGRADE_TYPEFORM_ID = window.freeDowngradeSurveyId;
export const ANY_DOWNGRADE_TYPEFORM_ID = window.anyDowngradeSurveyId;
export const KEYCLOAK_AUTH = window.keycloakAuth || false;
export const ADFS_AUTH = window.adfsAuth || false;
export const DISABLE_SURVEY = window.disableSurvey || false;
export const ALLOW_FLOWS = window.allowFlows || false;
export const ALLOW_AI = window.allowAI || false;
export const SSO_LOGIN_ONLY = window.ssoLoginOnly || false;
export const SELF_HOSTED = window.selfHosted || false;
export const ALLOW_RELATIVE_URLS = window.allowEditorRelUrls || false;

// EMBED BOARD + WIDGET
export const IS_IFRAME =
    (IS_VOTING_BOARD && !getBooleanRequestParam('preview') && (getBooleanRequestParam('iframe') || pageInIframe())) ||
    false;
export const IS_IFRAME_TRANSPARENT = (IS_IFRAME && getBooleanRequestParam('transparent')) || false;
export const IS_IFRAME_CUSTOM_AUTH = (IS_IFRAME && getBooleanRequestParam('customAuth')) || false;
export const IS_IFRAME_WIDGET = (IS_IFRAME && getBooleanRequestParam('widget')) || false;
export const IS_IFRAME_NEW_IDEA = (IS_IFRAME && getBooleanRequestParam('newIdea')) || false;
export const IS_IFRAME_MY_IDEAS = (IS_IFRAME && getBooleanRequestParam('myIdeas')) || false;
export const IS_IFRAME_MY_VOTES = (IS_IFRAME && getBooleanRequestParam('myVotes')) || false;

// When 404 on subdomain
export const MAIN_404_PAGE = window.is404?.isDomain404 || false;

export const UNSAVED_MODEL_ID = -1;

export const VIEWS = {
    TOP: 'top',
    EVALUATION: 'ag',
    MATRIX: 'matrix',
    TOTAL_ALIGNMENT: 'total-alignment',
    USERS_ALIGNMENT: 'users-alignment',
    CRITERIA_ALIGNMENT: 'criteria-alignment',
    ALIGNMENT: 'alignment',
    QUESTIONS: 'questions',
    REPORT: 'report',
    IDEAS: 'ideas',
    VOTING: 'voting',
    SCORES: 'scores',
    FOCUS_MODE: 'focus-mode',

    ERROR_404: 404,
    ERROR_403: 403,
    DASHBOARD: 'dashboard',
    INTEGRATIONS: 'integrations',
    CONNECT_TRACKER: 'connect-tracker',
    SYNC: 'sync',
    TEMPLATES: 'templates',
    RELEASE_NOTES: 'release-notes',
    DESCRIPTION: 'description',

    PUBLIC_ANNOUNCES: 'public-announces',
    PUBLIC_ANNOUNCE: 'public-announce',
    PUBLIC_RELEASE_NOTES: 'public-release-notes',
    PUBLIC_RELEASE_NOTE: 'public-release-note',

    PROFILE: 'profile',
    NOTIFICATIONS: 'notifications',
    CRITERIA: 'criteria',
};

export const VIEWS_DATA = {
    [VIEWS.TOP]: { icon: CustomIconName.TOP_PRIORITY, title: 'Backlog' },
    [VIEWS.EVALUATION]: { icon: CustomIconName.EVALUATION, title: 'Evaluation', tooltip: 'Score issues here' },
    [VIEWS.IDEAS]: { icon: CustomIconName.LAMP, title: 'Voting Board' },
    [VIEWS.VOTING]: { icon: CustomIconName.LAMP, title: 'Voting Board' },
    [VIEWS.SCORES]: { icon: CustomIconName.SCORES, title: 'Scores', tooltip: 'All rated issues' },
    [VIEWS.QUESTIONS]: { icon: CustomIconName.QUESTIONS, title: 'Questions' },
    [VIEWS.MATRIX]: { icon: CustomIconName.MATRIX, title: 'Matrix' },
    [VIEWS.ALIGNMENT]: {
        icon: CustomIconName.ALIGN_TOTAL,
        title: 'Team Alignment',
    },
    [VIEWS.USERS_ALIGNMENT]: {
        icon: CustomIconName.ALIGN_USERS,
        title: 'Users Alignment',
        tooltip: 'See the differences of opinion by teammates',
    },
    [VIEWS.CRITERIA_ALIGNMENT]: {
        icon: CustomIconName.ALIGN_CRITERIA,
        title: 'Criteria Alignment',
        tooltip: 'See the differences of opinion in criteria',
    },
    [VIEWS.FOCUS_MODE]: { icon: CustomIconName.FOCUS_MODE, title: 'Focus Mode' },
    [VIEWS.ERROR_404]: { title: '404 - Not Found' },
    [VIEWS.ERROR_403]: { title: '403 - Forbidden' },
    [VIEWS.REPORT]: { title: 'Report' },
    [VIEWS.DASHBOARD]: { title: 'Dashboard' },
    [VIEWS.INTEGRATIONS]: { title: 'Integrations' },
    [VIEWS.CONNECT_TRACKER]: { title: 'Connect issue tracker' },
    [VIEWS.SYNC]: { title: 'Sync Settings' },
    [VIEWS.TEMPLATES]: { title: 'Prioritization Templates' },
    [VIEWS.RELEASE_NOTES]: { title: 'Release notes', icon: CustomIconName.CHANGELOG },
    [VIEWS.DESCRIPTION]: { title: 'Description', icon: CustomIconName.INFO },
    [VIEWS.PROFILE]: { title: 'Profile', icon: CustomIconName.PROFILE },
    [VIEWS.NOTIFICATIONS]: { title: 'Notifications', icon: CustomIconName.NOTIFICATIONS },
    [VIEWS.CRITERIA]: { title: 'Global Criteria Management', icon: CustomIconName.CRITERIA },
};

export const PROVIDER_JIRA = 'jira';
export const PROVIDER_JIRA_CUSTOM = 'jira_custom';
export const PROVIDER_TRELLO = 'trello';
export const PROVIDER_ASANA = 'asana';
export const PROVIDER_DUCALIS = 'ducalis';
export const PROVIDER_YT = 'youtrack';
export const PROVIDER_YT_CUSTOM = 'youtrack_custom';
export const PROVIDER_CLICKUP = 'clickup';
export const PROVIDER_LINEAR = 'linear';
export const PROVIDER_GITHUB = 'github';
export const PROVIDER_KAITEN = 'kaiten';

export const isJira = (platformName) => platformName === PROVIDER_JIRA || platformName === PROVIDER_JIRA_CUSTOM;
export const isYT = (platformName) => [PROVIDER_YT, PROVIDER_YT_CUSTOM].indexOf(platformName) !== -1;

export const YTPLAFORM = window.loadedPlatforms && window.loadedPlatforms.find((el) => isYT(el.provider));

export const PLATFORM_CREATION_YT = (YTPLAFORM && YTPLAFORM.id) || null;

export const POPOVER_PROPS_DIV = {
    targetTagName: 'div',
    wrapperTagName: 'div',
};

export const POPOVER_AI_PROPS = {
    popoverClassName: 'ai-popover-text',
    canEscapeKeyClose: false,
    hasBackdrop: false,
    inheritDarkTheme: false,
    interactionKind: 'click',
    isControlled: true,
    minimal: true,
    modifiers: {
        arrow: {
            enabled: false,
        },
        flip: {
            enabled: false,
        },
        preventOverflow: {
            enabled: true,
        },
    },
    fill: true,
    position: 'bottom-left',
    usePortal: false,
};

// TODO: remove by https://concertwithme.atlassian.net/browse/DCLS-6215
export const WEEKDAYS_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const WEEKDAYS_NAMES_ALT = ['', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const WEEKDAYS_NAMES_SHORT = ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const WEEKDAYS_MENU = [1, 2, 3, 4, 5, 6, 7].map((id) => ({ id, label: WEEKDAYS_NAMES_ALT[id] }));

export const OWNER_REQUESTS = 'owner-requests';
export const AUTHOR_REQUESTS = 'author-requests';
export const ANOTHER_REQUESTS = 'another-requests';

export const IS_APP = !!document.getElementById('root');

export const DICTIONARY_TYPES = 'issue-types';
export const DICTIONARY_STATUSES = 'issue-statuses';
export const DICTIONARY_LABELS = 'issue-labels';
export const DICTIONARY_LABELS_VOTING = 'voting-issue-label';
export const DICTIONARY_STATUSES_VOTING = 'idea-statuses';

export const SHOW_TOOLTIPS = 'showTooltips';
export const FAST_INPUT = 'fastInput';
export const SLIM_BOARD = 'slimBoard';

export const PAYWALL_LIMITS = {
    PUSH_ISSUE: 'push_issue',
    USERS_LIMIT: 'users_limit',
    ISSUES_LIMIT: 'issues_limit',
    VOTING_APPEARANCE: 'voting_appearance',
    VOTING_DOMAIN: 'voting_domain',
    GRID_FILTER: 'grid_filter',
    BOARDS_LIMIT: 'boards_limit',
    MATRIX: 'matrix',
    ALIGNMENT: 'alignment',
    CUSTOMISE_COLUMNS: 'customise_columns',
    VOTES_RESET: 'votes_reset',
    VOTES_RESTORE: 'votes_restore',
    CHANGE_ROLE: 'change_role',
    PRIVATE_BOARDS: 'private_boards',
    PRIVATE_VOTING_BOARD: 'private_voting_board',
    IDEA_TEMPLATE: 'idea_template',
    INTEGRATION_ADMIN: 'integration_admin',
    SYNC_AVAILABLE: 'sync_available',
    REMOVE_DUCALIS_BRANDING: 'remove_ducalis_branding',
    VOTING_ADD_TO_BACKLOG: 'voting_add_to_backlog',
    VOTING_ANALYTICS: 'voting_analytics',
    TWO_WAY_SYNC_REPORTS: 'two_way_sync_reports',
    TWO_WAY_SYNC_BOARDS: 'two_way_sync_boards',
    TWO_WAY_SYNC_CUSTOM_FIELDS: 'two_way_sync_custom_fields',
    BOARDS_REPORTS: 'boards_reports',
    VOTES_TEAM_RESET: 'votes_team_reset',
    SKIP_ISSUE_VOTE: 'skip_issue_vote',
    VOTING_VOTE_BEHALF: 'voting_vote_behalf',
    CUSTOM_VOTE: 'custom_vote',
    EVALUATION_CHANGE: 'evaluation_change',
    RESTRICT_SCORES_RE_EVALUATION: 'restrict_scores_re_evaluation',
    VOTING_VOTES_LABELS: 'voting_votes_labels',
    VOTING_CUSTOM_STATUSES: 'voting_custom_statuses',
    IDEA_STATUS_SYNC: 'idea_status_sync',
    ISSUE_DEPENDENCIES: 'issue_dependencies',
    RELEASE_WEBHOOKS: 'release_webhooks',
    CRITERIA_SCORE_BACKUPS: 'criteria_score_backups',
    TWO_WAY_SYNC_FIELDS_MAP: 'two_way_sync_fields_map',
    VOTES_MANUAL_RESET: 'votes_manual_reset',
    VOTING_GTM: 'voting_gtm',
    CRITERIA_BULK_ACTIONS: 'criteria_bulk_actions',
    EXPORT_ACCESS: 'export_access',
    EXPORT_LOGS: 'export_logs',
    VOTING_EMAIL_DOMAIN: 'voting_email_domain',
};

export const ISSUE_CARD_POSITION = {
    LEFT: 0,
    RIGHT: 1,
    FULL: 2,
};

export const ANOTHER_REASON = 'another-reason';

export const ERROR_TYPES = {
    'too-many-files': 'You can only upload one file',
    'file-invalid-type': 'Only *.jpeg and *.png images will be accepted',
    [ANOTHER_REASON]: 'Uploading error',
};

export const ACCEPT_FILES = {
    'image/png': [],
    'image/jpeg': [],
    'image/svg+xml': [],
    'image/webp': [],
};

export const FILTER_TYPES = {
    STRING: 'string',
    INT: 'int',
    TEXTAREA: 'textarea',
    DATETIME: 'datetime',
    DATE: 'date',
    CHECKBOX: 'checkbox',
    LIST: 'list',
    COMPLEX_SELECT: 'complex_select',
    USER: 'user',
    USER_LIST: 'user_list',
    TYPE_ATTACHMENT_SELECT: 'attachment_select',
};

export const FILTER_RULES = {
    all: 'all',
    any: 'any',
    neither: 'neither',
    notAll: 'notAll',
    before: 'before',
    after: 'after',
    gt: 'gt',
    lt: 'lt',
    gte: 'gte',
    lte: 'lte',
    contains: 'contains',
    notContains: 'notContains',
    is: 'is',
    not: 'not',
    empty: 'empty',
    notEmpty: 'notEmpty',
};

export const defaultPopoverProps = {
    minimal: true,
    boundary: 'window',
    position: 'bottom-left',
};

export const SORT_DIRECTION = { asc: 'ASC', desc: 'DESC' };

export const DEF_MAIN_COLOR = '#f1d17e';
export const DEF_TEXT_COLOR = '#282a2f';

export const FINAL_SCORE_EDIT_VARIANTS = {
    disabled: 'disabled',
    facilitator: 'facilitator',
    all: 'all',
};
export const FINAL_SCORE_EDIT_VARIANTS_DESC = {
    [FINAL_SCORE_EDIT_VARIANTS.disabled]: 'Nobody can change final scores',
    [FINAL_SCORE_EDIT_VARIANTS.facilitator]: 'Facilitators can change final scores',
    [FINAL_SCORE_EDIT_VARIANTS.all]: 'Everybody can change final scores',
};

export const AGGR = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    QUARTER: 'quarter',
    YEAR: 'year',
};

export const AGGR_NAME = {
    [AGGR.DAY]: 'Days',
    [AGGR.WEEK]: 'Weeks',
    [AGGR.MONTH]: 'Months',
    [AGGR.QUARTER]: 'Quarters',
    [AGGR.YEAR]: 'Years',
};

export const AGGR_ITEMS = [
    { id: AGGR.WEEK, label: AGGR_NAME[AGGR.WEEK] },
    { id: AGGR.MONTH, label: AGGR_NAME[AGGR.MONTH] },
];

export const AUTH_CODE_PLACEHOLDER = ['', '', '', '', '', ''];

export const DUCALIS_SESSION_ID = (IS_IFRAME && getSessionId()) || null;

export const USER_ROLE = {
    Member: 1,
    Viewer: 2,
    Admin: 3,
};

export const ROLES_GROUPS = {
    [USER_ROLE.Viewer]: 'Viewers',
    [USER_ROLE.Member]: 'Members',
    [USER_ROLE.Admin]: 'Admins',
};

export const ROLES = {
    [USER_ROLE.Viewer]: 'Viewer',
    [USER_ROLE.Member]: 'Member',
    [USER_ROLE.Admin]: 'Admin',
};

export const PAYMENT_STATUS = {
    cancelled: 'cancelled',
    stopped: 'stopped',
    trial_ended: 'trial_ended',
    trial: 'trial',
};

export const ANNOUNCE_STATUS = {
    DRAFT: 'draft',
    APPROVED: 'approved',
    AI_IN_PROGRESS: 'ai_in_progress',
    AI_READY: 'ai_ready',
    AI_ERROR: 'ai_error',
};

export const USER_STATUS = {
    pending: -1,
    active: 1,
    removed: 0,
};

export const SHORT_RELATIVE_TIME_OPTIONS = {
    style: 'short',
    unit: ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'],
};

export const EXPORT_ACCESS = {
    owner: 'owner',
    admins_members: 'admins_members',
    admins: 'admins',
};

export const EMPTY_ARRAY = [];
export const FAKE_LIST = Array(20).fill({ is_fake: true, id: uuidv4() });

export const MESSAGE_EMPTY = 'Nothing to prioritize! No issues found by the filter.';
export const MESSAGE_DONE =
    'Seems like you are going to import completed or test/review issues that no longer require prioritization.';

export const PLATFORM_FILTER_TYPE = {
    custom: 'custom', // ducalis filter
    platform: 'platform', // filter from tracker
    jql: 'jql', // jira jql filter
};

export const APP_STORAGE_KEYS = {
    board_sort: 'board.sort.',
    report_sort: 'report.sort.',
    columns_width: 'columns.width.v1',
    kanban_column: 'kanban.column.',
    timezones: 'timezones',
    video_banner: 'video.banner.',
    user_colors: 'user.colors',
    stripe_open: 'stripe.open.',
    issue_card_width: 'issue.card.width',
    dashboard_folder: 'dashboard.folder.',
    board_form: 'board.form.',
    settings_path: 'settings.path',
    config: 'config',
    aside_banner: 'aside.banner.',
    voting_action: 'voting.action',
    force: 'force',
    board_sync: 'board.sync',
    board_init: 'board.init',
    backup: 'backup.',
    templates: 'templates',
    trash: 'trash',
};
