import React from 'react';

import { observer } from 'mobx-react-lite';

import {
    GROUP_ITEM_AVERAGE,
    GROUP_ITEM_AVERAGE_ROUND_UP,
    MAIN_GROUP,
} from 'modules/SettingsDialog/SettingsDialogBoardPages/BoardCriteriaSettings/Criteria/Formulas/consts';

import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';

import DarkTooltip from 'components/DarkTooltip';
import PopoverTooltip from 'components/PopoverTooltip';
import SettingsLinkButton from 'components/SettingsLinkButton';

function FormulaTooltip({ board }) {
    const formulaKey = board?.settings?.formulaRound ? GROUP_ITEM_AVERAGE_ROUND_UP : GROUP_ITEM_AVERAGE;
    const formula = MAIN_GROUP.find((el) => el.key === formulaKey);

    const value = (
        <div className="t-w-lb">{utilsStore.settings.alignmentSlim ? formula?.shortName : formula?.name}</div>
    );

    if (!mainStore.currentUser?.isAdmin || !mainStore.activeBoard) {
        return (
            <DarkTooltip
                popoverClassName="limit limit-240"
                content={
                    <>
                        Calculated as <b>{formula?.name}</b> formula across each teammate’s score
                    </>
                }
            >
                {value}
            </DarkTooltip>
        );
    }

    return (
        <PopoverTooltip
            content={
                <div className="limit limit-240">
                    Calculated as <b>{formula?.name}</b>{' '}
                    <SettingsLinkButton
                        likeLink
                        minimal
                        active
                        inline
                        text="formula"
                        link={`/board/${mainStore.activeBoard.id}/formula`}
                    />{' '}
                    across each teammate’s score
                </div>
            }
        >
            {value}
        </PopoverTooltip>
    );
}

export default observer(FormulaTooltip);
