import restApi from 'api';
import { failRequest, logEvent, toUrl } from 'utils';

export async function removeLinking({ issueId, linked_issue_id }) {
    const data = toUrl({ linked_issue_id, issue_id: issueId });
    try {
        await restApi.delete('/issue/link-issue', { data });
    } catch (e) {
        failRequest(e);
    }
    logEvent('Remove linked/blocked issue');
}
