import React, { useCallback, useMemo } from 'react';

import { useHotkeys } from '@blueprintjs/core';

import { Button } from 'components/Button';
import Kbd, { KeyCombo } from 'components/Kbd';

function UndoButton({ onClick }) {
    const undoAction = useCallback(
        (e) => {
            e.preventDefault();
            onClick(e);
        },
        [onClick],
    );

    const hotkeys = useMemo(() => {
        return [
            {
                combo: 'mod+z',
                global: true,
                label: 'Undo',
                group: 'Table',
                onKeyDown: undoAction,
            },
        ];
    }, [undoAction]);

    useHotkeys(hotkeys);

    return (
        <Button
            className="flex-shrink"
            size={24}
            border
            id="undo-btn"
            text="Undo"
            onClick={undoAction}
            rightElm={
                <KeyCombo>
                    <Kbd>cmd</Kbd>
                    <Kbd>z</Kbd>
                </KeyCombo>
            }
        />
    );
}

export default UndoButton;
