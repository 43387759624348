import React, { useState } from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import Avatar from 'components/Avatar';
import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import { DisabledScoreValueTooltip } from 'components/DisabledScoreValueTooltip/DisabledScoreValueTooltip';
import Spinner from 'components/Spinner';
import Tooltip from 'components/Tooltip';

export const SkippedUser = observer(({ userId, issue }) => {
    const [loading, setLoading] = useState(false);
    const user = mainStore.users.usersIds.get(userId);
    if (!user) return null;

    async function handleResume() {
        setLoading(true);
        await issue.resumeEvaluation({ user_id: userId });
        setLoading(false);
    }

    if (user.id !== mainStore.currentUser.id && !mainStore.currentUser?.isAdmin) {
        return <Button as="div" block minimal leftElm={<Avatar size={20} user={user} />} text={user.name} />;
    }

    if (issue?.board?.disableEvaluation) {
        return (
            <DisabledScoreValueTooltip date={issue.board.sprint?.ends_at} isAdmin={mainStore.currentUser?.isAdmin}>
                <Button
                    disabled
                    border
                    block
                    minimal
                    leftElm={<Avatar size={20} user={user} />}
                    text={user.name}
                    rightIcon={CustomIconName.REWIND}
                />
            </DisabledScoreValueTooltip>
        );
    }

    return (
        <Tooltip content="Resume evaluation of this issue for this user" side="left">
            <Button
                onClick={handleResume}
                border
                block
                minimal
                disabled={loading}
                leftElm={<Avatar size={20} user={user} />}
                text={user.name}
                rightIcon={loading ? undefined : CustomIconName.REWIND}
                rightElm={loading ? <Spinner size={16} /> : null}
            />
        </Tooltip>
    );
});
