import React from 'react';

import { observer } from 'mobx-react-lite';
import { Link, useNavigate } from 'react-router-dom';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import delay from 'utils/delay';

import { AnchorButton } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import Emoji from 'components/Emoji';
import Flex from 'components/Flex';

export const IdeaInternalLink = observer(function IdeaInternalLink({ idea }) {
    const navigate = useNavigate();
    const emojiBoard = <Emoji emoji={idea.board.voting_settings.emoji} style={{ fontSize: 16 }} />;

    function handleClick(e) {
        e.preventDefault();
        navigate(`${idea.board.basePath}/public-ideas`);
        delay(300).then(() => issuesList.setActiveIssue(idea));
    }

    if (idea.board_id === mainStore.activeBoard?.id) {
        return (
            <Flex center css={{ width: 20 }}>
                <CustomIcon icon={CustomIconName.LAMP} />
            </Flex>
        );
    }

    return (
        <DarkTooltip
            popoverClassName="limit limit-300"
            content={`Open the idea on the “${idea.board.publicFullName}” (The idea attached at the other voting board)`}
            position="left"
            fast
        >
            <AnchorButton
                center
                onClick={handleClick}
                border
                minimal
                size={20}
                leftElm={emojiBoard}
                css={{ padding: 0 }}
                as={Link}
                to={idea.internalHref}
            />
        </DarkTooltip>
    );
});
