import { Intent } from '@blueprintjs/core';

import validateVote, { getVoteError } from 'utils/validateVote';

import { errorSingleNotification } from 'components/AppToaster';

import { globalNavigation } from './globalNavigation';

export const onBlur = (event, row, criterion, activeIssue, oldValue, setVote) => {
    const value = event.target.value;
    globalNavigation(true);

    if (validateVote(value || '', criterion, true)) {
        setVote(value);
        const old = oldValue !== null ? oldValue.toString() : oldValue;

        if (old !== value) {
            activeIssue.vote({ criterion, vote: value });
        }
    } else {
        errorSingleNotification.show({ message: getVoteError(criterion), intent: Intent.DANGER, timeout: 4000 });
        event.target.focus();
        return false;
    }
};
