import { FIELDS_NAME } from 'components/BoardHistory/constants';

export function flatBoardHistory(history, field, data) {
    if (['notifications', 'settings'].includes(field)) {
        return ((history[field] && Object.keys(history[field])) || []).map((fld) => {
            return flatBoardHistory(history[field], fld, data[field]);
        });
    } else if (['voting_slack_data', 'slack_data'].includes(field)) {
        return {
            key: field,
            field,
            content: FIELDS_NAME[field] || `<b>${field}</b>`,
            history: { ...history[field].channel, value: data[field].channel },
        };
    } else if (history && typeof history[field] !== 'undefined') {
        return {
            key: field,
            field,
            content: FIELDS_NAME[field] || `<b>${field}</b>`,
            history: { ...history[field], value: data[field] },
        };
    }
    return null;
}
