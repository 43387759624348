import React from 'react';

import { observer } from 'mobx-react-lite';

import AssigneeElm from 'modules/InsideIssue/components/AssigneeElm';
import DueDate from 'modules/InsideIssue/components/DueDate';
import { Status } from 'modules/InsideIssue/components/index';
import Type from 'modules/InsideIssue/components/Type';

import Flex from 'components/Flex';

import { IssueMenu } from './IssueMenu';
import LinearCycle from './LinearCycle';
import LinearProject from './LinearProject';
import SprintInfo from './SprintInfo';

import styles from './HeaderMeta.module.sass';

function HeaderMeta({ children, isPreview }) {
    return (
        <Flex gap={24} spaceBetween align="none" className={styles.meta}>
            <Flex gap={24}>
                <Status />

                <Type />

                <LinearCycle />

                <SprintInfo />

                <AssigneeElm />

                <DueDate />

                <LinearProject />
            </Flex>

            <Flex data-place="actions" column align="none" gap={1} shrink css={{ marginLeft: 'auto' }}>
                {children}
                {!isPreview && <IssueMenu />}
            </Flex>
        </Flex>
    );
}

export default observer(HeaderMeta);
