import React, { useState, useMemo } from 'react';

import { useHotkeys } from '@blueprintjs/core';

import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import CustomImageFullscreen from 'components/CustomImageFullscreen';
import DarkTooltip from 'components/DarkTooltip';
import DotDivider from 'components/DotDivider';
import Kbd from 'components/Kbd';

export function ImageToolbar({ editor }) {
    const [image, setImage] = useState('');

    const isActive = editor.isActive('image') && editor.isFocused;

    const hotkeys = useMemo(() => {
        if (!isActive && !image) {
            return [];
        }
        return [
            {
                combo: 'space',
                label: `Fullscreen image`,
                preventDefault: true,
                stopPropagation: true,
                allowInInput: true,
                global: true,
                group: 'Image',
                onKeyDown: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    logEvent('Use hot key: Full screen Img');
                    previewImage();
                    return false;
                },
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, image]);

    useHotkeys(hotkeys);

    function onClose() {
        setImage('');
        editor.chain().focus().run();
    }

    function previewImage() {
        const a = editor.getAttributes('image');
        const src = `${a.src}?full=1`;
        if (image === src) {
            return onClose();
        }
        setImage((state) => {
            if (state === src) {
                onClose();
                return '';
            }
            return src;
        });
    }

    return (
        <>
            <CustomImageFullscreen onClose={onClose} imageSrc={image} />

            <DarkTooltip
                fast
                position="top-right"
                content={
                    <>
                        Fullscreen
                        <DotDivider />
                        <Kbd>Space</Kbd>
                    </>
                }
            >
                <div style={{ background: 'var(--colors-bg2)', borderRadius: 4 }}>
                    <Button size={24} border icon={CustomIconName.EXPAND} onClick={previewImage} />
                </div>
            </DarkTooltip>
        </>
    );
}
