import React from 'react';

import ImageWrapper from 'components/ImageWrapper';

import styles from './Loom.module.sass';

export default function Loom({ file }) {
    if (!file) {
        return null;
    }
    return (
        <ImageWrapper className={styles.wrapper}>
            <iframe
                title="loom video"
                src={`https://www.loom.com/embed/${file}?hide_owner=true&hide_speed=true&hide_share=true`}
                frameBorder="0"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            />
        </ImageWrapper>
    );
}
