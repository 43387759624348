import React from 'react';

import { observer } from 'mobx-react-lite';

import { PROVIDER_ASANA, PROVIDER_CLICKUP, PROVIDER_JIRA, PROVIDER_TRELLO, SERVICE_NAME } from 'utils/consts';
import logEvent from 'utils/logEvent';

import ButtonLink from 'components/ButtonLink';
import { CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import Flex from 'components/Flex';
import PlatformIcon from 'components/PlatformIcon';

const FakePushToTracker = ({ boardId }) => {
    return (
        <DarkTooltip position="left" content={`Connect tracker and push ${SERVICE_NAME} issues to it`} fast>
            <ButtonLink
                onClick={() => logEvent('Click - Fake Push to Task Tracker')}
                to={`/integration/${boardId}`}
                icon={CustomIconName.ARROW_RIGHT}
                rightElm={
                    <Flex wrap css={{ width: 20 }} className="flex-shrink">
                        <PlatformIcon size={10} provider={PROVIDER_JIRA} />
                        <PlatformIcon size={10} provider={PROVIDER_ASANA} />
                        <PlatformIcon size={10} provider={PROVIDER_TRELLO} />
                        <PlatformIcon size={10} provider={PROVIDER_CLICKUP} />
                    </Flex>
                }
                minimal
                block
                title="Push to Task Tracker"
                size={30}
            />
        </DarkTooltip>
    );
};

export default observer(FakePushToTracker);
