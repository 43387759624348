import React, { useCallback, useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';

import VotersList from 'modules/IdeaVotersList';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Space from 'components/Space';

import StatisticTitle from './StatisticTitle';
import StatsDetails from './StatsDetails';

export const VotingIssueUsersTable = observer(({ idea }) => {
    const [open, setOpen] = useState(false);

    const handleClose = useCallback(() => setOpen(false), []);
    const handleClick = useCallback(() => setOpen((state) => !state), []);

    const hideComponent = !idea?.votersCount && !idea.isDone;

    useEffect(() => {
        setOpen((state) => (state && hideComponent ? false : state));
    }, [hideComponent]);

    if (hideComponent) return null;

    const showStats = idea.vote_stats && idea.isDone;
    let title = idea.isDone ? <StatisticTitle idea={idea} /> : `Voters’ List (${idea.votersCount})`;

    return (
        <>
            <Space height={15} />

            <Button
                text={title}
                icon={showStats ? undefined : CustomIconName.TEAM_ALT}
                rightIcon={CustomIconName.CHEVRON_RIGHT}
                border
                size={24}
                onClick={handleClick}
            />

            {showStats && <StatsDetails idea={idea} />}

            <VotersList idea={idea} open={open} onClose={handleClose} />
        </>
    );
});
