import React from 'react';

import { observer } from 'mobx-react-lite';

import styles from './VoteInput.module.sass';

export const DisabledVoteInput = observer(({ criterion, columnIndex, issue, rowIndex }) => {
    const value = issue.userVotes?.get(criterion) ?? '';

    const id = `i${rowIndex}${columnIndex}`;

    return (
        <div className="flex-grow">
            <input className={styles.input} disabled id={id} value={value} />
        </div>
    );
});
