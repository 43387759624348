import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { PAYWALL_LIMITS } from 'utils/consts';

import Flex from 'components/Flex';
import MasterTable from 'components/MasterTable';
import Space from 'components/Space';

import { useTableColumns } from './hooks/useTableColumns';
import { useUserFilter } from './hooks/useUserFilter';
import { StatsViewersDetails } from './StatsViewersDetails';
import VotersListCustomFieldsBanner from './VotersListCustomFieldsBanner';
import VotersListDialogHeader from './VotersListDialogHeader';
import VotersListDialogRemoveVoter from './VotersListDialogRemoveVoter';

import styles from './IdeaVotersList.module.sass';

export const VotersListTable = observer(({ onClose, idea }) => {
    const [loading, setLoading] = useState(true);
    const [query, setQuery] = useState('');
    const [list, setList] = useState([]);
    const [customFields, setCustomFields] = useState({});
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);

    const users = useUserFilter(query, list);
    const showStats =
        idea.vote_stats && idea.isDone && mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.VOTING_ANALYTICS);
    const columns = useTableColumns(customFields, showStats, setOpenRemoveDialog, idea.last_status_update);

    async function getVoters() {
        const { companies_custom_fields, users } = await idea.fetchVoters();
        setCustomFields((state) => companies_custom_fields || state);
        setList(users);
        setLoading(false);
    }

    useEffect(() => {
        getVoters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hideBanner = mainStore.currentUser.closed_notes.includes('voters-custom-fields');

    return (
        <>
            <Flex column css={{ padding: 16, paddingBottom: 0, height: '100%' }} align="none">
                <VotersListDialogHeader
                    onClose={onClose}
                    setQuery={setQuery}
                    query={query}
                    idea={idea}
                    loading={loading}
                />

                {customFields.length === 0 && !hideBanner ? <VotersListCustomFieldsBanner /> : <Space height={12} />}

                {showStats && <StatsViewersDetails idea={idea} />}

                <Flex grow column style={{ width: '100%', marginTop: 5 }} align="none">
                    <MasterTable
                        className={styles.table}
                        rowClassNames={(item) => (!item.is_active ? styles.notActiveRow : styles.row)}
                        list={users}
                        tableName="voters-list"
                        columns={columns}
                        board={idea.board}
                        disabled
                        showHover
                        rowProps={{ btnHover: true }}
                    />
                </Flex>
            </Flex>

            {!!openRemoveDialog && (
                <VotersListDialogRemoveVoter
                    setOpenRemoveDialog={setOpenRemoveDialog}
                    setList={setList}
                    openRemoveDialog={openRemoveDialog}
                    ideaId={idea.id}
                />
            )}
        </>
    );
});
