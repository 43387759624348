import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';

import { IS_VOTING_BOARD } from 'utils/consts';

export const render = (ListComponent) => () => {
    let reactRenderer;
    let popup;

    return {
        onStart: (props) => {
            reactRenderer = new ReactRenderer(ListComponent, {
                props,
                editor: props.editor,
            });

            if (!props.clientRect) {
                return;
            }

            popup = tippy('body', {
                getReferenceClientRect: props.clientRect,
                appendTo:
                    props.editor.view.dom.closest('[data-radix-scroll-area-viewport] > div') ||
                    props.editor.view.dom.parentElement,
                content: reactRenderer.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: IS_VOTING_BOARD ? 'top-start' : 'bottom-start',
                onShow: () => {
                    try {
                        props.clientRect?.();
                    } catch {
                        return false;
                    }
                },
            });
        },

        onUpdate(props) {
            try {
                reactRenderer.updateProps(props);

                if (!props.clientRect) {
                    return;
                }

                popup[0].setProps({
                    getReferenceClientRect: props.clientRect,
                });
            } catch (e) {
                console.error(e);
            }
        },

        onKeyDown(props) {
            if (props.event.key === 'Escape') {
                popup[0].hide();

                return true;
            }

            return reactRenderer?.ref?.onKeyDown(props);
        },

        onExit() {
            try {
                popup[0].destroy();
                reactRenderer.destroy();
            } catch (e) {
                console.error(e);
            }
        },
    };
};
