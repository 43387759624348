import React from 'react';

import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { UNSAVED_MODEL_ID } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import PushToButton from 'components/PushToButton';

import { MergeBtn } from './MergeBtn';

function SingleVotingActions({ issue }) {
    if (issue.issue_id) {
        if (issue.parentIssue === undefined) {
            return (
                <Flex column align="none" gap={6}>
                    <div className="flex flex-a-center">
                        <h3 style={{ margin: '2px 10px 0px 0' }}>
                            Not Found Linked Issue - {issuesList.idea.issue_id}
                        </h3>
                        {!mainStore.currentUser?.isViewer && (
                            <Button size={24} border text="Unlink" onClick={() => issue.unMergeWithIssue()} />
                        )}
                    </div>
                    <div className="t-mutted">Linked Issue could be removed from platform</div>
                </Flex>
            );
        }

        return <Flex gap={4} grow />;
    }

    return (
        <Flex gap={4} grow>
            <CustomIcon className="t-intent-yellow" icon={CustomIconName.WARNING} />
            <strong className="t-intent-yellow bp5-text-large">Idea is not at backlog</strong>
            <div className="flex-grow" />

            <Flex gap={12} shrink>
                <PushToButton disabled={mainStore.currentUser?.isViewer || issue.id === UNSAVED_MODEL_ID} />
                <MergeBtn />
            </Flex>
        </Flex>
    );
}

export default observer(SingleVotingActions);
