export const ORG_DB_VERSION = 1;

/**
 * Enum for Org DB stores name
 *
 * @readonly
 * @enum {string}
 */
export const ORG_DB_STORES = {
    users: 'users',
    boards: 'boards',
    votedPercents: 'votedPercents',
    platforms: 'platforms',
    reports: 'reports',
    announces: 'announces',
    comments: 'comments',
    issuesLinks: 'issuesLinks',
    IssueStatus: 'IssueStatus',
    IdeaLabel: 'IdeaLabel',
    IdeaStatus: 'IdeaStatus',
    IssueLabel: 'IssueLabel',
    IssueType: 'IssueType',
    IdeaAnnounceLabel: 'IdeaAnnounceLabel',
    issues: 'issues',
    issuesScore: 'issuesScore',
    ideas: 'ideas',
    releaseNotes: 'releaseNotes',
    criteria: 'criteria',
    criteriaBoards: 'criteriaBoards',
};

export function updateOrgDB(db) {
    db.createObjectStore(ORG_DB_STORES.users, { keyPath: 'id' });
    db.createObjectStore(ORG_DB_STORES.boards, { keyPath: 'id' });
    db.createObjectStore(ORG_DB_STORES.votedPercents, { keyPath: 'id' });
    db.createObjectStore(ORG_DB_STORES.platforms, { keyPath: 'id' });
    db.createObjectStore(ORG_DB_STORES.reports, { keyPath: 'id' });
    db.createObjectStore(ORG_DB_STORES.announces, { keyPath: 'id' });
    db.createObjectStore(ORG_DB_STORES.comments, { keyPath: 'id' });
    db.createObjectStore(ORG_DB_STORES.issuesLinks, { keyPath: 'id' });

    db.createObjectStore(ORG_DB_STORES.IssueStatus, { keyPath: 'id' });
    db.createObjectStore(ORG_DB_STORES.IssueLabel, { keyPath: 'id' });
    db.createObjectStore(ORG_DB_STORES.IssueType, { keyPath: 'id' });

    const ideaLabelStore = db.createObjectStore(ORG_DB_STORES.IdeaLabel, { keyPath: 'id' });
    ideaLabelStore.createIndex('boardId', 'board_id');

    const ideaStatusStore = db.createObjectStore(ORG_DB_STORES.IdeaStatus, { keyPath: 'id' });
    ideaStatusStore.createIndex('boardId', 'board_id');

    const ideaAnnounceLabelStore = db.createObjectStore(ORG_DB_STORES.IdeaAnnounceLabel, { keyPath: 'id' });
    ideaAnnounceLabelStore.createIndex('boardId', 'board_id');

    const issuesStore = db.createObjectStore(ORG_DB_STORES.issues, { keyPath: ['boardId', 'id'] });
    issuesStore.createIndex('boardId', 'boardId');

    const issuesScoreStore = db.createObjectStore(ORG_DB_STORES.issuesScore, { keyPath: ['boardId', 'id'] });
    issuesScoreStore.createIndex('boardId', 'boardId');

    const ideas = db.createObjectStore(ORG_DB_STORES.ideas, { keyPath: 'id', autoIncrement: false });
    ideas.createIndex('boardId', 'boardId');

    const releaseNotesStore = db.createObjectStore(ORG_DB_STORES.releaseNotes, { keyPath: 'id', autoIncrement: false });
    releaseNotesStore.createIndex('boardId', 'board_id');

    db.createObjectStore(ORG_DB_STORES.criteria, { keyPath: 'id', autoIncrement: false });

    const criteriaBoardsStore = db.createObjectStore(ORG_DB_STORES.criteriaBoards, {
        keyPath: 'id',
        autoIncrement: false,
    });
    criteriaBoardsStore.createIndex('board_id', 'board_id');
    criteriaBoardsStore.createIndex('criterion_id', 'criterion_id');
}
