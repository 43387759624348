import React from 'react';

import { observer } from 'mobx-react-lite';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Kbd, { KeyCombo } from 'components/Kbd';

function CopyLinkBtn({ issue }) {
    return (
        <Button
            data-place="copy"
            onClick={issue.copyLink}
            block
            className="bp5-popover-dismiss"
            text="Copy Link"
            minimal
            icon={CustomIconName.LINK}
            rightElm={
                <KeyCombo>
                    <Kbd>cmd</Kbd> <Kbd>shift</Kbd> <Kbd>,</Kbd>
                </KeyCombo>
            }
        />
    );
}

export default observer(CopyLinkBtn);
