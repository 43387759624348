import React from 'react';

import { observer } from 'mobx-react-lite';

import IssueEpic from 'modules/InsideIssue/components/IssueEpic';

import { utilsStore } from 'store/models/UtilsStore';

import IssueName from 'components/IssueName';
import Space from 'components/Space';

function Title({ issue, editable }) {
    const autoFocus = (issue.needFocus || issue.id === -1) && !utilsStore.cardIsHidden;

    return (
        <>
            <IssueEpic issue={issue} />
            <IssueName issue={issue} editable={editable} placeholder="Add Issue Name" autoFocus={autoFocus} />
            <Space height={16} />
        </>
    );
}

export default observer(Title);
