import React, { forwardRef, useCallback } from 'react';

import highlightText from 'utils/highlightText';

import Avatar from 'components/Avatar';
import { Button } from 'components/Button';
import Flex from 'components/Flex';
import { activeItemIndex } from 'components/TextEditor/Extensions/Mention/MentionList/activeItemIndex';
import VirtuosoList from 'components/VirtuosoList';

import { useMentionListNavigator } from './useMentionListNavigator';

const MentionList = forwardRef(({ query, ...props }, ref) => {
    const { selectedIndex, selectItem } = useMentionListNavigator(props.items, props.command, ref);

    const renderItem = useCallback(
        (item, index) => {
            return (
                <Button
                    block
                    minimal
                    key={item.id}
                    active={index === selectedIndex}
                    onClick={() => selectItem(index)}
                    leftElm={<Avatar size={24} user={item} />}
                >
                    {highlightText(item.name, query)}
                </Button>
            );
        },
        [selectedIndex, query, selectItem]
    );

    return (
        <Flex
            ref={ref}
            column
            align="none"
            css={{ padding: 4, background: '$bg2', borderRadius: 4, border: '1px solid $grayA4' }}
        >
            <VirtuosoList
                noResults={<Button minimal block disabled={true} text="No results." />}
                className="hide-v-scroll"
                sizeItem={30}
                activeItemIndex={activeItemIndex}
                filteredItems={props.items}
                activeItem={props.items?.[selectedIndex]}
                maxCount={6}
                renderItem={renderItem}
            />
        </Flex>
    );
});

export default MentionList;
