import { runInAction, toJS } from 'mobx';

import { rootApi } from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function pushNotificationsSettings(props) {
    logEvent('Change subscriptions', { props });

    try {
        let requestData;
        if (props.type) {
            requestData = this.notificationsSettings.map((item) => {
                const el = toJS(item);
                if (el.type === props.type) {
                    const result = { type: el.type };

                    if (['evaluation_reminder', 'issue_activity'].indexOf(el.type) !== -1) {
                        result.data = { ...(el.data || {}), ...(props.data || {}) };
                    }

                    if (el.channels) {
                        result.channels = { ...el.channels, ...(props.channels || {}) };
                    }

                    return result;
                }
                return { type: el.type, channels: el.channels, data: el.data };
            });
        } else {
            requestData = [{ channels: { mail: props.checked } }];
        }

        const { data } = await rootApi.post('/notifications/subscribe', toUrl({ settings: requestData }), {
            params: { k: this.userKey },
        });
        runInAction(() => {
            this.notificationsSettings = data;
        });
    } catch (e) {
        failRequest(e);
    } finally {
        props.onFinally && props.onFinally();
    }
}
