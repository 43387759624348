import React from 'react';

import { observer } from 'mobx-react-lite';

import CellAlignmentContent from 'components/MasterTable/components/CellAlignment/CellAlignmentContent';
import Tooltip from 'components/Tooltip';

import { UsersVotesTableTooltipContent } from './UsersVotesTableTooltipContent';

export const AlignCriterionCell = observer(({ issue, criterionId }) => {
    if (!issue.externalData?.criteriaAlignmentIds?.has(criterionId)) return null;

    const fieldRed = 'alignmentRed';
    const fieldYellow = 'alignmentYellow';

    return (
        <Tooltip debug content={<UsersVotesTableTooltipContent issue={issue} crId={criterionId} />}>
            <CellAlignmentContent
                value={issue.externalData.criteriaAlignmentIds.get(criterionId)}
                fieldRed={fieldRed}
                fieldYellow={fieldYellow}
            />
        </Tooltip>
    );
});
