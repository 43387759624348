import { globalNavigation } from './globalNavigation';

export const onFocus = (event) => {
    event.preventDefault();
    globalNavigation(false);
    const target = event.target;

    setTimeout(() => {
        document.activeElement === target && target.select();
    }, 0);
};
