import delay from 'utils/delay';

export function focusNext(event, el) {
    if (el && !el.disabled) {
        event.stopPropagation();
        const selectorSmart = `input[data-x="${el.getAttribute('data-x')}"][data-y="${el.getAttribute('data-y')}"]`;
        delay(0).then(() => document.querySelector(selectorSmart)?.focus());
    } else if (el && el.disabled) {
        const row = el.getAttribute('data-row');
        const col = el.getAttribute('data-col');
        const nextEl = document.querySelector(`#i${+row + 1}${col}`);
        if (nextEl) {
            event.stopPropagation();
            setTimeout(() => nextEl.focus(), 0);
        }
    }
}
