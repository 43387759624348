/**
 * debounce(func, [wait=0], [options={}])
 *
 * @param {Function} func The function to debounce.
 * @param {number} [wait=0] The number of milliseconds to delay.
 * @param {Object} [options={}] The options object.
 * @param {boolean} [options.leading=false] Specify invoking on the leading edge of the timeout.
 * @param {cancelObj} [options.cancelObj='canceled'] Specify the error object to be rejected.
 * @returns {Function} Returns the new debounced function.
 */
function DebouncePromise(func, wait = 0, { leading = false, cancelObj = 'canceled' } = {}) {
    let timerId, latestResolve, shouldCancel;

    return function (...args) {
        if (!latestResolve) {
            // The first call since last invocation.
            return new Promise((resolve, reject) => {
                latestResolve = resolve;
                if (leading) {
                    invokeAtLeading.apply(this, [args, resolve, reject]);
                } else {
                    timerId = setTimeout(invokeAtTrailing.bind(this, args, resolve, reject), wait);
                }
            });
        }

        shouldCancel = true;
        return new Promise((resolve, reject) => {
            latestResolve = resolve;
            timerId = setTimeout(invokeAtTrailing.bind(this, args, resolve, reject), wait);
        });
    };

    function invokeAtLeading(args, resolve, reject) {
        func.apply(this, args).then(resolve).catch(reject);
        shouldCancel = false;
        latestResolve = null;
    }

    function invokeAtTrailing(args, resolve, reject) {
        if (shouldCancel && resolve !== latestResolve) {
            reject(cancelObj);
        } else {
            func.apply(this, args).then(resolve).catch(reject);
            shouldCancel = false;
            clearTimeout(timerId);
            timerId = latestResolve = null;
        }
    }
}

const DefaultOptions = {
    // One distinct debounced function is created per key and added to an internal cache
    // By default, the key is null, which means that all the calls
    // will share the same debounced function
    key: () => null,

    // By default, a debounced function will only resolve
    // the last promise it returned
    // Former calls will stay unresolved, so that you don't have
    // to handle concurrency issues in your code
    // Setting this to false means all returned promises will resolve to the last result
    onlyResolvesLast: true,
};

export function createImperativePromise(promiseArg) {
    let resolve = null;
    let reject = null;

    const wrappedPromise = new Promise((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
    });

    promiseArg &&
        promiseArg.then(
            (val) => {
                resolve && resolve(val);
            },
            (error) => {
                reject && reject(error);
            }
        );

    return {
        promise: wrappedPromise,
        resolve: (value) => {
            resolve && resolve(value);
        },
        reject: (reason) => {
            reject && reject(reason);
        },
        cancel: () => {
            resolve = null;
            reject = null;
        },
    };
}

function onlyResolvesLast(asyncFunction) {
    let cancelPrevious = null;

    return (...args) => {
        cancelPrevious && cancelPrevious();
        const initialPromise = asyncFunction(...args);
        const { promise, cancel } = createImperativePromise(initialPromise);
        cancelPrevious = cancel;
        return promise;
    };
}

// We create a debouncing function cache, because when wrapping the original function,
// we may actually want to route the function call to different debounced functions depending function paameters
class DebounceCache {
    constructor(config) {
        this.config = config;
        this.debounceSingleton = null;
        this.debounceCache = {}; // when key feature is used
    }

    _createDebouncedFunction() {
        let debouncedFunc = DebouncePromise(this.config.func, this.config.wait, this.config.options);
        if (this.config.options.onlyResolvesLast) {
            debouncedFunc = onlyResolvesLast(debouncedFunc);
        }
        return {
            func: debouncedFunc,
        };
    }

    getDebouncedFunction(args) {
        const key = this.config.options.key(...args);
        if (key === null || typeof key === 'undefined') {
            if (!this.debounceSingleton) {
                this.debounceSingleton = this._createDebouncedFunction();
            }
            return this.debounceSingleton;
        } else {
            if (!this.debounceCache[key]) {
                this.debounceCache[key] = this._createDebouncedFunction();
            }
            return this.debounceCache[key];
        }
    }
}

function AwesomeDebouncePromise(func, wait, options) {
    const finalOptions = {
        ...DefaultOptions,
        ...options,
    };

    const debounceCache = new DebounceCache({
        func,
        wait,
        options: finalOptions,
    });

    const AwesomeDebouncePromiseWrapper = (...args) => {
        const debouncedFn = debounceCache.getDebouncedFunction(args).func;
        return debouncedFn(...args);
    };

    /*
    AwesomeDebouncePromiseWrapper.cancel = (key?: string) => {};
    */
    return AwesomeDebouncePromiseWrapper;
}

export default AwesomeDebouncePromise;
