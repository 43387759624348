import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FormGroup, InputGroup } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';
import { isSingleNumberCriterion } from 'store/models/utils';
import { utilsStore } from 'store/models/UtilsStore';

import { FAST_INPUT, SHOW_TOOLTIPS } from 'utils/consts';

import CriterionDesc from 'components/CriterionDesc/CriterionDesc';
import DotDivider from 'components/DotDivider';
import Flex from 'components/Flex';
import Kbd, { KeyCombo } from 'components/Kbd';
import Tooltip from 'components/Tooltip';

import { onBlur } from './onBlur';
import { onKeyDown } from './onKeyDown';
import { setValue } from './setValue';

import styles from './VoteInput.module.sass';

const VoteInput = observer(({ criterion, id, issue, crCnt }) => {
    const [focus, setFocus] = useState(false);
    const [selected, setSelected] = useState(false);
    const value = issue?.userVotes?.get(criterion.id) ?? '';
    const [vote, setVote] = useState(value);

    const ref = useRef();

    useEffect(() => {
        if (focus) {
            ref.current.select();
            setSelected(true);
        }
    }, [ref, focus]);

    const fastInput = utilsStore.settings[FAST_INPUT];
    const showTooltips = utilsStore.settings[SHOW_TOOLTIPS];

    const isSingleNumber = useMemo(
        () =>
            isSingleNumberCriterion({
                type: criterion.scale_type,
                min: criterion.scale_min,
                max: criterion.scale_max,
                series: criterion.scale_series,
            }),
        [criterion.scale_type, criterion.scale_min, criterion.scale_max, criterion.scale_series],
    );
    const needAction = fastInput && !isSingleNumber;
    const singleFastInput = fastInput && isSingleNumber;

    const tabIndex = id + 1;
    const criterionItem = (
        <FormGroup label={criterion.name} key={criterion.id} inline>
            <InputGroup
                data-1p-ignore=""
                large
                inputRef={ref}
                autoFocus={id === 0}
                tabIndex={tabIndex}
                fill
                value={value}
                onKeyDown={(e) => onKeyDown(e, selected, tabIndex, setSelected, singleFastInput)}
                onBlur={(e) => onBlur(e, criterion, issue, crCnt, setFocus, setSelected, vote, setVote)}
                onFocus={() => setFocus(true)}
                onChange={(e) => setValue(e, criterion, issue, crCnt, singleFastInput)}
            />
        </FormGroup>
    );

    const TooltipDesc = useCallback(() => {
        if (!showTooltips && !needAction) {
            return null;
        }
        return showTooltips ? (
            <CriterionDesc boardId={mainStore.activeBoard?.id} criterion={criterion} needAction={needAction} />
        ) : (
            <>
                Next criterion
                <DotDivider />
                <KeyCombo>
                    <Kbd>tab</Kbd>
                    <DotDivider text="or" />
                    <Kbd>enter</Kbd>
                </KeyCombo>
            </>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showTooltips, needAction])();

    if (TooltipDesc) {
        return (
            <Flex>
                <Tooltip
                    open={focus}
                    contentClassName={styles.tooltip}
                    content={TooltipDesc}
                    side="right"
                    align="start"
                >
                    <div>{criterionItem}</div>
                </Tooltip>
            </Flex>
        );
    }

    return criterionItem;
});

export default VoteInput;
