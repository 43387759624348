import { styled } from 'stitches.config';

export const StFakeValueInput = styled('div', {
    $$shadowColorDef: '$colors$grayA5',
    $$shadowColorHover: '$colors$grayA7',
    $$shadowSuccess: '$colors$success',
    position: 'absolute',
    left: -6,
    top: -2,
    right: -6,

    'input.bp5-input': {
        maxHeight: 26,
        minHeight: 26,
        padding: '0 6px !important',
    },

    variants: {
        focus: {
            true: {},
            false: {
                'input.bp5-input': {
                    background: 'none !important',
                    boxShadow: '0 0 0 1px $$shadowColorDef !important',
                    '&:not(:disabled):hover': {
                        boxShadow: '0 0 0 1px $$shadowColorHover !important',
                    },
                },
                '.bp5-input-group.bp5-intent-success input.bp5-input': {
                    boxShadow: '0 0 0 1px $$shadowSuccess !important',
                },
            },
        },
    },
});
