import React from 'react';

import DarkTooltip from 'components/DarkTooltip';

export default function DomTooltip({ content, inner }) {
    const htmlContent = <div dangerouslySetInnerHTML={{ __html: content }} />;
    return (
        <DarkTooltip content={htmlContent}>
            <span dangerouslySetInnerHTML={{ __html: inner }} />
        </DarkTooltip>
    );
}
