import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function saveFilter() {
    const isNew = !this.filter.id;
    try {
        const { data } = await api.post(`${this.apiEndpoint}/search/save`, toUrl(this.filter.getClearJson()));
        this.filter.clearForm();
        if (isNew) {
            this.filter.setId(data.id);

            runInAction(() => {
                if (!this.searches.some((filter) => filter.id === data.id)) {
                    this.searches.push(data);
                }
            });
        }
    } catch (e) {
        failRequest(e);
    }
}
