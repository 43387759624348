import { IS_VOTING_BOARD, MAIN_SITE_URL } from 'utils/consts';
import { getRedirectAuthUrl } from 'utils/getRedirectAuthUrl';
import toUrl from 'utils/toUrl';

export function getAuthButtonUrl({ isLogin, authClient = 'google' }) {
    const url = IS_VOTING_BOARD ? window.votingSiteUrl : MAIN_SITE_URL;

    const search = toUrl({
        authclient: authClient,
        scenario: isLogin ? 'login' : 'default',
        redirect: getRedirectAuthUrl(),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        lang: window.navigator.language || 'en-US',
    });

    return `${url}/login/auth?${search}`;
}
