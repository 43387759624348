import React from 'react';

import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';

import EditView from './EditView';
import ReadOnlyView from './ReadOnlyView';

function PublicVotingIssue() {
    if (issuesList.idea?.isEditMode) {
        return <EditView />;
    }

    return <ReadOnlyView />;
}

export default observer(PublicVotingIssue);
