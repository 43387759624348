import React from 'react';

import { Switch, Alignment } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { utilsStore } from 'store/models/UtilsStore';

import Flex from 'components/Flex';

function Switcher({ disabled = false, icon, field, label, alignIndicator = Alignment.LEFT, style }) {
    const settings = utilsStore.settings;
    const value = settings[field];

    function onChange() {
        utilsStore.updateSettings({ field, value: !value });
    }

    let labelEl;
    if (icon) {
        labelEl = (
            <Flex gap={4} inline>
                {icon}
                {label}
            </Flex>
        );
    } else {
        labelEl = label;
    }

    return (
        <Switch
            style={style}
            disabled={disabled}
            alignIndicator={alignIndicator}
            labelElement={labelEl}
            checked={value}
            onChange={onChange}
            name={field}
        />
    );
}

export default observer(Switcher);
