import api from 'api';
import { failRequest } from 'utils';

import { mainStore } from 'store/models/MainStore';

import logEvent from 'utils/logEvent';

import { GhostNotification } from 'components/AppToaster';

export async function changeWatcher(user, isAdd = false) {
    let fnRequest;
    if (isAdd) {
        logEvent('Edit watchers', { action: 'add' });
        fnRequest = api.post;
        mainStore.addWatcher({ issue_id: this.id, user_id: user.id });
    } else {
        logEvent('Edit watchers', { action: 'remove' });
        fnRequest = api.delete;
        mainStore.removeWatcher({ issue_id: this.id, user_id: user.id });
    }

    if (mainStore.currentUser?.id === user.id) {
        GhostNotification.show({
            message: isAdd
                ? "You're watching this issue. We'll notify you when there's activity on it."
                : 'You stopped watching this issue',
            timeout: 3000,
        });
    }

    try {
        await fnRequest(`${this.apiCurrentEndpoint}/watchers/${user.id}`);
    } catch (error) {
        failRequest(error);
        isAdd
            ? mainStore.removeWatcher({ issue_id: this.id, user_id: user.id })
            : mainStore.addWatcher({ issue_id: this.id, user_id: user.id });
    }
}
