import React from 'react';

import IssueCommentMeta from 'components/IssueCommentMeta';

function IssueCommentJoinCount({ count }) {
    if (!count) {
        return null;
    }
    return <IssueCommentMeta>{count} joined</IssueCommentMeta>;
}

export default IssueCommentJoinCount;
