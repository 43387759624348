import React from 'react';

import { observer } from 'mobx-react-lite';

import { logEvent } from 'utils';

import { utilsStore } from 'store/models/UtilsStore';

import { AnchorButton } from 'components/Button';
import Flex from 'components/Flex';

import { StDesc, StSimilarIdeaCard } from './atoms';

export const SimilarIdeaCard = observer(({ idea }) => {
    if (!idea.board) return null;

    const clickIdea = () => {
        logEvent('Click Idea Suggestion');
    };

    return (
        <StSimilarIdeaCard
            as={AnchorButton}
            block
            size={null}
            onClick={clickIdea}
            target="_blank"
            href={idea.href}
            minimal
            mobile={utilsStore.isMobile}
        >
            <Flex gap={4} column align="none">
                <Flex spaceBetween className="o-6" style={{ marginBottom: 2 }}>
                    <div className="t-crop">{idea.board?.fullName}</div>
                    <div>{idea.statusName}</div>
                </Flex>
                <div className="t-w-sb t-size-14">{idea.name}</div>
                {idea.mobileDesc && (
                    <StDesc className="multilineCrop" dangerouslySetInnerHTML={{ __html: idea.mobileDesc }} />
                )}
            </Flex>
        </StSimilarIdeaCard>
    );
});
