import React from 'react';

import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import styles from 'pages/Board/Voting/components/VotesCount/VotesCountBtn.module.sass';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';

export const VotesCountButtons = observer(({ small, canVote, canDownVote, issue, mobile }) => {
    const classes = classNames(styles.btnGroup, {
        [styles.btnGroup__mobile]: mobile,
    });

    if (!issue.board.voting_settings?.multiple_upvotes) {
        return (
            <Button
                tabIndex={-1}
                border
                size={28}
                color={canDownVote ? 'accent' : undefined}
                icon={CustomIconName.UPVOTE}
                onClick={() => issue.voting(canVote)}
                text={issue.votesCount || 0}
            />
        );
    }

    return (
        <Flex gap={8} className={classes} tabIndex={-1}>
            <Button
                size={small ? 24 : 28}
                border
                tabIndex={-1}
                disabled={!canVote}
                color={canDownVote ? 'accent' : undefined}
                icon={CustomIconName.UPVOTE}
                onClick={() => canVote && issue.voting(true)}
                text={issue.votesCount || 0}
            />
            <Button
                size={small ? 24 : 28}
                border
                tabIndex={-1}
                disabled={!canDownVote}
                icon={CustomIconName.DOWNVOTE}
                onClick={() => canDownVote && issue.voting(false)}
            />
        </Flex>
    );
});
