import { styled } from 'stitches.config';

export const StAvatarsList = styled('div', {
    '[data-place="avatar"]': {
        borderRadius: '50%',
    },

    variants: {
        merge: {
            true: {
                '[data-place="avatar"]': {
                    border: '2px solid $bg1',
                },
            },
        },
        isButton: {
            true: {
                '[data-place="avatar"]': {
                    border: '2px solid $btnBg !important',
                    background: 'none',
                },
            },
        },
    },
});
