import React, { useEffect, useState } from 'react';

import { Spinner } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { StCardStripe, StCardStripeHeader, StCardStripeOpacityElement } from 'atoms/StCardStripe';

import Flex from 'components/Flex';
import Stripe from 'components/Stripe';

import * as IdeaStripeComponents from './components';
import PublicIdeaStripeContent from './components/PublicIdeaStripeContent';
import PublicIdeaStripeTitle from './PublicIdeaStripeTitle';

function getLanguageNameByCode(code, langCode) {
    const languageNames = new Intl.DisplayNames([code], {
        type: 'language',
    });
    const languageName = languageNames.of(langCode);
    return languageName.charAt(0).toUpperCase() + languageName.slice(1);
}

function PublicIdeaStripe({ idea, editable, isOpen = false }) {
    const [open, setOpen] = useState(isOpen || !idea.allow_voting);

    const tempId = idea.tempId;
    const getIssue = idea.issue_id && !idea.parentIssue;

    useEffect(() => {
        getIssue && idea.getIssue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getIssue]);

    useEffect(() => {
        tempId && setOpen(true);
    }, [tempId]);

    if (idea.text_status === 'ai_in_progress') {
        const langName = getLanguageNameByCode('en', idea.board.voting_settings.language);

        return (
            <StCardStripe role="button" className="interactive" disabled>
                <StCardStripeHeader as={Flex} gap={8}>
                    <Spinner size={18} />
                    <StCardStripeOpacityElement as={Flex} grow>
                        <div className="animate-typing">AI Translating the Idea to {langName || '...'}</div>
                    </StCardStripeOpacityElement>
                </StCardStripeHeader>
            </StCardStripe>
        );
    }

    return (
        <Stripe
            action={<IdeaStripeComponents.IdeaMenu idea={idea} />}
            title={<PublicIdeaStripeTitle idea={idea} />}
            initialOpen={open}
            intent="yellow"
        >
            <PublicIdeaStripeContent idea={idea} editable={editable} />
        </Stripe>
    );
}

export default observer(PublicIdeaStripe);
