import { Node, mergeAttributes } from '@tiptap/core';

export default Node.create({
    name: 'audio',

    content: 'block*',
    inline: false,
    group: 'block',
    draggable: true,
    atom: true,

    addAttributes() {
        return {
            src: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'audio',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['audio', { controls: true }, ['source', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]];
    },
});
