import { runInAction } from 'mobx';

import api from 'api';

import { APP_STORAGE_KEYS } from 'utils/consts';
import failRequest from 'utils/failRequest';
import appStorage from 'utils/LsHelper';

export async function fetchTimezones() {
    if (this.timezones.length > 0) {
        return;
    }

    try {
        const { data } = await api.get('/organization/timezones');
        if (data) {
            runInAction(() => {
                this.timezones = data;
            });
            appStorage.set(APP_STORAGE_KEYS.timezones, data);
        }
    } catch (e) {
        failRequest(e);
    }
}
