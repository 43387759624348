import React from 'react';

import { DateTime } from 'luxon';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import Dot from 'components/Dot';
import Flex from 'components/Flex';
import Space from 'components/Space';

export const StatsViewersDetails = ({ idea }) => {
    const { voters_read, registered_read, anon_read, total_read } = idea.vote_stats;

    const date = DateTime.fromISO(idea.dateUpdate).toFormat('DD');

    return (
        <>
            <Flex gap={4} css={{ paddingLeft: 0 }} className="t-mutted">
                <CustomIcon icon={CustomIconName.GRAPH} />
                <Flex gap={8} css={{ paddingLeft: 0 }} className="t-mutted">
                    <span>{total_read} Viewers include:</span>
                    <span>Voters {voters_read}</span>
                    <Dot className="o-4" />
                    <span>Authorized, not voters {registered_read - voters_read}</span>
                    <Dot className="o-4" />
                    <span>Anonymous {anon_read}</span>
                </Flex>
                <Space width={4} />
                <DarkTooltip
                    content={`Stats collection started after you change that idea’s status to Done on ${date}`}
                >
                    <CustomIcon icon={CustomIconName.INFO} size={14} className="o-4 o-4--hover" />
                </DarkTooltip>
            </Flex>
            <Space height={6} />
        </>
    );
};
