import React from 'react';

import { ASSESSMENT_VIEW_SETTINGS } from 'pages/Board/Evaluation/SettingsBtn';

import { CustomIcon } from 'components/CustomIcon';
import Switcher from 'components/Switcher';

export function SettingsSwitcher({ field, style, alignIndicator = 'right' }) {
    const setting = ASSESSMENT_VIEW_SETTINGS[field];

    const switcher = (
        <Switcher
            style={style}
            icon={<CustomIcon icon={setting.icon} />}
            alignIndicator={alignIndicator}
            label={setting.text}
            field={field}
        />
    );
    if (setting.tooltip) {
        return setting.tooltip(switcher, 'bottom');
    }
    return switcher;
}
