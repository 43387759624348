import { useCallback, useMemo } from 'react';

import { observer } from 'mobx-react-lite';

import { APP_STORAGE_KEYS } from 'utils/consts';
import appStorage from 'utils/LsHelper';

import Stripe from 'components/Stripe';

function getStoreKey(key) {
    return `${APP_STORAGE_KEYS.stripe_open}${key}`;
}

function StripeWithSavedState({ storeKey, defaultValue = false, ...props }) {
    const initialState = useMemo(() => appStorage.get(getStoreKey(storeKey), defaultValue), [defaultValue, storeKey]);

    const onToggle = useCallback(
        (isOpen) => {
            appStorage.set(getStoreKey(storeKey), isOpen);
        },
        [storeKey],
    );

    return <Stripe initialOpen={initialState} onOpenChange={onToggle} activeIcon {...props} />;
}

export default observer(StripeWithSavedState);
