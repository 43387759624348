import React, { useEffect, useState } from 'react';

import { EditableText } from '@blueprintjs/core';

import { StTitle, StTitleWrapper } from 'atoms/StTitle';

export default function InputEditor({
    onChange,
    text,
    onDone,
    multiline = false,
    editableTextProps = {},
    isEditing = false,
    css,
    error,
}) {
    const [value, setValue] = useState(text);
    const [edit, setEdit] = useState(isEditing);

    useEffect(() => {
        setValue((state) => (state !== text ? text : state));
    }, [text]);

    function onConfirm(value) {
        if ([null, undefined, ''].includes(value)) {
            return setValue(text);
        }
        onDone(value, true);
        setEdit(false);
    }

    function onChangeValue(value) {
        setValue(value);
        onChange && onChange(value);
    }

    return (
        <StTitleWrapper css={css} error={!!error}>
            <StTitle
                as={EditableText}
                isEditing={edit || undefined}
                {...editableTextProps}
                multiline={multiline}
                onChange={onChangeValue}
                onConfirm={onConfirm}
                value={value}
            />
        </StTitleWrapper>
    );
}
