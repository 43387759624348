import React, { useRef, useState } from 'react';

import { Popover } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import alertStore from 'store/models/Alert';
import { Idea } from 'store/models/Idea';

import { defaultPopoverProps } from 'utils/consts';
import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';

function MergedIssueDotMenu({ issue, boardId, className }) {
    const [open, setOpen] = useState(false);
    const votingIssue = useRef(new Idea({ ...issue, boardId: boardId })).current;

    function unMerge() {
        alertStore.confirm({
            message:
                'Are you sure you want to Unmerge Public Idea? The public idea will be unmerged from this idea, but you can find it on the Public Ideas page.',
            onConfirm: () => {
                logEvent('undo the merge voting issue');
                votingIssue.unMergeWithIdea();
            },
            confirmButtonText: 'Unmerge',
        });
    }

    const menu = (
        <Flex column css={{ padding: 4 }}>
            <Button block icon={CustomIconName.UNLINK} onClick={unMerge} text="Unmerge Idea" minimal />
            <Button
                block
                icon={CustomIconName.TRASH}
                onClick={votingIssue.remove}
                color="red"
                text="Remove Idea permanently"
            />
        </Flex>
    );

    return (
        <Popover
            {...defaultPopoverProps}
            onOpening={() => setOpen(true)}
            onClosing={() => setOpen(false)}
            position="bottom-right"
            content={menu}
        >
            <Button active={open} className={className} size={24} border minimal icon={CustomIconName.DOTES_H} />
        </Popover>
    );
}

export default observer(MergedIssueDotMenu);
