import { CRITERION_SCALE_TYPE } from 'constants/Criterion';

function isCanBeNegative(criterion) {
    switch (criterion.scale_type) {
        case CRITERION_SCALE_TYPE.range:
            return criterion.scale_min < 0;
        case CRITERION_SCALE_TYPE.series:
            return (
                String(criterion.scale_series)
                    .split(',')
                    .findIndex((el) => el < 0) !== -1
            );
        case CRITERION_SCALE_TYPE.positive:
        case CRITERION_SCALE_TYPE.percent:
        default:
            return;
    }
}

export function validateNumber(value, criterion, strict = false) {
    const number = parseFloat(value);
    switch (criterion.scale_type) {
        case CRITERION_SCALE_TYPE.range:
            if (number < criterion.scale_min && (criterion.scale_min > 9 || criterion.scale_max > 9)) {
                return true;
            }
            return number >= criterion.scale_min && number <= criterion.scale_max;
        case CRITERION_SCALE_TYPE.percent:
            return number >= 0 && number <= 100;
        case CRITERION_SCALE_TYPE.series:
            if (strict) {
                return String(criterion.scale_series).split(',').includes(String(value));
            }
            return String(criterion.scale_series).indexOf(number) !== -1;
        case CRITERION_SCALE_TYPE.positive:
            return number >= 0;
        default:
            return;
    }
}

export default function validateVote(value, criterion, strict = false) {
    if (value === '' || criterion.is_custom) {
        return true;
    }
    if (value === ' ') {
        return false;
    }
    if (!strict && isCanBeNegative(criterion) && value === '-') {
        return true;
    }
    return validateNumber(value, criterion, strict);
}

export function prepareNumber(value) {
    if (value === '' || value === '-' || /.?/.test(value)) {
        return value;
    }
    return parseFloat(value);
}

export function getVoteError(criterion) {
    switch (criterion.scale_type) {
        case CRITERION_SCALE_TYPE.range:
            return `Evaluation scores are from ${criterion.scale_min} to ${criterion.scale_max}, you can't use other numbers`;
        case CRITERION_SCALE_TYPE.percent:
            return `Evaluation scores are from 0 to 100, you can't use other numbers`;
        case CRITERION_SCALE_TYPE.series:
            return `Evaluation scores are ${String(criterion.scale_series).split(',').join(', ')}, you can't use other numbers`;
        case CRITERION_SCALE_TYPE.positive:
            return `Evaluation scores are from 0 to infinity, you can't use other numbers`;
        default:
            return;
    }
}
