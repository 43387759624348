import React, { memo } from 'react';

import { DateTime } from 'luxon';

export const CreatedDate = memo(({ created }) => {
    if (!created) return null;

    return (
        <>
            <span className="o-6">Created</span> {DateTime.fromISO(created).toRelative()}
        </>
    );
});
