import React from 'react';

import classNames from 'classnames';

import highlightText from 'utils/highlightText';

import Flex from 'components/Flex';

export function itemRenderer(item, { modifiers, handleClick, query }) {
    const classes = classNames('bp5-menu-item', {
        'bp5-active': modifiers.active,
    });

    return (
        <Flex className={classes} key={item.id} onClick={handleClick} spaceBetween>
            <div className="flex-shrink">{highlightText(item.name, query)}</div>
            <div className="bp5-text-overflow-ellipsis t-mutted">{highlightText(item.email, query)}</div>
        </Flex>
    );
}
