import React, { useState } from 'react';

import { utilsStore } from 'store/models/UtilsStore';

import { EMPTY_ARRAY, IS_VOTING_BOARD } from 'utils/consts';

import ImagePreview from 'components/ImagePreview';

import styles from './ImageTextViewer.module.sass';

export default function ImageTextViewer({ children, itemProp }) {
    const [image, setImage] = useState(null);

    function openImageOnParentWindow(src) {
        window.parent.postMessage({ image: src }, '*');
    }

    const handleClick = (e) => {
        const target = e.target;
        if (target.tagName === 'IMG') {
            e.stopPropagation();
            e.preventDefault();
            const src = IS_VOTING_BOARD || /ducalis/.test(target.src) ? `${target.src}?full=1` : target.src;
            if (window.parent && utilsStore.isMobile) {
                openImageOnParentWindow(src);
            } else {
                setImage(src);
            }
            return false;
        }
    };

    const images = (image && [{ source: image }]) || EMPTY_ARRAY;

    return (
        <>
            <div onClick={handleClick} className={styles.wrapper} itemProp={itemProp}>
                {children}
            </div>
            <ImagePreview images={images} isOpen={!!image} onClose={() => setImage(null)} />
        </>
    );
}
