import React from 'react';

import { observer } from 'mobx-react-lite';

import { StLineThrough } from 'atoms/StLineThrough';

import Flex from 'components/Flex';

export const AlignCriterionRow = observer(({ issue, criterion, showOld }) => {
    const newValue = issue.externalData.custom_votes.get(criterion.id);
    const value = issue.externalData.cr_weightless.get(criterion.id);

    let content;
    if (showOld && newValue && newValue.value !== value) {
        content = (
            <Flex gap={4} align="end">
                <StLineThrough className="o-6">{criterion.value}</StLineThrough>
                {newValue.value}
            </Flex>
        );
    } else {
        content = criterion.value;
    }

    return (
        <tr>
            <td>{criterion.name}</td>
            <td className="t-r table-numbers">
                {content}
                {criterion.postfix}
            </td>
        </tr>
    );
});
