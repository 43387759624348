import React, { forwardRef } from 'react';

import classNames from 'classnames';

import styles from './RoundCounter.module.sass';

function RoundCounter({ size, count, className, border, ...props }, forwardedRef) {
    const placeholdersSize = { width: size, height: size, fontSize: size >= 30 ? 13 : 9, zIndex: 1 };
    const classes = classNames(styles.counter, 'flex-shrink', className, {
        [styles.counter__border]: border,
    });

    return (
        <div
            style={placeholdersSize}
            className={classes}
            data-count={`+${count > 99 ? 99 : count}`}
            ref={forwardedRef}
            {...props}
        />
    );
}

export default forwardRef(RoundCounter);
