import { keyframes, styled } from 'stitches.config';

import { StCellWrapperBtnDropdown } from 'atoms/StCellWrapperBtnDropdown';

const disappear = keyframes({
    '0%': { opacity: 0.6, left: 0 },
    '100%': { opacity: 0, left: -150 },
});

export const StRow = styled('div', {
    willChange: 'transform',
    transitionTimingFunction: 'ease',
    transitionDuration: '.2s',
    transitionProperty: 'transform, background-color',
    variants: {
        active: {
            true: {
                backgroundColor: '$grayA4',
                '.btn-row-hovered': {
                    opacity: 1,
                },
            },
        },
        interactive: {
            true: {
                cursor: 'pointer',
            },
        },
        scrolling: {
            true: {
                pointerEvents: 'none',
                '*': {
                    pointerEvents: 'none',
                    transition: 'none !important',
                },
                transition: 'none !important',
            },
        },
        removed: {
            true: {
                opacity: 0.6,
                textDecoration: 'line-through',
                pointerEvents: 'none',
                animation: `${disappear} .3s`,
                animationFillMode: 'forwards',
            },
        },
        btnHover: {
            true: {
                '&:hover': {
                    [StCellWrapperBtnDropdown]: {
                        button: {
                            display: 'flex',
                            opacity: 1,
                        },
                    },
                },
            },
        },
    },
    compoundVariants: [
        {
            interactive: true,
            active: false,
            css: {
                '&:hover': {
                    backgroundColor: '$grayA3',
                },
            },
        },
    ],
});

StRow.displayName = 'StRow';
