import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { CustomIconName } from 'components/CustomIcon';
import { PopoverClose } from 'components/Popover';
import SettingsLinkButton from 'components/SettingsLinkButton';

function FormulaDropDownLink() {
    if (!mainStore.currentUser?.isAdmin || !mainStore.activeBoard) return null;

    return (
        <PopoverClose asChild>
            <SettingsLinkButton
                icon={CustomIconName.SETTINGS}
                text="Edit formula"
                link={`/board/${mainStore.activeBoard.id}/formula`}
                minimal
                block
            />
        </PopoverClose>
    );
}

export default observer(FormulaDropDownLink);
