import React, { useCallback, useMemo } from 'react';

import { useHotkeys } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import useUndoNotification from 'hooks/useUndoNotification';

import isUnsavedIssue from 'utils/isUnsavedIssue';

function RemoveAction({ issue }) {
    const unsavedIssue = isUnsavedIssue(issue);
    const notify = useUndoNotification();

    const votingIssueLink = issue?.idea?.internalHref;

    const infoMessage = useCallback(() => {
        votingIssueLink &&
            notify(
                <>
                    Issue <b>{issue.name}</b> was removed. Public part is still visible on Voting Board.
                </>
            );
    }, [issue.name, notify, votingIssueLink]);

    const hotkeys = useMemo(() => {
        if (!unsavedIssue) {
            return [
                {
                    combo: 'alt+backspace',
                    global: true,
                    group: 'Issue',
                    label: 'Permanently delete',
                    preventDefault: true,
                    onKeyDown: () => issue.deleteFromBoard(infoMessage),
                },
                {
                    combo: 'alt+delete',
                    global: true,
                    group: 'Issue',
                    label: '',
                    preventDefault: true,
                    onKeyDown: () => issue.deleteFromBoard(infoMessage),
                },
            ];
        }
        return [];
    }, [issue, unsavedIssue, infoMessage]);

    useHotkeys(hotkeys);

    return null;
}

export default observer(RemoveAction);
