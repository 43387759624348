import React, { forwardRef } from 'react';

import {
    PROVIDER_ASANA,
    PROVIDER_CLICKUP,
    PROVIDER_DUCALIS,
    PROVIDER_GITHUB,
    PROVIDER_KAITEN,
    PROVIDER_LINEAR,
    PROVIDER_TRELLO,
    PROVIDER_YT,
} from 'utils/consts';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';

/**
 * Create platform icon
 *
 * @param {Object} param
 * @param {String} param.provider
 * @param {number} param.size
 */
const PlatformIcon = forwardRef(function PlatformIcon({ provider, size = 16, ...props }, ref) {
    if (!provider) return null;

    switch (provider) {
        case PROVIDER_ASANA:
        case PROVIDER_TRELLO:
        case PROVIDER_CLICKUP:
        case PROVIDER_YT:
        case PROVIDER_LINEAR:
        case PROVIDER_GITHUB:
        case PROVIDER_DUCALIS:
        case PROVIDER_KAITEN:
        case 'instance':
            return <CustomIcon ref={ref} size={size} icon={provider} {...props} />;
        default:
            return <CustomIcon ref={ref} size={size} icon={CustomIconName.JIRA} {...props} />;
    }
});

export default PlatformIcon;
