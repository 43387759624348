import { memo } from 'react';

import { CRITERION_TYPE } from 'constants/Criterion';

import CriterionTypeIcon from './CriterionTypeIcon';

export const CriterionValueIcon = memo((props) => <CriterionTypeIcon type={CRITERION_TYPE.value} {...props} />);
export const CriterionEffortIcon = memo((props) => <CriterionTypeIcon type={CRITERION_TYPE.effort} {...props} />);
export const CriterionMultiplierIcon = memo((props) => (
    <CriterionTypeIcon type={CRITERION_TYPE.multiplier} {...props} />
));

export default CriterionTypeIcon;
