import { runInAction } from 'mobx';

import api from 'api';

import IssuesData from 'store/models/IssuesData';
import { issuesList } from 'store/models/IssuesList';

import { UNSAVED_MODEL_ID } from 'utils/consts';
import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';

export async function pushIssueToServer() {
    const isUnsavedIssue = this.id === UNSAVED_MODEL_ID;
    const { name, description, assignee_id, reporter_id, type_id, status_id, label_ids } = this;

    if (isUnsavedIssue && !name && !description) {
        return null;
    }

    isUnsavedIssue &&
        runInAction(() => {
            this.needClear = true;
        });

    try {
        let apiFn = !isUnsavedIssue ? api.put : api.post;

        let url = !isUnsavedIssue ? this.apiCurrentEndpoint : this.apiBaseEndpoint;
        const { data } = await apiFn(
            url,
            toUrl({
                type_id,
                status_id,
                name,
                description,
                assignee_id,
                reporter_id,
                label_ids,
            })
        );

        if (isUnsavedIssue) {
            const key = `${data.id}-${this.boardId}`;
            if (!issuesList.issuesData.has(key)) {
                issuesList.issuesData.set(key, new IssuesData({ id: data.id, boardId: this.boardId }));
            }
            this.updateModel(data);
            const issue = issuesList.allIssues.find((el) => el.id === data.id && el.boardId === this.boardId);
            if (issue) {
                issue.updateModel({ tempid: this.tempid, needFocus: this.needFocus });
            }

            if (this.board.demoIssuesCount > 0 && !this.board.keep_demo_issues) {
                this.board.toggleDemoRemover(true);
            }
        }
    } catch (error) {
        failRequest(error);
    }
}
