import React from 'react';

import { styled } from 'stitches.config';

import { getContrastColor } from 'utils/getContrastColor';

const StColorPanel = styled('div', {
    padding: 4,
    borderRadius: 4,
    whiteSpace: 'initial',
    margin: '12px 0 6px',
    '> *': {
        margin: '0 !important',
    },
});

export const ColorPanel = ({ children, color }) => (
    <StColorPanel style={{ background: color, color: getContrastColor(color) }}>{children}</StColorPanel>
);
