import React from 'react';

import { Dialog } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { VotersListTable } from './VotersListTable';

const VotersList = ({ open, onClose, idea }) => {
    return (
        <Dialog isOpen={open} transitionDuration={100} className="limit limit-full limit-h-full" onClose={onClose}>
            <VotersListTable onClose={onClose} idea={idea} />
        </Dialog>
    );
};

export default observer(VotersList);
