import React from 'react';

import { observer } from 'mobx-react-lite';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import styles from 'modules/PublicVotingIssue/PublicVotingIssue.module.sass';
import VotesSelector from 'modules/PublicVotingIssue/VotesSelector';

import IssueDescription from 'components/IssueDescription';
import IssueName from 'components/IssueName';
import Space from 'components/Space';

import { IdeaNameRecommended } from './IdeaNameRecommended';

function PublicVotingIssueEditBody({ issue }) {
    const intl = useIntl();
    const messages = defineMessages({
        desc: {
            id: 'voting_issue.desc_placeholder',
            defaultMessage: 'Describe why it’s important for you...',
        },
        name: {
            id: 'voting_issue.name_placeholder',
            defaultMessage: 'New Idea',
        },
    });

    const namePlaceholder = intl.formatMessage(messages.name);
    const descPlaceholder = intl.formatMessage(messages.desc);

    return (
        <div className={styles.descWrapper} aria-readonly={false}>
            {issue.board.voting_settings?.multiple_upvotes && (
                <>
                    <p className="o-6">
                        <FormattedMessage id="idea.votes.title" defaultMessage="How important is this for you?" />
                    </p>
                    <VotesSelector idea={issue} />

                    <Space height={18} />
                </>
            )}

            {issue.id === -1 ? (
                <IdeaNameRecommended placeholder={namePlaceholder} issue={issue} />
            ) : (
                <IssueName placeholder={namePlaceholder} issue={issue} editable autoFocus />
            )}

            <Space height={18} />

            <div className={styles.desc}>
                <IssueDescription
                    defaultValue={issue.board.ideaTemplate}
                    showUploadBtn
                    placeholder={descPlaceholder}
                    issue={issue}
                    editable
                />
                <Space height={34} />
            </div>
        </div>
    );
}

export default observer(PublicVotingIssueEditBody);
