import React from 'react';

import { observer } from 'mobx-react-lite';

import usePaywallUpgrade from 'hooks/usePaywallUpgrade';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';
import { providerForm } from 'store/models/ProviderForm';

import { PAYWALL_LIMITS, PROVIDER_DUCALIS, SERVICE_NAME } from 'utils/consts';
import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import DarkTooltip from 'components/DarkTooltip';

const PushToTracker = () => {
    const handlePaywallClick = usePaywallUpgrade(PAYWALL_LIMITS.PUSH_ISSUE);
    if (!issuesList.idea?.board?.canSendToPlatform) return null;

    if (issuesList.idea.board.needNewOwner && !issuesList.idea.board.error) {
        return (
            <DarkTooltip position="left" content="The board must have an owner" fast>
                <Button block disabled>
                    In <span className="t-cap">{issuesList.idea.board.provider}</span> (Linked with {SERVICE_NAME})
                </Button>
            </DarkTooltip>
        );
    }

    const handleClick = () => {
        logEvent('Click Push to Tracker', { provider: PROVIDER_DUCALIS });
        if (!mainStore.organization.canPushToTracker) {
            handlePaywallClick();
        } else {
            providerForm.togglePushIssue(issuesList.idea);
        }
    };

    return (
        <Button block onClick={handleClick}>
            In <span className="t-cap">{issuesList.idea.board.provider}</span> (Linked with {SERVICE_NAME})
        </Button>
    );
};

export default observer(PushToTracker);
