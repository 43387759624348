import React, { useState } from 'react';

import { Popover } from '@blueprintjs/core';

import { IssueMenuContent } from 'modules/InsideIssue/components/HeaderMeta/IssueMenuContent';

import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';

import { defaultPopoverProps, POPOVER_PROPS_DIV, VIEWS } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

export const IssueMenu = () => {
    const [open, setOpen] = useState(false);

    return (
        <Popover
            {...POPOVER_PROPS_DIV}
            {...defaultPopoverProps}
            isPortal={true}
            onOpening={() => setOpen(true)}
            onClosing={() => setOpen(false)}
            position="bottom-right"
            isOpen={
                ![VIEWS.MATRIX, VIEWS.QUESTIONS].includes(mainStore.page) && utilsStore.cardIsHidden ? false : undefined
            }
            content={<IssueMenuContent />}
        >
            <Button block active={open} border minimal icon={CustomIconName.DOTES_H} size={24} />
        </Popover>
    );
};
