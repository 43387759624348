import React from 'react';

import { observer } from 'mobx-react-lite';
import { useParams, useNavigate } from 'react-router-dom';

import { useOneHotKey } from 'hooks/useHotKey';

import { ESC } from 'utils/consts';

import ButtonLink from 'components/ButtonLink';
import { CustomIconName } from 'components/CustomIcon';

import styles from './CloseBtn.module.sass';

const CloseBtn = observer(() => {
    const { id } = useParams();
    let navigate = useNavigate();

    const url = `/board/${id}/unvoted`;

    function closeFocusMode() {
        navigate(url);
    }

    useOneHotKey(ESC, closeFocusMode, true);

    return (
        <ButtonLink
            border
            block={false}
            className={styles.close}
            minimal
            size={34}
            icon={CustomIconName.X_CROSS}
            to={url}
            relative={false}
        />
    );
});

export default CloseBtn;
