import Axios from 'axios';
import orderBy from 'lodash/orderBy';
import { makeAutoObservable, runInAction } from 'mobx';

import api from 'api';
import { globalSocket } from 'socket/init';
import { failRequest } from 'utils';

import { mainStore } from 'store/models/MainStore';

import { IS_VOTING_BOARD } from 'utils/consts';

const BannerPlacement = {
    top: 'top',
    left: 'left',
};
class EducationBanners {
    bannersIds = new Map();
    activeBannersIds = new Map();

    constructor() {
        makeAutoObservable(this);

        if (!IS_VOTING_BOARD) {
            globalSocket.on('EducationBanner.update', this.updateEducationBanner.bind(this));
            globalSocket.on('EducationBanner.delete', this.removeEducationBanner.bind(this));
        }
    }

    get activeBanners() {
        return Array.from(this.activeBannersIds.values());
    }

    get banners() {
        return Array.from(this.bannersIds.values());
    }

    get topBanner() {
        const activeTopBannersIds = this.activeBanners
            .filter((item) => null === item.board_id)
            .map((item) => item.education_banner_id);

        return this.banners.find(
            (banner) => banner.placement === BannerPlacement.top && activeTopBannersIds.includes(banner.id)
        );
    }
    get activeBoardBanners() {
        const boardId = mainStore.activeBoard?.id;

        const activeBoardBannersIds = this.activeBanners
            .filter((item) => [null, boardId].includes(item.board_id))
            .map((item) => item.education_banner_id);

        return this.banners.filter(
            (banner) => banner.placement === BannerPlacement.left && activeBoardBannersIds.includes(banner.id)
        );
    }

    updateActiveBanner = (banner) => {
        this.activeBannersIds.set(banner.id, banner);
    };

    removeActiveBanner = (banner) => {
        this.activeBannersIds.delete(banner.id);
    };

    updateEducationBanner = ({ item }) => {
        if (item.role && item.role !== mainStore.currentUser?.role) return;

        this.bannersIds.set(item.id, item);
    };

    removeEducationBanner = ({ item }) => {
        this.bannersIds.delete(item.id);
    };

    async fetchAll() {
        try {
            const requests = ['education-banners', 'user/banners'];
            const [banners, activeBanners] = await Axios.all(requests.map((url) => api.get(url)));

            runInAction(() => {
                orderBy(banners.data, 'position').forEach((banner) => {
                    if (!banner.role || banner.role === mainStore.currentUser?.role)
                        this.bannersIds.set(banner.id, banner);
                });
                activeBanners.data.forEach((el) => {
                    this.activeBannersIds.set(el.id, el);
                });
            });
        } catch (e) {
            failRequest(e);
        }
    }
}

const educationBanners = new EducationBanners();
export default educationBanners;
