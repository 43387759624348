import React from 'react';

import Tooltip from 'components/Tooltip';

import styles from './VideoTooltip.module.sass';

export default function VideoTooltip({ children, text, video, side = 'bottom', align = 'center' }) {
    const content = (
        <>
            {text && <p>{text}</p>}
            {video && (
                <video autoPlay loop muted playsInline>
                    <source src={video} type="video/mp4" />
                </video>
            )}
        </>
    );
    return (
        <Tooltip contentClassName={styles.popover} content={content} side={side} align={align}>
            {children}
        </Tooltip>
    );
}
