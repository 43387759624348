import React from 'react';

import ImageWrapper from 'components/ImageWrapper';

export default function VideoElm({ src }) {
    return (
        <ImageWrapper>
            <video preload="metadata" controls>
                <source src={src} type="video/mp4" />
            </video>
        </ImageWrapper>
    );
}
