import React from 'react';

import { observer } from 'mobx-react-lite';

import PublicIdeaStripe from 'modules/PublicIdeaStripe';
import * as IdeaStripeComponents from 'modules/PublicIdeaStripe/components';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { IS_PUBLIC_BOARD, VIEWS } from 'utils/consts';

const IdeaStripe = observer(() => {
    if (IS_PUBLIC_BOARD) return null;

    if (!issuesList.activeIssue?.board?.isVoting) return null;

    if (issuesList.idea) {
        return (
            <PublicIdeaStripe
                isOpen={mainStore.page === VIEWS.IDEAS}
                showVotes
                idea={issuesList.idea}
                editable={!mainStore.currentUser?.isViewer}
            />
        );
    }

    return <IdeaStripeComponents.CreateButton issue={issuesList.issue} />;
});

export default IdeaStripe;
