import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';

import { IS_VOTING_BOARD } from 'utils/consts';

import IssueCommentAuthor from 'components/IssueCommentAuthor';
import IssueCommentBody from 'components/IssueCommentBody';
import IssueCommentDate from 'components/IssueCommentDate';
import IssueCommentMenu from 'components/IssueCommentMenu';
import IssueCommentText from 'components/IssueCommentText';

import styles from './IssueCommentChild.module.sass';

function IssueCommentChild({ comment, parent, isIdea }) {
    const author = comment.author || comment.user || mainStore.users.usersIds.get(comment.author_id);

    const canEdit = isIdea
        ? author.id === mainStore.currentUser?.voting_user_id
        : author?.id === mainStore.currentUser?.id;

    let showMenu;
    if (IS_VOTING_BOARD) {
        showMenu = author.id === mainStore.currentUser?.id;
    } else {
        showMenu = !mainStore.currentUser?.isViewer || canEdit;
    }

    return (
        <div className={styles.child} itemProp="comment" itemType="https://schema.org/Comment" itemScope>
            <div className="flex flex-a-center">
                <IssueCommentAuthor author={author} users={comment.users} isIdea={isIdea} />
                <div className="flex-grow" />
                {showMenu && <IssueCommentMenu classNameBtn={styles.menuBtn} comment={comment} parent={parent} />}
            </div>
            <IssueCommentBody>
                <IssueCommentText
                    isIdea={isIdea}
                    issue={(parent || comment).issue}
                    comment={comment}
                    parent={parent}
                    canEdit={parent.open && canEdit}
                />
                <IssueCommentDate date={comment.created} />
            </IssueCommentBody>
        </div>
    );
}

export default observer(IssueCommentChild);
