import { runInAction } from 'mobx';

import api from 'api';
import { sendToSentry } from 'utils';

import failRequest from 'utils/failRequest';
import toUrl from 'utils/toUrl';
import { prepareNumber } from 'utils/validateVote';

export async function vote({ criterion, vote, custom }, update = false) {
    const board = this.board;
    const boardId = board.id;
    const issue_id = this.id;
    const criterion_id = criterion.id;

    this.changeVote({ value: prepareNumber(vote), criterion });

    const requestData = { vote, issue_id, criterion_id, custom };

    try {
        await api.post(`/boards/${boardId}/vote`, toUrl(requestData));

        if (update) {
            runInAction(() => {
                this.externalData.unvoted = !this.fullVote;
            });
        }
        runInAction(() => {
            this.externalData.isUserNotAllVoted = !this.fullVote;
            this.failCells = this.failCells?.filter((el) => el.criterion_id !== criterion.id);
        });
    } catch (error) {
        failRequest(error);
        sendToSentry('Fail vote', { error, boardId, issue_id, criterion_id, vote });
        this.failVote({ criterion, vote });
    }
}
