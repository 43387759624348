import { makeAutoObservable, action } from 'mobx';

import { PROVIDER_DUCALIS } from 'utils/consts';

import { Platform } from './Platform';

export default class Platforms {
    platforms = [];
    needConfirmation = null;

    constructor() {
        makeAutoObservable(this, { getPlatformById: action });
    }

    set = (platforms) => {
        this.platforms = platforms.map((platform) => new Platform(platform));
    };

    setConfirmation(needConfirmation) {
        this.needConfirmation = needConfirmation;
    }

    /**
     * @returns {undefined|Platform}
     */
    get ducalisPlatform() {
        return this.platforms.find((el) => el.provider === PROVIDER_DUCALIS);
    }

    clearLoadingStatus() {
        this.platforms.forEach((el) => (el.loading = false));
    }

    getPlatformById(id) {
        return this.platforms.find((el) => el.id === id);
    }

    get removingPlatform() {
        return this.needConfirmation ? this.platforms.find((el) => el.id === this.needConfirmation) : undefined;
    }

    removeSingle(platformId) {
        this.platforms = this.platforms.filter((el) => el.id !== platformId);
    }

    update(data) {
        const platform = this.platforms.find((el) => el.id === data.id);
        if (platform) {
            platform.fillModel(data);
        } else {
            this.platforms.push(new Platform(data));
        }
    }
}
