import React, { createContext, useEffect, useState } from 'react';

import { logEvent } from 'utils';

export const IssueOmnibarContext = createContext({});

export function IssueOmnibarContextWrapper({ children }) {
    const [openBlocks, setOpenBLocks] = useState(0);

    useEffect(() => {
        if (openBlocks === 0) return;

        const action = openBlocks === 1 ? 'blockedby' : openBlocks === 2 ? 'blocking' : 'relates';
        logEvent(`Start - ${action}`);
    }, [openBlocks]);

    return (
        <IssueOmnibarContext.Provider value={{ openBlocks, setOpenBLocks }}>{children}</IssueOmnibarContext.Provider>
    );
}
