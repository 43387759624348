import api from 'api';

import failRequest from 'utils/failRequest';

export async function fetchTemplates() {
    try {
        const { data } = await api.get('/board-templates');
        return data;
    } catch (error) {
        failRequest(error);
    }
}
