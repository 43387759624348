import React, { useContext } from 'react';

import { HotkeysContext } from '@blueprintjs/core';
import { FormattedMessage } from 'react-intl';

import { POPOVER_PROPS_DIV } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import DotDivider from 'components/DotDivider';
import Kbd, { KeyCombo } from 'components/Kbd';

export default function HotkeysHelper({ iconOnly, className }) {
    const [data, dispatch] = useContext(HotkeysContext);

    if (!data?.hotkeys?.length) return null;

    let btn;
    if (iconOnly) {
        btn = <Button onClick={() => dispatch({ type: 'OPEN_DIALOG' })} minimal icon={CustomIconName.HASH} size={34} />;
    } else {
        btn = (
            <Button
                block
                className={className}
                onClick={() => dispatch({ type: 'OPEN_DIALOG' })}
                minimal
                text={<FormattedMessage id="sidebar.shortcuts" defaultMessage="Shortcuts" />}
                icon={CustomIconName.HASH}
            />
        );
    }

    return (
        <>
            <DarkTooltip
                {...POPOVER_PROPS_DIV}
                position="right-bottom"
                content={
                    <>
                        <FormattedMessage id="sidebar.shortcuts" defaultMessage="Shortcuts" />
                        <DotDivider />
                        <KeyCombo>
                            <Kbd>?</Kbd>
                        </KeyCombo>
                    </>
                }
            >
                {btn}
            </DarkTooltip>
        </>
    );
}
