import { createContext, useCallback, useContext, useRef } from 'react';

const ReleaseNoteEditorContext = createContext(() => {});
const ReleaseNoteEditorFrameContext = createContext(null);

export const useFrameUpdater = () => {
    return useContext(ReleaseNoteEditorContext);
};

export const useFramePreview = () => {
    return useContext(ReleaseNoteEditorFrameContext);
};

function ReleaseNoteEditorProvider({ children }) {
    const frameRef = useRef(null);

    const updateFrame = useCallback(() => {
        frameRef.current?.contentWindow?.postMessage('update', '*');
    }, [frameRef]);

    return (
        <ReleaseNoteEditorFrameContext.Provider value={frameRef}>
            <ReleaseNoteEditorContext.Provider value={updateFrame}>{children}</ReleaseNoteEditorContext.Provider>
        </ReleaseNoteEditorFrameContext.Provider>
    );
}

export default ReleaseNoteEditorProvider;
