import React from 'react';

import { CustomIcon } from 'components/CustomIcon';
import Tooltip from 'components/Tooltip';

export const HeaderColumnIcon = ({ tooltip, icon }) => (
    <Tooltip side="top" align="end" content={tooltip}>
        <CustomIcon size={16} className="o-4" icon={icon} />
    </Tooltip>
);
