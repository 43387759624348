import { runInAction } from 'mobx';

import api from 'api';

import failRequest from 'utils/failRequest';
import logEvent from 'utils/logEvent';
import toUrl from 'utils/toUrl';

export async function deleteFilter() {
    logEvent('Remove Filter - Confirm');

    try {
        await api.delete(`${this.apiEndpoint}/search/save`, {
            data: toUrl({ id: this.filter.id }),
        });
        runInAction(() => {
            this.filter = null;
        });
    } catch (e) {
        failRequest(e);
    }
}
