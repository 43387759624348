import React, { useState } from 'react';

import { IntlProvider } from 'react-intl';

import English from 'lang/en.json';

import InviteError from 'pages/InviteError';

import AuthChild from 'modules/Auth/AuthChild';

function checkError() {
    if (window.invalid_invite_email_signup) {
        const { email, invitedEmail } = window.invalid_invite_email_signup;

        if (email && invitedEmail) {
            return { email, type: 'google', invitedEmail };
        }
    }

    return {};
}

function Signup() {
    const [inviteError, setInviteError] = useState(checkError());

    if (inviteError.email && inviteError.type && inviteError.invitedEmail) {
        return (
            <InviteError
                email={inviteError.email}
                type={inviteError.type}
                invitedEmail={inviteError.invitedEmail}
                setInviteError={setInviteError}
            />
        );
    }

    return (
        <>
            <AuthChild isSignup setInviteError={setInviteError} />
            <div className="bp5-divider"></div>
            <div className="flex flex-center signup-desc">
                <ul className="login-list o-4 flex-vc bp5-text-large bp5-list bp5-list-unstyled">
                    <li>No credit card required</li>
                    <li>Free plan available</li>
                    <li>No need to connect trackers</li>
                    <li>Customer support with real human beings</li>
                    <li>
                        <div className="h-30" />
                        <div className="h-30" />
                        <div className="h-30" />
                    </li>
                </ul>
            </div>
        </>
    );
}

function SignupApp() {
    return (
        <IntlProvider messages={English} locale="en">
            <Signup />
        </IntlProvider>
    );
}

export default SignupApp;
