import React from 'react';

import { observer } from 'mobx-react-lite';

import InsideIssue from 'modules/InsideIssue';

import { IssueOmnibarContextWrapper } from './IssueOmnibarContext';

function IssueCard(props) {
    return (
        <IssueOmnibarContextWrapper>
            <InsideIssue {...props} />
        </IssueOmnibarContextWrapper>
    );
}

export default observer(IssueCard);
