import { useMemo } from 'react';

import { useHotkeys } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { settingsStore } from 'modules/SettingsDialog/SettingsStore';

import { issuesList } from 'store/models/IssuesList';
import modelCopyMoveStore, { DIALOG_STATUS } from 'store/models/modelCopyMoveStore';
import { providerForm } from 'store/models/ProviderForm';

import { PROVIDER_DUCALIS } from 'utils/consts';
import logEvent from 'utils/logEvent';

const IssueHotkeys = () => {
    const issue = issuesList.issue;
    const isDucalis = issue?.provider === PROVIDER_DUCALIS;

    const usePushToTracker = issue.canPushToTracker;
    const settingsIsOpen = settingsStore.isOpen;

    const hotkeys = useMemo(() => {
        const list = [
            {
                combo: 'mod+shift+,',
                disabled: settingsIsOpen,
                global: true,
                label: 'Copy Issue Link',
                group: 'Issue',
                allowInInput: true,
                onKeyDown: issue.copyLink,
            },
        ];
        if (isDucalis) {
            list.push({
                combo: 'C',
                disabled: settingsIsOpen,
                global: true,
                label: 'Copy issue',
                group: 'Issue',
                allowInInput: false,
                onKeyDown: () =>
                    modelCopyMoveStore.openDialog({ dialogType: DIALOG_STATUS.copyIssue, model: issuesList.issue }),
            });
            list.push({
                combo: 'M',
                disabled: settingsIsOpen,
                global: true,
                label: 'Move issue',
                group: 'Issue',
                allowInInput: false,
                onKeyDown: () =>
                    modelCopyMoveStore.openDialog({ dialogType: DIALOG_STATUS.moveIssue, model: issuesList.issue }),
            });
            list.push({
                combo: 'p',
                disabled: !usePushToTracker || settingsIsOpen,
                global: true,
                label: 'Push to task tracker',
                group: 'Issue',
                onKeyDown: () => {
                    logEvent('Use hot key: Push to Tracker', { provider: issuesList.issue?.board?.provider });
                    providerForm.togglePushIssue(issuesList.issue);
                },
            });
        }
        return list;
    }, [usePushToTracker, issue, isDucalis, settingsIsOpen]);

    useHotkeys(hotkeys);

    return null;
};

export default observer(IssueHotkeys);
