import React, { useEffect } from 'react';

import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';

import { useFrameUpdater } from 'pages/Board/ReleaseNotes/ReleaseNoteEditor/ReleaseNoteEditorContext';

import HistoryButton from 'modules/IdeaAnnounce/HistoryButton';

import { StTextEditor } from 'atoms/StTextEditor';

import { IS_VOTING_BOARD, UNSAVED_MODEL_ID } from 'utils/consts';

import { GhostNotification } from 'components/AppToaster';
import Flex from 'components/Flex';
import InputEditor from 'components/InputEditor';
import ReadOnlyDescription from 'components/ReadOnlyDescription';
import Space from 'components/Space';
import Tooltip from 'components/Tooltip';

import AnnounceAIButtons from './AnnounceAIButton';
import AnnounceAITextApprove from './AnnounceAITextApprove';
import AnnounceClearButton from './AnnounceClearButton';
import AnnounceLabels from './AnnounceLabels';

export const IdeaAnnounceContent = observer(({ readOnly, announce }) => {
    const updateFrame = useFrameUpdater();

    useEffect(() => {
        const disposer = reaction(
            () => announce?.updated,
            () => updateFrame(),
        );

        return () => {
            disposer();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!announce) return null;

    const save = async (announceData) => {
        if (!announce.id || announce.id === UNSAVED_MODEL_ID) {
            announce.idea.createIdeaAnnounce(announce);
        } else {
            await announce.update(announceData);
            if (IS_VOTING_BOARD) {
                GhostNotification.show({ message: 'Announcement saved', timeout: 3000 });
            }
        }
    };

    const saveName = (value) => {
        if (value === announce?.name) return;
        save({ name: value });
    };

    const updateDesc = (value) => {
        if (value === announce?.description) return;
        save({ description: value });
    };

    return (
        <>
            {!readOnly && (
                <Flex gap={8} css={{ marginBottom: 8 }} className="p-r">
                    <Flex spaceBetween grow>
                        {!announce.isEmpty && <AnnounceAITextApprove announce={announce} />}
                        {announce.board?.allowAnnounceAI && announce.idea && <AnnounceAIButtons announce={announce} />}
                    </Flex>
                    <HistoryButton announce={announce} />
                    <Tooltip side="top" align="end" content="Clear the announcement">
                        <AnnounceClearButton announce={announce} size={24} border />
                    </Tooltip>
                </Flex>
            )}
            {readOnly ? (
                <div style={{ fontSize: 24, fontWeight: 700 }}>{announce?.name || ''}</div>
            ) : (
                <InputEditor
                    editableTextProps={{
                        confirmOnEnterKey: true,
                        tabIndex: 2,
                        large: true,
                        border: true,
                        css: { fontWeight: 'bold' },
                        minWidth: 10,
                        placeholder: 'Enter a title...',
                    }}
                    multiline
                    text={announce?.name || ''}
                    onDone={saveName}
                />
            )}
            <Space height={8} />
            <AnnounceLabels announce={announce} editable={!readOnly} />
            <Space height={8} />
            {readOnly ? (
                <ReadOnlyDescription hasMention description={announce?.description || ''} />
            ) : (
                <StTextEditor
                    allowYoutube
                    hasMention={false}
                    placeholder={''}
                    tabIndex={2}
                    description={announce?.description || ''}
                    onConfirm={updateDesc}
                    css={{
                        '.ProseMirror': {
                            minHeight: 80,
                        },
                    }}
                />
            )}
        </>
    );
});
