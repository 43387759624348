import React from 'react';

import { observer } from 'mobx-react-lite';

import { POPOVER_PROPS_DIV } from 'utils/consts';
import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import DarkTooltip from 'components/DarkTooltip';

function RestoreBtn({ comment }) {
    function onRestore() {
        logEvent('Restore Question');
        comment.issue.sendSubscribe({ question: comment });
    }

    return (
        <DarkTooltip fast {...POPOVER_PROPS_DIV} content="I want to re-open & discuss the question again">
            <Button block minimal className="bp5-popover-dismiss" text="Restore" onClick={onRestore} />
        </DarkTooltip>
    );
}

export default observer(RestoreBtn);
