import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react-lite';

import alertStore from 'store/models/Alert';
import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';

import { StCardStripe, StCardStripeHeader, StCardStripeOpacityElement } from 'atoms/StCardStripe';

import { PAYWALL_LIMITS } from 'utils/consts';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import PopoverAIText from 'components/PopoverAIText/PopoverAIText';
import Spinner from 'components/Spinner';
import TextAIPreview from 'components/TextAIPreview';
import Tooltip from 'components/Tooltip';
import UpgradePlanBtn from 'components/UpgradePlanBtn';

const CreateIdeaButton = forwardRef(({ disabled, onClick, needLoader, useAI, ...props }, ref) => {
    const loaderAI = needLoader && disabled;

    return (
        <StCardStripe ref={ref} role="button" className="interactive" disabled={disabled} onClick={onClick} {...props}>
            <StCardStripeHeader as={Flex} gap={8}>
                {loaderAI ? <Spinner size={18} /> : <CustomIcon icon={CustomIconName.LAMP} />}
                <StCardStripeOpacityElement as={Flex} grow>
                    {useAI ? 'Create Public Idea with AI' : 'Create Public Idea'}
                </StCardStripeOpacityElement>
            </StCardStripeHeader>
        </StCardStripe>
    );
});

export const TextEl = observer(({ onConfirm, onCancel, loading, onRepeat, onStop }) => {
    return (
        <TextAIPreview
            title={issuesList.issue.aiTitle}
            onRepeat={onRepeat}
            onStop={onStop}
            text={issuesList.issue.aiText}
            onConfirm={onConfirm}
            onCancel={onCancel}
            loading={loading}
            saveButtonText="Create Idea"
        />
    );
});
export default function CreateButton({ issue }) {
    const [open, setOpen] = useState(false);
    const [block, setBlock] = useState(false);
    const [loader, setLoader] = useState(false);
    const confirm = useRef(false);
    const abortController = useRef(null);

    useEffect(() => {
        utilsStore.toggleTabNav(open);
    }, [open]);

    useEffect(() => {
        return () => {
            utilsStore.toggleTabNav(false);
            abortController.current?.abort();
        };
    }, []);

    const handleClickGenerate = async () => {
        setLoader(true);
        setOpen(true);
        abortController.current = new AbortController();

        try {
            await issue.generateAI(abortController.current);
            await issue.getTitleByAI(abortController.current, true);
        } finally {
            setLoader(false);
            abortController.current = null;
        }
    };
    const handleClickCancel = () => {
        abortController.current?.abort();
        confirm.current = true;
        setOpen(false);
        setBlock(false);
        issue.clearAIText();
    };

    const handleClickConfirm = () => {
        confirm.current = true;
        setOpen(false);
        issue.createIdea(issue.aiText, issue.aiTitle).finally(() => setBlock(false));
        issue.clearAIText();
    };

    const onStop = useCallback(() => {
        try {
            abortController.current.abort();
        } catch (e) {
            console.error('fail abort', e);
        }
    }, []);

    async function create() {
        setBlock(true);
        if (issue.board?.allowAI) {
            setOpen(true);
            handleClickGenerate();
            return;
        }
        issue.createIdea().finally(() => setBlock(false));
    }

    if (mainStore.currentUser?.isViewer) {
        return (
            <Tooltip content="Viewer can't create a new Idea">
                <CreateIdeaButton disabled useAI={issue.board?.allowAI} />
            </Tooltip>
        );
    }

    if (!mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.VOTING_ADD_TO_BACKLOG)) {
        return (
            <UpgradePlanBtn paywall={PAYWALL_LIMITS.VOTING_ADD_TO_BACKLOG}>
                <CreateIdeaButton disabled useAI={issue.board?.allowAI} />
            </UpgradePlanBtn>
        );
    }

    const onClose = () => {
        if (confirm.current) {
            confirm.current = false;
            return;
        }
        alertStore.confirm({
            message: 'Do you want to discard the AI response?',
            onConfirm: handleClickCancel,
            confirmButtonText: 'Discard',
        });
    };

    return (
        <div className={mainStore.currentUser?.showMegaVideoBanner ? 'ai-portal-text p-r' : 'p-r'}>
            <PopoverAIText
                side="right"
                onClose={onClose}
                open={open}
                content={
                    <TextEl
                        loading={loader}
                        onConfirm={handleClickConfirm}
                        onCancel={handleClickCancel}
                        onStop={onStop}
                        onRepeat={handleClickGenerate}
                    />
                }
            />
            <Tooltip
                side="left"
                contentClassName="limit limit-300"
                content="Create a public Idea description for gathering feedback at a voting board"
            >
                <CreateIdeaButton
                    needLoader={issue.board.voting_settings.chatgpt_idea_creation}
                    disabled={block || issue.id === -1}
                    onClick={create}
                    useAI={issue.board?.allowAI}
                />
            </Tooltip>
        </div>
    );
}
