import React from 'react';

import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';

const MetaVotes = observer(() => {
    if (!issuesList.idea?.votesCount) return null;

    return (
        <>
            <meta itemProp="interactionType" content="https://schema.org/LikeAction" />
            <meta itemProp="agentInteractionStatistic" content={issuesList.idea.votesCount} />
        </>
    );
});

const MetaUrl = observer(() => {
    if (!issuesList.idea?.href) return null;

    return (
        <>
            <meta itemProp="mainEntityOfPage" content={issuesList.idea.href} />
            <meta itemProp="url" content={issuesList.idea.href} />
        </>
    );
});

const MetaImage = observer(() => {
    if (!issuesList.idea?.firstImagePath) return null;

    return <meta itemProp="image" content={issuesList.idea.firstImagePath} />;
});

export { MetaImage, MetaUrl, MetaVotes };
