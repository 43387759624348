import React from 'react';

import { observer } from 'mobx-react-lite';

import useUndoNotification from 'hooks/useUndoNotification';

import { issuesList } from 'store/models/IssuesList';

import { POPOVER_PROPS_DIV, SERVICE_NAME } from 'utils/consts';

import { Button } from 'components/Button';
import DarkTooltip from 'components/DarkTooltip';

const MakeDucalisIssue = ({ setLoader }) => {
    const notify = useUndoNotification();
    const idea = issuesList.idea;

    if (idea.id === 1 && idea.board.needNewOwner && !idea.board.error) {
        return (
            <DarkTooltip position="left" {...POPOVER_PROPS_DIV} content="The board must have an owner" fast>
                <Button text={`In ${SERVICE_NAME} only`} disabled minimal block />
            </DarkTooltip>
        );
    }

    const handleClick = async () => {
        setLoader(true);
        await issuesList.idea.moveToBacklog();
        setLoader(false);
        notify(
            <>
                Issue <strong>{issuesList.idea.name}</strong> was created and linked with this idea
            </>
        );
    };

    return <Button text={`In ${SERVICE_NAME} only`} minimal block onClick={handleClick} />;
};

export default observer(MakeDucalisIssue);
