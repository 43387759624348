import React from 'react';

import { observer } from 'mobx-react-lite';

import CellWrapper from 'components/CellWrapper';

import CellDataContent from './CellDataContent';

import { TableInputCell } from '../VoteInput/TableInputCell';

function BodyCell({ columnIndex, dataKey, rowData, rowIndex, showTooltips, disabled, hideTop, columnData }) {
    if (rowData.is_fake) {
        return (
            <CellWrapper>
                <span className="bp5-skeleton">{dataKey}</span>
            </CellWrapper>
        );
    }

    if (rowData.id && columnData.type === 'input' && columnData.criterion_id) {
        if (rowData.skipped) {
            return <CellWrapper isNumber>-</CellWrapper>;
        }

        return (
            <CellWrapper fill editable disabled={rowData?.id === -1}>
                <TableInputCell
                    showTooltips={showTooltips}
                    columnIndex={columnIndex}
                    rowIndex={rowIndex}
                    issue={rowData}
                    columnData={columnData}
                />
            </CellWrapper>
        );
    }
    return (
        <CellDataContent
            column={columnData}
            issue={rowData}
            rowIndex={rowIndex}
            disabled={disabled}
            hideTop={hideTop}
        />
    );
}

export default observer(BodyCell);
