import React from 'react';

import { observer } from 'mobx-react-lite';

import { CustomIconName } from 'components/CustomIcon';
import IssueComments from 'components/IssueComments';
import Space from 'components/Space';
import { StripeDotSubtitle } from 'components/Stripe';
import StripeWithSavedState from 'components/StripeWithSavedState/StripeWithSavedState';

function IssuesComments({ issue, closed = false }) {
    if (closed ? issue.closedComments.length === 0 : issue.activeComments.length === 0) {
        return null;
    }

    if (!closed) {
        return <IssueComments comments={issue.activeComments} />;
    }

    return (
        <StripeWithSavedState
            storeKey="QCL"
            icon={CustomIconName.QUESTIONS}
            title="Resolved Questions"
            subTitle={<StripeDotSubtitle>{issue.closedComments.length}</StripeDotSubtitle>}
        >
            <Space height={6} />
            <IssueComments comments={issue.closedComments} />
            <Space height={6} />
        </StripeWithSavedState>
    );
}

export default observer(IssuesComments);
