export const AARRR = 'aarrr';
export const ADD = 'add';
export const AI = 'ai';
export const ALIGNMENT = 'alignment';
export const ALIGN_CRITERIA = 'align-criteria';
export const ALIGN_TOTAL = 'align-total';
export const ALIGN_USERS = 'align-users';
export const ALL = 'all';
export const ANGLE_DOUBLE_UP = 'angle-double-up';
export const ARCHIVE = 'archive';
export const ARROW_ALT_LEFT = 'arrow-alt-left';
export const ARROW_DOWN = 'arrow-down';
export const ARROW_LEFT = 'arrow-left';
export const ARROW_RIGHT = 'arrow-right';
export const ARROW_UP = 'arrow-up';
export const ASANA = 'asana';
export const ATTACH = 'attach';
export const AUTOSUBSCRIBE = 'autosubscribe';
export const BACKUP = 'backup';
export const BLANK = 'blank';
export const BLOCKED_BY = 'blocked-by';
export const BLOCKS = 'blocks';
export const BOARDS = 'boards';
export const BOLD = 'bold';
export const BOLT = 'bolt';
export const BOOK = 'book';
export const BOOKMARK = 'bookmark';
export const BUILDING = 'building';
export const BULLET_LIST = 'bullet-list';
export const BULLHORN = 'bullhorn';
export const CALENDAR = 'calendar';
export const CALENDAR_BLANK = 'calendar-blank';
export const CALENDAR_PLUS = 'calendar-plus';
export const CALENDAR_TICK = 'calendar-tick';
export const CALENDAR_X = 'calendar-x';
export const CARD = 'card';
export const CHANGELOG = 'changelog';
export const CHEVRON_DOWN = 'chevron-down';
export const CHEVRON_RIGHT = 'chevron-right';
export const CHEVRON_UP = 'chevron-up';
export const CIRCLE = 'circle';
export const CLEAR = 'clear';
export const CLICKUP = 'clickup';
export const CODE = 'code';
export const COLLAPSE_ALT = 'collapse-alt';
export const COLLAPSE_LEFT = 'collapse-left';
export const COLLAPSE_RIGHT = 'collapse-right';
export const COLUMNS = 'columns';
export const COMMENT = 'comment';
export const COPY = 'copy';
export const COPY_BUFFER = 'copy-buffer';
export const CRITERIA = 'criteria';
export const CROSS_M = 'cross-m';
export const CSV = 'csv';
export const CUSTOM_FIELD = 'custom_field';
export const DASHBOARD = 'dashboard';
export const DESCRIPTION = 'description';
export const DISCORD = 'discord';
export const DOCUMENT = 'document';
export const DOT = 'DOT';
export const DOTES_H = 'dotes-h';
export const DOWNLOAD = 'download';
export const DOWNVOTE = 'downvote';
export const DRAFT = 'draft';
export const DUCALIS = 'ducalis';
export const EDIT = 'edit';
export const EFFORT = 'effort';
export const ENVELOP = 'envelop';
export const ENVELOP_OFF = 'envelop_off';
export const EVALUATION = 'evaluation';
export const EXCLAMATION_CIRCLE = 'exclamation_circle';
export const EXPAND = 'expand';
export const EXPAND_H = 'expand-h';
export const EXPIRATION = 'expiration';
export const EXTERNAL_LINK = 'external-link';
export const EYE = 'eye';
export const EYE_SLASH = 'eye-slash';
export const FACILITATOR = 'facilitator';
export const FILE_IMPORT = 'file-import';
export const FILTER = 'filter';
export const FOCUS_MODE = 'focus-mode';
export const FOLDER = 'folder';
export const FORMULA = 'formula';
export const FORWARD = 'forward';
export const GITHUB = 'github';
export const GLOBE = 'globe';
export const GOOGLE = 'google';
export const GRAPH = 'graph';
export const HALF_FILLED = 'half-filled';
export const HASH = 'hash';
export const HASHTAG = 'hashtag';
export const HEADER = 'header';
export const HEADER_1 = 'header-1';
export const HEADER_2 = 'header-2';
export const HEADER_3 = 'header-3';
export const HEADER_4 = 'header-4';
export const HEADER_5 = 'header-5';
export const HEADER_6 = 'header-6';
export const HEART = 'heart';
export const HELP = 'help';
export const HELP_ALT = 'help';
export const ICE = 'ice';
export const ID = 'id';
export const IMAGE = 'image';
export const INBOX = 'inbox';
export const INCOGNITO = 'incognito';
export const INFO = 'info';
export const INSTANCE = 'instance';
export const INTEGRATION = 'integration';
export const ITALIC = 'italic';
export const JIRA = 'jira';
export const KAITEN = 'kaiten';
export const KANBAN = 'kanban';
export const KEY = 'key';
export const KEYCLOACK = 'keycloack';
export const LAMP = 'lamp';
export const LAMP_OFF = 'lamp-off';
export const LANGUAGE = 'language';
export const LINE = 'line';
export const LINEAR = 'linear';
export const LINK = 'link';
export const LIST = 'list';
export const LOCKED = 'locked';
export const LOGOUT = 'logout';
export const MAGIC_LINK = 'magic-link';
export const MATRIX = 'matrix';
export const MEMBER = 'member';
export const MENU = 'menu';
export const MERGE = 'merge';
export const MINUS = 'minus';
export const MULTIPLIER = 'multiplier';
export const MOVE = 'move';
export const NOTIFICATIONS = 'notifications';
export const NO_FORMAT = 'no-format';
export const NUMBER_LIST = 'number-list';
export const ORGANIZATION = 'organization';
export const O_GRAPH = 'o-graph';
export const PERCENT = 'percent';
export const PERSONAL = 'personal';
export const PLANE = 'plane';
export const PLAY = 'play';
export const PLUG = 'plug';
export const PLUS = 'plus';
export const PLUS_ALT = 'plus-alt';
export const POKER = 'poker';
export const POSITIVE = 'positive';
export const PRIOPLAN = 'prioplan';
export const PROFILE = 'profile';
export const QUESTIONS = 'questions';
export const RANGE = 'range';
export const REDO = 'redo';
export const REFERENCED = 'referenced';
export const REORDERING = 'reordering';
export const REPORT = 'report';
export const RESET = 'reset';
export const REWIND = 'rewind';
export const RICE = 'rice';
export const ROBOT = 'robot';
export const SAND_CLOCK = 'sand-clock';
export const SCORES = 'scores';
export const SEARCH = 'search';
export const SEARCH_ENGINE = 'search-engine';
export const SERIES = 'series';
export const SETTINGS = 'settings';
export const SETTINGS_FILLED = 'settings-filled';
export const SHARE = 'share';
export const SHIELD = 'shield';
export const SIGNATURE = 'signature';
export const SLACK = 'slack';
export const SORT = 'sort';
export const SORT_DISABLE = 'sort-disable';
export const STAR = 'star';
export const STAR_EMPTY = 'star-empty';
export const STOP = 'stop';
export const STOP_SIGN = 'stop-sign';
export const STRIKE = 'strike';
export const SUBTASK = 'subtask';
export const SUM = 'sum';
export const SYNC_BACK_BROKEN = 'sync_back_broken';
export const SYNC_NO = 'sync_no';
export const SYNC_ON = 'sync_on';
export const SYNC_ONE_WAY = 'sync_one_way';
export const TAGS = 'tags';
export const TEAM = 'team';
export const TEAM_ALT = 'team-alt';
export const TEMPLATES = 'templates';
export const TEXT_LONG = 'text-long';
export const THUMB_UP = 'thumb_up';
export const THUMB_UP_SLASHED = 'thumb_up_slashed';
export const TICK = 'tick';
export const TICK_CIRCLE = 'tick-circle';
export const TOP_PRIORITY = 'top-priority';
export const TRASH = 'trash';
export const TRELLO = 'trello';
export const TYPE = 'type';
export const UNDO = 'undo';
export const UNLINK = 'unlink';
export const UNLOCKED = 'unlocked';
export const UPDATE = 'update';
export const UPGRADE = 'upgrade';
export const UPLOAD = 'upload';
export const UPVOTE = 'upvote';
export const USERS = 'users';
export const USER_PLUS = 'user-plus';
export const USER_MINUS_ROUND = 'user-minus-round';
export const USER_PLUS_ROUND = 'user-plus-round';
export const VALUE = 'value';
export const VARIABLE = 'variable';
export const VIDEO = 'video';
export const VOTING = 'voting';
export const WARNING = 'warning';
export const WEBHOOK = 'zapier';
export const WEIGHT = 'weight';
export const WSJF = 'wsjf';
export const X_CROSS = 'x-cross';
export const YT = 'youtrack';
export const ZAPIER = 'zapier';
