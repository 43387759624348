import { render } from './render';

import AsyncMentionList from '../MentionList/AsyncMentionList';

export default {
    items: () => [],
    allowSpaces: true,
    allowedPrefixes: null,
    render: render(AsyncMentionList),
};
