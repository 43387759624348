import React, { useState } from 'react';

import { Popover } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import useUndoNotification from 'hooks/useUndoNotification';

import { IdeaMenuContent } from 'modules/PublicIdeaStripe/components/IdeaMenu/IdeaMenuContent';

import alertStore from 'store/models/Alert';
import { mainStore } from 'store/models/MainStore';

import { POPOVER_PROPS_DIV } from 'utils/consts';
import logEvent from 'utils/logEvent';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

function IdeaMenu({ idea }) {
    const [open, setOpen] = useState(false);
    const notify = useUndoNotification();

    if (mainStore.currentUser?.isViewer && !idea.allow_voting) return null;

    function unLinkIdea() {
        alertStore.confirm({
            message:
                'Are you sure you want to Unlink Public Idea? The public idea will be disconnected from this issue, but you can find it on the Public Ideas page.',
            onConfirm: () => {
                logEvent('undo the merge voting issue');
                const issue_id = idea.parentIssue?.internal_id;
                idea.unMergeWithIssue().then(() => {
                    notify(
                        <>
                            The Idea was disconnected from this issue, you can find it on the{' '}
                            <a href={idea.internalHref}>Voting page</a>
                        </>,
                        () => idea.mergeWithIssue(issue_id),
                    );
                });
            },
            confirmButtonText: 'Unlink',
        });
    }

    function runAction(type, e) {
        e.stopPropagation();
        switch (type) {
            case 'unlink':
                unLinkIdea();
                break;
            case 'copy':
                idea.copyInternalLink();
                break;
            case 'copy-public':
                idea.copyLink();
                break;
            case 'unpublish':
                idea.update({ allow_voting: false });
                break;
            case 'remove':
                idea.remove();
                break;
            default:
                break;
        }
        setOpen(false);
    }

    return (
        <div className="flex-shrink">
            <Popover
                {...POPOVER_PROPS_DIV}
                usePortal={false}
                isOpen={open}
                minimal
                position="bottom-right"
                content={<IdeaMenuContent onClick={runAction} idea={idea} />}
                onClose={() => setOpen(false)}
            >
                <Button
                    active={open}
                    border
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpen((state) => !state);
                    }}
                    minimal
                    size={24}
                    icon={CustomIconName.DOTES_H}
                />
            </Popover>
        </div>
    );
}

export default observer(IdeaMenu);
