import React from 'react';

import Flex from 'components/Flex';

import styles from './HeaderMeta.module.sass';

export function EmptyHeaderMeta({ dataElm, children }) {
    return (
        <Flex gap={8} className={styles.meta} spaceBetween>
            {dataElm}
            <Flex data-place="actions" gap={4} shrink>
                {children}
            </Flex>
        </Flex>
    );
}
