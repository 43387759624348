import React, { useEffect } from 'react';

import { observer } from 'mobx-react-lite';

import TextAIPreview from 'components/TextAIPreview';

export const NewAnnounceAIRewriteText = observer(({ idea, onConfirm, onCancel, loading, onRepeat, onStop }) => {
    useEffect(() => {
        return () => {
            idea.clearAIText();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TextAIPreview
            title={idea.aiName}
            onRepeat={onRepeat}
            onStop={onStop}
            text={idea.aiText}
            onConfirm={onConfirm}
            onCancel={onCancel}
            loading={loading}
            rewriteButtonText="Rewrite Announcement"
            saveButtonText="Save Announcement"
        />
    );
});
