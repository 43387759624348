import React from 'react';

import { Dialog } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { logEvent } from 'utils';

import useUndoNotification from 'hooks/useUndoNotification';

import { mainStore } from 'store/models/MainStore';

import { Button } from 'components/Button';
import Flex from 'components/Flex';
import Space from 'components/Space';

const VotersListDialogRemoveVoter = ({ setOpenRemoveDialog, openRemoveDialog, setList, ideaId }) => {
    const notify = useUndoNotification();

    const removeSelected = async () => {
        const { user, onlyIdea = false } = openRemoveDialog;
        openRemoveDialog.isRemoved = true;

        setOpenRemoveDialog(false);

        logEvent('Remove From Voters List');

        try {
            await mainStore.activeBoard.deleteVotingEmails([user.id], {
                remove: 1,
                idea: onlyIdea ? ideaId : null,
            });

            setList((state) => state.filter((el) => el.id !== user.id));

            notify(
                onlyIdea
                    ? `Successfully deleted ${user.name}\`s votes`
                    : `Successfully deleted all ideas and votes of ${user.name}`
            );
        } catch (e) {}
    };

    const { user, onlyIdea = false } = openRemoveDialog;

    return (
        <Dialog onClose={() => setOpenRemoveDialog(false)} isOpen={!!openRemoveDialog}>
            <div style={{ padding: 20 }} className="typography">
                {onlyIdea ? (
                    <p>
                        Are you sure you want to delete <strong>{user.name}</strong>'s votes for this idea?
                    </p>
                ) : (
                    <>
                        <p>
                            Are you sure you want to permanently delete the user <strong>{user.name}</strong>?
                            Including:
                        </p>
                        <ul>
                            <li>all his votes</li>
                            <li>all his comments</li>
                            <li>and ideas he has created</li>
                        </ul>
                    </>
                )}
                <br />
                <strong>This action cannot be undone!</strong>
                <Space height={20} />
                <Flex gap={12}>
                    <div className="flex-grow" />
                    <Button border text="Cancel" onClick={() => setOpenRemoveDialog(false)} />
                    <Button border onClick={removeSelected} color="red" text="Delete" />
                </Flex>
            </div>
        </Dialog>
    );
};

export default observer(VotersListDialogRemoveVoter);
