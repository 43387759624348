import { ENTER } from 'utils/consts';
import getKeyCode from 'utils/getKeyCode';

import { focusNext } from './focusNext';
import { getNextSelector } from './getNextSelector';

export const onKeyDown = (rowIndex, columnIndex, focus, setFocus, singleFastInput) => (event) => {
    const isCurrentValue = event.target.value !== '' && singleFastInput && +event.key === +event.target.value;
    const selector = getNextSelector(event, rowIndex, columnIndex, focus, isCurrentValue);
    const el = document.querySelector(selector);

    if (!event.shiftKey) focusNext(event, el);

    singleFastInput && setFocus(false);

    if (!el && !singleFastInput && getKeyCode(event) === ENTER) event.target.blur();
};
