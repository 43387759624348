export default (str) => {
    return (
        str
            // remove html style
            .replace(/style=".+"/gi, '')
            .replace(/<(span)(.+)<\/(span)>/gi, '<p $2</p>')
            // remove long base64
            .replace(/!\[(|.+)\]\(data:image\/.+\)(\{(.+)\})?/gi, '<ImgYt error="base64" />')
            // Attachment
            .replace(/!\[(|.+)\]\((.+?)\)(\{(.+)\})?/gi, '<ImgYt name="$2" alt="$1" $4 />')
    );
};
