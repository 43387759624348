import React from 'react';

import { Popover } from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import { defaultPopoverProps, IS_VOTING_BOARD, POPOVER_PROPS_DIV } from 'utils/consts';

import { GhostNotification } from 'components/AppToaster';
import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';

function EditDateButton({ announce, date, readOnly, children }) {
    if (readOnly) {
        return announce.release_date ? DateTime.fromISO(announce.release_date).toFormat('DD') : 'None';
    }

    function onSelect(date) {
        const release_date = new Date(date).toISOString();
        announce.update({ release_date });
        if (IS_VOTING_BOARD) {
            GhostNotification.show({ message: 'Announcement Date saved', timeout: 3000 });
        }
    }

    const maxDate = new Date();

    return (
        <Popover
            {...POPOVER_PROPS_DIV}
            {...defaultPopoverProps}
            content={
                <DatePicker maxDate={maxDate} canClearSelection={false} onChange={onSelect} value={new Date(date)} />
            }
        >
            {children || (
                <Button
                    border
                    block
                    size={24}
                    text={announce.release_date ? DateTime.fromISO(announce.release_date).toFormat('DD') : 'None'}
                    rightElm={<CustomIcon icon={CustomIconName.CHEVRON_DOWN} size={14} />}
                />
            )}
        </Popover>
    );
}

export default observer(EditDateButton);
