import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Select } from '@blueprintjs/select';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react-lite';

import useUndoNotification from 'hooks/useUndoNotification';

import { itemRenderer } from 'modules/PublicIdeaStripe/components/SingleVotingActions/itemRenderer';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { PAYWALL_LIMITS } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import DarkTooltip from 'components/DarkTooltip';
import Flex from 'components/Flex';
import UpgradePlanBtn from 'components/UpgradePlanBtn';
import VirtuosoList from 'components/VirtuosoList';

import AddTrackerButton from './AddTrackerButton';

function activeItemIndex(list, active) {
    return list.findIndex(({ id }) => id === active?.id);
}

export const MergeBtn = observer(() => {
    const showPayWall = !mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.VOTING_ADD_TO_BACKLOG);
    const abortControllerRef = useRef(new AbortController());
    const notify = useUndoNotification();
    const [query, setQuery] = useState('');
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(null);

    useEffect(() => {}, []);

    async function onQueryChange(query) {
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();

        setLoading(true);

        const data = await mainStore.activeBoard.suggestIssue(query, abortControllerRef.current);

        if (data) {
            setLoading(false);
            setList(data);
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onQueryChangeDebounced = useCallback(
        debounce((query) => onQueryChange(query), 400),
        [],
    );

    const btn = (
        <Button
            icon={CustomIconName.LINK}
            color="primary"
            size={24}
            disabled={showPayWall || mainStore.currentUser?.isViewer}
            text="Link"
            border
            rightElm={<CustomIcon icon={CustomIconName.CHEVRON_DOWN} size={14} />}
        />
    );

    if (mainStore.currentUser?.isViewer) {
        return <DarkTooltip content="Viewer can't link Idea to Backlog">{btn}</DarkTooltip>;
    }

    if (showPayWall) {
        return (
            <UpgradePlanBtn
                paywall={PAYWALL_LIMITS.VOTING_ADD_TO_BACKLOG}
                text="to unlock this feature."
                helpArticleText="Add to Backlog"
                helpArticle="voting-board/#creating-a-public-version-of-your-backlog"
            >
                {btn}
            </UpgradePlanBtn>
        );
    }
    async function onItemSelect(item) {
        try {
            setSelected(item.id);
            const name = issuesList.idea.name;
            const idea = await issuesList.idea.mergeWithIssue(item.id);
            const text = item.key ? `${item.key}: ${item.name}` : item.name;

            if (idea.parent_id) {
                issuesList.setActiveVotingIssueById(idea.parent_id);
            }

            setSelected(null);
            setList([]);

            notify(
                <>
                    Idea <b>{name}</b> was linked with issue <b>{text}</b>
                </>,
            );
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <Select
            popoverProps={{
                position: 'bottom-right',
                minimal: true,
                popoverClassName: 'select-400',
                onClosed: () => abortControllerRef.current?.abort(),
            }}
            filterable
            items={list}
            itemDisabled={(item) => item.id === selected}
            itemRenderer={itemRenderer}
            onItemSelect={onItemSelect}
            query={query}
            onQueryChange={(query, event) => {
                if (!!event) return;
                setQuery(query);
                onQueryChangeDebounced(query);
            }}
            itemListRenderer={(props) => (
                <>
                    <VirtuosoList
                        width={388}
                        noResults={
                            <Button
                                minimal
                                block
                                disabled={true}
                                text={!query ? 'Start typing for suggestions' : 'No results.'}
                            />
                        }
                        className="hide-v-scroll"
                        {...props}
                        sizeItem={30}
                        activeItemIndex={activeItemIndex}
                    />
                    <AddTrackerButton boardId={mainStore.activeBoard?.id} />
                </>
            )}
            inputProps={{
                className: 'custom-select-clear',
                leftIcon: <CustomIcon icon={CustomIconName.SEARCH} />,
                rightElement:
                    query.length > 0 ? (
                        <Flex center style={{ height: 30, width: 30 }}>
                            <Button
                                loading={loading}
                                icon={CustomIconName.CROSS_M}
                                minimal
                                size={24}
                                border
                                onClick={() => setQuery('')}
                            />
                        </Flex>
                    ) : undefined,
                placeholder: 'Search issue...',
            }}
        >
            <DarkTooltip
                position="top-right"
                content="Choose the existing issue from your task tracker backlog to link this idea with"
            >
                {btn}
            </DarkTooltip>
        </Select>
    );
});
