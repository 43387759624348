import React from 'react';

import { Spinner } from '@blueprintjs/core';

export default () => (
    <div className="t-c">
        <br />
        <br />
        <br />
        <Spinner />
    </div>
);
