import React from 'react';

import { Position, OverlayToaster as Toaster } from '@blueprintjs/core';

import { sendToSentry } from 'utils';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { APP_STORAGE_KEYS } from 'utils/consts';
import appStorage from 'utils/LsHelper';

/** Singleton toaster instance. Create separate instances for different options. */
export const AppToaster = Toaster.create({
    position: Position.TOP,
});

function fakeBtnLinkHandler(e) {
    try {
        const model = issuesList.activeIssue?.board || mainStore.activeBoard;

        if (!model) return;

        switch (e.target.className) {
            case 'js-action--sync-now':
                e.preventDefault();
                model.platformSync.runSync({ withSync: true });
                break;

            case 'js-action--auth':
                e.preventDefault();
                appStorage.set(APP_STORAGE_KEYS.board_sync, model.id);
                const WRITE_AUTH_URL = model.platform._links.auth_url;
                window.location.href = `${WRITE_AUTH_URL}&redirect_url=${window.location.href}`;
                break;

            case 'js-action--update-board':
                e.preventDefault();
                model.platformSync.runSync({ withSync: false });
                break;

            default:
                break;
        }
    } catch (error) {
        sendToSentry('Fail fakeBtnLinkHandler', error);
    }
}

export const showToaster = (props) => {
    props.message = <div onClick={fakeBtnLinkHandler} dangerouslySetInnerHTML={{ __html: props.message }} />;
    AppToaster.show(props);
};

export const errorSingleNotification = Toaster.create({
    maxToasts: 1,
});

export const GhostNotification = Toaster.create({
    className: 'simple-bottom-notification',
    maxToasts: 1,
});

export const UpdateNotification = Toaster.create({
    position: Position.TOP,
    className: 'hide-cross success-action',
    autoFocus: true,
    canEscapeKeyClear: false,
    maxToasts: 1,
});
