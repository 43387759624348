import React from 'react';

import { Spinner } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite';

import { StCardStripe, StCardStripeHeader, StCardStripeOpacityElement } from 'atoms/StCardStripe';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';

function CreateAnnounceButton({ disabled, onClick, needLoader, useAI, className }) {
    const loaderAI = needLoader && disabled;

    return (
        <StCardStripe role="button" className="interactive" disabled={disabled} onClick={onClick}>
            <StCardStripeHeader as={Flex} gap={8}>
                {loaderAI ? <Spinner size={18} /> : <CustomIcon icon={CustomIconName.CHANGELOG} />}
                <div>Announcement</div>
                <StCardStripeOpacityElement as={Flex} grow>
                    <div className={className}>
                        {useAI ? 'Write with AI for future Release Note' : 'Write manually for future Release Note'}
                    </div>
                </StCardStripeOpacityElement>
            </StCardStripeHeader>
        </StCardStripe>
    );
}

export default observer(CreateAnnounceButton);
