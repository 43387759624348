import { useIntl } from 'react-intl';

import alertStore from 'store/models/Alert';
import { issuesList } from 'store/models/IssuesList';
import { utilsStore } from 'store/models/UtilsStore';

import { UNSAVED_MODEL_ID } from 'utils/consts';

export default function useCloseIdeaEditMode(issue) {
    const intl = useIntl();

    function handleCloseClick() {
        if (!issue.onEdit && issue.id !== UNSAVED_MODEL_ID) {
            return utilsStore.toggleCardHidden(true);
        }

        if (
            issue.backupData &&
            issue.backupData.name === issue.name &&
            issue.backupData.description === issue.description
        ) {
            return closeEditMode();
        }

        if (issue.id === UNSAVED_MODEL_ID && !issue.name && !issue.description) {
            return closeEditMode();
        }

        const message = intl.formatMessage({
            id: 'alert.close-idea',
            defaultMessage: 'Unsaved changes will be lost. Are you sure you want to close?',
        });
        const cancelButtonText = intl.formatMessage({
            id: 'btn.cancel',
            defaultMessage: 'Cancel',
        });
        const confirmButtonText = intl.formatMessage({
            id: 'btn.close',
            defaultMessage: 'Close',
        });

        alertStore.confirm({
            message,
            cancelButtonText,
            confirmButtonText,
            onConfirm: () => {
                closeEditMode();
            },
        });
    }

    function closeEditMode() {
        if (issue.id === UNSAVED_MODEL_ID) {
            issuesList.setActiveIssue(null);
            utilsStore.toggleCardHidden(true);
        } else {
            issue.toggleEditMode();
            issue.restoreFromBackup();
        }
    }

    return handleCloseClick;
}
