import React from 'react';

import { observer } from 'mobx-react-lite';

import FIELD_TYPE from 'constants/FieldType';

import columnsStore from 'store/models/Columns';

import CellWrapper from 'components/CellWrapper';
import DraggableDiv from 'components/DraggableDiv/DraggableDiv';
import { ColumnIcon } from 'components/MasterTable/components/HeaderCell/ColumnIcon';
import Tooltip from 'components/Tooltip';

import ColumnDropDown from '../ColumnDropDown';
import CriteriaHeaderCell from '../CriteriaHeaderCell';

const HeaderLabel = ({ tooltip, label }) => {
    if (tooltip) {
        return (
            <Tooltip content={tooltip}>
                <div className="ReactVirtualized__Table__headerTruncatedText">{label}</div>
            </Tooltip>
        );
    }
    return <div className="ReactVirtualized__Table__headerTruncatedText">{label}</div>;
};

function HeaderCell({ columnData, resizeRow, showTooltips, index, ...props }) {
    let title;
    const column = columnsStore.getColumnById(props.dataKey);
    if (columnData.fieldType === FIELD_TYPE.criterion) {
        title = <CriteriaHeaderCell column={column || columnData} showTooltips={showTooltips} {...props} />;
    } else {
        const name = columnData.name || column?.name;
        title = (
            <CellWrapper
                fill={columnData.fill}
                className={columnData.className}
                button={<ColumnDropDown field={column || columnData} disableSort={props.disableSort} {...props} />}
            >
                <ColumnIcon
                    label={name}
                    isAlignment={column?.fieldType === FIELD_TYPE.alignment}
                    votingField={column?.votingField}
                    icon={columnData.icon}
                />
                <HeaderLabel label={name} tooltip={columnData.tooltip} />
            </CellWrapper>
        );
    }

    return (
        <React.Fragment key={index}>
            {title}
            {!columnData.fixSize && <DraggableDiv onDrag={(deltaX) => resizeRow({ col: index, deltaX })} slim />}
        </React.Fragment>
    );
}

export default observer(HeaderCell);
