import Axios from 'axios';
import { runInAction } from 'mobx';

import { storageApi } from 'api';
import { failRequest } from 'utils';

import { issuesList } from 'store/models/IssuesList';
import { getPages } from 'store/models/utils/getUrlByPage';

import debugLog from 'utils/debugLog';

export async function fetchIdeas() {
    try {
        const boards =
            this.boardsList.activeBoardsWithAccess?.filter((board) => board.isVoting)?.map((board) => board.id) || [];

        const responses = await Axios.all(boards.map((board) => storageApi.get('/ideas', { params: { board } })));
        const list = responses.map(({ data }) => data).flat();

        const pagesRequests = boards.reduce((res, boardId, index) => {
            const headers = responses[index]?.headers;
            const pages = getPages({ headers });
            pages.forEach((page) => res.push({ board: boardId, page }));
            return res;
        }, []);

        if (pagesRequests.length) {
            const responsesPages = await Axios.all(pagesRequests.map((params) => storageApi.get('ideas', { params })));

            const ideasList = responsesPages.map(({ data }) => data).flat();
            list.push(...ideasList);
        }

        process.env.REACT_APP_ENV !== 'prod' && debugLog('Before issuesList.fillIdeas');

        issuesList.fillIdeas(list, boards);
        runInAction(() => {
            this.readyIdeas = true;
        });
        await this.db.saveList(list, 'ideas');
    } catch (error) {
        failRequest(error);
    }
}
