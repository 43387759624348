import React from 'react';

import { observer } from 'mobx-react-lite';

import useVotingAuthor from 'hooks/useVotingAuthor';

import { mainStore } from 'store/models/MainStore';

import { IS_PUBLIC_BOARD } from 'utils/consts';

import Avatar from 'components/Avatar';
import Flex from 'components/Flex';

import IdeaAuthorSuggest from './IdeaAuthorSuggest';

import styles from './IdeaAuthor.module.sass';

function IdeaAuthor({ idea }) {
    const desc = useVotingAuthor(idea.author, undefined, true);

    if (!idea.author) {
        return null;
    }

    if (!IS_PUBLIC_BOARD && mainStore.currentUser.isAdmin) {
        return <IdeaAuthorSuggest idea={idea} desc={desc} />;
    }

    return (
        <Flex gap={8} itemProp="author" itemType="https://schema.org/Person" itemScope>
            <Avatar size={28} user={idea.author} />
            <Flex gap="0 4px" className={styles.meta} wrap itemProp="name">
                {desc}
            </Flex>
        </Flex>
    );
}

export default observer(IdeaAuthor);
