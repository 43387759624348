import { makeAutoObservable } from 'mobx';

import { VIEWS } from 'utils/consts';

import { mainStore } from './MainStore';
import { parseUserVotes } from './utils';

function parseIssueVotes(votes) {
    if (!votes) return null;

    return votes.split(',').map((el) => {
        const [criterion_id, value] = el.split(':').map((el) => Number(el));
        return { criterion_id, value };
    });
}

export default class IssuesData {
    /** @type {number} */
    id = 0;

    /** @type {null|number} */
    alignment = null;

    /** @type {null|number} */
    usersAlignment = null;

    /** @type {Map<number, number>} */
    criteriaAlignmentIds = new Map();

    /** @type {Map<number, number>} */
    usersAlignmentIds = new Map();

    /** @type {boolean} */
    all_voted = false;

    /** @type {null|number} */
    total = null;

    /** @type {Array} */
    votes = [];

    /** @type {null|number} */
    voting_percent = 0;

    /** @type {string|number} */
    boardId = 0;

    /** @type {boolean} */
    unvoted = true;

    /** @type {boolean} */
    skipped = false;

    /** @type {boolean} */
    isUserNotAllVoted = false;

    /** @type {Map<number, number>} */
    userVotes = new Map();

    /** @type {Map<number, number>} */
    cr_weightless = new Map();

    /** @type {Map<number, number>} */
    custom_votes = new Map();

    /** @type {Map<number, number>} */
    votesUsersId = new Map();

    constructor(data) {
        data && this.fillModel(data, true);

        makeAutoObservable(this);
    }

    fillModel({ cr_weightless, custom_votes, alignment, users_alignment, ...data }, force) {
        data && Object.assign(this, data);

        if (custom_votes) {
            this.custom_votes.clear();
            (custom_votes || []).forEach((item) => {
                this.custom_votes.set(item.criterion_id, item);
            });
        }

        if (cr_weightless !== undefined) {
            this.cr_weightless.clear();
            (cr_weightless || []).forEach((item) => {
                this.cr_weightless.set(item.criterion_id, item.value);
            });
        }

        if (alignment !== undefined) {
            this.alignment = alignment?.value ?? null;
            this.criteriaAlignmentIds.clear();
            (alignment?.data || []).forEach((item) => {
                this.criteriaAlignmentIds.set(item.criterion_id, item.value);
            });
        }

        if (users_alignment !== undefined) {
            this.usersAlignmentIds.clear();
            this.usersAlignment = users_alignment?.value ?? null;
            (users_alignment?.data || []).forEach((item) => {
                this.usersAlignmentIds.set(item.user_id, item.value);
            });
        }

        this.votesUsersId.clear();
        (data.votes || []).forEach((el) => this.votesUsersId.set(el.user_id, parseIssueVotes(el.votes)));

        if (
            force ||
            document.visibilityState === 'hidden' ||
            ![VIEWS.EVALUATION, VIEWS.SCORES, VIEWS.FOCUS_MODE].includes(mainStore.page)
        ) {
            const { unvoted = true, skipped = false } = parseUserVotes(data.votes, this.userVotes);
            this.unvoted = unvoted;
            this.skipped = skipped;
            this.isUserNotAllVoted = unvoted;
        }
    }

    changeVote({ value, criterion }) {
        if (value === '') {
            this.userVotes.delete(criterion.id);
        } else {
            this.userVotes.set(criterion.id, value);
        }
    }
}
