import React from 'react';

export default function Members({ membersList }) {
    if (!membersList) {
        return null;
    }

    const content =
        membersList.length === 0 ? '-' : membersList.map((member) => member.name || member.login).join(', ');

    return (
        <div className="bp5-text-overflow-ellipsis" title={content}>
            {content}
        </div>
    );
}
