import { styled } from 'stitches.config';

export const StAlignment = styled('div', {
    variants: {
        cell: {
            true: {
                width: '100%',
                flexGrow: 1,
                textAlign: 'right',
                padding: '0 8px 0 4px',
                color: '$grayA9',
                display: 'block',
                height: 31,
                lineHeight: '31px',
                margin: '0 1px 1px 0',
            },
        },
        label: { true: { borderRadius: 4, padding: '0 4px', fontSize: 12, fontWeight: 400 } },
        danger: { true: { color: '$tc1', background: '$danger' } },
        warning: { true: { color: '$tc1', background: '$warning' } },
    },
});
