import React from 'react';

import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';
import { mainStore } from 'store/models/MainStore';

import { StHr } from 'atoms/StHr';

import { IS_PUBLIC_BOARD, PAYWALL_LIMITS } from 'utils/consts';

import Flex from 'components/Flex';

import { BlocksButtons } from './BlocksButtons';
import MoveActions from './MoveActions';
import { WatchContextMenuButton } from './WatchContextMenuButton';

import CopyLinkBtn from '../CopyLinkBtn';
import { RemoveBtn } from '../index';
import PlatformAction from '../PlatformAction';

export const IssueMenuContent = observer(() => {
    const issue = issuesList.issue;

    return (
        <Flex column align="none" css={{ width: 252, padding: 4 }} gap={4}>
            <PlatformAction issue={issue} />
            <CopyLinkBtn issue={issue} />
            <WatchContextMenuButton issue={issue} />
            {issue.isEditable && <MoveActions issue={issue} />}
            {!IS_PUBLIC_BOARD && !mainStore.currentUser?.isViewer && <StHr />}
            {!IS_PUBLIC_BOARD && !mainStore.currentUser?.isViewer && (
                <BlocksButtons
                    showPaywall={!mainStore.organization.hasPaymentPlan(PAYWALL_LIMITS.ISSUE_DEPENDENCIES)}
                />
            )}
            {issue.isEditable && <StHr />}
            {issue.isEditable && <RemoveBtn issue={issue} />}
        </Flex>
    );
});
