import React from 'react';

import { FormattedMessage } from 'react-intl';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Kbd from 'components/Kbd';
import Space from 'components/Space';

export default function VotesCountTooltip({ votesLeft, multiple }) {
    return (
        <>
            <p>
                {multiple ? (
                    <FormattedMessage
                        id="voting_issue.votes_left"
                        defaultMessage="{votesLeft, plural, one {# vote} other {# votes}} left for the Idea"
                        values={{ votesLeft: votesLeft < 0 ? 0 : votesLeft }}
                    />
                ) : votesLeft > 0 ? (
                    <FormattedMessage id="voting_issue.upvote" defaultMessage="Upvote" />
                ) : (
                    <FormattedMessage id="voting_issue.downvote" defaultMessage="Downvote" />
                )}
            </p>
            <div className="flex flex-a-center gap-2">
                <CustomIcon icon={CustomIconName.UPVOTE} /> <Kbd>q</Kbd>
                <Space width={8} />
                <CustomIcon icon={CustomIconName.DOWNVOTE} /> <Kbd>w</Kbd>
            </div>
        </>
    );
}
