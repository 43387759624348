import { mainStore } from 'store/models/MainStore';

import queryInText from 'utils/queryInText';

import { render } from './render';

import MentionList from '../MentionList';

export default {
    items: ({ query }) => {
        const filteredUsers = !query
            ? mainStore.users.activeUsers
            : mainStore.users.activeUsers.filter((item) => queryInText(item.name || item.email, query));
        return (filteredUsers.length > 0 && filteredUsers) || [];
    },
    allowSpaces: true,
    allowedPrefixes: null,
    render: render(MentionList),
};
