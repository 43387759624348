import React from 'react';

import { IssueOmnibarContext } from 'modules/IssueCard/IssueOmnibarContext';

import { PAYWALL_LIMITS } from 'utils/consts';

import { Button } from 'components/Button';
import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Kbd, { KeyCombo } from 'components/Kbd';
import UpgradePlanBtn from 'components/UpgradePlanBtn';

const blocksActions = [
    { id: 2, text: 'Mark as blocking', icon: CustomIconName.BLOCKS, hotkey: 'X' },
    { id: 1, text: 'Mark as blocked by', icon: CustomIconName.BLOCKED_BY, hotkey: 'B' },
    { id: 3, text: 'Relates to', icon: CustomIconName.REFERENCED, hotkey: 'L' },
];

export const BlocksButtons = ({ showPaywall }) => {
    if (showPaywall) {
        return blocksActions.map((btn) => (
            <UpgradePlanBtn paywall={PAYWALL_LIMITS.ISSUE_DEPENDENCIES}>
                <Button
                    css={{ cursor: 'not-allowed' }}
                    key={btn.id}
                    as="div"
                    block
                    className="bp5-popover-dismiss"
                    minimal
                    text={btn.text}
                    icon={btn.icon}
                    rightElm={<CustomIcon icon={CustomIconName.UPGRADE} className="t-intent-yellow" />}
                />
            </UpgradePlanBtn>
        ));
    }
    return (
        <IssueOmnibarContext.Consumer>
            {({ setOpenBLocks }) =>
                blocksActions.map((btn) => (
                    <Button
                        key={btn.id}
                        block
                        className="bp5-popover-dismiss"
                        minimal
                        text={btn.text}
                        icon={btn.icon}
                        onClick={() => setOpenBLocks(btn.id)}
                        rightElm={
                            <KeyCombo>
                                <Kbd>{btn.hotkey}</Kbd>
                            </KeyCombo>
                        }
                    />
                ))
            }
        </IssueOmnibarContext.Consumer>
    );
};
