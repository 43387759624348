import { showToaster } from 'components/AppToaster';

export default function failRequest(error) {
    if (!error?.response || [503, 401].includes(error.response.status)) {
        return;
    }

    const { data, status } = error.response;

    let message;

    if ([413].includes(status)) {
        message = 'The file is too large and cannot be uploaded. Please reduce the size of the file and try again';
    } else if (Array.isArray(data)) {
        message = data.map((e) => e.message).join(' ');
    } else if (typeof data === 'string') {
        message = data;
    } else {
        message = data?.message || error?.message || 'Some Error';
    }

    try {
        showToaster({ message, intent: 'danger', timeout: 7000 });
    } catch (e) {}
    console.error('ERROR:', error);
}
