import React from 'react';

import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';

import { SHORT_RELATIVE_TIME_OPTIONS, UNSAVED_MODEL_ID } from 'utils/consts';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import MetaItem from 'components/MetaItem';
import { UserView } from 'components/UserBlock/UserView';

import EditDateButton from './EditDateButton';

import { mainStore } from '../../store/models/MainStore';

function AnnounceFooter({ announce }) {
    if (!announce?.id || announce?.id === UNSAVED_MODEL_ID) return null;

    return (
        <Flex gap={24} style={{ marginTop: 8 }}>
            <MetaItem title="Created">
                <Flex gap={4} className="t-mutted">
                    <div className="t-crop">
                        {DateTime.fromISO(announce.created).toRelative(SHORT_RELATIVE_TIME_OPTIONS)}
                    </div>
                    <div className="t-crop">
                        {announce.author ? (
                            <UserView user={announce.author} size={16} />
                        ) : (
                            <>
                                <CustomIcon size={16} icon={CustomIconName.AI} /> AI
                            </>
                        )}
                    </div>
                </Flex>
            </MetaItem>
            {!!announce.updater && (
                <MetaItem title="Updated">
                    <Flex gap={4} className="t-mutted">
                        <div className="t-crop">
                            {DateTime.fromISO(announce.updated).toRelative(SHORT_RELATIVE_TIME_OPTIONS)}
                        </div>
                        <div className="t-crop">
                            {announce.updater ? <UserView user={announce.updater} size={16} /> : '...'}
                        </div>
                    </Flex>
                </MetaItem>
            )}

            {announce.isPublished ? (
                <MetaItem
                    title="Published"
                    tooltip="By making this change, you will alter the placement of the announcement and idea on the changelog and voting board."
                >
                    <EditDateButton
                        readOnly={mainStore.currentUser?.isViewer}
                        date={announce.release_date}
                        announce={announce}
                    />
                </MetaItem>
            ) : null}
        </Flex>
    );
}

export default observer(AnnounceFooter);
