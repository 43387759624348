import { useEffect, useImperativeHandle, useState } from 'react';

export function useMentionListNavigator(items, command, ref) {
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => setSelectedIndex(0), [items]);

    const selectItem = (index) => {
        const item = items[index];

        if (item) {
            command(item);
        }
    };

    const upHandler = () => {
        setSelectedIndex((selectedIndex + items.length - 1) % items.length);
    };

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1) % items.length);
    };

    const enterHandler = () => {
        selectItem(selectedIndex);
    };

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }) => {
            if (event.key === 'ArrowUp') {
                upHandler();
                return true;
            }

            if (event.key === 'ArrowDown') {
                downHandler();
                return true;
            }

            if (event.key === 'Enter') {
                enterHandler();
                return true;
            }

            return false;
        },
    }));

    return { selectedIndex, selectItem, setSelectedIndex };
}
