import React from 'react';

import { observer } from 'mobx-react-lite';

import { issuesList } from 'store/models/IssuesList';
import modelCopyMoveStore, { DIALOG_STATUS } from 'store/models/modelCopyMoveStore';

import { StHr } from 'atoms/StHr';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';
import Kbd, { KeyCombo } from 'components/Kbd';

const MoveActions = () => {
    return (
        <>
            <StHr />
            <Button
                block
                className="bp5-popover-dismiss"
                minimal
                icon={CustomIconName.COPY}
                onClick={() =>
                    modelCopyMoveStore.openDialog({ dialogType: DIALOG_STATUS.copyIssue, model: issuesList.issue })
                }
                text="Copy issue"
                rightElm={
                    <KeyCombo>
                        <Kbd>C</Kbd>
                    </KeyCombo>
                }
            />
            <Button
                block
                className="bp5-popover-dismiss"
                minimal
                icon={CustomIconName.MOVE}
                onClick={() =>
                    modelCopyMoveStore.openDialog({ dialogType: DIALOG_STATUS.moveIssue, model: issuesList.issue })
                }
                text="Move issue"
                rightElm={
                    <KeyCombo>
                        <Kbd>M</Kbd>
                    </KeyCombo>
                }
            />
        </>
    );
};

export default observer(MoveActions);
