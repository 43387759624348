import React from 'react';

import { observer } from 'mobx-react-lite';

import useCloseIdeaEditMode from 'hooks/useCloseIdeaEditMode';

import { issuesList } from 'store/models/IssuesList';

import { Button } from 'components/Button';
import { CustomIconName } from 'components/CustomIcon';

function CloseEditModeButton() {
    const handleCloseClick = useCloseIdeaEditMode(issuesList.idea);

    return <Button data-test="close-btn" icon={CustomIconName.X_CROSS} border onClick={handleCloseClick} />;
}

export default observer(CloseEditModeButton);
